import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';
import { PageLoader } from 'new-ui';

import { getText } from '../../../../../../../i18n';

import { useStores } from '../../../../../../bi/context';
import { MOBX_STORES } from '../../../../../../bi/context/stores';

import ChartsAnalytics from '../../../../../../bi/services/chartsAnalytics';

import SERVICETYPE from '../../../../../../bi/constants/serviceType';

import { CommonWithSubSections } from './commonWithSubSections';

import { MainAnalytic } from '../../../../../../bi/utils/analytics';
import { TYPE_SMART, TYPE_SMART_SERVICE_TYPE } from '../../../../../../bi/constants/chartsAnalytics';

const LABELS = {
  TOOLTIP: getText('chartsAnalytics:menuItems.smart.indexChart.tooltip.air.one'),
};

const AirPageSubSections = observer(({ chartsAnalyticsService, path }: { chartsAnalyticsService: ChartsAnalytics, path: string }) => {
  const {
    chartsAnalyticsSmartSummaryStore,
    chartsAnalyticsSmartSummaryStore: {
      loading,
    },
    chartsAnalyticsStore: {
      filtersType,
    },
  } = useStores([
    MOBX_STORES.CHARTS_ANALYTICS_STORE,
    MOBX_STORES.CHARTS_ANALYTICS_SMART_SUMMARY_STORE,
  ]);

  const {
    getSmartAirlinePage,
  } = chartsAnalyticsService;

  useEffect(() => {
    getSmartAirlinePage();
  }, [getSmartAirlinePage, filtersType]);

  const history = useHistory();

  if (loading) {
    return <PageLoader />;
  }

  const handleOpen = ({ type, label }: { type: string, label: string }) => {
    const analyticType = ({
      [TYPE_SMART_SERVICE_TYPE.TIMELINESS]: MainAnalytic.ACTIONS.ANALYTICS.SPENDING_AIRLINE_ADVANCE_PAYMENT_TICKET_OPENED,
      [TYPE_SMART_SERVICE_TYPE.RETURNS]: MainAnalytic.ACTIONS.ANALYTICS.SPENDING_AIRLINE_TICKET_RETURNS_OPENED,
      [TYPE_SMART_SERVICE_TYPE.TRAVEL_POLICIES_USING]: MainAnalytic.ACTIONS.ANALYTICS.SPENDING_AIRLINE_TRAVEL_APPROVAL_OPENED,
      [TYPE_SMART_SERVICE_TYPE.CONTRACT_3D_USING]: MainAnalytic.ACTIONS.ANALYTICS.SPENDING_AIRLINE_CORPORATE_PROGRAM_OPENED,
      [TYPE_SMART_SERVICE_TYPE.TRAVELLER_BONUS_USING]: MainAnalytic.ACTIONS.ANALYTICS.SPENDING_AIRLINE_PERSONAL_BONUSES_OPENED,
    })[type];

    MainAnalytic.sendAmplitude(analyticType);
    chartsAnalyticsService.changeSmartType(SERVICETYPE.AIR);
    chartsAnalyticsService.setSmartSubType(type);
    chartsAnalyticsService.changeSmartLabel(label);

    return history.push(path);
  };

  return (
    <CommonWithSubSections
      typeStore={ chartsAnalyticsSmartSummaryStore }
      onPageChange={ handleOpen }
      tooltip={ LABELS.TOOLTIP }
      type={ TYPE_SMART.AIR }
    />
  );
});

export { AirPageSubSections };
