import React from 'react';
import { observer } from 'mobx-react';

import { Text } from 'new-ui';
import { getText } from '../../../../../../i18n';

import { RightsDialogWrap } from '../RightsDialogWrap';
import { ListRightsTexts } from '../../ListRightsText';

import { convertAvailableRightsToText } from '../../../../../bi/utils/rights';
import { isSmartAgent } from '../../../../../bi/utils/env';

import { QA_ATTRIBUTES } from '../../../../../bi/constants/attributesForTests';

import {
  IApprovalShemas,
  IApprovalShemasERA,
  UsersType,
  UsersTypeLigth,
  IStringData,
} from '../../../../../bi/services/employee/types';

const LABELS = {
  SUBTITLE: (empl: string) => getText('settings:employees.employee.steps.rights.steps.copy.subtitle', { empl }),
};

interface ICopyStep {
  email: string,
  employee: string,
  active: string,
  header: string,
  rights: {
    buyTripAccount: boolean,
    buyTravelPolicy: boolean,
    buyAfterApprove: boolean,
    buyApprovalScheme: boolean,
    approvers: string[],
    createERAfterApprove: boolean,
    createERApprovalScheme: boolean,
  }
  travelPolicy?: UsersTypeLigth,
  approvalScheme: number,
  approvalSchemes: IApprovalShemasERA[],
  approvalERSchemes: IApprovalShemas[],
  approveERUsers: UsersType[],
  viewExpenseReportApprovers: string[],
  approvalERScheme: number,
  allApproveUsers: IStringData[],
  onClose(): void,
  onSave(): void,
}

const DEF_TP = {
  label: '',
  value: '',
};

const CopyStep = observer(({
  email,
  employee,
  active,
  header,
  rights,
  travelPolicy = DEF_TP,
  approvalScheme,
  approvalSchemes,
  approvalERSchemes,
  approveERUsers,
  viewExpenseReportApprovers,
  approvalERScheme,
  allApproveUsers,
  onClose,
  onSave,
}: ICopyStep) => {
  const {
    buyTripAccount,
    buyTravelPolicy,
    buyAfterApprove,
    buyApprovalScheme,
    approvers,
    createERAfterApprove,
    createERApprovalScheme,
  } = rights;

  const preparedRights = {
    ...rights,
    isSmartAgent,
  };

  return (
    <RightsDialogWrap
      header={ header }
      theme={ active }
      onClickButton={ onSave }
      onClickCancel={ onClose }
      qaAttrHeader={ QA_ATTRIBUTES.employee.documents.access.modals.copy.title }
      qaAttrButton={ QA_ATTRIBUTES.employee.documents.access.modals.copy.buttonDone }
    >
      <Text>
        { LABELS.SUBTITLE(employee) }
      </Text>
      <ListRightsTexts
        // @ts-ignore
        rights={ convertAvailableRightsToText(preparedRights) }
        email={ email }
        buyTripAccount={ buyTripAccount }
        buyTravelPolicy={ buyTravelPolicy }
        buyAfterApprove={ buyAfterApprove }
        buyApprovalScheme={ buyApprovalScheme }
        approvalScheme={ approvalScheme }
        travelPolicy={ travelPolicy }
        approveUsers={ approvers }
        allApproveUsers={ allApproveUsers }
        approveERUsers={ approveERUsers }
        approvalSchemes={ approvalSchemes }
        viewExpenseReportApprovers={ viewExpenseReportApprovers }
        chosenApprovalERScheme={ approvalERScheme }
        approvalERSchemes={ approvalERSchemes }
        createERAfterApprove={ createERAfterApprove }
        createERApprovalScheme={ createERApprovalScheme }
      />
    </RightsDialogWrap>
  );
});

export { CopyStep };
