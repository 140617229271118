import React, { FC } from 'react';

import { Icon, Input, Text, Tooltip } from 'new-ui';
import { getText } from '../../../../../../../i18n';

import { FieldLabel } from '../../../../../../components/FieldLabel';
import { FormWrapper } from '../../../../../../components/FormWrapper';

import DOCUMENTSFIELDS from '../../../../../../bi/constants/documentsFields';
import PASSPORTS from '../../../../../../bi/constants/passport';
import { IDS_FOR_INTEGRATION_TESTS } from '../../../../../../bi/constants/idsForInregrationTests';
import { QA_ATTRIBUTES } from '../../../../../../bi/constants/attributesForTests';

import styles from '../index.module.css';

interface ErrorInterface {
  Surname: string;
  Name: string;
  Patronymic: string;
  Number: string;
}

interface DocumentInterface {
  Surname: string;
  Name: string;
  Patronymic: string;
  Number: string;
}

interface BirthCertificateProps {
  error: ErrorInterface;
  document: DocumentInterface;
  disabled: boolean;
  disabledFieldsNamesChangeAirTrip: boolean;
  onResetErrors(field1: string, field2?: string): void;
  onValidationInput(field1: string, field2?: string): void;
  onInputChange(value: string, field: string): void;
  onBlurInput(value: string, field: string): void;
  onCertificateNumberChange(field: string): void;
}

const LABELS = {
  SURNAME: getText('settings:employees.employee.steps.documents.surname'),
  NAME: getText('settings:employees.employee.steps.documents.name'),
  NUMBER: getText('settings:employees.employee.steps.documents.birthCertificate.number'),
  PATRONYMIC: getText('settings:employees.employee.steps.documents.birthCertificate.patronymic'),
  TOOLTIP: {
    ONE: getText('settings:employees.employee.steps.documents.birthCertificate.tooltip.one'),
    TWO: getText('settings:employees.employee.steps.documents.birthCertificate.tooltip.two'),
    THREE: getText('settings:employees.employee.steps.documents.birthCertificate.tooltip.three'),
    FOUR: getText('settings:employees.employee.steps.documents.birthCertificate.tooltip.four'),
    FIVE: getText('settings:employees.employee.steps.documents.birthCertificate.tooltip.five'),
  },
};

const NUMBER_PLACEHOLDER = '__-__ ______';

const {
  NAME: NAME_ID,
  SURNAME: SURNAME_ID,
  NUMBER: NUMBER_ID,
  PATRONYMIC: PATRONYMIC_ID,
} = IDS_FOR_INTEGRATION_TESTS.EMPLOYEE.DOCUMENTS.BIRTHDAY;

const mustHaveFields = [
  DOCUMENTSFIELDS.NAME,
  DOCUMENTSFIELDS.SURNAME,
  DOCUMENTSFIELDS.PATRONYMIC,
  DOCUMENTSFIELDS.NUMBER,
];

const { employee: { documents: { birthCertificate } } } = QA_ATTRIBUTES;

const BirthCertificate: FC<BirthCertificateProps> = ({
  error: {
    Surname: errSurname,
    Name: errName,
    Patronymic: errPatronymic,
    Number: errNumber,
  },
  document,
  document: {
    Number,
    Name,
    Patronymic,
    Surname,
  },
  disabled,
  disabledFieldsNamesChangeAirTrip,
  onResetErrors,
  onValidationInput,
  onInputChange,
  onBlurInput,
  onCertificateNumberChange,
}) => {
  const showMustHave = mustHaveFields.some((field) => !!document[field].trim().length);

  const renderTooltip = Object.values(LABELS.TOOLTIP).map((text, ind) => (
    <Text
      type='NORMAL_14_130'
      color='white'
      key={ ind }
    >
      { text }
    </Text>
  ));

  const wrapperClassName = disabled ? styles.disabled : '';
  const disabledItemClassName = !disabled && disabledFieldsNamesChangeAirTrip ? styles.disabled : '';

  return (
    <div className={ wrapperClassName }>
      <FormWrapper className={ styles.row }>
        <div className={ `${styles.item} ${disabledItemClassName}` }>
          <FieldLabel text={ LABELS.SURNAME } mustHave={ showMustHave } />
          <Input
            id={ SURNAME_ID }
            value={ Surname }
            onFocus={ () => onResetErrors(DOCUMENTSFIELDS.SURNAME) }
            onBlur={ () => onBlurInput(Surname, DOCUMENTSFIELDS.SURNAME) }
            onChange={ v => onInputChange(v, DOCUMENTSFIELDS.SURNAME) }
            error={ errSurname }
            disabled={ disabledFieldsNamesChangeAirTrip }
            qaAttr={ birthCertificate.surname }
          />
        </div>
        <div className={ `${styles.item} ${disabledItemClassName}` }>
          <FieldLabel text={ LABELS.NAME } mustHave={ showMustHave } />
          <Input
            id={ NAME_ID }
            value={ Name }
            onFocus={ () => onResetErrors(DOCUMENTSFIELDS.NAME) }
            onBlur={ () => onBlurInput(Name, DOCUMENTSFIELDS.NAME) }
            onChange={ v => onInputChange(v, DOCUMENTSFIELDS.NAME) }
            error={ errName }
            disabled={ disabledFieldsNamesChangeAirTrip }
            qaAttr={ birthCertificate.name }
          />
        </div>
        <div className={ `${styles.item} ${disabledItemClassName}` }>
          <FieldLabel text={ LABELS.PATRONYMIC } mustHave={ showMustHave } />
          <Input
            id={ PATRONYMIC_ID }
            value={ Patronymic }
            onFocus={ () => onResetErrors(DOCUMENTSFIELDS.PATRONYMIC) }
            onBlur={ () => onValidationInput(DOCUMENTSFIELDS.PATRONYMIC) }
            onChange={ v => onInputChange(v, DOCUMENTSFIELDS.PATRONYMIC) }
            error={ errPatronymic }
            disabled={ disabledFieldsNamesChangeAirTrip }
            qaAttr={ birthCertificate.patronymic }
          />
        </div>
      </FormWrapper>
      <FormWrapper className={ styles.row }>
        <div className={ styles.item }>
          <FieldLabel text={ LABELS.NUMBER } mustHave={ showMustHave } />
          <Input
            id={ NUMBER_ID }
            value={ Number }
            onFocus={ () => onResetErrors(DOCUMENTSFIELDS.NUMBER, PASSPORTS.BIRTH_CERTIFICATE.TYPE) }
            withValidation
            validateFn={ v => {
              const replacedValue = v.replace(/[^A-Za-zА-ЯЁа-яё0-9]/g, '');

              return replacedValue.length > 12;
            } }
            onChange={ value => {
              const replacedValue = value.replace(/[^A-Za-zА-ЯЁа-яё0-9]/g, '');

              let maskedValue = '';

              if (replacedValue.length > 4) {
                maskedValue = `${replacedValue.substring(0, 2)}-${replacedValue.substring(2, 4)} ${replacedValue.substring(4, 12)}`;
              } else if (replacedValue.length > 1 && replacedValue.length < 5) {
                maskedValue = `${replacedValue.substring(0, 2)}-${replacedValue.substring(2)}`;
              } else {
                maskedValue = replacedValue;
              }

              onCertificateNumberChange(maskedValue.toUpperCase());
            } }
            onBlur={ () => onValidationInput(DOCUMENTSFIELDS.NUMBER, PASSPORTS.BIRTH_CERTIFICATE.TYPE) }
            placeholder={ NUMBER_PLACEHOLDER }
            debounceMs={ 0 }
            error={ errNumber }
            disabled={ disabled }
            qaAttr={ birthCertificate.number }
          />
        </div>
        <Tooltip
          position='right'
          className={ styles.certificate_icon }
          bodyClassName={ styles.tooltip }
          renderContent={ () => (
            <div className={ styles.certificate_tooltip }>
              { renderTooltip }
            </div>
          ) }
        >
          <Icon type='question'/>
        </Tooltip>
      </FormWrapper>
    </div>
  );
};

export { BirthCertificate };
