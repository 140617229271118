import React, { FC, useEffect } from 'react';
import { Text } from 'new-ui';
import { observer } from 'mobx-react';

import { getText } from '../../../i18n';

import { useStores } from '../../bi/context';
import { MOBX_STORES } from '../../bi/context/stores';

import { TransferHistoryList } from './components/TransferHistory';
import { SearchLoading } from '../../components/SearchLoading';
import TransferSearchMenu from '../../components/Menu/TransferSearchMenu';
import { WarningMessage } from '../../components/WarningMessage';
import { DateInSearch } from '../../components/DateInSerch';
import { BackToTripLink } from '../../components/BackToTripLink';
import { WarningBlock } from '../../components/WarningBlock';

import { MainAnalytic } from '../../bi/utils/analytics';
import { parseSearchString } from '../../bi/utils/convertSearchParams';
import { isSmartAgent } from '../../bi/utils/env';

import ROUTES from '../../bi/constants/routes';
import { QA_ATTRIBUTES } from '../../bi/constants/attributesForTests';
import SERVICETYPE, { ANALYTIC_SERVICE_TYPES, SMART_AGENT_SERVICE_TYPE } from '../../bi/constants/serviceType';

import { TransferSearchProps, SearchObject } from '../../bi/types/transfer';

import styles from './index.module.css';

const LABELS = {
  TITLE: getText('search:transfers.title'),
  LOADER: getText('search:transfers.loader'),
  ORDER_FOR_A_TRIP: getText('components:trip.orderForATrip'),
};

const TransferSearch: FC<TransferSearchProps> = observer(({
  transferService,
  userSessionService,
  history,
  location,
  featureFlagsService: {
    getTripRuleCustomMessage,
    getShowElementForSmartagent,
  },
  smartdeskService: {
    setSearchSmartdesk,
  },
  accountSettings,
}) => {
  const { transferSearchStore: { textTemplateWarning } } = useStores([MOBX_STORES.TRANSFER_SEARCH_STORE]);
  const { transferStore: { loading, history: historyList } } = useStores([MOBX_STORES.TRANSFER_STORE]);

  const handleLoadHistory = () => transferService.getSearchHistory();

  const searchParams = parseSearchString(location.search);

  const tripId = searchParams?.tripId || '';
  const tripDate = searchParams?.tripDate || '';
  const tripName = searchParams?.tripName || '';

  const handleSearchByParams = async () => {
    const travelPolicy = userSessionService.get().travelPolicies;

    const body = transferService.getSearchObjectByParams(searchParams);

    MainAnalytic.send(MainAnalytic.CATEGORY.TRANSFER, MainAnalytic.ACTIONS.TRANSFER.SEARCHONEWAY);

    const searchId = await transferService.searchByHistoryItem(travelPolicy, body);

    history.push({
      pathname: ROUTES.SEARCH.TRANSFER_RESULTS(searchId),
    });
  };

  useEffect(() => {
    const { searchSettings } = accountSettings.getAccountSettings();

    if (isSmartAgent) {
      MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.SMART_AGENT.MAIN_SEARCH_OPENED, { SAservice: SMART_AGENT_SERVICE_TYPE.TRANSFER });
    }

    MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.SEARCH.SEARCH_TRANSFER_SCREEN_OPENED);

    if (searchParams?.is_favorite) {
      handleSearchByParams();
    }

    handleLoadHistory();
    transferService.getTextWarning();

    if ((isSmartAgent
      && ((getShowElementForSmartagent() && history.location.pathname === ROUTES.SEARCH.TRANSFER)
      || (!searchSettings.includes(SERVICETYPE.TRANSFER))))) {
      history.push({
        pathname: ROUTES.SMARTDESK,
      });
    }
  }, []);

  const serchURL = (searchId: string) => history.push({ pathname: ROUTES.SEARCH.TRANSFER_RESULTS(searchId), search: `?tripId=${tripId}` });

  const handleSearch = async () => {
    setSearchSmartdesk(true);

    const travelPolicy = userSessionService.get().travelPolicies;

    MainAnalytic.send(MainAnalytic.CATEGORY.TRANSFER, MainAnalytic.ACTIONS.TRANSFER.SEARCHONEWAY);

    const searchId = await transferService.search(travelPolicy);

    if (tripId) {
      return serchURL(searchId);
    }

    MainAnalytic.sendAmplitudeArrayArgs(
      MainAnalytic.ACTIONS.SEARCH.SEARCH_FIND_BUTTON_PRESSED(ANALYTIC_SERVICE_TYPES.TRANSFER),
    );

    setSearchSmartdesk(false);

    return history.push({ pathname: ROUTES.SEARCH.TRANSFER_RESULTS(searchId) });
  };

  const handleSearchHistory = async (item: SearchObject) => {
    const travelPolicy = userSessionService.get().travelPolicies;

    MainAnalytic.send(MainAnalytic.CATEGORY.TRANSFER, MainAnalytic.ACTIONS.TRANSFER.SEARCHBYHISTORYONEWAY);

    const searchHistoryObj = transferService.getSearchObjectByHistory(item);

    const searchId = await transferService.searchByHistoryItem(travelPolicy, searchHistoryObj);

    if (tripId) {
      return serchURL(searchId);
    }

    return history.push({ pathname: ROUTES.SEARCH.TRANSFER_RESULTS(searchId) });
  };

  const renderLoading = () => {
    const wrapper = isSmartAgent && getShowElementForSmartagent()
      ? `${styles.loading} ${styles.loading_width}`
      : styles.loading;

    return (
      <div className={ wrapper }>
        <SearchLoading
          text={ LABELS.LOADER }
          qaAttrLoadingText={ QA_ATTRIBUTES.search.airline.bestAirlines }
        />
      </div>
    );
  };

  const renderWarningText = () => {
    if (!textTemplateWarning) return null;

    return <WarningBlock text={ textTemplateWarning } type='SEMIBOLD_16' color='red' />;
  };

  const renderWarningMessage = getTripRuleCustomMessage() ? <WarningMessage /> : null;

  const renderTitle = (title: string) => {
    if (isSmartAgent && getShowElementForSmartagent()) {
      return null;
    }

    return (
      <Text type='bold_32' qaAttr={ QA_ATTRIBUTES.transfer.title }>
        { title }
      </Text>
    );
  };

  const renderHistory = () => {
    if (isSmartAgent && getShowElementForSmartagent()) {
      return null;
    }

    return (
      <TransferHistoryList
        list={ historyList }
        onSearch={ handleSearchHistory }
      />
    );
  };

  const renderSearchMenu = () => {
    const title = tripId ? LABELS.ORDER_FOR_A_TRIP : LABELS.TITLE;
    const addingTriipDate = tripId ? <DateInSearch name={ tripName } date={ tripDate }/> : null;

    const wrapper = isSmartAgent && getShowElementForSmartagent() && !loading
      ? styles.wrapper
      : `${styles.wrapper} ${styles.wrapper_sw}`;

    return (
      <div>
        <div className={ wrapper }>
          <BackToTripLink tripId={ tripId } />
          { renderTitle(title) }
          { addingTriipDate }
          <TransferSearchMenu
            transferService={ transferService }
            onSearch={ handleSearch }
            getShowElementForSmartagent={ getShowElementForSmartagent() }
          />
          { renderWarningMessage }
        </div>
        { renderWarningText() }
        { renderHistory() }
      </div>
    );
  };

  if (loading) {
    return renderLoading();
  }

  return renderSearchMenu();
});

export { TransferSearch };
