import React, { useEffect } from 'react';
import { observer } from 'mobx-react';

import { Button } from 'new-ui';

import { getText } from '../../../../../../../i18n';

import { useStores } from '../../../../../../bi/context';
import { MOBX_STORES } from '../../../../../../bi/context/stores';

import scrollToTop from '../../../../../utils/scrollToTop';

import ChartsAnalytics from '../../../../../../bi/services/chartsAnalytics';

import { TaxiVoucherTable } from '../../../taxi/tableForVoucher';

import styles from './styles.module.css';

const LABELS = {
  EXCEL: getText('expenseReports:report.download.excel'),
};

const TaxiCommonVoucher = observer(({ chartsAnalyticsService }: { chartsAnalyticsService: ChartsAnalytics }) => {
  const {
    chartsAnalyticsSmartSummaryStore,
    chartsAnalyticsStore: { filtersType, filters: { employees, departments, projects, analytics, tags } },
  } = useStores([MOBX_STORES.CHARTS_ANALYTICS_SMART_SUMMARY_STORE, MOBX_STORES.CHARTS_ANALYTICS_STORE]);

  const {
    getTaxiSmartVoucher,
    downloadSubSmartXls,
  } = chartsAnalyticsService;

  useEffect(() => {
    getTaxiSmartVoucher();
  }, [
    getTaxiSmartVoucher,
    filtersType,
    employees.selected,
    departments.selected,
    projects.selected,
    analytics.selected,
    tags.selected,
  ]);

  const handlePageChange = (page: number) => chartsAnalyticsService.changePageTaxiVoucher(page).then(() => scrollToTop());

  const renderDownload = () => (
    <div className={ styles.header }>
      <div className={ styles.row }>
        <Button type='textual' onClick={ () => downloadSubSmartXls() }>
          { LABELS.EXCEL }
        </Button>
      </div>
    </div>
  );

  return (
    <>
      <div className={ styles.wrapper }>
        { renderDownload() }
      </div>
      <TaxiVoucherTable
        typeStore={ chartsAnalyticsSmartSummaryStore }
        onPageChange={ handlePageChange }
        changeVoucher={ () => {} }
        path={ '' }
      />
    </>
  );
});

export { TaxiCommonVoucher };
