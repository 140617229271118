import React, { Component } from 'react';
import { Select, Button, Tooltip, Text, Price, Icon } from 'new-ui';
import { Moment } from 'moment';

import { getText } from '../../../../../../../i18n';

import CONFIG from '../../../../../../../config';

import { TravelPolicyDialog } from '../../../../../../components/TravelPolicyDialog';
import { NameErrorsTravelPolicy } from '../../../../../../components/NameErrorsTravelPolicy';
import { GeneralInfoList } from '../GeneralInfoList';
import { ToNote } from '../../../../../../components/ToNote';
import AgencyFee from '../../../../../../components/AgencyFee';

import MoneyFormat from '../../../../../../bi/utils/money';
import trimTimezone from '../../../../../../bi/utils/trimTimezone';
import { dateUtcFormat } from '../../../../../../bi/utils/formatDate';
import {
  prepareHotelPrice,
  getRzPvModifiers,
  prepareRecRatePrice,
  preparedDatesAvailableContract,
} from '../../../../../../bi/utils/hotel';
import { firstCapitalLetter } from '../../../../../../bi/utils/strings';
import { MainAnalytic } from '../../../../../../bi/utils/analytics';
import { isSmartAgent } from '../../../../../../bi/utils/env';

import ANIMATION from '../../../../../../bi/constants/animation';
import CLASSNAMESFORTESTS from '../../../../../../bi/constants/classnamesfortests';
import { QA_ATTRIBUTES } from '../../../../../../bi/constants/attributesForTests';
import { IDS_FOR_INTEGRATION_TESTS } from '../../../../../../bi/constants/idsForInregrationTests';
import { PATTERN } from '../../../../../../bi/constants/dateFormats';

import { ANALYTIC_SERVICE_TYPES } from '../../../../../../bi/constants/serviceType';
import { PrepareRate } from '../../../../../../bi/services/hotels/types';
import { AccountTravelPolicy } from '../../../../../../bi/services/workspace/types';

import styles from './styles/index.module.css';

const LABELS = {
  TEXTBESTPRICE: getText('hotels:hotelResult.item.rate.bestPrice'),
  TEXTTOCART: getText('hotels:hotelResult.item.rate.toCart'),
  TEXTADDED: getText('hotels:hotelResult.item.rate.added'),
  PRICE_DETAILS: getText('hotels:hotelResult.item.rate.middlePrice'),
  RZ_PV_PRICE_DETAILS: getText('hotels:hotelResult.item.rate.includePayFor'),
  DINAMIC_PRICE: getText('hotels:hotelResult.item.rate.withoutDynamic'),
  SEND_REQUEST: getText('hotels:hotelResult.item.rate.sendRequest'),
  REQUESTHASBEENSEND: getText('hotels:hotelResult.item.rate.sentRequest'),
  ADDTONOTEPAD: getText('hotels:hotelResult.item.rate.addToNote'),
  IS_CONTRACT: getText('hotels:hotelResult.item.rate.specialOffer'),
  ONLINE_BOOKING_NOT_AVAILABLE: {
    FIRST_PART: getText('hotels:hotelResult.item.rate.onlineBookingNotAvailable.firstPart'),
    SECOND_PART: getText('hotels:hotelResult.item.rate.onlineBookingNotAvailable.secondPart'),
    THIRD_PART: getText('hotels:hotelResult.item.rate.onlineBookingNotAvailable.thirdPart'),
  },
  ROOMS: getText('hotels:hotelResult.item.rate.rooms'),
  LAST_ROOM: getText('hotels:hotelResult.item.rate.lastRoom'),
  LESS_COST: getText('hotels:hotelResult.item.rate.lessCost'),
  ADD_TAX: getText('hotels:hotelResult.item.rate.andTax'),
  CORPORATE_TARIFF: getText('hotels:hotelResult.item.rate.corporateTariff'),
  PROVIDER_NAME: getText('hotels:hotelResult.item.rate.testPriceDetails.providerName'),
  PRICE_WITHOUT_COMMISSION: getText('hotels:hotelResult.item.rate.testPriceDetails.priceWithoutCommission'),
  COMMISSION: getText('hotels:hotelResult.item.rate.testPriceDetails.commission'),
  FINAL_PRICE: getText('hotels:hotelResult.item.rate.testPriceDetails.finalPrice'),
  REC_RATE: getText('hotels:hotelResult.item.rate.testPriceDetails.recRate'),
  BOOKING: getText('hotels:hotelResult.item.rate.testPriceDetails.booking'),
  MORE_EXPENSIVE: getText('hotels:hotelResult.item.rate.moreExpensive'),
  ITEM_REC_RATE: getText('hotels:hotelResult.item.rate.recRate'),
  TAXES: {
    TITLE: getText('hotels:hotelResult.item.rate.taxes.title'),
    MANDATORY_FEE: getText('hotels:hotelResult.item.rate.taxes.mandatoryFee'),
    RESORT_TAX: getText('hotels:hotelResult.item.rate.taxes.resortTax'),
    MANDATORY_TAX: getText('hotels:hotelResult.item.rate.taxes.mandatoryTax'),
  },
  NO_AVAILABLE_PERIODS: (dates: string) => getText('hotels:hotelResult.item.rate.noAvailablePeriods', { dates }),
  FROM: getText('hotels:hotelResult.item.rate.from'),
  RUB: getText('common:currency'),
};

const applyTravelPolicy = (
  rate: PrepareRate | null,
  unavailableTravelPolicy: boolean,
  accountTravelPolicy: AccountTravelPolicy,
) => {
  const hasTP = rate?.TravelPolicy && rate?.TravelPolicy.Apply;

  if (!hasTP) {
    return false;
  }

  const selectedTP = unavailableTravelPolicy && !!accountTravelPolicy ?
    !!rate?.TravelPolicy.Errors[accountTravelPolicy.Id]
    : false;

  return hasTP && selectedTP;
};

interface RateProps {
  rate: PrepareRate,
  countryCode: string,
  accountTravelPolicy?: AccountTravelPolicy,
  showPriceDetails?: boolean,
  unavailableTravelPolicy: boolean,
  isContract: boolean,
  isMessageSend?: boolean,
  disableToCartIfTPApply?: boolean,
  tpRights?: boolean,
  travelPolicyList: any[],
  searchSettings: {
    customCheckin: Moment | null,
    customCheckout: Moment | null,
    daysCount: number,
  },
  chatState: {
    sendingMessageToAdmin: boolean,
  },
  onToCart?(rate: PrepareRate): void,
  onToNote?(rate: PrepareRate): void,
  onChangeCount?(bookId: string, value: any): void,
  onSendRequest?(price: string, rateId: string, textError: string): void,
  qaAttrContainer?: string,
}

interface RateState {
  animatedClass: string,
  showTravelPolicyDialog: boolean,
}

class Rate extends Component<RateProps, RateState> {
  static defaultProps = {
    showPriceDetails: false,
    showTravelPolicyDialog: false,
    isMessageSend: false,
    disableToCartIfTPApply: false,
    tpRights: false,
    accountTravelPolicy: null,
    onToCart: () => {},
    onToNote: () => {},
    onChangeCount: () => {},
    onSendRequest: () => {},
    qaAttrContainer: '',
  };

  state = {
    animatedClass: '',
    showTravelPolicyDialog: false,
  };

  handleAnimationEnd = () => this.setState({
    animatedClass: '',
  });

  handleAddToCart = (e: React.MouseEvent) => {
    const { onToCart, rate } = this.props;

    e.preventDefault();
    this.setState({
      animatedClass: ANIMATION.MOVETOCART,
      showTravelPolicyDialog: false,
      // @ts-ignore
    }, () => onToCart(rate));
  };

  handleAddToNote = (e: React.MouseEvent) => {
    const { onToNote, rate } = this.props;

    e.preventDefault();
    this.setState({
      animatedClass: ANIMATION.MOVETONOTE,
      // @ts-ignore
    }, () => onToNote({ ...rate, qaAttr: QA_ATTRIBUTES.hotels.current.room.notificationNote }));

    MainAnalytic.sendAmplitudeArrayArgs(
      MainAnalytic.ACTIONS.SEARCH.SEARCH_RESULTS_NOTEBOOK_BUTTON_PRESSED(ANALYTIC_SERVICE_TYPES.HOTEL),
    );
  };

  handleSelect = (value: any) => this.props.onChangeCount?.(this.props.rate.BookId, value);

  handleShowTravelPolicyDialog = () => this.setState({
    showTravelPolicyDialog: true,
  });

  handleCloseTravelPolicyDialog = () => this.setState({
    showTravelPolicyDialog: false,
  });

  isShowRatesDetails = () => CONFIG.HOTEL.SHOW_RATES_DETAILS;

  renderTravelPolicyDialog = () => {
    const { rate, accountTravelPolicy, unavailableTravelPolicy } = this.props;
    const { showTravelPolicyDialog } = this.state;

    let errors = rate.TravelPolicy.Errors;

    if (unavailableTravelPolicy && !!accountTravelPolicy) {
      errors = {
        [accountTravelPolicy.Id]: rate.TravelPolicy.Errors[accountTravelPolicy.Id],
      };
    }

    return (
      <TravelPolicyDialog
        show={ showTravelPolicyDialog }
        errors={ errors }
        onClose={ this.handleCloseTravelPolicyDialog }
        onAdd={ this.handleAddToCart }
      />
    );
  };

  renderTestPriceDetails = () => {
    const { rate, isContract } = this.props;
    const {
      Price: {
        TotalPrice,
        Base,
        ProviderName,
        Commission,
        // @ts-ignore
        RateType, // TODO нет вообще
        RecRate,
        IsBooking,
      },
      NoAvailabilityPeriods,
    } = rate;

    if (isContract) {
      return (
        <div className={ styles.row }>
          <Text color='white' type='NORMAL_16_140'>
            { LABELS.IS_CONTRACT }<br/>
            { preparedDatesAvailableContract(NoAvailabilityPeriods) }
          </Text>
        </div>
      );
    }

    return (
      <div>
        {
          ProviderName && (
            <div className={ styles.row }>
              <Text color='white' type='SEMIBOLD_16'>{ LABELS.PROVIDER_NAME }</Text>
              <Text color='white'>{ ProviderName }</Text>
            </div>
          )
        }
        <div className={ styles.row }>
          <Text color='white' type='SEMIBOLD_16'>{ LABELS.PRICE_WITHOUT_COMMISSION }</Text>
          <Text color='white'>{ MoneyFormat.money(Base, true) }</Text>
        </div>
        <div className={ styles.row }>
          <Text color='white' type='SEMIBOLD_16'>{ LABELS.COMMISSION }</Text>
          <Text color='white'>{ MoneyFormat.money(Commission, true) }</Text>
        </div>
        <div className={ styles.row }>
          <Text color='white' type='SEMIBOLD_16'>{ LABELS.FINAL_PRICE }</Text>
          <Text color='white'>{ MoneyFormat.money(TotalPrice, true) }</Text>
        </div>
        <div className={ styles.row }>
          <Text color='white' type='SEMIBOLD_16'>{ RateType }</Text>
        </div>
        { typeof RecRate === 'number' && (
          <div className={ styles.row }>
            <Text color='white' type='SEMIBOLD_16'>{ LABELS.REC_RATE }</Text>
            <Text color='white'>{ MoneyFormat.money(RecRate, true) }</Text>
          </div>
        )
        }
        { IsBooking && (
          <div className={ styles.row }>
            <Text color='white' type='SEMIBOLD_18'>{ LABELS.BOOKING }</Text>
          </div>
        )}
      </div>
    );
  };

  renderProdPriceDetails = () => {
    const { searchSettings, isContract, rate: { NoAvailabilityPeriods } } = this.props;
    const { customCheckin, customCheckout } = searchSettings;
    const rzpvHtml = `${getRzPvModifiers(searchSettings)}`;

    const content = () => {
      if (isContract) {
        return (
          <Text type='NORMAL_14_130' color='white'>
            { LABELS.IS_CONTRACT }<br/>
            { preparedDatesAvailableContract(NoAvailabilityPeriods) }
          </Text>
        );
      }

      if (customCheckin || customCheckout) {
        return (
          <Text type='NORMAL_14_130' color='white' >
            { LABELS.PRICE_DETAILS }
            <br />
            { LABELS.RZ_PV_PRICE_DETAILS } { rzpvHtml }
          </Text>
        );
      }

      return (
        <Text type='NORMAL_14_130' color='white'>{ LABELS.PRICE_DETAILS }</Text>
      );
    };

    return (
      <div className={ styles['tooltip-content'] }>
        { content() }
      </div>
    );
  };

  renderAction = (price = '') => {
    const {
      rate,
      unavailableTravelPolicy,
      accountTravelPolicy,
      chatState,
      onSendRequest,
      isMessageSend,
      disableToCartIfTPApply,
      tpRights,
    } = this.props;

    const {
      Offline,
      Select: {
        // @ts-ignore
        items,
        // @ts-ignore
        count,
      },
      RateId,
      TravelPolicy: {
        Errors,
      },
    } = rate;
    // @ts-ignore
    const applyTP = applyTravelPolicy(rate, unavailableTravelPolicy, accountTravelPolicy);
    const buyTripPersonal = unavailableTravelPolicy && !!accountTravelPolicy;
    const selectedTravelPolicy = buyTripPersonal ? accountTravelPolicy.Id : '';

    const textError = buyTripPersonal
      ? Errors[selectedTravelPolicy]
      : '';

    if (Offline) {
      const { sendingMessageToAdmin } = chatState;
      const label = isMessageSend ? LABELS.REQUESTHASBEENSEND : LABELS.SEND_REQUEST;
      const textErrorChat = textError || '';
      const disabled = sendingMessageToAdmin || isMessageSend;

      return (
        <Tooltip
          position='top'
          renderContent={ () => (
            <div className={ styles['tooltip-wrapper'] }>
              <Text
                color='white'
                type='NORMAL_14_130'
                className={ styles.offline }
              >
                { LABELS.ONLINE_BOOKING_NOT_AVAILABLE.FIRST_PART }<br />
                { LABELS.ONLINE_BOOKING_NOT_AVAILABLE.SECOND_PART }<br />
                { LABELS.ONLINE_BOOKING_NOT_AVAILABLE.THIRD_PART }
              </Text>
            </div>
          ) }
        >
          <Button
            disabled={ disabled }
            type='primary'
            loading={ sendingMessageToAdmin }
            onClick={ () => onSendRequest?.(price, RateId, textErrorChat) }
            qaAttr={ QA_ATTRIBUTES.hotels.current.room.request }
          >
            { label }
          </Button>
        </Tooltip>
      );
    }

    const hasFreeRooms = !!items.length;
    const labelHtml = hasFreeRooms ? LABELS.TEXTTOCART : LABELS.TEXTADDED;
    const valuesErrorTp =
      rate.TravelPolicy.Errors && accountTravelPolicy
        ? Object.entries(rate.TravelPolicy.Errors)
          // @ts-ignore
          .find(k => k[0] === accountTravelPolicy.Id && k[1].includes(` ${LABELS.MORE_EXPENSIVE} `))
        : [];

    const disabledRuleTp = disableToCartIfTPApply && applyTP && (tpRights && !!valuesErrorTp);

    const selectHtml = hasFreeRooms && items.length > 1 && (
      <div className={ styles.rooms }>
        <Text type='NORMAL_14'>{ LABELS.ROOMS }</Text>
        <div className={ styles.select }>
          <Select
            qaAttr={ QA_ATTRIBUTES.hotels.current.room.select }
            theme='border'
            items={ items }
            value={ count }
            onChange={ this.handleSelect }
          />
        </div>
      </div>
    );

    return (
      <div className={ styles.book }>
        <div className={ styles['book-content'] }>
          { selectHtml }
          <Tooltip
            show={ disabledRuleTp }
            renderContent={ () => (
              <div className={ styles['tooltip-content'] }>
                <Text color='white' type='NORMAL_14_130'>
                  { LABELS.LESS_COST }
                </Text>
              </div>
            ) }
          >
            <Button
              qaAttr={ QA_ATTRIBUTES.hotels.current.room.cart }
              type='secondary'
              // @ts-ignore
              onlyBorder={ applyTP } // TODO ????
              // @ts-ignore
              onClick={ applyTP ? this.handleShowTravelPolicyDialog : this.handleAddToCart }
              disabled={ !hasFreeRooms || Offline || disabledRuleTp }
              id={ IDS_FOR_INTEGRATION_TESTS.TO_CART.HOTEL }
              className={ styles.button }
            >
              { labelHtml }
            </Button>
          </Tooltip>
        </div>
        { !selectHtml && <Text type='SEMIBOLD_14' color='red' >{ LABELS.LAST_ROOM }</Text> }
      </div>
    );
  };

  renderTooltip = () => {
    const { rate } = this.props;
    const { PropertyFees } = rate;

    const descriptions = PropertyFees.reduce((pre, { Description }) => [...pre, ...Description], [] as string[]);
    const taxesText = descriptions.map((item: string, idx: number) => (
      <Text
        key={ idx }
        type='NORMAL_14'
        color='white'
        className={ styles.tax_price }
      >
        { item }
      </Text>
    ));

    return (
      <div className={ styles.tooltip_text }>
        <Text
          type='SEMIBOLD_14'
          color='white'
          className={ styles.title }
        >
          { LABELS.TAXES.TITLE }
        </Text>
        { taxesText }
      </div>
    );
  };

  renderPriceAvailability = (price: string) => {
    const { rate: { NoAvailabilityPeriods } } = this.props;

    if (NoAvailabilityPeriods && NoAvailabilityPeriods.length) {
      return (
        <Text type='NORMAL_14' className={ styles.contract_question }>
          <Icon type='question' />{ LABELS.RUB }
        </Text>
      );
    }

    return (
      <Price
        qaAttr={ QA_ATTRIBUTES.hotels.current.room.price }
        value={ price }
        type='bold_24'
        marginSmall
      />
    );
  };

  render() {
    const {
      rate,
      countryCode,
      showPriceDetails,
      unavailableTravelPolicy,
      accountTravelPolicy,
      travelPolicyList,
      searchSettings,
      isContract,
      qaAttrContainer,
    } = this.props;
    const { animatedClass, showTravelPolicyDialog } = this.state;
    const {
      Name,
      Price: {
        TotalPrice,
        AgentFee,
        HasVAT,
        VAT,
        NightRate,
        // @ts-ignore
        RecRate,
      },
      Select: {
        // @ts-ignore
        count,
      },
      Offline,
      CancellationPolicy: {
        Refundable,
        DeadLine,
      },
      Meal,
      IsOptimalRate,
      PropertyFees,
      NoAvailabilityPeriods = [],
    } = rate;
    const { daysCount, customCheckin, customCheckout } = searchSettings;
    // @ts-ignore
    const applyTP = applyTravelPolicy(rate, unavailableTravelPolicy, accountTravelPolicy);
    const travelPolicyDialogHtml = showTravelPolicyDialog && this.renderTravelPolicyDialog();
    const buyTripPersonal = unavailableTravelPolicy && !!accountTravelPolicy;
    const countToVat = count || 1;
    const moneyFormatVat = MoneyFormat.moneyWithDecimal(Number((VAT * countToVat).toFixed(2)), true);

    const wrapperStyles = [styles.wrapper, animatedClass];

    if (IsOptimalRate) {
      wrapperStyles.push(styles.optimal);
    }

    const noteButtonHtml = !Offline && (
      <div className={ styles.note }>
        <ToNote
          disabled={ applyTP }
          label={ LABELS.ADDTONOTEPAD }
          onClick={ this.handleAddToNote }
          className={ `${styles.notepad} ${CLASSNAMESFORTESTS.HOTELS.TONOTEPAD}` }
          qaAttr={ QA_ATTRIBUTES.hotels.current.room.note }
        />
      </div>
    );

    const vatHtml = !NoAvailabilityPeriods.length && HasVAT && (
      <div className={ styles.vat }>
        <Text className={ styles.label } type='NORMAL_12' color='gray'>
          { LABELS.ADD_TAX }: { moneyFormatVat }
        </Text>
      </div>
    );

    const contractHotelHtml = isContract && (
      <Text type='bold_22' >{ LABELS.CORPORATE_TARIFF }</Text>
    );

    const {
      countString,
      daysString,
      nightRate,
      rzpvString,
      price,
      fee,
    } = prepareHotelPrice(
      count,
      TotalPrice,
      AgentFee,
      daysCount,
      NightRate,
      searchSettings,
      customCheckin,
      customCheckout,
    );

    const actionHtml = this.renderAction(price);

    const priceDetailsContent = showPriceDetails ? this.renderTestPriceDetails() : this.renderProdPriceDetails();

    const feesTooltip = PropertyFees && PropertyFees.length ? (
      <Tooltip
        position='left'
        className={ styles.tooltip }
        renderContent={ this.renderTooltip }
      >
        <Icon type='question' />
      </Tooltip>
    ) : null;

    const showPriceWithInfo = (count > 1
      || daysCount > 1
      || customCheckin
      || customCheckout)
      && !NoAvailabilityPeriods.length;

    const priceHtml = showPriceWithInfo ? (
      <div className={ styles.price_full }>
        <Tooltip
          className={ styles.details }
          childrenClassName={ styles.details_price }
          position='left'
          renderContent={ () => (
            <div className={ styles['tooltip-wrapper'] }>
              { priceDetailsContent }
            </div>
          ) }
        >
          <Text
            qaAttr={ QA_ATTRIBUTES.hotels.current.room.tariff }
            className={ styles.addendums }
            type='NORMAL_12'
          >
            { countString } { daysString } { nightRate } { rzpvString } =
          </Text>
          <div className={ styles.total }>
            { this.renderPriceAvailability(price) }
          </div>
        </Tooltip>
        { feesTooltip }
      </div>
    ) : (
      <div className={ styles.total }>
        <Tooltip
          className={ styles.details }
          position='left'
          renderContent={ () => (
            <div className={ styles['tooltip-wrapper'] }>
              { priceDetailsContent }
            </div>
          ) }
        >
          { this.renderPriceAvailability(price) }
        </Tooltip>
        { feesTooltip }
      </div>
    );

    const renderRecRate = RecRate && isSmartAgent ? (
      <div className={ styles['price-wrapper-rec'] }>
        <Text
          color='dim-gray'
          type='NORMAL_12'
        >
          { LABELS.ITEM_REC_RATE }
        </Text>
        &nbsp;
        <Price
          value={ prepareRecRatePrice(count, RecRate) }
          type='SEMIBOLD_16'
        />
      </div>) : null;

    const priceDetailsHtml = (
      <div className={ styles['price-wrapper'] }>
        { priceHtml }
      </div>
    );

    const rateName = firstCapitalLetter(Name);
    const selectedTravelPolicyValue = buyTripPersonal ? accountTravelPolicy.Id : '';
    const deadLine = DeadLine ? dateUtcFormat(trimTimezone(DeadLine), PATTERN.DAY_OF_MONTH_TIME) : '';

    const renderAgentFee = () => {
      if (!isSmartAgent) {
        return null;
      }

      return (
        <div className={ styles.agentFee }>
          <AgencyFee fee={ fee * count }/>
        </div>
      );
    };

    return (
      <div
        className={ wrapperStyles.join(' ') }
        onAnimationEnd={ this.handleAnimationEnd }
        data-qa={ qaAttrContainer }
      >
        <div className={ styles.header }>
          <Text
            qaAttr={ QA_ATTRIBUTES.hotels.current.room.title }
            type='SEMIBOLD_16'
          >
            { rateName }
          </Text>
          <div className={ styles.actions }>
            <NameErrorsTravelPolicy
              hiddenNames
              item={ rate }
              applyTP={ applyTP }
              oneTravelPolicy={ buyTripPersonal }
              travelPolicyList={ travelPolicyList }
              selectedTravelPolicy={ selectedTravelPolicyValue }
            />
            { noteButtonHtml }
          </div>
        </div>
        <div className={ styles.body }>
          <div className={ styles['rate-info'] }>
            <GeneralInfoList
              refundable={ Refundable }
              // @ts-ignore
              meal={ Meal }
              date={ deadLine }
              countryCode={ countryCode }
            />
          </div>
          <div className={ styles.action }>
            <div className={ styles.price }>
              { contractHotelHtml }
              <div className={ styles['amount-wrapper'] }>
                { priceDetailsHtml }
              </div>
              { vatHtml }
              { renderRecRate }
            </div>
            { actionHtml }
          </div>
        </div>
        { travelPolicyDialogHtml }
        { renderAgentFee() }
      </div>
    );
  }
}

export { Rate };
