// @ts-nocheck
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Button, Dialog, PROPS, Text, Textarea, Tooltip } from 'new-ui';

import { getText } from '../../../../i18n';

import { useStores } from '../../../bi/context';
import { MOBX_STORES } from '../../../bi/context/stores';

import { getDateDescriptor } from '../../../bi/utils/formatDate';

import { checkFieldsForTooltip, disabledApprovalButton } from '../../../bi/utils/approval';
import { MainAnalytic } from '../../../bi/utils/analytics';

import { APPROVE_STATUS } from '../../../bi/constants/travelApproval';

import { AddresserInfoItem } from '../../../components/AddresserInfoItem';

import { SpendingBlock } from './SpendingBlock';
import GeneralBlock from './GeneralBlock';
import { Comments } from './Comments';

import styles from '../styles/approverContent.module.css';

const LABELS = {
  APPROVE: getText('components:travelApproval.approve'),
  CONFIRM_ACTION: getText('components:travelApproval.confirmAction'),
  DECLINE: getText('components:travelApproval.decline'),
  COMMENT_FOR_APPROVE: getText('components:travelApproval.commentForApprove'),
  COMMENT_FOR_DECLINE: getText('components:travelApproval.commentForDecline'),
  REQUEST: getText('components:travelApproval.requestFrom'),
  REQUIRED_FIELDS: getText('components:travelApproval.requiredFields'),
};

const {
  TEXT: {
    TYPES: { BOLD_32, BOLD_20, NORMAL_14_130, NORMAL_16, BOLD_16 },
    COLORS: { WHITE },
  },
  BUTTON: { TYPES: { SECONDARY, TEXTUAL_MEDIUM, PRIMARY } },
} = PROPS;

const ApproverContent = observer(({
  region,
  readOnly,
  isMultiCompany,
  isDemo,
  employeeId,
  disabled,
  applicationSchemeTemplate,
  disabledToUpdateField,
  currentUserId,
  accountEmail,
  userAnalytics,
  selectedAnalytics,
  onApproveRequest,
  getEmployees,
  onSuggestSelected,
  onAddEmployee,
  onRemoveEmployee,
  onAddEmployeeCompany,
  mayApprove,
  isNew,
  customAnalyticsService,
  userSessionService,
  featureFlagsService,
  onAddProject,
  onAddCustomAnalytic,
  accountSettingsService,
  workspaceService,
  travelApprovalService,
  tripTagsService,
  hotelsService,
}) => {
  const {
    travelApprovalStore,
    settingsStore,
  } = useStores([MOBX_STORES.TRAVEL_APPROVAL_STORE, MOBX_STORES.SETTINGS_STORE]);

  const [approveDialog, setApproveDialog] = useState(false);
  const [declineDialog, setDeclineDialog] = useState(false);
  const [comment, setComment] = useState('');

  const {
    travelApproval,
    approvalPopUp,
    travelApproval: { Employees, Budget, Destinations, StartDate, EndDate, Purpose, Comment, Approves },
    purposes,
    employeesWithRights,
    loadingSendButton,
  } = travelApprovalStore;
  const { companiesWithCostCenter } = settingsStore;

  const destinationNull = Destinations?.length ?
    Destinations.some(destination => destination.SourceId === null || destination.Id === null) : false;
  const destination = (applicationSchemeTemplate && applicationSchemeTemplate.IsRequiredDestinationCity) ? !destinationNull : true;

  const datesIsValid = StartDate && EndDate;
  const { disabled: disableApproveButton } = disabledApprovalButton(
    Employees,
    Budget,
    destination,
    datesIsValid,
    Purpose,
    Comment,
    applicationSchemeTemplate,
    true,
    companiesWithCostCenter,
    userAnalytics,
    selectedAnalytics,
  );

  const handleApprove = (field) => {
    if (field === APPROVE_STATUS.ACCEPTED) {
      MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.APPLICATION_SCHEME.APPLICATION_SCHEME_APPROVE_BUTTON_PRESSED);

      setApproveDialog(false);
    }

    if (field === APPROVE_STATUS.DECLINED) {
      setDeclineDialog(false);
    }

    setComment('');

    return onApproveRequest(field, comment);
  };

  const handleCloseApproveDialog = () => {
    setApproveDialog(false);
    setComment('');
  };

  const handleCloseDeclineDialog = () => {
    setDeclineDialog(false);
    setComment('');
  };

  const renderApproveDialog = () => {
    if (!approveDialog) return null;

    return (
      <Dialog
        showClosing
        show={ approveDialog }
        onChange={ () => handleCloseApproveDialog() }
      >
        <div className={ styles['dialog-content'] }>
          <Text type={ BOLD_20 } className={ styles['dialog-header'] }>
            { LABELS.CONFIRM_ACTION }
          </Text>
          <Textarea
            placeholder={ LABELS.COMMENT_FOR_APPROVE }
            value={ comment }
            onChange={ setComment }
          />
          <div className={ styles['dialog-actions'] }>
            <Button
              type={ PRIMARY }
              onClick={ () => handleApprove(APPROVE_STATUS.ACCEPTED) }
            >
              { LABELS.APPROVE }
            </Button>
          </div>
        </div>
      </Dialog>
    );
  };

  const renderDeclineDialog = () => {
    if (!declineDialog) return null;

    return (
      <Dialog
        showClosing
        show={ declineDialog }
        onChange={ () => handleCloseDeclineDialog() }
      >
        <div className={ styles['dialog-content'] }>
          <Text type={ BOLD_20 } className={ styles['dialog-header'] }>
            { LABELS.CONFIRM_ACTION }
          </Text>
          <Textarea
            placeholder={ LABELS.COMMENT_FOR_DECLINE }
            value={ comment }
            onChange={ setComment }
          />
          <div className={ styles['dialog-actions'] }>
            <Button
              type={ PRIMARY }
              onClick={ () => handleApprove(APPROVE_STATUS.DECLINED) }
            >
              { LABELS.DECLINE }
            </Button>
          </div>
        </div>
      </Dialog>
    );
  };

  const tooltip = () => {
    const nonFilledFields = checkFieldsForTooltip(
      Employees,
      Budget,
      Destinations[0].SourceId || Destinations[0].Id,
      datesIsValid,
      Purpose,
      Comment,
      applicationSchemeTemplate,
      true,
      companiesWithCostCenter,
      userAnalytics,
      selectedAnalytics,
    );
    const tooltipText = nonFilledFields.join(', ');

    const textHtml = nonFilledFields.length ? (
      <Text
        color={ WHITE }
        type={ NORMAL_14_130 }
        className={ styles['error-text'] }
      >
        { tooltipText }
      </Text>
    ) : null;

    if (isDemo) {
      return (
        <div className={ styles['tooltip-wrapper'] }>
          <Text color={ WHITE } type={ NORMAL_14_130 }>
            { LABELS.YOU_ARE_IN_DEMO }
          </Text>
          <Text color={ WHITE } type={ NORMAL_14_130 }>
            { LABELS.CREATE_REQUEST_NOT_AVAILABLE }
          </Text>
        </div>
      );
    }

    return (
      <div className={ styles['tooltip-wrapper'] }>
        <Text color={ WHITE } type={ BOLD_16 }>
          { LABELS.REQUIRED_FIELDS }
        </Text>
        { textHtml }
      </div>
    );
  };

  const renderPanel = () => {
    const { Status } = travelApproval;

    if (Status !== APPROVE_STATUS.WAITING_APPROVE || !mayApprove) return null;

    return (
      <div className={ styles.panel }>
        <div className={ styles.panel_content }>
          <Tooltip
            show={ isDemo || disableApproveButton || !destination }
            renderContent={ tooltip }
          >
            <Button
              loading={ loadingSendButton }
              disabled={ isDemo || loadingSendButton || !destination || disableApproveButton }
              type={ SECONDARY }
              className={ styles.panel_button }
              onClick={ () => setApproveDialog(true) }
            >
              { LABELS.APPROVE }
            </Button>
          </Tooltip>
          <Tooltip
            show={ isDemo }
            renderContent={ tooltip }
          >
            <Button
              disabled={ isDemo }
              type={ TEXTUAL_MEDIUM }
              onClick={ () => setDeclineDialog(true) }
            >
              { LABELS.DECLINE }
            </Button>
          </Tooltip>
        </div>
      </div>
    );
  };

  const header = !!Destinations.length && Destinations[0].Name.split(',')[0];
  const firstComment = !!Approves.length && Approves[0].Messages[0];
  const date = getDateDescriptor(firstComment.Date).toLowerCase();

  return (
    <div className={ styles.wrapper }>
      <Text type={ BOLD_32 }>{ header }</Text>
      <div className={ styles.request }>
        <AddresserInfoItem
          date={ date }
          email={ firstComment.Email }
          name={ firstComment.Name }
          label={ LABELS.REQUEST }
          typeText={ NORMAL_16 }
        />
      </div>
      <Comments userId={ currentUserId } accountEmail={ accountEmail } />
      <SpendingBlock />
      <GeneralBlock
        applicationSchemeTemplate={ applicationSchemeTemplate }
        travelApproval={ travelApproval }
        approvalPopUp={ approvalPopUp }
        purposes={ purposes }
        region={ region }
        readOnly={ readOnly }
        isMulti={ isMultiCompany }
        employeesWithRights={ employeesWithRights }
        employeeId={ employeeId }
        disabledField={ disabled }
        disabledToUpdateField={ disabledToUpdateField }
        getEmployees={ getEmployees }
        onSuggestSelected={ onSuggestSelected }
        onAddEmployee={ onAddEmployee }
        onRemoveEmployee={ onRemoveEmployee }
        onAddEmployeeCompany={ onAddEmployeeCompany }
        isNew={ isNew }
        userSessionService={ userSessionService }
        onAddProject={ onAddProject }
        featureFlagsService={ featureFlagsService }
        customAnalyticsService={ customAnalyticsService }
        accountSettingsService={ accountSettingsService }
        workspaceService={ workspaceService }
        tripTagsService={ tripTagsService }
        hotelsService={ hotelsService }
        travelApprovalService={ travelApprovalService }
        onAddCustomAnalytic={ onAddCustomAnalytic }
      />
      { renderPanel() }
      { renderApproveDialog() }
      { renderDeclineDialog() }
    </div>
  );
});

ApproverContent.propTypes = {
  region: PropTypes.object.isRequired,
  readOnly: PropTypes.bool.isRequired,
  isMultiCompany: PropTypes.bool.isRequired,
  isDemo: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  employeeId: PropTypes.number.isRequired,
  currentUserId: PropTypes.string,
  accountEmail: PropTypes.string,
  onApproveRequest: PropTypes.func.isRequired,
  onSuggestSelected: PropTypes.func,
  getEmployees: PropTypes.func,
  onAddEmployee: PropTypes.func,
  onRemoveEmployee: PropTypes.func,
  onAddEmployeeCompany: PropTypes.func,
  mayApprove: PropTypes.bool,
  featureFlagsService: PropTypes.func.isRequired,
  userAnalytics: PropTypes.object.isRequired,
  selectedAnalytics: PropTypes.object.isRequired,
  accountSettingsService: PropTypes.func.isRequired,
  travelApprovalService: PropTypes.object.isRequired,
};

ApproverContent.defaultProps = {
  currentUserId: '',
  accountEmail: '',
  mayApprove: false,
  onSuggestSelected: () => {},
  getEmployees: () => {},
  onAddEmployee: () => {},
  onRemoveEmployee: () => {},
  onAddEmployeeCompany: () => {},
  isRequiredField: () => {},
};

export { ApproverContent };
