// @ts-nocheck
import React from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { IconButton, PROPS, RadioButton, StyledWrapper, Text, ItemLoader } from 'new-ui';

import { getText } from '../../../../../i18n';

import { useStores } from '../../../../bi/context';
import { MOBX_STORES } from '../../../../bi/context/stores';

import { getEmployeeFullNameWithSimplicity } from '../../../../bi/utils/employees';
import { formatRangeDateWithSimplicity } from '../../../../bi/utils/formatDate';
import { isSmartAgent } from '../../../../bi/utils/env';

import ROUTES from '../../../../bi/constants/routes';
import { APPROVAL_STATUS_PAGE } from '../../../../bi/constants/travelApproval';
import { QA_ATTRIBUTES } from '../../../../bi/constants/attributesForTests';

import styles from './styles.module.css';
import { AGGREGATORS_ACCOUNT } from '../../../../bi/constants/accounts';

const {
  TEXT: { TYPES: { SEMIBOLD_14 }, COLORS: { DEFAULT } },
  ICON: { TYPES: { PLUS: { ROUND }, WARNING } },
  ICON_BUTTON: { THEMES: { DEFAULT_RED } },
} = PROPS;

const LABELS = {
  CREATE_NEW: getText('components:travelApproval.newRequestLower'),
  TRAVEL_APPROVE: getText('components:travelApproval.travelApprovalRequest'),
  NO_DATE: getText('components:travelApproval.noDate'),
};

const CartApproveRequest = observer(({
  id,
  travelApprovalService,
  aggregationId,
  isTravelApproval,
  history,
  handleValidRequestOnChange,
}) => {
  const { travelApprovalsStore } = useStores([MOBX_STORES.TRAVEL_APPROVALS]);

  if (!isTravelApproval) {
    return null;
  }

  const { approvedList, chosenApprovedRequest, validateLoading } = travelApprovalsStore;
  const chosenApprovedRequestId = chosenApprovedRequest?.Id || null;
  const loadingHtml = validateLoading && <ItemLoader />;
  const cKRRestrictions = aggregationId === AGGREGATORS_ACCOUNT.CKR;

  const handleGoNewTravelApproval = () => {
    travelApprovalService.setTravelApproval(null);
    travelApprovalService.setTravelApprovalStatusPage(APPROVAL_STATUS_PAGE.CREATE);

    history.push(`${ROUTES.NEW_REQUEST}`);
  };

  const renderNewTravelApprovalRequest = !cKRRestrictions ? (
    <IconButton
      iconType={ ROUND }
      className={ styles.icon }
      onClick={ handleGoNewTravelApproval }
      qaAttr={ QA_ATTRIBUTES.cart.approval.scheme.request.createNew }
    >
      { LABELS.CREATE_NEW }
    </IconButton>
  ) : null;

  const renderApprovedRequest = (item) => {
    const { Destinations, Employees, StartDate, EndDate } = item;
    const dates = !StartDate && !EndDate ? LABELS.NO_DATE : formatRangeDateWithSimplicity(StartDate, EndDate);
    const cities = Destinations.map(({ Name }) => Name).join(', ');
    const employees = Employees.map(employee => getEmployeeFullNameWithSimplicity(employee, true)).join(', ');

    return (
      <div className={ styles.name }>
        { dates }: { cities }. { employees }
      </div>
    );
  };

  const approvedItem = (item, index) => (
    <div
      key={ `travel_approval_${item.Id}_${index}` }
      className={ styles.item }
      data-qa={ `${QA_ATTRIBUTES.cart.approval.scheme.request.item}-${index}` }
    >
      <RadioButton
        disabled={ validateLoading }
        value={ String(chosenApprovedRequestId) }
        checked={ chosenApprovedRequestId === item.Id }
        alternativeDesign={ isSmartAgent }
        onChange={ () => handleValidRequestOnChange(item.Id, id) }
      >
        { renderApprovedRequest(item) }
      </RadioButton>
    </div>
  );

  return (
    <StyledWrapper className={ styles.wrapper_approve_request }>
      { loadingHtml }
      <div className={ styles['approve-request'] }>
        <div className={ styles['approve-header'] }>
          <IconButton
            theme={ DEFAULT_RED }
            iconType={ WARNING }
          />
          <Text
            type={ SEMIBOLD_14 }
            color={ DEFAULT }
            className={ styles['header-text'] }
            qaAttr={ QA_ATTRIBUTES.cart.approval.scheme.request.warning }
          >
            { LABELS.TRAVEL_APPROVE }
          </Text>
        </div>
        { approvedList.map(approvedItem) }
        { renderNewTravelApprovalRequest }
      </div>
    </StyledWrapper>
  );
});

CartApproveRequest.propTypes = {
  id: PropTypes.number.isRequired,
  travelApprovalService: PropTypes.object.isRequired,
  isTravelApproval: PropTypes.bool.isRequired,
  history: PropTypes.object.isRequired,
  handleValidRequestOnChange: PropTypes.func.isRequired,
};

export { CartApproveRequest };
