// @ts-nocheck
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Button, Datepicker, Select, Text, PROPS } from 'new-ui';
import { getText } from '../../../../../i18n';

import { getMoment, startOfDay } from '../../../../bi/utils/formatDate';

import TRAVELERS from '../../../../bi/constants/travelers';
import { QA_ATTRIBUTES } from '../../../../bi/constants/attributesForTests';

import styles from '../../styles/dialog/train.module.css';

const LABELS = {
  TITLE: getText('favorites:dialogs.train.title'),
  DATE: getText('favorites:dialogs.train.date'),
  START_SEARCH: getText('favorites:dialogs.train.startSearch'),
  CANCEL: getText('common:undo'),
  TRAVELLERS: getText('favorites:dialogs.train.travellers'),
};

class TrainSearchStartDialog extends Component {
  static propTypes = {
    onSearch: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    qaAttrs: PropTypes.object,
  };

  static defaultProps = {
    qaAttrs: {
      wrapper: '',
      header: '',
      date: '',
      passengers: '',
      buttons: {
        startSearch: '',
        cancel: '',
      },
    },
  };

  state = {
    dateFrom: getMoment(),
    minDate: startOfDay(),
    travellers: TRAVELERS[0].value,
  };

  handleChangeDate = value => this.setState({ dateFrom: value });

  handleChangeTravelers = value => this.setState({ travellers: value });

  render() {
    const { dateFrom, travellers, minDate } = this.state;
    const { onSearch, onClose, qaAttrs } = this.props;

    return (
      <div className={ styles.wrapper }>
        <Text type={ PROPS.TEXT.TYPES.BOLD_20 } qaAttr={ qaAttrs.header }>
          { LABELS.TITLE }
        </Text>
        <Datepicker
          placeholder={ LABELS.DATE }
          inputClassName={ styles.input }
          wrapperClassName={ styles.date }
          value={ dateFrom }
          min={ minDate }
          onChange={ this.handleChangeDate }
          qaAttr={ qaAttrs.date }
          qaAttrNextMonth={ QA_ATTRIBUTES.favorites.chooseDateDialog.checkInNextMonth }
        />
        <Select
          withLabel
          theme={ PROPS.SELECT.THEMES.DEFAULT_BORDER }
          className={ styles.select }
          value={ travellers }
          placeholder={ LABELS.TRAVELLERS }
          items={ TRAVELERS }
          onChange={ this.handleChangeTravelers }
          qaAttr={ qaAttrs.passengers }
        />
        <div className={ styles.action }>
          <Button
            onClick={ () => onSearch({ date: dateFrom, travellers }) }
            qaAttr={ qaAttrs.buttons.startSearch }
          >
            { LABELS.START_SEARCH }
          </Button>
          <Button
            className={ styles.cancel }
            type={ PROPS.BUTTON.TYPES.TEXTUAL_MEDIUM }
            onClick={ onClose }
            qaAttr={ qaAttrs.buttons.cancel }
          >
            { LABELS.CANCEL }
          </Button>
        </div>
      </div>
    );
  }
}

export default TrainSearchStartDialog;
