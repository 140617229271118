import React, { FC } from 'react';
import { Text, Button, Icon, Price, ItemPanel } from 'new-ui';
import { Link } from 'react-router-dom';
import * as queryString from 'query-string';

import { getText } from '../../../../../i18n';

import MainAnalytic from '../../../../bi/utils/analytics/main';

import { QA_ATTRIBUTES } from '../../../../bi/constants/attributesForTests';
import { FLIGHT_TYPE_FORMAT } from '../../../../bi/constants/vipHall';
import ROUTES from '../../../../bi/constants/routes';

import { ImageGallery } from '../../../../components/ImageGallery/ImageGallery';

import { VipHallItemProps } from '../../../../bi/types/vipHall';

import styles from './index.module.css';

const LABELS = {
  PRICE: getText('vipHall:item.price'),
  PER_PASSENGER: getText('vipHall:item.perPassenger'),
  ORDER: getText('vipHall:item.order'),
  DOMESTIC: getText('vipHall:item.domestic'),
  INTERNATIONAL: getText('vipHall:item.international'),
};

const VipHallItem: FC<VipHallItemProps> = ({
  item: {
    hallId,
    additionalServices,
    description,
    flightType,
    images,
    name,
    price,
  },
  match: { params: { tripId, ticketId, guid } },
  isVipHallPage,
}) => {
  const { route, segment, terminal } = queryString.parse(location.search);

  const isInternationalFlight = flightType === FLIGHT_TYPE_FORMAT.INTERNATIONAL.toLowerCase();

  const handleGoToInfo = () => {
    const analyticType = isVipHallPage
      ? MainAnalytic.ACTIONS.VIP_HALL.VIP_BUTTON_PRESSED
      : MainAnalytic.ACTIONS.VIP_HALL.ESCORT_BUTTON_PRESSED;

    MainAnalytic.sendFirebase(analyticType, {
      name_service: name,
      price,
    });
  };

  const renderHeader = () => (
    <div className={ styles.header }>
      <Text type='NORMAL_18'>
        { name }
      </Text>
    </div>
  );

  const renderImage = () => {
    const imageStyles = {
      minHeight: '230px',
      maxHeight: '230px',
      objectFit: 'cover',
    };
    const items = images?.map((image) => ({ original: image }));

    return images?.length ? (
      <div className={ styles['img-wrapper'] }>
        <ImageGallery
          showThumbnails={ false }
          items={ items }
          originalImageStyles={ imageStyles }
        />
      </div>
    ) : null;
  };

  const renderFlightIcon = () => {
    const typeIcon = isInternationalFlight ? 'international' : 'menuSmartdesk';

    return (
      <Icon type={ typeIcon } color='blue' className={ styles.icon } />
    );
  };

  const renderAdditional = () => additionalServices?.slice(0, 3).map((service: string, index: number) => {
    const serviceText = service.length > 20 ? `${service.substring(0, 20)}...` : service;

    return (
      <div key={ index } className={ styles.additional_item }>
        <Text type='NORMAL_14' color='gray'>{ serviceText }</Text>
      </div>
    );
  });

  const renderInfo = () => {
    const flightTypeText = isInternationalFlight ? LABELS.INTERNATIONAL : LABELS.DOMESTIC;
    const descriptionText = description.length > 80 ? `${description.substring(0, 80)}...` : description;

    return (
      <div className={ styles.info }>
        <div className={ styles.flight }>
          { renderFlightIcon() }
          <Text type='bold_14' className={ styles.flight_text }>{ flightTypeText }</Text>
        </div>
        <Text type='NORMAL_16' className={ styles.description }>{ descriptionText }</Text>
        <div className={ styles.additional }>{ renderAdditional() }</div>
      </div>
    );
  };

  const renderPrice = () => {
    const link = isVipHallPage ?
      ROUTES.ADDITIONAL_SERVICES.INFO_VIP(hallId, tripId, ticketId, guid, route, segment, terminal) :
      ROUTES.ADDITIONAL_SERVICES.INFO_ESCORT(hallId, tripId, ticketId, guid, route, segment, terminal);

    return (
      <div className={ styles.actions }>
        <Text type='NORMAL_14'>{ LABELS.PRICE }</Text>
        <Price
          qaAttr={ QA_ATTRIBUTES.vipHall.result.price }
          className={ styles.price_tooltip }
          value={ price }
          marginSmall
          type='bold_24'
        />
        <Text type='NORMAL_12' color='gray'>{ LABELS.PER_PASSENGER }</Text>
        <div className={ styles.action }>
          <Link
            target='_blank'
            to={ link }
          >
            <Button
              qaAttr={ QA_ATTRIBUTES.vipHall.result.orderButton }
              type='secondary'
              onClick={ handleGoToInfo }
            >
              { LABELS.ORDER }
            </Button>
          </Link>
        </div>
      </div>
    );
  };

  return (
    <ItemPanel
      className={ styles.item }
      renderHeader={ renderHeader }
    >
      <div className={ styles.content }>
        <div className={ styles.main }>
          <div>
            { renderImage() }
          </div>
          { renderInfo() }
        </div>
        <div className={ styles.actions }>
          { renderPrice() }
        </div>
      </div>
    </ItemPanel>
  );
};

export { VipHallItem };
