// @ts-nocheck
export const createDiscountSapsan = (list) =>
  list.reduce((acc, {
    Data: {
      DiscountAvailable,
      Discount,
      DateDepartureLocal,
    },
    Id,
    Employees,
  }) => {
    if (DiscountAvailable) {
      const second = list.find(({ Id: secondId }) => secondId === Discount.BackwardId) || null;

      if (second) {
        const newPayload = {
          Id,
          FirstDate: DateDepartureLocal,
          SecondDate: second.Data.DateDepartureLocal,
          Discount,
          Employees,
        };

        return [...acc, newPayload];
      }
    }

    return acc;
  }, []);

export const mappedOnlyTrainList = (list) => list
  .map(item => ({
    ...item,
    Data: JSON.parse(item.Data),
  }));
