import React from 'react';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import { Button, Paginate, Table, Text, PageLoader } from 'new-ui';

import { getText } from '../../../../../../i18n';

import ROUTES from '../../../../../bi/constants/routes';

import { ChartAnalyticsNoResult } from '../../noResult';

import { INestedTable, ISmartData } from '../type';
import { SubTableStringsType } from '../../../../../bi/services/chartsAnalytics/types';

import styles from './styles.module.css';

const CANCELED = getText('chartsAnalytics:canceled');

const TrainNestedTable = observer(({ store, onPageChange }: INestedTable) => {
  const {
    subTableInfo: {
      strings,
      columnsName,
    },
    paginate: {
      currentPage,
      itemsPerPage,
      total,
    },
    loading,
  } = store;

  if (loading) return <PageLoader />;

  if (!strings?.length) return <ChartAnalyticsNoResult />;

  const firstLabel = () => (
    <Text className={ styles.route } type='SEMIBOLD_14'>{ columnsName[0] }</Text>
  );

  const header = [firstLabel(), ...columnsName.slice(1)];

  const renderCanceled = (isCanceled: boolean, data: string[], indexData: number) => {
    if (isCanceled && indexData === data.length - 1) {
      return <div className={ styles.canceled }>{ CANCELED }</div>;
    }

    return null;
  };

  const mapperData = (value: string, indexData: number, isCanceled: boolean, data: any) => {
    if (indexData === 0) return null;

    return (
      <td key={ indexData }>
        <div className={ styles.value_wrap }>
          { value }
          { renderCanceled(isCanceled, data, indexData) }
        </div>
      </td>
    );
  };

  const routeHtml = (route: string, tripId: number) => (tripId ? (
    <Link
      to={ `${ROUTES.TRIP.MAIN}/${tripId}` }
      target='_blank'
    >
      <Button
        className={ styles.link }
        type='textual'
      >
        <div className={ styles.route_wrap }>{ route }</div>
      </Button>
    </Link>
  ) : <div className={ `${styles.route_color} ${styles.route_wrap}` }>{ route }</div>);

  const renderСolumn = (items: string[], info: ISmartData) => items.map((value, indexData) => mapperData(value, indexData, info?.isCanceled, items));

  const renderTable = (items: SubTableStringsType[]) =>
    items.map(({ data, subPageInfo }, indexRow) => (
      <tr key={ indexRow }>
        <td>
          { routeHtml(data[0], subPageInfo?.tripId) }
        </td>
        { renderСolumn(data, subPageInfo) }
      </tr>
    ));

  return (
    <>
      <div className={ styles.table }>
        <Table
          labelsHeader={ header as any }
        >
          { renderTable(strings) }
        </Table>
      </div>
      <div className={ styles.footer }>
        <div className={ styles.paginate }>
          <Paginate
            page={ currentPage }
            itemsPerPage={ itemsPerPage }
            total={ total }
            onChange={ onPageChange }
          />
        </div>
      </div>
    </>
  );
});

export { TrainNestedTable };
