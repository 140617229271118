import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Moment } from 'moment';
import { RouteComponentProps } from 'react-router-dom';
import { Text, PageLoader, NoResults } from 'new-ui';

import { useStores } from '../../bi/context';
import { MOBX_STORES } from '../../bi/context/stores';

import { getText } from '../../../i18n';

import ROUTES from '../../bi/constants/routes';
import { APPROVE_TYPE } from '../../bi/constants/approve';

import ExpenseReportsService from '../../bi/services/expenseReports';
import Report from '../../bi/services/report';
import DepartmentsService from '../../bi/services/departments';

import { DepartmentsListItem } from '../../bi/services/departments/types';

import { companiesWithDepartmentsMapper } from '../../bi/utils/departments';
import { MainAnalytic } from '../../bi/utils/analytics';

import ErrorReportDialog from '../../components/ErrorReportDialog';

import ReportApproveDialog from '../Approve/components/ApproveReportDialog';

import { ExpenseReportItem } from './components/expenseReportItem';

import styles from './styles/index.module.css';

const LABELS = {
  TOOLTIP_TEXT: getText('approve:tooltip'),
  DOWNLOAD: getText('approve:download'),
  CLOSE: getText('approve:aggressiveClose'),
  NO_LIST: getText('approve:noResultsExpenses'),
  LOADER: getText('approve:loader'),
  NO_APPROVING: getText('approve:noApproving'),
  EXPENSE_REPORTS: getText('approve:expenseReports'),
};

interface IApproveExpenseReportPage {
  departmentsService: DepartmentsService,
  reportService: Report,
  expenseReportsService: ExpenseReportsService,
  history: RouteComponentProps['history'],
}

const ApproveExpenseReportPage = observer(({
  departmentsService,
  reportService,
  expenseReportsService,
  history,
}: IApproveExpenseReportPage) => {
  useEffect(() => {
    MainAnalytic.sendAmplitude(
      MainAnalytic.ACTIONS.APPROVEMENT.COORDINATION_EXPENSE_SCREEN_OPENED,
    );
  }, []);

  const [selectCompaniesItems, setSelectCompaniesItems] = useState([]);

  const { approveStore, approveReportStore } = useStores([MOBX_STORES.EXPENSE_REPORTS_STORE_APPROVE, MOBX_STORES.APPROVE_REPORT_STORE]);

  const { loading, approves } = approveStore;
  const { errorDialog, downloadDialog } = approveReportStore;

  const { getApproves } = expenseReportsService;

  const loadCompaniesWithDepartments = () =>
    departmentsService.justLoadList()
      .then((list: DepartmentsListItem) => setSelectCompaniesItems(companiesWithDepartmentsMapper(list)));

  useEffect(() => {
    getApproves();
    loadCompaniesWithDepartments();
  }, [getApproves]);

  const handleExpenseReportItemClick = ({ ExpenseReportId, Id }: { ExpenseReportId: number, Id: number }) => {
    expenseReportsService.updateApproveId(Id);
    history.push(ROUTES.EXPENSE.EXPENSE_REPORT_ID(ExpenseReportId));

    MainAnalytic.sendAmplitude(
      MainAnalytic.ACTIONS.APPROVEMENT.COORDINATION_EXPENSE_TRIP_AGREED_CHOSE,
    );
  };

  const handleDownloadApproveReport = async (startDate: Moment, endDate: Moment, companies: any) => {
    MainAnalytic.sendAmplitude(
      MainAnalytic.ACTIONS.APPROVEMENT.COORDINATION_EXPENSE_DOWNLOAD_PRESSED,
    );

    return reportService.downloadReportApprove(startDate, endDate, companies, APPROVE_TYPE.EXPENSE_REPORTS);
  };

  const handleErrorDialog = (show: boolean, msg: string) => reportService.setErrorReportDialog(show, msg);

  const handleDownloadDialog = (show: boolean, load: boolean) => reportService.setDownloadReportDialog(show, load);

  const renderLoading = () => (
    <PageLoader text={ LABELS.LOADER } />
  );

  const renderDownloadDialog = () => (
    <ReportApproveDialog
      show={ downloadDialog.show }
      loading={ downloadDialog.loading }
      onClose={ (show: boolean) => handleDownloadDialog(show, downloadDialog.loading) }
      onDownload={ handleDownloadApproveReport }
      companies={ selectCompaniesItems }
    />
  );

  const renderEmptyList = () => (
    <div className={ styles.empty }>
      <div className={ styles.header }>
        { renderDownloadDialog() }
      </div>
      <ErrorReportDialog errorDialog={ errorDialog } onErrorDialog={ handleErrorDialog } />
      <NoResults label={ LABELS.NO_LIST } />
    </div>
  );

  const renderApprovesList = (approvesList: any) => {
    const html = (item: any, ind: number) => (
      <ExpenseReportItem
        key={ `${item.Id}_${ind}` }
        item={ item }
        onClick={ () => handleExpenseReportItemClick(item) }
      />
    );

    return (
      <div className={ styles.list }>
        { approvesList.map(html) }
      </div>
    );
  };

  const renderLists = (approvesList: any) => {
    if (!approvesList.length) {
      return renderEmptyList();
    }

    const approvesListHtml = !!approvesList.length &&
      renderApprovesList(approvesList);

    return (
      <div className={ styles.list }>
        <div className={ styles.header }>
          <Text type='bold_32'>{ LABELS.EXPENSE_REPORTS }</Text>
          { renderDownloadDialog() }
        </div>
        { approvesListHtml }
        <ErrorReportDialog errorDialog={ errorDialog } onErrorDialog={ handleErrorDialog } />
      </div>
    );
  };

  if (loading) {
    return renderLoading();
  }

  return (
    <div className={ styles.wrapper }>
      { renderLists(approves) }
    </div>
  );
});

export { ApproveExpenseReportPage };
