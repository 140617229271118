import * as React from 'react';
import { getText } from '../../../../../../../i18n';

import styles from './index.module.css';

import percentSvg from '../../images/percent.svg';
import milesSvg from '../../images/miles.svg';
import okSvg from '../../images/ok.svg';

import travellerSvg from '../../images/traveller.svg';

const LABELS = {
  CASHBACK_TO: getText('settings:bonusProgram.promo.save.cashbackTo'),
  WITH: getText('settings:bonusProgram.promo.save.with'),
  TRIP_ON: getText('settings:bonusProgram.promo.save.tripOn'),
  COMPANY_ACCOUNT: getText('settings:bonusProgram.promo.save.companyAccount'),
  S7_MILES: getText('settings:bonusProgram.promo.save.s7Miles'),
  ON: getText('settings:bonusProgram.promo.save.on'),
  PERSONAL_ACCOUNT: getText('settings:bonusProgram.promo.save.personalEmployeeAccount'),
  ENOUGH: getText('settings:bonusProgram.promo.save.enough'),
  ONLY_REGISTRATION: getText('settings:bonusProgram.promo.save.onlyRegistration'),
  NO_ONE_PAPER: getText('settings:bonusProgram.promo.save.noOnePaper'),
};

const FEATURES = [
  {
    icon: percentSvg,
    jsx: (
      <p className={ styles.save_text }>
        <strong>{ LABELS.CASHBACK_TO }</strong> { LABELS.WITH } { LABELS.TRIP_ON } { LABELS.COMPANY_ACCOUNT }
      </p>
    ),
  },
  {
    icon: milesSvg,
    jsx: (
      <div className={ styles.wrap }>
        <p className={ styles.save_text }>
          <strong>{ LABELS.S7_MILES }</strong>
        </p>
        <p className={ styles.save_text }>{ LABELS.ON } { LABELS.PERSONAL_ACCOUNT }</p>
      </div>
    ),
  },
  {
    icon: okSvg,
    jsx: (
      <div className={ styles.wrap }>
        <p className={ styles.save_text }>
          <strong>{ LABELS.ENOUGH }</strong>
        </p>
        <p className={ styles.save_text }>{ LABELS.NO_ONE_PAPER }</p>
      </div>
    ),
  },
];

const Save = () => {
  const items = FEATURES.map(({ icon, jsx }, key) => (
    <div className={ styles.item } key={ key }>
      <div className={ styles.item_icon }>
        <img src={ icon } alt='' />
      </div>
      { jsx }
    </div>
  ));

  return (
    <div className={ styles.save }>
      <div className={ styles.items }>
        {items}
      </div>
      <div className={ styles.save_image }>
        <img src={ travellerSvg } alt='' />
      </div>
    </div>
  );
};

export { Save };
