import React, { useState, useEffect } from 'react';
import { Button, Dialog, Icon, PageLoader, Text, Tooltip } from 'new-ui/src';

import { getText } from '../../../../../i18n';

import { VouchersPropsType } from '../../../../bi/types/voucherSettings';

import { MainAnalytic } from '../../../../bi/utils/analytics';

import { EditVouchers } from './components/EditVouchers/EditVouchers';

import styles from './styles/index.module.css';

const LABELS = {
  TITLE: getText('settings:vouchers:header:formation'),
  RESET_SETTINGS: getText('settings:vouchers:default:reset'),
  EDIT: getText('settings:vouchers:default:edit'),
  LOGO: getText('settings:vouchers:logo:title'),
  CONTACT_INFO: getText('settings:vouchers:contactInfo:title'),
  ADDITIONAL_INFO: getText('settings:vouchers:additionalInfo:title'),
  MODAL_WARNING: getText('settings:vouchers:default:warning'),
  CANCEL: getText('settings:vouchers:cancel'),
  RESET: getText('settings:vouchers:reset'),
  SIZE_MB: (size: number) => getText('settings:vouchers:logo:size', { size }),
  NO_LOGO: getText('settings:vouchers:validationDownloadDocument:errors:noLogo'),
  TOOLTIP_SAVE: getText('settings:vouchers:tooltipSave'),
};

const Vouchers = ({
  workspaceService: {
    isAdmin,
    isDemo,
  },
  settingsService,
  settingsService: {
    setSettingsVoucher,
    setShowEditVoucher,
    getInterVoucherSettings,
  },
  stores,
  stores: {
    settingsStore: {
      voucherSetting: {
        ContactInfo,
        AdditionalInfo,
        OriginalFileName,
        OriginalFileSize,
        IsLogo,
        SuccessUpload,
      },
    },
  },
}: VouchersPropsType) => {
  const [showResetDialog, setShowResetDialog] = useState(false);
  const [isShowEdit, setIsShowEdit] = useState(false);

  useEffect(() => {
    MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.SMART_AGENT.SETTINGS_FORM_OPENED);
  }, []);

  if (!SuccessUpload) {
    return <PageLoader/>;
  }

  const setShowEdit = (value: boolean) => {
    setShowEditVoucher(value);

    setIsShowEdit(value);
  };

  const voucherReset = () => {
    setSettingsVoucher();

    return setShowResetDialog(false);
  };

  const openDialog = (open: boolean) => {
    getInterVoucherSettings();
    MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.SMART_AGENT.SETTINGS_FORM_EDIT_BUTTON);

    setShowEdit(open);
  };

  const handleResetSettings = () => {
    MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.SMART_AGENT.SETTINGS_FORM_DELETE_BUTTON);
    setShowResetDialog(true);
  };

  const renderResetDialog = () => (
    <Dialog
      show={ showResetDialog }
      showClosing
      onChange={ () => setShowResetDialog(false) }
    >
      <Text
        color='orange'
        type='NORMAL_16'
        className={ styles.text_modal }
      >
        { LABELS.MODAL_WARNING }
      </Text>
      <div
        className={ styles.button_modal_wrapper }
      >
        <Button
          type='textual'
          onClick={ () => setShowResetDialog(false) }
        >
          { LABELS.CANCEL }
        </Button>
        <Button
          onClick={ () => voucherReset() }
        >
          { LABELS.RESET }
        </Button>
      </div>
    </Dialog>
  );

  const tooltipSave = () => (
    <div>
      <Text
        className={ styles.tooltip_demo }
        color='white'
        type='NORMAL_14'
      >
        { LABELS.TOOLTIP_SAVE }
      </Text>
    </div>
  );

  const renderReset = () => {
    if (!isAdmin) {
      return null;
    }

    return (
      <Tooltip
        className={ styles.tooltip_wrapper }
        show={ isDemo }
        clickable={ false }
        renderContent={ () => tooltipSave() }
      >
        <Button
          type='textual'
          onClick={ handleResetSettings }
          disabled={ isDemo }
        >
          <Text
            color='orange'
          >
            { LABELS.RESET_SETTINGS }
          </Text>
        </Button>
      </Tooltip>
    );
  };

  const renderLogo = () => {
    const sizeMB = LABELS.SIZE_MB(Number(OriginalFileSize[0]) + 1);

    return (
      <div
        className={ styles.wrapper_log }
      >
        <Icon
          className={ styles.icon_indent }
          type='menuReportsBiggest'
        />
        <div>
          <Text
            className={ styles.name_files }
            type='bold_16'
          >
            { OriginalFileName }
          </Text>
          <Text
            className={ styles.text_size }
            type='NORMAL_14_130'
          >
            { sizeMB }
          </Text>
        </div>
      </div>
    );
  };

  const renderInfo = (info: string) => (
    <Text
      color='gray'
    >
      { info }
    </Text>
  );

  const renderButtonEdit = () => {
    if (!isAdmin) {
      return null;
    }

    return (
      <Button
        type='textual'
        className={ styles.button_cancel }
        onClick={ () => openDialog(!isShowEdit) }
      >
        { LABELS.EDIT }
      </Button>
    );
  };

  if (!IsLogo || isShowEdit) {
    return (
      <EditVouchers
        settingsService={ settingsService }
        // @ts-ignore
        stores={ stores }
        setShow={ () => setShowEdit(!isShowEdit) }
        isDemo={ isDemo }
      />
    );
  }

  return (
    <div
      className={ styles.wrapper }
    >
      { renderResetDialog() }
      <div
        className={ styles.header_wrapper }
      >
        <Text
          type='bold_24'
        >
          { LABELS.TITLE }
        </Text>
        { renderReset() }
      </div>
      <div
        className={ styles.logo_upload_wrapper }
      >
        <Text
          type='NORMAL_16'
        >
          { LABELS.LOGO }
        </Text>
        { renderLogo() }
      </div>
      <div
        className={ styles.wrapper_info }
      >
        <Text>
          { LABELS.CONTACT_INFO }
        </Text>
        { renderInfo(ContactInfo) }
      </div>
      <div
        className={ styles.wrapper_info }
      >
        <Text>
          { LABELS.ADDITIONAL_INFO }
        </Text>
        { renderInfo(AdditionalInfo) }
      </div>
      <div
        className={ styles.button_save_wrapper }
      >
        { renderButtonEdit() }
      </div>
    </div>
  );
};

export { Vouchers };
