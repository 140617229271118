import { Moment } from 'moment';

import { getText } from '../../../../../../i18n';

const labels: { [key: string]: string } = {
  name: getText('event:events.fields.name'),
  place: getText('event:events.fields.place'),
  member: getText('event:events.fields.members'),
  budget: getText('event:events.fields.budget'),
  startDate: getText('event:events.fields.startDate'),
  endDate: getText('event:events.fields.endDate'),
};

interface IValid {
  name: string,
  place: string,
  budget: string,
  startDate: string | Moment | null,
  endDate: string | Moment | null,
  member: string
}

const setFormValidationMice = (values: IValid) => {
  const missingValues = Object.entries(values)
    .filter(([key, value]) => !value && key)
    .map(([key]) => labels[key]);

  return missingValues.join(', ');
};

export {
  setFormValidationMice,
};
