import React, { useState, useEffect, FC } from 'react';

import { observer } from 'mobx-react';

import { useStores } from '../../../../bi/context';
import { MOBX_STORES } from '../../../../bi/context/stores';

import OperationsTable from '../../../../components/OperationsTable';
import { Header } from './header';
import { DialogRequestAct } from './DialogRequestAct';
import { DialogRequestError } from './DialogRequestError';
import { PaymentDetailsDialog } from './PaymentDetails';

import { IOperationsList, IOperationsTotal, ReportService, IDownloadSpecifiedReport } from '../../../../bi/types/report';
import PaymentService from '../../../../bi/services/payment';
import { SendArgs } from '../../../../bi/types/notification';
import { Company } from '../../../../bi/services/workspace/types';

import { MainAnalytic } from '../../../../bi/utils/analytics';

interface OperationsComponentProps {
  reportService: ReportService,
  paymentService: PaymentService,
  currentCompany: number,
  sendNotification: (args: SendArgs) => void,
  companies: Company[] | null,
  onChangeCompanyId: (value: number) => void,
  overdraft: number,
  showFinanceDetails: boolean,
  companyEmail: string,
  companyInn: string,
  companyName: string,
  isDemo: boolean,
  isSpecifiedReport: boolean,
  multi: boolean,
  allCompanies: Company[],
  accountId: number,
  isSpecifiedReportGS: boolean,
  paymentType: string | number,
}

const prepareComapniesItems = (companies: Company[] | null) => {
  if (!companies) {
    return [];
  }

  return companies.map(({ ShortCompanyName, CompanyId, CompanyName }) => ({
    label: ShortCompanyName || CompanyName,
    value: CompanyId,
  }));
};

const OperationsComponent: FC<OperationsComponentProps> = observer(({
  reportService,
  paymentService,
  sendNotification,
  companies,
  currentCompany,
  overdraft,
  showFinanceDetails,
  companyEmail,
  companyInn,
  companyName,
  isDemo,
  isSpecifiedReport,
  isSpecifiedReportGS,
  allCompanies,
  onChangeCompanyId,
  accountId,
  multi,
  paymentType,
}) => {
  const {
    requestDialogStore: {
      showRequestDialog,
      showRequestErrorDialog,
      setShowRequestDialog,
      setShowRequestErrorDialog,
      setShowSelection,
    },
  } = useStores([MOBX_STORES.REQUEST_DIALOG_STORE]);

  const { items: startItems, total: startTotal, more: startMore } = reportService.getOperations();
  const [items, setItems] = useState<IOperationsList[]>(startItems);
  const [total, setTotal] = useState<IOperationsTotal>(startTotal);
  const [more, setMore] = useState<boolean>(startMore);
  const [loading, setLoading] = useState<boolean>(true);
  const [bankDetails, setBankDetails] = useState<string>('');
  const [showDetailsDialog, setShowDetailsDialog] = useState<boolean>(false);

  const updateOperations = ({ loading: updLoading, items: updItems, total: updTotal, more: updMore, bankDetails: updBankDetails }: {
    loading: boolean,
    items: IOperationsList[],
    total: IOperationsTotal,
    more: boolean,
    bankDetails: string,
  }) => {
    setLoading(updLoading);
    setItems(updItems);
    setTotal(updTotal);
    setMore(updMore);
    setBankDetails(updBankDetails);
  };

  useEffect((): void => {
    const unsubscribeFn = reportService.subscribeOperations(updateOperations);
    reportService.loadOperations(currentCompany);
    reportService.loadTotalOperations(currentCompany);
    reportService.getInvoiceRequisites(currentCompany);

    return unsubscribeFn;
  }, [reportService, currentCompany]);

  const preparedCompanies: Company[] | null = companies ? companies.filter(({ IsShowReporting }) => IsShowReporting) : null;

  const companiesItems = prepareComapniesItems(preparedCompanies);

  const handleChangeCompany = (value: number) => {
    onChangeCompanyId(value);
  };

  const handleDownloadSpecifiedReport = (data: IDownloadSpecifiedReport): Promise<void> => (
    isSpecifiedReportGS ? reportService.downloadSpecifiedReportGsprom(data) : reportService.downloadSpecifiedReportSevmash(data)
  );

  const handleShowDetailsDialog = (value: boolean) => setShowDetailsDialog(value);

  const handleRequestAct = async () => {
    MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.REPORTING.ACT_DOWNLOAD_PRESSED);

    await reportService.sendRequestAct(currentCompany);
  };

  const downloadPaymentDetails = async (startDate: string | null, endDate: string | null, periodType: string) => {
    await reportService.downloadPaymentDetails(currentCompany, startDate, endDate, periodType);
    handleShowDetailsDialog(false);
  };

  const handleShowRequestDialog = (value: boolean) => {
    setShowSelection(false);
    setShowRequestDialog(value);
  };

  const handleShowRequestErrorDialog = (value: boolean) => {
    setShowRequestErrorDialog(value);
  };

  const renderRequestActDialogHtml = () => {
    if (!showRequestDialog) {
      return null;
    }

    return (
      <DialogRequestAct
        companiesItems={ companiesItems }
        onRequest={ handleRequestAct }
        accountId={ accountId }
        currentCompany={ currentCompany }
        onClose={ () => handleShowRequestDialog(false) }
      />
    );
  };

  const renderPaymentDetailsDialogHtml = () => {
    if (!showDetailsDialog) {
      return null;
    }

    return (
      <PaymentDetailsDialog
        onDownload={ downloadPaymentDetails }
        onCloseDialog={ () => handleShowDetailsDialog(false) }
        show={ showDetailsDialog }
      />
    );
  };

  const renderRequestErrorDialogHtml = () => {
    if (!showRequestErrorDialog) {
      return null;
    }

    return (
      <DialogRequestError
        onShow={ handleShowRequestErrorDialog }
        onClose={ () => handleShowRequestErrorDialog(false) }
      />
    );
  };

  const companiesBool: boolean = !!(companies && companies.length > 1);

  return (
    <div>
      <Header
        companiesItems={ companiesItems }
        companiesBool={ companiesBool }
        currentCompany={ currentCompany }
        more={ more && showFinanceDetails }
        isSpecifiedReport={ isSpecifiedReport }
        onDownload={ handleDownloadSpecifiedReport }
        onChangeCompany={ handleChangeCompany }
        onShowRequestActDialog={ () => handleShowRequestDialog(true) }
        onShowDetailsDialog={ () => handleShowDetailsDialog(true) }
        bankDetails={ bankDetails }
        paymentType={ paymentType }
      />
      <OperationsTable
        multi={ multi }
        items={ items }
        total={ total }
        loading={ loading }
        currentCompany={ currentCompany }
        reportService={ reportService }
        sendNotification={ sendNotification }
        paymentService={ paymentService }
        overdraft={ overdraft }
        showFinanceDetails={ showFinanceDetails }
        companyEmail={ companyEmail }
        companyInn={ companyInn }
        companyName={ companyName }
        companiesItems={ companiesItems }
        isDemo={ isDemo }
        allCompanies={ allCompanies }
      />
      { renderRequestActDialogHtml() }
      { renderPaymentDetailsDialogHtml() }
      { renderRequestErrorDialogHtml() }
    </div>
  );
});

export default OperationsComponent;
