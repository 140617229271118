import React, { useEffect, useState } from 'react';
import { Button, Datepicker, Dialog, Input, Text, Textarea, Tooltip } from 'new-ui';
import { Moment } from 'moment';

import { getText } from '../../../../../i18n';

import { FieldLabel } from '../../../../components/FieldLabel';

import { onlyNumbersOrEmpty } from '../../../../bi/utils/text';
import { MainAnalytic } from '../../../../bi/utils/analytics';
import { currentDate, momentObject } from '../../../../bi/utils/formatDate';
import { setFormValidationMice } from './setFormValidation/setFormValidation';

import { TEventToBack } from '../../../../bi/services/events/type';

import styles from './styles/index.module.css';

const LABELS = {
  TITLE: getText('event:addEvent.title'),
  NAME: getText('event:addEvent.name'),
  PLACE: getText('event:addEvent.place'),
  MEMBER: getText('event:addEvent.member'),
  BUDGET: getText('event:addEvent.budget'),
  COMMENT: getText('event:addEvent.comment'),
  CREATE: getText('event:addEvent.create'),
  CANCEL: getText('event:addEvent.cancel'),
  DATE: getText('event:addEvent.date'),
  START_DATE: getText('event:addEvent.startDate'),
  END_DATE: getText('event:addEvent.endDate'),
  FIELD_ERRORS: getText('event:addEvent.fieldErrors'),
};

const FIELD_STATE = {
  NAME: 'name',
  PLACE: 'place',
  MEMBER: 'member',
  BUDGET: 'budget',
  START_DATE: 'startDate',
  END_DATE: 'endDate',
  COMMENT: 'comment',
};

interface IAddEvent {
  show: boolean,
  onAddEvent(value: TEventToBack): void,
  onClose(): void,
}

const AddEvent = ({
  show,
  onAddEvent,
  onClose,
}: IAddEvent) => {
  const [requiredState, setRequiredState] = useState({
    name: '',
    place: '',
    member: '',
    budget: '',
    startDate: '',
    endDate: '',
  });
  const { name, place, member, budget, startDate, endDate } = requiredState;

  const [comment, setComment] = useState<string>('');
  const [isValid, setIsValid] = useState<boolean>(false);
  const [errorField, setErrorField] = useState<string>('');

  const restrictionsStartDate = endDate ? momentObject(endDate) : null;

  const updateFormField = (field: string, value: string | Moment | null) => {
    setRequiredState(prevState => ({
      ...prevState,
      [field]: value,
    }));
  };

  useEffect(() => {
    const validation = setFormValidationMice(requiredState);

    setIsValid(!validation);
    setErrorField(validation);
  }, [requiredState]);

  const conditionMin = startDate || currentDate();

  const changeNumberValue = (field: string, value: string) => {
    if (onlyNumbersOrEmpty(value)) {
      updateFormField(field, value);
    }
  };

  const handleSumbit = (e: any) => {
    e.preventDefault();

    if (isValid) {
      onAddEvent({
        name,
        place,
        member,
        budget,
        comment,
        startDate,
        endDate,
      });

      MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.MICE.EVENT.CREATE);
    }
  };

  const renderTooltip = () => (
    <div
      className={ styles.wrapper_tooltip }
    >
      <Text color='white'>
        { LABELS.FIELD_ERRORS }
      </Text>
      <Text color='white'>
        { errorField }
      </Text>
    </div>
  );

  return (
    <Dialog
      show={ show }
      showClosing
      onChange={ onClose }
    >
      <div
        className={ styles.wrapper }
      >
        <Text
          type='bold_22'
        >
          { LABELS.TITLE }
        </Text>
        <form
          onSubmit={ (e) => handleSumbit(e) }
        >
          <div
            className={ `${styles.margin}` }
          >
            <FieldLabel
              mustHave
              text={ LABELS.NAME }
            />
            <Input
              value={ name }
              onChange={ (value: string) => updateFormField(FIELD_STATE.NAME, value) }
            />
          </div>
          <div
            className={ styles.wrapper_place }
          >
            <div
              className={ styles.margin }
            >
              <FieldLabel
                mustHave
                text={ LABELS.PLACE }
              />
              <Input
                className={ styles.place }
                value={ place }
                onChange={ (value: string) => updateFormField(FIELD_STATE.PLACE, value) }
              />
            </div>
            <div
              className={ styles.margin }
            >
              <FieldLabel
                mustHave
                text={ LABELS.MEMBER }
              />
              <Input
                value={ member }
                onChange={ (value: string) => changeNumberValue(FIELD_STATE.MEMBER, value) }
              />
            </div>
          </div>
          <div
            className={ styles.margin }
          >
            <FieldLabel
              mustHave
              text={ LABELS.DATE }
            />
            <div
              className={ styles.wrapper_date }
            >
              <div
                className={ styles.date }
              >
                <Text
                  className={ styles.text_date }
                  color='gray'
                >
                  { LABELS.START_DATE }
                </Text>
                <div
                  className={ styles.date_width }
                >
                  <Datepicker
                    ligthLabel
                    min={ currentDate() }
                    max={ restrictionsStartDate }
                    inputClassName={ styles.date_width }
                    value={ startDate }
                    onChange={ (value: string | Moment | null) => updateFormField(FIELD_STATE.START_DATE, value) }
                  />
                </div>
              </div>
              <div
                className={ styles.date }
              >
                <Text
                  className={ styles.text_date }
                  color='gray'
                >
                  { LABELS.END_DATE }
                </Text>
                <div
                  className={ styles.date_width }
                >
                  <Datepicker
                    ligthLabel
                    min={ conditionMin as Moment }
                    value={ endDate }
                    onChange={ (value: string | Moment | null) => updateFormField(FIELD_STATE.END_DATE, value) }
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            className={ `${styles.margin}` }
          >
            <FieldLabel
              mustHave
              text={ LABELS.BUDGET }
            />
            <Input
              value={ budget }
              onChange={ (value: string) => changeNumberValue(FIELD_STATE.BUDGET, value) }
            />
          </div>
          <div
            className={ `${styles.margin}` }
          >
            <FieldLabel
              text={ LABELS.COMMENT }
            />
            <Textarea
              noneResize
              value={ comment }
              onChange={ (value: string) => setComment(value) }
              className={ styles.input_size }
              withInput
            />
          </div>
          <div
            className={ styles.wrapper_button }
          >
            <Tooltip
              show={ !isValid }
              renderContent={ renderTooltip }
            >
              <Button
                formType='submit'
                onClick={ (e) => handleSumbit(e) }
                disabled={ !isValid }
              >
                { LABELS.CREATE }
              </Button>
            </Tooltip>
            <Button
              formType='submit'
              onClick={ () => onClose() }
              type='textual'
              className={ styles.button_cancel }
            >
              { LABELS.CANCEL }
            </Button>
          </div>
        </form>
      </div>
    </Dialog>
  );
};

export default AddEvent;
