// @ts-nocheck
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Button, Checkbox, Icon, PROPS, StyledWrapper, Text, PageLoader, Price, BackLink } from 'new-ui';
import { getText } from '../../../i18n';

import { MainAnalytic } from '../../bi/utils/analytics';

import ROUTES from '../../bi/constants/routes';
import { TAXI_CONFLICTS_TYPE } from '../../bi/constants/checkout';
import { QA_ATTRIBUTES } from '../../bi/constants/attributesForTests';
import SERVICETYPE from '../../bi/constants/serviceType';
import { AGGREGATORS_ACCOUNT, CART_WARN_COMPANIES } from '../../bi/constants/accounts';

import TripName from '../../components/TripName';
import DuplicateDialog from '../../components/DuplicateDialog';
import { TaxiConflictDialog } from '../../components/TaxiConflictDialog';
import DiscountDialog from '../../components/DiscountDialog';
import RateDialog from '../../components/RateDialog';
import DialogRules from '../../components/RulesDialog';
import CheckoutItem from './components/CheckoutItem/checkoutItem';
import AnalyticsBar from '../../components/AnalyticsBar';
import { CartWarning } from '../../components/UnderageWarning';

import { isSmartAgent } from '../../bi/utils/env';
import { getPriceAllCertificates } from '../../bi/utils/airline';
import parseSearchString from '../../bi/utils/convertSearchParams';

import { ICompanyFunds } from '../../bi/types/app';

import styles from './styles/checkout.module.css';

const { BUTTON: { TYPES: { TEXTUAL, SECONDARY } } } = PROPS;

const BILLTITLES = {
  SHOW: getText('checkout:bill.show'),
  SHOW_FEW: getText('checkout:bill.showFew'),
  NOTSHOW: getText('checkout:bill.notShow'),
  NOTSHOWMANY: getText('checkout:bill.notShowMany'),
};

const LABELS = {
  TITLE: getText('checkout:title'),
  TITLE_BOOK_TRIP: getText('checkout:titleBookTrip'),
  BOOK_TRIP_LABEL: getText('checkout:bookTripLabel'),
  ATTENTION: getText('checkout:finish.attention'),
  CHECKBOX: {
    ONE: getText('checkout:finish.checkbox.one'),
    TWO: getText('checkout:finish.checkbox.two'),
  },
  PAY: getText('checkout:finish.pay'),
  CART: getText('checkout:finish.cart'),
};

class Checkout extends Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    checkoutService: PropTypes.object.isRequired,
    workspaceService: PropTypes.object.isRequired,
    appService: PropTypes.object.isRequired,
    notepadService: PropTypes.object.isRequired,
    userSessionService: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    aggregationId: PropTypes.number.isRequired,
    customAnalyticsService: PropTypes.object.isRequired,
    accountSettingsService: PropTypes.object.isRequired,
    featureFlagsService: PropTypes.object.isRequired,
    eventService: PropTypes.object.isRequired,
  };

  getPersonalBonusCards = this.props.accountSettingsService.getPersonalBonusCards();

  constructor(props) {
    super(props);

    const {
      id,
      sources,
      name,
      loading,
      minDate,
      maxDate,
      price,
      accept,
      checkoutData,
      conflicts,
      discountSapsan,
      userAnalytics,
    } = props.checkoutService.get();

    const userSessionServiceGet = props.userSessionService.get();

    this.state = {
      id,
      sources,
      name,
      loading,
      minDate,
      maxDate,
      price,
      accept,
      checkoutData,
      conflicts,
      discountSapsan,
      userAnalytics,
      showDialog: false,
      duplicateDialog: false,
      discountDialog: false,
      rateDialog: false,
      isMultiCompany: props.workspaceService.get().Companies.length > 1,
      companies: props.workspaceService.get().Companies,
      projects: userSessionServiceGet.projects,
      documentType: userSessionServiceGet.enums.documents,
      isFiltersHotelsInMicroservice: this.props.featureFlagsService.getFiltersHotelsInMicroservice(),
    };
  }

  componentDidMount() {
    const { checkoutService, appService, match, customAnalyticsService } = this.props;
    const id = match.params.id;

    this.unsubscribeFn = checkoutService.subscribeCart(this.updateState);
    this.unsubscribeAppFn = appService.subscribe(this.updateCompanyFunds);

    checkoutService.start();
    checkoutService.load(id);
    checkoutService.loadPreCheckout(id);
    checkoutService.loadDiscount(id);
    customAnalyticsService.getAnalytics();
  }

  componentWillUnmount() {
    this.unsubscribeFn();
    this.unsubscribeAppFn();
    this.props.checkoutService.clearState();
  }

  updateCompanyFunds = ({ header: { CompanyFunds } }) => this.setState({ companyFunds: CompanyFunds });

  updateState = ({
    sources,
    loading,
    name,
    id,
    minDate,
    maxDate,
    price,
    accept,
    checkoutData,
    conflicts,
    discountSapsan,
    userAnalytics,
  }) => {
    const userSessionServiceGet = this.props.userSessionService.get();
    const { cartConflicts, tripConflicts, rateConflicts, taxiConflicts } = conflicts;
    const duplicateDialog = cartConflicts.length > 0 || tripConflicts.length > 0;
    const rateDialog = rateConflicts;
    const taxiDialogWrongBothDates = taxiConflicts.length > 0 &&
      !!taxiConflicts.find(({ Type }) => Type === TAXI_CONFLICTS_TYPE.WRONG_BOTH_DATES);
    const taxiDialogWrongStartDate = taxiConflicts.length > 0 &&
      !!taxiConflicts.find(({ Type }) => Type === TAXI_CONFLICTS_TYPE.WRONG_START_DATE);
    const companyFunds: ICompanyFunds[] = this.props.appService.get().header.CompanyFunds;

    this.setState({
      sources,
      loading,
      name,
      id,
      minDate,
      maxDate,
      price,
      accept,
      checkoutData,
      conflicts,
      companyFunds,
      duplicateDialog,
      taxiDialogWrongBothDates,
      taxiDialogWrongStartDate,
      rateDialog,
      discountSapsan,
      userAnalytics,
      projects: userSessionServiceGet.projects,
      documentType: userSessionServiceGet.enums.documents,
    });
  };

  handleGoToCart = () => this.props.history.push(ROUTES.CART.MAIN);

  handleGoToTaxiSearch = () => this.props.history.push(ROUTES.SEARCH.TAXI);

  setDuplicateDialog = value => this.setState({
    duplicateDialog: value,
  });

  setTaxiDialog = (field, value) => {
    if (field === TAXI_CONFLICTS_TYPE.DIALOG_WRONG_BOTH_DATES) return this.handleGoToCart();

    return this.setState({
      [field]: value,
    });
  };

  setDiscountDialog = value => this.setState({
    discountDialog: value,
  });

  setRateDialog = value => this.setState({
    rateDialog: value,
  });

  setRulesDialog = (value) => {
    if (value) {
      MainAnalytic.send(MainAnalytic.CATEGORY.CART, MainAnalytic.ACTIONS.CART.OPENINGTARIFFRULES);
    }

    this.setState({
      showDialog: value,
    });
  };

  handleGoToBook = id => {
    const { history } = this.props;
    const searchParams = parseSearchString(location.search);

    if (searchParams?.chosenApprovedRequestId) {
      const currentUrlParams = new URLSearchParams(window.location.search);
      currentUrlParams.set('chosenApprovedRequestId', searchParams.chosenApprovedRequestId);

      return history.push(`/cart/booking/${id}?${currentUrlParams.toString()}`);
    }

    return history.push(`/cart/booking/${id}`);
  };

  handleRenameTrip = (name) => {
    const { id } = this.state;

    this.props.checkoutService.rename({ name, id });
  };

  handleChangeCheckbox = value => this.props.checkoutService.changeAccept(value);

  handlePay = (id) => {
    const { sources, isFiltersHotelsInMicroservice } = this.state;

    const hotelOrders = sources.filter(({ ServiceType }) => ServiceType === SERVICETYPE.HOTEL);

    if (isFiltersHotelsInMicroservice && hotelOrders.length) {
      const preparedOrders = hotelOrders.map(({ Data }) => {
        const jsonData = JSON.parse(Data);

        return {
          idHotel: jsonData.hotel.ClassificatorId,
          rateName: jsonData.room.Name.toLowerCase(),
          rateSum: jsonData.room.Price.TotalPrice,
        };
      });

      MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.CART.CART_PAY_SCREEN_PAY, {
        orders: preparedOrders,
      });
    }

    if (!isFiltersHotelsInMicroservice || !hotelOrders.length) {
      MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.CART.CART_PAY_SCREEN_PAY);
    }

    if (this.state.discountSapsan.length) {
      return this.setDiscountDialog(true);
    }

    return this.handleGoToBook(id);
  };

  handleGetRules = itemId => this.props.checkoutService.cancellationInfo(itemId);

  handleApplyDiscount = value => this.props.checkoutService.applyDiscount(value)
    .finally(() => {
      this.setDiscountDialog(false);
      this.handleGoToBook(this.state.id);
    });

  renderTripName = (priceAllCertificates) => {
    const { eventService } = this.props;
    const {
      name,
      price,
      minDate,
      maxDate,
    } = this.state;

    return (
      <div className={ styles['trip-container'] }>
        <TripName
          name={ name }
          price={ price + priceAllCertificates }
          minDate={ minDate }
          maxDate={ maxDate }
          isCheckout
          onChange={ this.handleRenameTrip }
          eventService={ eventService }
          onClick={ () => {} }
          qaAttrName={ QA_ATTRIBUTES.cart.checkout.editName.name }
          qaAttrClose={ QA_ATTRIBUTES.cart.checkout.editName.close }
          qaAttrSave={ QA_ATTRIBUTES.cart.checkout.editName.success }
          qaAttrPrice={ QA_ATTRIBUTES.cart.checkout.price }
        />
      </div>
    );
  };

  renderLoading = () => (
    <PageLoader />
  );

  renderPassportWarn = () => {
    if (CART_WARN_COMPANIES.includes(
      this.props.workspaceService.getCompanyData().CompanyId,
    )) {
      return (
        <div className={ styles.warning }>
          <CartWarning type='PASSPORT_WARN'/>
        </div>
      );
    }

    return <div className={ styles.delimiter } />;
  };

  renderCheckoutList = (priceAllCertificates) => {
    const { documentType, sources, projects } = this.state;
    const {
      workspaceService,
      notepadService,
      checkoutService,
      accountSettingsService,
      customAnalyticsService: {
        store: { sortedCustomAnalytics },
      },
    } = this.props;

    const tripAnalytics = sortedCustomAnalytics.filter(({ ApplyToTrip }) => !ApplyToTrip);
    const cartEmployeeBirthday = accountSettingsService.getCartEmployeeBirthday();
    const listHtml = sources.map((item, index) => (
      <div
        key={ `checkout-item__${index}` }
        className={ styles.item }
      >
        <CheckoutItem
          item={ item }
          projects={ projects }
          allowedEmployees={ item.Employees }
          documentType={ documentType }
          checkoutService={ checkoutService }
          notepadService={ notepadService }
          isMulti={ workspaceService.isMultiCompany() }
          customAnalytics={ tripAnalytics }
          cartEmployeeBirthday={ cartEmployeeBirthday }
          isPersonalBonusCards={ this.getPersonalBonusCards }
          qaAttrPrice={ QA_ATTRIBUTES.cart.checkout.itemPrice }
        />
      </div>
    ));

    return (
      <StyledWrapper
        className={ styles.list }
      >
        <div className={ styles.cartList }>
          { listHtml }
        </div>
        { this.renderPassportWarn() }
        { this.renderFinishStep(priceAllCertificates) }
      </StyledWrapper>
    );
  };

  renderFinishStep = () => {
    const { accept, checkoutData, isMultiCompany, id, companies, companyFunds } = this.state;
    const { workspaceService, aggregationId } = this.props;

    let title = null;

    const canBookArray = [];
    const bookMessages = {
      enabled: [],
      disabled: [],
    };

    const companiesBill = [];
    checkoutData.forEach(({ Company }) => {
      const companyBill = companies.find(item => item.CompanyName === Company);

      if (companyBill) companiesBill.push(companyBill);
    });

    if (companiesBill.length === 0) {
      title = BILLTITLES.SHOW;
    } else {
      const showArr = companiesBill.filter(item => item.ShowFinanceDetails);

      if (showArr.length === companiesBill.length) title = showArr.length > 1 ? BILLTITLES.SHOW_FEW : BILLTITLES.SHOW;

      if (showArr.length === 0) title = BILLTITLES.NOTSHOW;
    }

    const billHtml = checkoutData.map((bill, index) => {
      const newBill = { ...bill };

      if (workspaceService.isDisabledAccount) {
        newBill.State = workspaceService.bookStatusEnum.DisabledAccount;
      }

      const checkResult = workspaceService.checkAccessRights(newBill, companyFunds);
      canBookArray.push(checkResult.canBook);
      bookMessages[checkResult.canBook ? 'enabled' : 'disabled'].push(checkResult.msg);

      let billTitle = null;

      if (!title) {
        const currentCompany = companies.find(item => item.CompanyName === bill.Company);
        billTitle = currentCompany.ShowFinanceDetails
          ? BILLTITLES.SHOW
          : BILLTITLES.NOTSHOWMANY;
      }

      const companyNameContent = isMultiCompany && (<Text color={ PROPS.TEXT.COLORS.GRAY }>&nbsp;({ bill.Company })</Text>);
      const billTitleContent = !!billTitle && (<Text className={ styles.title }>{ billTitle }</Text>);

      return (
        <div key={ index } className={ styles.bill }>
          { billTitleContent }
          <div className={ styles.content }>
            <Price
              marginSmall
              value={ bill.AmountToPay }
              type={ PROPS.TEXT.TYPES.BOLD_24 }
              className={ styles.price }
              qaAttr={ QA_ATTRIBUTES.cart.checkout.finish.price }
            />
            { companyNameContent }
          </div>
        </div>
      );
    });

    const canBook = canBookArray.every(Boolean);
    const bookMsg = bookMessages[canBook ? 'enabled' : 'disabled'];
    const selectTitle = aggregationId === AGGREGATORS_ACCOUNT.CKR ? LABELS.BOOK_TRIP_LABEL : LABELS.PAY;

    const attentionContent = canBook && accept && (
      <Text className={ styles.text } color={ PROPS.TEXT.COLORS.RED }>{ LABELS.ATTENTION }</Text>
    );

    const acceptRulesCheckbox = canBook && (
      <div className={ styles['accept-rules-wrapper'] }>
        <Checkbox
          value={ accept }
          onChange={ this.handleChangeCheckbox }
          qaAttr={ QA_ATTRIBUTES.cart.checkout.finish.acceptRules }
        >
          { LABELS.CHECKBOX.ONE }
        </Checkbox>
        <Button
          type={ TEXTUAL }
          data-qa={ QA_ATTRIBUTES.cart.checkout.finish.link }
          onClick={ () => {
            this.setRulesDialog(true);
          } }
        >
          &nbsp; { LABELS.CHECKBOX.TWO }
        </Button>
      </div>
    );

    const bookMsgContent = !canBook && (
      <div className={ styles['book-unavailable'] }>
        <Icon type={ PROPS.ICON.TYPES.WARNING } className={ styles.icon }/>
        <div className={ styles.messages }>
          {bookMsg.map((msg, index) => (
            <Text key={ index } color={ PROPS.TEXT.COLORS.RED } className={ styles.message }>{ msg }</Text>
          ))}
        </div>
      </div>
    );

    return (
      <div className={ styles['finish-step'] }>
        <Text type={ PROPS.TEXT.TYPES.NORMAL_18 }>{ title }</Text>
        { billHtml }
        { acceptRulesCheckbox }
        { bookMsgContent }
        <div className={ styles['action-wrapper'] }>
          <Button
            type={ SECONDARY }
            disabled={ !canBook || !accept }
            onClick={ () => this.handlePay(id) }
            qaAttr={ QA_ATTRIBUTES.cart.checkout.finish.buy }
          >
            { selectTitle }
          </Button>
          { attentionContent }
        </div>
      </div>
    );
  };

  renderRulesDialog = () => {
    const { sources, showDialog } = this.state;

    return (
      <DialogRules
        show={ showDialog }
        items={ sources }
        onClose={ () => this.setRulesDialog(false) }
        onGetRules={ this.handleGetRules }
      />
    );
  };

  renderDuplicateDialog = () => {
    const { duplicateDialog } = this.state;

    return (
      <DuplicateDialog
        show={ duplicateDialog }
        conflicts={ this.state.conflicts }
        onClose={ () => this.setDuplicateDialog(false) }
        onBack={ this.handleGoToCart }
      />
    );
  };

  renderTaxiDialog = () => {
    const { taxiDialogWrongBothDates, taxiDialogWrongStartDate, sources, conflicts: { taxiConflicts } } = this.state;

    return (
      <TaxiConflictDialog
        showWrongStartDate={ taxiDialogWrongStartDate }
        showWrongBothDates={ taxiDialogWrongBothDates }
        sources={ sources }
        conflicts={ taxiConflicts }
        onClose={ this.setTaxiDialog }
        onBack={ this.handleGoToCart }
        onBackTaxi={ this.handleGoToTaxiSearch }
      />
    );
  };

  renderDiscountDialog = () => {
    const { discountDialog } = this.state;

    return (
      <DiscountDialog
        show={ discountDialog }
        items={ this.state.discountSapsan }
        onApply={ this.handleApplyDiscount }
        onClose={ this.setDiscountDialog }
      />
    );
  };

  renderRateDialog = () => {
    const { rateDialog } = this.state;

    return (
      <RateDialog
        show={ rateDialog }
        onClose={ () => this.setRateDialog(false) }
        onBack={ this.handleGoToCart }
      />
    );
  };

  renderCustomAnalytics = () => {
    const { customAnalyticsService: { store: { sortedCustomAnalytics } } } = this.props;
    const { id: cartId, userAnalytics } = this.state;

    const analyticsWhichAppliesToTrip = sortedCustomAnalytics.filter(({ ApplyToTrip }) => ApplyToTrip);

    return (
      <AnalyticsBar
        disabled
        analyticsList={ analyticsWhichAppliesToTrip }
        cartId={ cartId }
        userAnalytics={ userAnalytics }
        onSet={ () => {} }
        onUnset={ () => {} }
      />
    );
  };

  render() {
    const { loading, sources } = this.state;
    const { customAnalyticsService: { store: { loading: analyticsLoading } }, aggregationId } = this.props;

    const title = aggregationId === AGGREGATORS_ACCOUNT.CKR ? LABELS.TITLE_BOOK_TRIP : LABELS.TITLE;

    if (loading || analyticsLoading) {
      return this.renderLoading();
    }

    const priceAllCertificates = getPriceAllCertificates(sources);

    return (
      <div className={ styles.wrap }>
        <BackLink qaAttr={ QA_ATTRIBUTES.cart.checkout.back } text={ LABELS.CART } link={ ROUTES.CART.MAIN } className={ styles.back } alternativeDesign={ isSmartAgent }/>
        <Text qaAttr={ QA_ATTRIBUTES.cart.checkout.title } type={ PROPS.TEXT.TYPES.BOLD_32 } className={ styles.header }>{title}</Text>
        { this.renderTripName(priceAllCertificates) }
        { this.renderCustomAnalytics() }
        { this.renderCheckoutList(priceAllCertificates) }
        { this.renderRateDialog() }
        { this.renderRulesDialog() }
        { this.renderDuplicateDialog() }
        { this.renderTaxiDialog() }
        { this.renderDiscountDialog() }
      </div>
    );
  }
}

export default Checkout;
