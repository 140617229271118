// @ts-nocheck
import { GET_AEROEXPRESS_ADDED_TO_CART_TEXT } from '../../services/aeroexpress/const';

import { QA_ATTRIBUTES } from '../../constants/attributesForTests';

class PopupsProtocol {
  constructor(services) {
    Object.keys(services).forEach((s) => {
      this[s] = services[s];
    });
  }

  offerAeroexpress = (airlineTrip, { count, isComplex }, tripId: number | null = null, requestItemId = null) => {
    this.Popups.offerAeroexpressPurchase(
      airlineTrip,
      { count, isComplex },
      async (aeroexpressSearchModel) => {
        this.Aeroexpress.updateSearchSettings(aeroexpressSearchModel);
        await this.Aeroexpress.search();

        const searchIds = this.Aeroexpress.store.searchResult.items.reduce((r, { id }) => ([...r, id]), []);

        searchIds.forEach(k =>
          this.Aeroexpress.addToCart({ SearchId: k, RequestId: requestItemId || null, TripId: tripId })
            .then(() => {
              this.Notification.send({
                message: GET_AEROEXPRESS_ADDED_TO_CART_TEXT,
                type: 'success',
                qaAttr: QA_ATTRIBUTES.search.aeroexpress.popup.aeroexpress.notification,
              });

              this.Cart.load();
              this.Aeroexpress.setNewSearch();
            }),
        );
      });
  };
}

export default PopupsProtocol;
