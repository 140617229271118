import React from 'react';
import { Icon, Text, Tooltip } from 'new-ui';
import { getText } from '../../../../i18n';

import { formatDate, momentObject } from '../../../bi/utils/formatDate';
import { secondsToLabel } from '../../../bi/utils/time';
import getTravelTime from '../../../bi/utils/timeLabel';
import { isDepartureChanged, isArrivalChanged } from '../../../bi/utils/train';

import { PATTERN } from '../../../bi/constants/dateFormats';
import TIMETYPELIST from '../../../bi/constants/timeTypeList';

import { TrainItemDetailsTime } from './time';
import { FreePlacesDetails } from './freePlaces';

import { TrainDetails, TrainItemDetailsProps } from './types';

import styles from '../styles/itemDetails.module.css';

const LABELS = {
  CHANGE_DURATION: getText('trains:results.details.transfer'),
  INFO_STATION: getText('trains:results.details.railwayStationChanging'),
  ATTENTION: getText('trains:results.details.attention'),
};

const TrainItemDetails = ({ details, travellers }: TrainItemDetailsProps) => {
  const detailHtml = ((item: TrainDetails, index: number) => {
    const {
      TrainId,
      DepartureDateLocal,
      DepartureDate,
      ArrivalDateLocal,
      ArrivalDate,
      StationFrom,
      StationTo,
      ChangeDuration,
      TravelTime,
      Cars,
    } = item;

    const classes = Cars.map(({ MinimalCost: { Total, AgentFee }, Type, FreePlaces }) => ({
      Type,
      FreePlaces,
      Price: Total,
      AgentFee,
    }));

    const tooltip = () => (
      <Text
        color='white'
        type='NORMAL_14_130'
        className={ styles.tooltip_box }
      >
        <div>{ LABELS.ATTENTION }</div>
        <div>{ LABELS.INFO_STATION }</div>
      </Text>
    );

    const stationHtml = (station: string, changed: boolean, to?: boolean) => {
      const color = changed ? 'red' : 'default';

      const stylesStation = [styles.station];

      if (to) {
        stylesStation.push(styles['station-to']);
      }

      return (
        <Tooltip show={ changed } renderContent={ tooltip }>
          <Text
            type='SEMIBOLD_16'
            color={ color }
            className={ stylesStation.join(' ') }
          >
            { station.toLocaleLowerCase() }
          </Text>
        </Tooltip>
      );
    };

    const changeDurationHtml = ChangeDuration > 0 ? (
      <div className={ styles.change }>
        <Text
          type='NORMAL_14'
          color='gray'
          className={ styles.title }
        >
          { LABELS.CHANGE_DURATION }
        </Text>
        <Text
          type='NORMAL_14'
          color='gray'
          className={ styles.duration }
        >
          { secondsToLabel(ChangeDuration) }
        </Text>
      </div>
    ) : null;

    const departureDate = momentObject(DepartureDate);
    const departureDateLocal = momentObject(DepartureDateLocal);
    const arrivalDate = momentObject(ArrivalDate);
    const arrivalDateLocal = momentObject(ArrivalDateLocal);

    return (
      <div key={ `train_item_detail_${TrainId}_${index}` } className={ styles.detail }>
        { changeDurationHtml }
        <div className={ styles.item }>
          <div className={ styles.col_departure }>
            <Text>
              { formatDate(departureDateLocal, PATTERN.DAY_OF_MONTH) }, { formatDate(departureDateLocal, PATTERN.WEEK) }
            </Text>
            <TrainItemDetailsTime
              date={ departureDate }
              dateLocal={ departureDateLocal }
              timeType={ TIMETYPELIST.DEPARTURE }
              className={ styles.time }
            />
            { stationHtml(StationFrom, isDepartureChanged(index, item, details)) }
          </div>
          <div className={ styles.col_train }>
            <Text
              type='NORMAL_14'
              color='gray'
              className={ styles.duration }
            >
              { getTravelTime(TravelTime) }
            </Text>
            <Icon
              type='searchTrainColor'
              className={ styles.icon }
            />
          </div>
          <div className={ styles.col_arrival }>
            <Text>
              { formatDate(arrivalDateLocal, PATTERN.DAY_OF_MONTH) }, { formatDate(arrivalDateLocal, PATTERN.WEEK) }
            </Text>
            <TrainItemDetailsTime
              date={ arrivalDate }
              dateLocal={ arrivalDateLocal }
              timeType={ TIMETYPELIST.ARRIVAL }
              className={ styles.time }
            />
            { stationHtml(StationTo, isArrivalChanged(index, item, details), true) }
          </div>
          <FreePlacesDetails
            classes={ classes }
            travellers={ travellers }
          />
        </div>
      </div>
    );
  });

  return (
    <div className={ styles.wrapper }>
      { details.map(detailHtml) }
    </div>
  );
};

export { TrainItemDetails };
