import Store from './store';

import { AccountSettingsProps } from './types';
import { CompanySetting } from '../../utils/accountSettings';

const CHAT_TYPE = {
  INTERCOM: 1,
  CARROT_QUEST: 2,
};

class AccountSettings {
  store = new Store();
  api: any;

  constructor(api: any) {
    this.api = api;
  }

  loadAccountSettings = () => this.api.accountSettings.getAccountSettings()
    .then((accountSettings: AccountSettingsProps) => this.store.setAccountSettings(accountSettings));

  getAccountSettings = () => this.store.accountSettings;

  isCarrotQuestChat = () => {
    const chatType = this.store.getChatType();

    return chatType === CHAT_TYPE.CARROT_QUEST;
  };

  getBookingTaxi = () => this.store.noBookingTaxi;

  getNoChangeOrReturnTrip = () => this.store.noChangeOrReturnTrip;

  getAnalytics = () => this.store.analytics;

  getCartEmployeeBirthday = () => this.store.cartEmployeeBirthday;

  getHowEmployeeDisplay = () => this.store.displayTripsEmployee;

  getSalary1C = () => this.store.salary1C;

  getSearchServiceType = () => this.store.searchServiceType;

  getNoExpenseReport = () => this.store.noExpenseReport;

  getHidePerDiem = () => this.store.hidePerDiem;

  getHideAdditionalOrders = () => this.store.hideAdditionalOrders;

  getPersonalBonusCards = () => !this.store.noPersonalBonusCards;

  getForceTravelPolicy = () => this.store.forceTravelPolicy;

  getForceEmployeeNumber = () => this.store.forceEmployeeNumber;

  getCompanySettings = () => this.store.companySettings as CompanySetting[];

  getDeleteProject = () => this.store.deleteProject;

  getNoMice = () => this.store.noMice;
}

export default AccountSettings;
