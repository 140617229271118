import { UI_SCHEMES } from '../../constants/accounts';

import { UiSettingsStore } from './store';
import { UiLanguageType, UiSettingsType } from './types';

class UiSettingsService {
  api: any;
  store = UiSettingsStore;

  constructor(api: any) {
    this.api = api.uiSettings;
  }

  setUiSettings = (value: UiSettingsType): void => this.store.setUiSettings(value);

  setAggregationId = (value: number): void => this.store.setAggregationId(value);

  setLngSettings = (value: UiLanguageType): void => this.store.setLngSettings(value);

  getUiSettings = async (aId: string, res: any): Promise<void> => {
    try {
      const aggregationId = await this.api.getUiSettings(aId);

      this.setAggregationId(aggregationId);

      // @ts-ignore
      if (!!aggregationId && !!UI_SCHEMES[aggregationId]) {
        this.setUiSettings({
          // @ts-ignore
          ...UI_SCHEMES[aggregationId],
          showTaxi: !res?.noBookingTaxi,
          showChartsAnalytics: !res?.noAnalytics,
        });
      }
    } catch {}
  };

  getLanguageSettings = async (uId: string | null, aId: string): Promise<void> => {
    try {
      const data = {
        UserId: uId || '',
        AccountId: aId,
      };

      const locale = await this.api.getLngSettings(data);

      if (locale) {
        this.setLngSettings(locale);
      }
    } catch {}
  };

  getCurrentLanguage = (): UiLanguageType => this.store.getCurrentLng;

  getHeadTitle = () => this.store.getHeadTitle;

  getCompanyName = () => this.store.getCompanyName;

  showChartsAnalytics = () => this.store.showChartsAnalytics;
}

export default UiSettingsService;
