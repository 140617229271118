// @ts-nocheck
import PropTypes from 'prop-types';
import React from 'react';
import { Text, PROPS } from 'new-ui';
import { getText } from '../../../../i18n';

import { timeLabelWithColon, timeLabel } from '../../../bi/utils/timeLabel';

import styles from '../styles/train.module.css';
import { getTrainNameByNumber } from '../../../bi/utils/train';

const LABELS = {
  IN_TRAVEL: getText('favorites:tripInProgress'),
};

const {
  TEXT: {
    TYPES: {
      SEMIBOLD_16,
      SEMIBOLD_14,
      NORMAL_14,
    },
    COLORS: {
      GRAY,
    },
  },
} = PROPS;

const TrainItem = ({
  favorite: {
    Item: {
      TrainName,
      TrainNumber,
      TrainNumberLocal,
      StationFrom,
      DepartureDate,
      DepartureDateLocal,
      TravelTime,
      StationTo,
      ArrivalDate,
      ArrivalDateLocal,
    },
  },
  qaAttrs: {
    from,
    to,
    name,
  },
}) => {
  const trainName = TrainName || getTrainNameByNumber(null, TrainNumber) || '';
  const departureDate = DepartureDateLocal || DepartureDate;
  const arrivalDate = ArrivalDateLocal || ArrivalDate;
  const trainNumber = TrainNumberLocal || TrainNumber;

  return (
    <div className={ styles.wrapper }>
      <div className={ styles.place }>
        <Text type={ SEMIBOLD_16 } qaAttr={ from }>
          { StationFrom }
        </Text>
        <Text type={ NORMAL_14 } color={ GRAY } className={ styles.time }>
          { timeLabelWithColon(departureDate.Time) }
        </Text>
      </div>
      <div className={ styles.train }>
        <Text type={ NORMAL_14 } color={ GRAY } qaAttr={ name }>
          { `${trainNumber} ${trainName ? `(${trainName})` : ''}` }
        </Text>
        <Text type={ SEMIBOLD_14 } className={ styles.time }>
          { timeLabel(TravelTime) }
        </Text>
        <Text type={ NORMAL_14 } color={ GRAY }>
          { LABELS.IN_TRAVEL }
        </Text>
      </div>
      <div className={ styles.place }>
        <Text type={ SEMIBOLD_16 } qaAttr={ to }>
          { StationTo }
        </Text>
        <Text type={ NORMAL_14 } color={ GRAY } className={ styles.time }>
          { timeLabelWithColon(arrivalDate.Time) }
        </Text>
      </div>
    </div>
  );
};

TrainItem.propTypes = {
  favorite: PropTypes.object.isRequired,
  qaAttrs: PropTypes.object,
};

TrainItem.defaultProps = {
  qaAttrs: {
    from: '',
    to: '',
    name: '',
  },
};

export default TrainItem;
