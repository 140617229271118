import React from 'react';
import { observer } from 'mobx-react';
import { DotLoading, SidePanel } from 'new-ui';

import { useServices } from '../../../../../../bi/context/services';

import { StatusCondition } from '../../../../../../components/StatusCondition';

import { TYPE_IMAGE } from '../../../../../../bi/constants/signature';
import { LoadingFields } from '../../../../../../bi/services/signature';

import styles from '../../../DocumentsForSignature/styles/index.module.css';

export const PreviewDoc = observer(() => {
  const {
    signatureService: {
      netStore,
      enhancedSignatureStore: { closeModal, previewDoc },
    },
  } = useServices(['Signature']);

  const Image = ({ image }: { image: string }) => {
    const srcString = () => TYPE_IMAGE.SVG + image;

    return (
      <div className={ styles.image_wrapper }>
        <img
          className={ styles.preview }
          src={ srcString() }
          alt='agreement'
        />
      </div>
    );
  };

  return (
    <SidePanel
      show
      onClose={ closeModal }
    >
      <div className={ styles.image_content }>
        <StatusCondition
          status={ netStore.getStatus(LoadingFields.callDocumentPreview) }
          onLoaded={ () => previewDoc.map((img, index) => <Image key={ index } image={ img } />) }
          fallback={ () => <div className={ styles.images_loading }><DotLoading /></div> }
        />
      </div>
    </SidePanel>
  );
});
