import React, { useState } from 'react';
import { Slider } from 'new-ui';
import { getText, getTextArray } from '../../../../../../../i18n';

import toDecline from '../../../../../../bi/utils/toDecline';
import MoneyFormat from '../../../../../../bi/utils/money';

import styles from './index.module.css';

import mskSpbIcon from '../../images/msk-spb.jpg';
import mskEkbIcon from '../../images/msk-ekb.jpg';
import mskNskIcon from '../../images/msk-nsk.jpg';

const LABELS = {
  MSK_SPB: getText('settings:bonusProgram.promo.calculator.mskSpb'),
  MSK_EKB: getText('settings:bonusProgram.promo.calculator.mskEkb'),
  MSK_NSK: getText('settings:bonusProgram.promo.calculator.mskNsk'),
  FLIGHTS: getTextArray('settings:bonusProgram.promo.calculator.flights'),
  FLIGHT_EXPENSES: getText('settings:bonusProgram.promo.calculator.flightExpenses'),
  REWARD: getText('settings:bonusProgram.promo.calculator.reward'),
  POINTS: getText('settings:bonusProgram.promo.calculator.points'),
  STATUS: getText('settings:bonusProgram.promo.calculator.status'),
  CASH_BACK: getText('settings:bonusProgram.promo.calculator.cashBack'),
  EXCHANGE: getText('settings:bonusProgram.promo.calculator.exchange'),
  CLARIFICATION: getText('settings:bonusProgram.promo.calculator.clarification'),
};

type DirectionType = {
  id: number,
  name: string,
  price: number,
  image: any,
};

type CalculatorProps = {
  defaultValue?: number,
};

const STATUSES: {
  [key: string]: {
    amount: number,
    percent: number,
    name: string,
  }
} = {
  profi: {
    amount: 0,
    percent: 5,
    name: 'Profi',
  },
  senior: {
    amount: 1000000,
    percent: 6,
    name: 'Senior',
  },
  master: {
    amount: 1700000,
    percent: 7,
    name: 'Master',
  },
};

const MONTHS = 12;
const DEFAULT_AMOUNT = 350000;
const DIRECTIONS: DirectionType[] = [
  {
    id: 0,
    name: LABELS.MSK_SPB,
    price: 2500,
    image: mskSpbIcon,
  },
  {
    id: 1,
    name: LABELS.MSK_EKB,
    price: 4500,
    image: mskEkbIcon,
  },
  {
    id: 2,
    name: LABELS.MSK_NSK,
    price: 10800,
    image: mskNskIcon,
  },
];

const getStatusByAmount = (amount: number): {
  amount: number,
  percent: number,
  name: string,
} => {
  if (amount >= STATUSES.master.amount) {
    return STATUSES.master;
  }

  if (amount >= STATUSES.senior.amount) {
    return STATUSES.senior;
  }

  return STATUSES.profi;
};

const calculatePoints = (amount: number, percent: number): number =>
  ((amount * MONTHS) / 100) * percent;

const Calculator = ({ defaultValue }: CalculatorProps) => {
  const [amount, setAmount] = useState<number>(defaultValue || DEFAULT_AMOUNT);

  const handleAmount = (value: number) => {
    setAmount(value);
  };

  // eslint-disable-next-line @typescript-eslint/default-param-last
  const renderDirection = (direction: DirectionType | null = null, points: number) => {
    if (!direction) {
      return null;
    }

    const { id, price, image, name } = direction;

    const amountOfFlights = Math.floor(points / price);
    const declinedLabel = toDecline(amountOfFlights, LABELS.FLIGHTS);

    return (
      <div key={ id } className={ styles.direction }>
        <div className={ styles.direction_icon }>
          <img src={ image } alt={ name } />
        </div>
        <div className={ styles.direction_info }>
          <div className={ styles.direction_amount }>
            { amountOfFlights } { declinedLabel }
          </div>
          <div className={ styles.direction_name }>
            { name }*
          </div>
        </div>
      </div>
    );
  };

  const { name, percent } = getStatusByAmount(amount);
  const rawPoints = calculatePoints(amount, percent);
  const points = MoneyFormat.money(rawPoints);
  const formattedAmount = MoneyFormat.money(amount, true);

  const directions = DIRECTIONS.map(direction => renderDirection(direction, rawPoints));

  return (
    <div className={ styles.wrap }>
      <div className={ styles.title }>{ LABELS.FLIGHT_EXPENSES }</div>
      <div className={ styles.amount }>{ formattedAmount }</div>
      <Slider
        value={ amount }
        onChange={ handleAmount }
        min={ 30000 }
        max={ 2000000 }
        step={ 10000 }
      />
      <div className={ styles.results }>
        <div className={ styles.points }>
          <div className={ styles.points_title }>{ LABELS.REWARD }</div>
          <div className={ styles.points_amount }>{ points } { LABELS.POINTS }</div>
        </div>
        <div className={ styles.status }>
          <div className={ styles.status_item }>{ LABELS.STATUS }<strong> { name }</strong></div>
          <div className={ styles.status_item }>{ LABELS.CASH_BACK }<strong> { percent }%</strong></div>
        </div>
      </div>
      <div className={ styles.description }>{ LABELS.EXCHANGE }</div>
      <div className={ styles.directions }>{ directions }</div>
      <div className={ styles.note }>{ LABELS.CLARIFICATION }</div>
    </div>
  );
};

export { Calculator };
