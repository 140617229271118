import React, { useState } from 'react';
import { useRouteMatch, useHistory } from 'react-router-dom';
import { observer } from 'mobx-react';
import { Icon, StyledWrapper, Text } from 'new-ui';

import { useStores } from '../../../../bi/context';
import { MOBX_STORES } from '../../../../bi/context/stores';

import { MainAnalytic } from '../../../../bi/utils/analytics';

import { MENU_KEYS } from '../../../../bi/constants/chartsAnalytics';

import styles from './styles.module.css';

interface ChartAnalProps {
  resetFilters(): void,
}

const sendAmplitudeAnalytic = (id: string) => {
  const analyticType = ({
    [MENU_KEYS.GENERAL]: MainAnalytic.ACTIONS.ANALYTICS.HOME_PAGE_OPENED,

    [MENU_KEYS.AIRLINE.SUMMARY]: MainAnalytic.ACTIONS.ANALYTICS.AIRLINE_SUMMARY_OPENED,
    [MENU_KEYS.AIRLINE.DIRECTIONS]: MainAnalytic.ACTIONS.ANALYTICS.AIRLINE_DIRECTIONS_OPENED,
    [MENU_KEYS.AIRLINE.COMPANIES]: MainAnalytic.ACTIONS.ANALYTICS.AIRLINE_COMPANIES_OPENED,
    [MENU_KEYS.AIRLINE.EARLINESS]: MainAnalytic.ACTIONS.ANALYTICS.AIRLINE_EARLINESS_OPENED,
    [MENU_KEYS.SMART.AIRLINE.FIRST]: MainAnalytic.ACTIONS.ANALYTICS.SPENDING_AIRLINE_PAGE_OPENED,

    [MENU_KEYS.TRAIN.SUMMARY]: MainAnalytic.ACTIONS.ANALYTICS.TRAIN_SUMMARY_OPENED,
    [MENU_KEYS.TRAIN.DIRECTIONS]: MainAnalytic.ACTIONS.ANALYTICS.TRAIN_DIRECTIONS_OPENED,
    [MENU_KEYS.SMART.TRAIN.FIRST]: MainAnalytic.ACTIONS.ANALYTICS.SPENDING_TRAIN_PAGE_OPENED,

    [MENU_KEYS.HOTEL.SUMMARY]: MainAnalytic.ACTIONS.ANALYTICS.HOTEL_SUMMARY_OPENED,
    [MENU_KEYS.HOTEL.DIRECTIONS]: MainAnalytic.ACTIONS.ANALYTICS.HOTEL_DIRECTIONS_OPENED,
    [MENU_KEYS.HOTEL.POPULAR]: MainAnalytic.ACTIONS.ANALYTICS.HOTEL_POPULAR_DIRECTIONS_OPENED,
    [MENU_KEYS.SMART.HOTEL.FIRST]: MainAnalytic.ACTIONS.ANALYTICS.SPENDING_HOTEL_PAGE_OPENED,

    [MENU_KEYS.TAXI.SUMMARY]: MainAnalytic.ACTIONS.ANALYTICS.TAXI_SUMMARY_OPENED,
    [MENU_KEYS.TAXI.DIRECTIONS]: MainAnalytic.ACTIONS.ANALYTICS.TAXI_DIRECTIONS_OPENED,
    [MENU_KEYS.SMART.TAXI.FIRST]: MainAnalytic.ACTIONS.ANALYTICS.SPENDING_TAXI_PAGE_OPENED,

    [MENU_KEYS.AEROEXPRESS.SUMMARY]: MainAnalytic.ACTIONS.ANALYTICS.AEROEXPRESS_SUMMARY_OPENED,
    [MENU_KEYS.AEROEXPRESS.DIRECTIONS]: MainAnalytic.ACTIONS.ANALYTICS.AEROEXPRESS_DIRECTIONS_OPENED,
    [MENU_KEYS.SMART.AEROEXPRESS.FIRST]: MainAnalytic.ACTIONS.ANALYTICS.SPENDING_AEROEXPRESS_PAGE_OPENED,

    [MENU_KEYS.TRANSFER.SUMMARY]: MainAnalytic.ACTIONS.ANALYTICS.TRANSFER_SUMMARY_OPENED,
    [MENU_KEYS.TRANSFER.DIRECTIONS]: MainAnalytic.ACTIONS.ANALYTICS.TRANSFER_DIRECTIONS_OPENED,
    [MENU_KEYS.SMART.TRANSFER.FIRST]: MainAnalytic.ACTIONS.ANALYTICS.SPENDING_TRANSFER_PAGE_OPENED,

    [MENU_KEYS.MICE.SUMMARY]: MainAnalytic.ACTIONS.ANALYTICS.MICE_SUMMARY_OPENED,
    [MENU_KEYS.SMART.MICE.FIRST]: MainAnalytic.ACTIONS.ANALYTICS.SPENDING_MICE_PAGE_OPENED,

    [MENU_KEYS.STRUCTURE.SERVICE_TYPE]: MainAnalytic.ACTIONS.ANALYTICS.STRUCTURE_SERVICE_TYPE_OPENED,
    [MENU_KEYS.STRUCTURE.DEPARTMENTS]: MainAnalytic.ACTIONS.ANALYTICS.STRUCTURE_DEPARTMENTS_OPENED,
    [MENU_KEYS.STRUCTURE.PROJECTS]: MainAnalytic.ACTIONS.ANALYTICS.STRUCTURE_PROJECTS_OPENED,
  })[id];

  if (id?.includes(MENU_KEYS.STRUCTURE.CUSTOM_ANALYTICS)) {
    MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.ANALYTICS.STRUCTURE_CUSTOM_ANALYTICS_OPENED);
  } else {
    MainAnalytic.sendAmplitude(analyticType);
  }
};

const ChartAnalyticsMenu = observer(({
  resetFilters,
}: ChartAnalProps) => {
  const {
    chartsAnalyticsStore: { menu },
  } = useStores([MOBX_STORES.CHARTS_ANALYTICS_STORE]);

  const preparedMenu = menu.map(({ id }) => ({ id, hide: false }));

  const [hiddenMenu, setHiddenMenu] = useState(preparedMenu);

  const { params } = useRouteMatch<{ tabId: string }>();
  const history = useHistory();

  const handleOpen = (id: string) => {
    sendAmplitudeAnalytic(id);
    history.push(`/chartsAnalytics/${id}`);
    resetFilters();
  };

  const hideElementMenu = (index: string) => {
    const hideMenu = hiddenMenu.map((item) => {
      if (item.id === index) {
        return {
          ...item,
          hide: !item.hide,
        };
      }

      return item;
    });

    setHiddenMenu(hideMenu);
  };

  const renderItemMenu = ({ title, id, items }: { title: string, id: string, items: any }, ind: number) => {
    if (items.length === 0) {
      const color = params.tabId === id ? 'red' : 'default';

      return (
        <div
          className={ `${styles.item} ${styles.hover}` }
          key={ `item_menu_${id}` }
          onClick={ () => handleOpen(id) }
        >
          <Text
            type='SEMIBOLD_14'
            color={ color }
            className={ styles.title_item }
          >
            { title }
          </Text>
        </div>
      );
    }

    const renderItem = (item: any) => {
      if (!item) {
        return null;
      }

      const { title: itemTitle, id: itemId } = item;
      const color = params.tabId === itemId ? 'red' : 'default';

      return (
        <div
          key={ itemId }
          className={ styles.item_sub_menu }
          onClick={ () => handleOpen(itemId) }
        >
          <Text type='SEMIBOLD_14' color={ color }>
            { itemTitle }
          </Text>
        </div>
      );
    };

    const iconType = hiddenMenu[ind]?.hide ? 'arrowsUpGray' : 'arrowsDownGray';

    const htmlMenu = hiddenMenu[ind]?.hide && (
      <div className={ styles.items }>
        { items.map(renderItem) }
      </div>
    );

    return (
      <div
        key={ id }
        className={ styles.item }
      >
        <div
          className={ styles.title_items }
          onClick={ () => hideElementMenu(id) }
        >
          <Text type='SEMIBOLD_14'>
            { title }
          </Text>
          { <Icon type={ iconType } className={ styles.icon } /> }
        </div>
        { htmlMenu }
      </div>
    );
  };

  return (
    <StyledWrapper className={ styles.wrapper }>
      { menu.map(renderItemMenu) }
    </StyledWrapper>
  );
});

export { ChartAnalyticsMenu };
