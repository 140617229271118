import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Text, PageLoader, NoResults } from 'new-ui';
import { History } from 'history';

import { useStores } from '../../bi/context';
import { MOBX_STORES } from '../../bi/context/stores';

import { getText } from '../../../i18n';

import Report from '../../bi/services/report';
import Departments from '../../bi/services/departments';
import TravelApproval from '../../bi/services/travelApproval';

import ROUTES from '../../bi/constants/routes';
import { APPROVE_TYPE } from '../../bi/constants/approve';
import { ApprovesType } from '../../bi/services/travelApproval/consts';

import { companiesWithDepartmentsMapper } from '../../bi/utils/departments';
import { MainAnalytic } from '../../bi/utils/analytics';

import ErrorReportDialog from '../../components/ErrorReportDialog';

import ReportApproveDialog from '../Approve/components/ApproveReportDialog';
import { RequestItems } from './components/travelApprovalItem';

import { DepartmentsListItem } from '../../bi/services/departments/types';
import { IMultiSelectList, IMultiSelectWithMainList } from '../../bi/types/shared';

import styles from './styles/index.module.css';

const LABELS = {
  TOOLTIP_TEXT: getText('approve:tooltip'),
  DOWNLOAD: getText('approve:download'),
  CLOSE: getText('approve:aggressiveClose'),
  TRAVEL_APPROVAL: getText('approve:requests'),
  NO_LIST: getText('approve:noResultsRequests'),
  LOADER: getText('approve:loader'),
  NO_APPROVING: getText('approve:noApproving'),
};

interface ApproveTravelPageProps {
  departmentsService: Departments
  reportService: Report
  travelApprovalService: TravelApproval
  history: History
}

export const ApproveTravelPage = observer(({
  departmentsService,
  reportService,
  travelApprovalService,
  history,
}: ApproveTravelPageProps) => {
  useEffect(() => {
    MainAnalytic.sendAmplitude(
      MainAnalytic.ACTIONS.APPROVEMENT.COORDINATION_APPLICATION_SCREEN_OPENED,
    );
  }, []);

  const [selectCompaniesItems, setSelectCompaniesItems] = useState<IMultiSelectList[]>([]);
  const { approvesStore, approveReportStore } = useStores([MOBX_STORES.APPROVES, MOBX_STORES.APPROVE_REPORT_STORE]);

  const { getApproves } = travelApprovalService;

  const { errorDialog, downloadDialog } = approveReportStore;

  const loadCompaniesWithDepartments = () =>
    departmentsService.justLoadList()
      .then((l: DepartmentsListItem[]) => setSelectCompaniesItems(companiesWithDepartmentsMapper(l)));

  useEffect(() => {
    getApproves();
    loadCompaniesWithDepartments();
  }, [getApproves]);

  const handleTravelApprovalItemClick = ({ RequestId }: ApprovesType) => {
    history.push(`${ROUTES.TRAVEL_APPROVAL}/${RequestId}`);

    MainAnalytic.sendAmplitude(
      MainAnalytic.ACTIONS.APPROVEMENT.COORDINATION_APPLICATION_TRIP_AGREED_CHOSE,
    );
  };

  const handleDownloadApproveReport = (startDate: string, endDate: string, companies: IMultiSelectWithMainList[]) => {
    reportService.downloadReportApprove(startDate, endDate, companies, APPROVE_TYPE.REQUESTS);

    MainAnalytic.sendAmplitude(
      MainAnalytic.ACTIONS.APPROVEMENT.COORDINATION_APPLICATION_DOWNLOAD_PRESSED,
    );
  };

  const handleErrorDialog = (show: boolean, msg: string) => reportService.setErrorReportDialog(show, msg);

  const handleDownloadDialog = (show: boolean, load: boolean) => reportService.setDownloadReportDialog(show, load);

  const renderLoading = () => (
    <PageLoader text={ LABELS.LOADER } />
  );

  const renderDownloadDialog = () => (
    <ReportApproveDialog
      show={ downloadDialog.show }
      loading={ downloadDialog.loading }
      onClose={ (show: boolean) => handleDownloadDialog(show, downloadDialog.loading) }
      onDownload={ handleDownloadApproveReport }
      companies={ selectCompaniesItems }
    />
  );

  const renderEmptyList = () => (
    <div className={ styles.empty }>
      <div className={ styles.header }>
        { renderDownloadDialog() }
      </div>
      <ErrorReportDialog errorDialog={ errorDialog } onErrorDialog={ handleErrorDialog } />
      <NoResults label={ LABELS.NO_LIST } />
    </div>
  );

  const renderApprovesList = (approvesList: ApprovesType[]) => {
    const html = (item: ApprovesType, ind: number) => (
      <RequestItems
        key={ `${item.Id}_${ind}` }
        item={ item }
        onClick={ () => handleTravelApprovalItemClick(item) }
      />
    );

    return (
      <div className={ styles.list }>
        { approvesList.map(html) }
      </div>
    );
  };

  const renderLists = (approvesList: ApprovesType[]) => {
    if (!approvesList.length) {
      return renderEmptyList();
    }

    const approvesListHtml = !!approvesList.length &&
      renderApprovesList(approvesList);

    return (
      <div className={ styles.list }>
        <div className={ styles.header }>
          <Text type='bold_32'>{ LABELS.TRAVEL_APPROVAL }</Text>
          { renderDownloadDialog() }
        </div>
        { approvesListHtml }
        <ErrorReportDialog errorDialog={ errorDialog } onErrorDialog={ handleErrorDialog } />
      </div>
    );
  };

  const { loading: approvesLoading, approves } = approvesStore;

  if (approvesLoading) {
    return renderLoading();
  }

  return (
    <div className={ styles.wrapper }>
      { renderLists(approves) }
    </div>
  );
});
