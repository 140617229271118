import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Collapse, Checkbox, Input } from 'new-ui';
import { getText } from '../../../../i18n';

import { QA_ATTRIBUTES } from '../../../bi/constants/attributesForTests';

import { withStores } from '../../../bi/context';
import { MOBX_STORES } from '../../../bi/context/stores';

import { FilterTravelPolicy } from '../../../components/FilterTravelPolicy';
import { FilterPanel } from '../../../components/FilterPanel';
import { DateTimeSlider } from '../../../components/DateTimeSlider';
import { PriceSlider } from '../../../components/PriceSlider';

import { isSapsan } from '../../../bi/services/trains/corporateTrains/sapsan';

import { isSmartAgent } from '../../../bi/utils/env';

import { Train, TrainsSearchFilterProps } from './types';

import styles from '../styles/searchFilter.module.css';

const LABELS = {
  PLACEHOLDER: getText('trains:filter.numberOrName'),
  FAVORITES: getText('trains:filter.favorites'),
  ONLY_FAVORITES: getText('trains:filter.onlyFavorites'),
  TRAVEL_POLICY: getText('trains:filter.travelPolicy'),
  PRICE: getText('trains:filter.price'),
  CAR_TYPE: getText('trains:filter.carType'),
  TRANSFERS: getText('trains:filter.transferStation'),
  DATE_TO: (date: string) => getText('trains:filter.dateTo', { date }),
  DEPARTURE_FROM: (city: string) => getText('trains:filter.departureFrom', { city }),
  ARRIVAL_TO: (city: string) => getText('trains:filter.arrivalTo', { city }),
  SAPSAN: getText('constants:train.trainNames.sapsan'),
};

const UPDATE_TIME_TYPES = {
  ARRIVAL: 'arrival',
  DEPARTURE: 'departure',
};

@withStores([MOBX_STORES.TRAIN_TICKETS])
@observer
class TrainsSearchFilter extends Component<TrainsSearchFilterProps> {
  renderTravelPolitics = () => {
    const {
      stores: {
        trainTicketsStore: {
          filters: {
            travelPolicyList: filtersTravelPolicies,
            selectedTravelPolicy,
          },
          unavailableTravelPolicy,
        },
      },
      travelPolicyList,
      updateTravelPolicyFilter,
    } = this.props;

    return filtersTravelPolicies.length > 1 && (
      <Collapse
        opened
        label={ LABELS.TRAVEL_POLICY }
        className={ styles.item }
      >
        <FilterTravelPolicy
          list={ filtersTravelPolicies }
          travelPolicyList={ travelPolicyList }
          readOnly={ unavailableTravelPolicy }
          selected={ selectedTravelPolicy }
          onChangeCheckbox={ updateTravelPolicyFilter }
          qaAttrCheckbox={ QA_ATTRIBUTES.search.train.filters.tp }
        />
      </Collapse>
    );
  };

  renderPriceSlider = () => {
    const {
      stores: {
        trainTicketsStore: {
          filters: {
            price: {
              from,
              to,
              border: {
                from: min,
                to: max,
              },
              roundBy,
            },
          },
        },
      },
      updatePriceFilter,
    } = this.props;

    return (
      <Collapse
        opened
        label={ LABELS.PRICE }
        className={ styles.item }
      >
        <PriceSlider
          min={ min }
          max={ max }
          start={ from }
          end={ to }
          roundBy={ roundBy }
          onSlide={ updatePriceFilter }
          qaAttrPriceMax={ QA_ATTRIBUTES.search.train.filters.priceMax }
          qaAttrPriceMin={ QA_ATTRIBUTES.search.train.filters.priceMin }
        />
      </Collapse>
    );
  };

  renderFavoriteCheckbox = () => {
    const {
      stores: { trainTicketsStore: { filters: { favoriteId }, isAnyFavorite } },
      updateFavoriteFilter,
    } = this.props;

    if (isSmartAgent || !isAnyFavorite) return null;

    return (
      <Collapse
        opened
        label={ LABELS.FAVORITES }
        className={ styles.item }
      >
        <Checkbox
          value={ favoriteId }
          onChange={ updateFavoriteFilter }
          className={ styles.favorites }
        >
          { LABELS.ONLY_FAVORITES }
        </Checkbox>
      </Collapse>
    );
  };

  renderRouteSlider = () => {
    const {
      stores: {
        trainTicketsStore: {
          filters: {
            time: {
              date,
              departure: {
                border: {
                  from: departureMin,
                  to: departureMax,
                },
                from: departureFrom,
                to: departureTo,
                city: departureCity,
              },
              arrival: {
                border: {
                  from: arrivalMin,
                  to: arrivalMax,
                },
                from: arrivalFrom,
                to: arrivalTo,
                city: arrivalCity,
              },
            },
          },
        },
      },
      updateTimeFilter,
    } = this.props;

    return (
      <Collapse
        opened
        label={ LABELS.DATE_TO(date) }
        className={ styles.item }
      >
        <DateTimeSlider
          min={ departureMin }
          max={ departureMax }
          label={ LABELS.DEPARTURE_FROM(departureCity) }
          from={ departureFrom }
          to={ departureTo }
          onChange={ (...props) => updateTimeFilter(UPDATE_TIME_TYPES.DEPARTURE, ...props) }
        />
        <DateTimeSlider
          min={ arrivalMin }
          max={ arrivalMax }
          label={ LABELS.ARRIVAL_TO(arrivalCity) }
          from={ arrivalFrom }
          to={ arrivalTo }
          onChange={ (...props) => updateTimeFilter(UPDATE_TIME_TYPES.ARRIVAL, ...props) }
        />
      </Collapse>
    );
  };

  renderSearchInput = () => {
    const { stores: { trainTicketsStore: { filters: { trainNumberOrName } } }, updateTrainNumberFilter } = this.props;
    const value = trainNumberOrName || '';

    return (
      <div className={ styles.item }>
        <Input
          isCleansing
          className={ styles.search }
          value={ value }
          placeholder={ LABELS.PLACEHOLDER }
          onChange={ updateTrainNumberFilter }
          qaAttr={ QA_ATTRIBUTES.search.train.filters.number }
        />
      </div>
    );
  };

  renderIsSapsan = () => {
    const {
      stores: {
        trainTicketsStore: {
          filters: { isSapsan: isSapsanFilter },
          sources,
          sourcesWithTransfer,
        },
      },
      updateIsSapsanFilter,
    } = this.props;

    const isSapsanAmongTrains = sources.some((item: { TrainNumber: string }) => isSapsan({ Number: item.TrainNumber }));

    const isSapasanAmongTrainsWithTransfer = sourcesWithTransfer.some((items: { Trains: Train[] }[]) => items.some(({ Trains }) =>
      Trains.some(item => isSapsan({ Number: item.TrainNumber })),
    ));

    const displayIsSapsanFilter = isSapsanAmongTrains || isSapasanAmongTrainsWithTransfer;

    if (!displayIsSapsanFilter) return null;

    return (
      <div className={ styles.item }>
        <Checkbox
          value={ isSapsanFilter }
          onChange={ updateIsSapsanFilter }
          className={ styles.checkbox_sapsan }
          qaAttr={ QA_ATTRIBUTES.search.train.filters.isSapsan }
        >
          { LABELS.SAPSAN }
        </Checkbox>
      </div>
    );
  };

  renderTrainTypes = () => {
    const { stores: { trainTicketsStore: { filters: { type } } }, updateTypeFilter } = this.props;
    const checkboxNames = Object.keys(type).sort();

    const content = checkboxNames.map((name, index) => (
      <Checkbox
        value={ type[name] }
        key={ name }
        onChange={ value => updateTypeFilter({ type: name, value }) }
        className={ styles.checkbox }
        qaAttr={ `${QA_ATTRIBUTES.search.train.filters.type}-${index}` }
      >
        { name }
      </Checkbox>
    ));

    return (
      <Collapse
        opened
        label={ LABELS.CAR_TYPE }
        className={ styles.item }
      >
        { content }
      </Collapse>
    );
  };

  renderTransfers = () => {
    const { stores: { trainTicketsStore: { filters: { transfers } } }, updateTransfersFilter } = this.props;
    const checkboxKeys = Object.keys(transfers);
    const checkboxNames = Object.keys(transfers).map(key => transfers[key].name).sort();

    if (!checkboxNames.length) return null;

    const content = checkboxNames.map(name => {
      const checkbox = checkboxKeys.find(item => transfers[item].name === name);

      if (!checkbox) return <React.Fragment key={ checkbox } />;

      return (
        <Checkbox
          value={ transfers[checkbox].selected }
          key={ checkbox }
          onChange={ value => updateTransfersFilter({ transfers: checkbox, value }) }
          className={ styles.checkbox_transfers }
        >
          { name.toLocaleLowerCase() }
        </Checkbox>
      );
    });

    return (
      <Collapse
        opened
        label={ LABELS.TRANSFERS }
        className={ styles.item }
      >
        { content }
      </Collapse>
    );
  };

  render() {
    return (
      <FilterPanel>
        { this.renderTravelPolitics() }
        { this.renderPriceSlider() }
        { this.renderFavoriteCheckbox() }
        { this.renderRouteSlider() }
        { this.renderSearchInput() }
        { this.renderIsSapsan() }
        { this.renderTransfers() }
        { this.renderTrainTypes() }
      </FilterPanel>
    );
  }
}

export { TrainsSearchFilter };
