import Auth from './auth';
import App from './app';
import Workspace from './workspace';
import UserSession from './userSession';
import Format from './format';

import Airline from './airline';
import Hotels from './hotels';
import Trains from './trains';
import Transfer from './transfer';
import VipHall from './vipHall';

import Cart from './cart';
import Checkout from './checkout';
import Notepad from './notepad';
import Booking from './booking';

import Order from './order';
import Payment from './payment';
import Report from './report';
import Employee from './employee';
import Settings from './settings';
import TravelPolicyService from './travelPolicy';
import Smartdesk from './smartdesk';

import Intercom from './chat/intercom';
import CarrotQuest from './chat/carrotQuest';
import ChatDecorator from './chat';
import Notification from './notification';
import ToDo from './toDo';
import Quality from './quality';
import Favorites from './favorites';

import FeatureFlags from './featureFlags';
import AccountSettings from './accountSettings';
import TripTags from './tripTags';
import Approve from './approve';
import Requests from './requests';
import Aeroexpress from './aeroexpress';
import Popups from './popups';
import SidePanel from './sidePanel';
import Departments from './departments';
import ApprovalSchemes from './approvalSchemes';
import ApprovalERSchemes from './approvalERSchemes';
import BonusProgram from './bonusProgram';
import AirlineSeats from './airlineSeats';
import ExpenseReports from './expenseReports';
import CustomAnalytics from './customAnalytics';
import UiSettings from './uiSettings';
import EventService from './events';

import TravelApproval from './travelApproval';
import Taxi from './taxi';
import ChartsAnalytics from './chartsAnalytics';
import Banners from './banners';
import NewHotels from './newHotels';

import { Metrics } from './metriсs';
import { BreadCrumbs } from './breadCrumbs';
import { TripComments } from './tripComments';
import { ProductAnalytics } from './productAnalytics';
import { Signature } from './signature';

export const CONSTRUCTORS = {
  Auth,
  App,
  Workspace,
  Format,
  Airline,
  Hotels,
  NewHotels,
  Trains,
  Transfer,
  VipHall,
  Cart,
  Checkout,
  Notepad,
  Booking,
  Order,
  Payment,
  Report,
  Employee,
  Settings,
  ChatDecorator,
  Smartdesk,
  Intercom,
  CarrotQuest,
  Notification,
  ToDo,
  Quality,
  UserSession,
  Favorites,
  TravelPolicy: TravelPolicyService,
  FeatureFlags,
  AccountSettings,
  TripTags,
  Requests,
  Approve,
  Aeroexpress,
  Popups,
  SidePanel,
  Departments,
  ApprovalSchemes,
  ApprovalERSchemes,
  BonusProgram,
  AirlineSeats,
  ExpenseReports,
  CustomAnalytics,
  UiSettings,
  TravelApproval,
  Taxi,
  ChartsAnalytics,
  Banners,
  Metrics,
  BreadCrumbs,
  TripComments,
  ProductAnalytics,
  EventService,
  Signature,
};

const getServiceConstructor = <List extends typeof CONSTRUCTORS, K extends keyof List>(name: K): List[K] =>
  (CONSTRUCTORS as List)[name];

export default getServiceConstructor;
