import React from 'react';
import { Text, Tooltip } from 'new-ui';

import { getText } from '../../../../i18n';

import AgencyFee from '../../../components/AgencyFee';

import MoneyFormat from '../../../bi/utils/money';
import { getShortTypeText } from '../../../bi/utils/train';
import { isSmartAgent } from '../../../bi/utils/env';

import { IClassDetails, IFreePlacesDetailsProps } from './types';

import styles from '../styles/freePlaces.module.css';

const LABELS = {
  RUB: getText('trains:ticket.rub'),
  LESS_SEATS: getText('trains:ticket.lessSeats'),
};

const FreePlacesDetails = ({ classes, travellers, qaAttrPrice }: IFreePlacesDetailsProps) => {
  const renderCarsAndCount = ({ FreePlaces, Type }: IClassDetails, index: number) => {
    const lessSeatsThanTravellers = travellers > FreePlaces;

    const countPlacesHtml = lessSeatsThanTravellers ? (
      <Tooltip
        renderContent={ () => (
          <Text
            color='white'
            className={ styles.tooltip }
            type='NORMAL_14_130'
          >
            { LABELS.LESS_SEATS }
          </Text>
        ) }
      >
        <Text
          className={ styles.count }
          type='NORMAL_14'
          color='red'
        >
          { FreePlaces }
        </Text>
      </Tooltip>
    ) : (
      <Text
        className={ styles.count }
        type='NORMAL_14'
        color='gray'
      >
        { FreePlaces }
      </Text>
    );

    return (
      <div className={ styles.types } key={ `type_${Type}_${styles.types}_${index}` }>
        <Text
          type='NORMAL_14'
          color='gray'
        >
          {getShortTypeText(Type, true)}:
        </Text>
        { countPlacesHtml }
      </div>
    );
  };

  const renderMinimalCost = ({ Price, Type, AgentFee }: IClassDetails, index: number) => {
    const qaAttr = qaAttrPrice ? `${qaAttrPrice}-${index}` : '';

    const renderAgentFee = () => {
      if (!isSmartAgent) {
        return null;
      }

      return (
        <AgencyFee
          fee={ Number(AgentFee) }
        />
      );
    };

    return (
      <div
        className={ styles.wrapper_cost }
        key={ `minimal_cost_${Type}_${styles.cost}_${index}` }
        data-qa={ qaAttr }
      >
        <div className={ styles.cost }>
          <Text type='SEMIBOLD_14'>
            { MoneyFormat.money(Price, false) }
          </Text>
          <Text
            className={ styles.currency }
            type='NORMAL_14'
          >
            { LABELS.RUB }
          </Text>
        </div>
        { renderAgentFee() }
      </div>
    );
  };

  return (
    <>
      <div className={ styles.cars }>
        { classes.map(renderCarsAndCount) }
      </div>
      <div className={ styles.costs }>
        { classes.map(renderMinimalCost) }
      </div>
    </>
  );
};

export { FreePlacesDetails };
