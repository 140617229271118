import React, { useState, useEffect } from 'react';
import { Checkbox, Text, PageLoader } from 'new-ui';

import { getText } from '../../../../../i18n';

import RequestService from '../../../../bi/services/requests';
import WorkspaceService from '../../../../bi/services/workspace';
import { Company } from '../../../../bi/services/workspace/types';

import { ISwitchIntData, IGuids, ICompanyList } from '../../../../bi/types/integration';

import { MainAnalytic } from '../../../../bi/utils/analytics';

import { ExternalLink } from '../../../../components/ExternalLink';
import { Identifier } from './components/Indentifier';
import { AddIdentifierButton } from './components/IdentifierButton';
import { ConfirmationDialog } from './components/ConfirmationDialog';
import { ErrorDialog } from './components/ErrorDialog';
import { PromoText } from './components/PromoText';

import styles from './index.module.css';

const mapCompanies = (companies: Company[] = []) =>
  companies.map(({ ShortCompanyName, CompanyId, CompanyName }) => ({
    label: ShortCompanyName || CompanyName,
    value: CompanyId,
  }));

const getFreeCompanies = (allCompanies: Company[] = [], boundIds: any[] = []) =>
  mapCompanies(allCompanies.filter(({ CompanyId }) => !boundIds.includes(CompanyId)));

const getCompaniesByIds = (ids: number[] = [], companies: Company[] = []) => ids.map((id) => {
  const defaultCompanies = { ShortCompanyName: '', CompanyName: '', CompanyId: 0 };
  const company = companies.find(({ CompanyId }) => id === CompanyId) || defaultCompanies;

  return {
    label: company.ShortCompanyName || company.CompanyName,
    value: company.CompanyId,
  };
});

const LABELS = {
  TITLE: getText('settings:integration.title'),
  DOWNLOAD: getText('settings:integration.download'),
  ENABLE_INTEGRATION: getText('settings:integration.enableIntegration'),
  CHOOSE_ORGANIZATION: getText('settings:integration.chooseOrganization'),
  PROMO_TEXT: {
    ONE: {
      BUH: getText('settings:integration.promoText.one.buh'),
      MOST_POPULAR: getText('settings:integration.promoText.one.mostPopular'),
      FIRM: getText('settings:integration.promoText.one.firm'),
      '1C': getText('settings:integration.promoText.one.1c'),
      UPGRADABLE: getText('settings:integration.promoText.one.upgradable'),
    },
    TWO: {
      BUH: getText('settings:integration.promoText.two.buh'),
      IN: getText('settings:integration.promoText.two.in'),
      EXPENSE_REPORTS: getText('settings:integration.promoText.two.expenseReports'),
    },
    THREE: {
      FOR_INTEGRATION: getText('settings:integration.promoText.three.forIntegration'),
      BUH: getText('settings:integration.promoText.three.buh'),
      TURN_ON_SETTINGS: getText('settings:integration.promoText.three.turnOnSettings'),
    },
  },
};

const ERRORS = {
  EMPTY_GUID: getText('settings:integration.errors.emptyGuid'),
  ALREADY_TAKEN: getText('settings:integration.errors.alreadyTaken'),
  COMPANIES: getText('settings:integration.errors.companies'),
  FOR: (companiesNames: string) => getText('settings:integration.errors.for', { companiesNames }),
};

const createErrorMessage = (companies: any = []) => {
  const companiesNames = companies.join(', ');

  return `${ERRORS.COMPANIES}${companiesNames.length ? ERRORS.FOR(companiesNames) : ''}`;
};

interface IIntegrationTab {
  requestsService: RequestService,
  workspaceService: WorkspaceService,
}

interface IState {
  loading: boolean,
  integration: {
    loading: boolean,
    enabled: boolean,
    guids: any,
    showEmptyIdentifier: boolean,
    editMode: boolean,
    error: boolean,
    failedCompanies: number[],
  },
}

const IntegrationTab = ({
  requestsService,
  workspaceService,
}: IIntegrationTab) => {
  const [loading, setLoading] = useState(true);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [enabled, setEnabled] = useState(false);
  const [guids, setGuids] = useState<IGuids[]>([]);
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const [editMode, setEditMode] = useState({});
  const [allCompanies, setAllCompanies] = useState<Company[]>([]);
  const [showEmptyIdentifier, setShowEmptyIdentifier] = useState(false);
  const [freeCompanies, setFreeCompanies] = useState<ICompanyList[]>([]);
  const [failedCompanies, setFailedCompanies] = useState<number[]>([]);
  const [error, setError] = useState(false);

  const updateState = (state: IState, updAllCompanies: Company[]) => {
    const {
      loading: updLoading,
      integration: {
        loading: updButtonLoading,
        enabled: updEnabled,
        guids: updGuids,
        showEmptyIdentifier: updShowEmptyIdentifier,
        editMode: updEditMode,
        failedCompanies: updFailedCompanies,
        error: updErr,
      },
    } = state;

    const boundIds = updGuids.reduce((acc: any, { companies }: { companies: string[] }) => [...acc, ...companies], []);
    const updFreeCompanies: { label: string; value: number }[] = getFreeCompanies(updAllCompanies, boundIds);

    setAllCompanies(updAllCompanies);
    setLoading(updLoading);
    setButtonLoading(updButtonLoading);
    setEnabled(updEnabled);
    setGuids(updGuids);
    setFreeCompanies(updFreeCompanies);
    setShowEmptyIdentifier(updShowEmptyIdentifier);
    setEditMode(updEditMode);
    setFailedCompanies(updFailedCompanies);
    setError(updErr);
  };

  useEffect(() => {
    const { Companies } = workspaceService.get();

    requestsService.getIntegration();
    MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.SETTINGS.INTEGRATION_OPEN);

    const unsubscribe = requestsService.subscribe((state: IState) => updateState(state, Companies));

    return () => {
      requestsService.clearStore();
      unsubscribe();
    };
  }, [requestsService, workspaceService]);

  const hasFreeCompanies = !!freeCompanies.length;

  const hasNoActiveIntegrations = !guids.length;

  const hasOnlyOneCompany = allCompanies.length === 1;

  const getValidateGuidError = (guid: string, prevGuid: string) => {
    if (!guid) {
      return ERRORS.EMPTY_GUID;
    }

    const isAlreadyTaken = guids.find(item => item.guid === guid);

    if (guid !== prevGuid && isAlreadyTaken) {
      return ERRORS.ALREADY_TAKEN;
    }

    return '';
  };

  const getValidateCompaniesError = (companies: number[]) => (!companies.length ? LABELS.CHOOSE_ORGANIZATION : '');

  const handleValidate = (guid = '', prevGuid = '', companies: number[]) => ({
    guidErr: getValidateGuidError(guid, prevGuid),
    companiesErr: getValidateCompaniesError(companies),
  });

  const handleToggleEditMode = ({ currentGuid, previousGuid }: { currentGuid: string, previousGuid: string }) => requestsService.toggleEditMode({ currentGuid, previousGuid });

  const handleSwitchIntegration = ({ previousGuid, previousCompanies, guid, companies }: ISwitchIntData) =>
    requestsService.switchIntegration({ guid, companies, previousGuid, previousCompanies });

  const handleToggleEmptyIdentifier = (v: boolean) => requestsService.toggleEmptyIdentifier(v);

  const handleAddEdentifier = () => {
    MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.SETTINGS.INTEGRATION_IDENTIFIER);
    handleToggleEmptyIdentifier(true);
  };

  const handleToggleIntegration = (value: boolean) => {
    const { enableIntegration, disableIntegration } = requestsService;

    if (value) {
      handleToggleEmptyIdentifier(true);
      enableIntegration();
      MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.SETTINGS.INTEGRATION_CHECKBOX_CHOSEN);

      return;
    }

    if (guids.length) {
      setShowConfirmationDialog(true);

      return;
    }

    disableIntegration();
  };

  const handleConfirmDisableIntegration = () => {
    setShowConfirmationDialog(false);
    requestsService.disableIntegration(true);

    MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.SETTINGS.INTEGRATION_DELETE_BUTTON);
  };

  const handleChangeEmptyIdentifier = () => {
    if (hasNoActiveIntegrations) {
      requestsService.disableIntegration();
    }

    handleToggleEmptyIdentifier(false);
  };

  const handleHideErrorDialog = () => requestsService.hideErrorDialog();

  const renderAddIdentifierLink = () => {
    if (!enabled || !hasFreeCompanies || hasNoActiveIntegrations || showEmptyIdentifier) {
      return null;
    }

    return <AddIdentifierButton onClick={ handleAddEdentifier } />;
  };

  const renderConfirmationDialog = () => (
    <ConfirmationDialog
      show={ showConfirmationDialog }
      loading={ loading }
      onClose={ () => setShowConfirmationDialog(false) }
      disableIntegration={ handleConfirmDisableIntegration }
    />
  );

  const renderErrorDialog = () => {
    const companiesNames = getCompaniesByIds(failedCompanies, allCompanies).map(({ label }) => label);
    const message = createErrorMessage(companiesNames);

    return (
      <ErrorDialog
        show={ error }
        onClick={ handleHideErrorDialog }
        message={ message }
      />
    );
  };

  const renderEmptyIdentifier = () => {
    if (!showEmptyIdentifier) {
      return null;
    }

    return (
      <Identifier
        editable
        loading={ buttonLoading }
        single={ hasOnlyOneCompany }
        companyList={ freeCompanies }
        switchIntegration={ handleSwitchIntegration }
        toggleEditMode={ handleChangeEmptyIdentifier }
        validateFn={ handleValidate }
        data={ {
          guid: '',
          companies: [],
        } }
      />
    );
  };

  const renderIdentifiers = () => (
    guids.map(({ guid, companies: guidCompanies }) => {
      const compList = [...getCompaniesByIds(guidCompanies, allCompanies), ...freeCompanies];

      return (
        <Identifier
          key={ guid }
          data={ { guid, companies: guidCompanies } }
          // @ts-ignore
          editable={ editMode[guid] || false }
          loading={ buttonLoading }
          single={ hasOnlyOneCompany }
          companyList={ compList }
          switchIntegration={ handleSwitchIntegration }
          toggleEditMode={ handleToggleEditMode }
          validateFn={ handleValidate }
        />
      );
    })
  );

  const titleHtml = (
    <Text type='bold_24'>
      { LABELS.TITLE }
    </Text>
  );

  const promoHtml = (
    <div className={ styles.promo }>
      <PromoText>
        «<ExternalLink link='http://v8.1c.ru/buhv8' text={ LABELS.PROMO_TEXT.ONE.BUH } />» { LABELS.PROMO_TEXT.ONE.MOST_POPULAR }
        { LABELS.PROMO_TEXT.ONE.FIRM } «<ExternalLink link='http://1c.ru' text={ LABELS.PROMO_TEXT.ONE['1C'] } />»
        { LABELS.PROMO_TEXT.ONE.UPGRADABLE }
      </PromoText>
      <PromoText>
        { LABELS.PROMO_TEXT.TWO.IN } «<ExternalLink link='http://v8.1c.ru/buhv8' text={ LABELS.PROMO_TEXT.TWO.BUH } />»
        { LABELS.PROMO_TEXT.TWO.EXPENSE_REPORTS }
      </PromoText>
      <PromoText>
        { LABELS.PROMO_TEXT.THREE.FOR_INTEGRATION } «<ExternalLink link='http://v8.1c.ru/buhv8' text={ LABELS.PROMO_TEXT.THREE.BUH } />»,
        { LABELS.PROMO_TEXT.THREE.TURN_ON_SETTINGS }
      </PromoText>
      <div onClick={ () => MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.SETTINGS.INTEGRATION_INSTRUCTION) }>
        <ExternalLink
          link={ `/documents/${encodeURIComponent('1С_БП + Smartway')}.pdf` }
          text={ LABELS.DOWNLOAD }
        />
      </div>
    </div>
  );

  const checkboxHtml = (
    <Checkbox
      value={ enabled }
      onChange={ handleToggleIntegration }
    >
      { LABELS.ENABLE_INTEGRATION }
    </Checkbox>
  );

  if (loading) {
    return <PageLoader />;
  }

  return (
    <>
      { titleHtml }
      { promoHtml }
      { checkboxHtml }
      { renderIdentifiers() }
      { renderEmptyIdentifier() }
      { renderAddIdentifierLink() }
      { renderConfirmationDialog() }
      { renderErrorDialog() }
    </>
  );
};

export { IntegrationTab };
