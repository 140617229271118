import React, { ReactNode, useState } from 'react';
import {
  IconButton,
  Text,
  Tooltip,
  Button,
  LinkButton,
  Dropdown,
  Icon,
} from 'new-ui';
import { getText } from '../../../../../i18n';

import { VoucherLink } from './components/VoucherLink';

import getTripDateBorders from '../../../../bi/utils/tripDateBorders';
import {
  formatDate,
  formatRangeDateWithSimplicity,
  formatDateWithMinutes,
} from '../../../../bi/utils/formatDate';
import { MainAnalytic } from '../../../../bi/utils/analytics';
import { isSmartAgent } from '../../../../bi/utils/env';

import { EditableText } from '../../../../components/EditableText';
import { StatusMarker } from '../../../../components/StatusMarker';
import { EventInfo } from '../../../../components/EventAppModal';

import { STATUSES } from '../../../../bi/constants/trips';
import { PATTERN } from '../../../../bi/constants/dateFormats';
import SERVICETYPE from '../../../../bi/constants/serviceType';
import FORMATS from '../../../../bi/constants/formats';
import { SMARTWAY } from '../../../../bi/constants/accounts';
import { FINANCEDOCUMENTSRIGHT } from '../../../../bi/constants/rights';
import { QA_ATTRIBUTES } from '../../../../bi/constants/attributesForTests';
import { PAYMENT_TYPE } from '../../../../bi/constants/payment';

import { TripComments } from '../TripComments';

import OrderService from '../../../../bi/services/order';
import AccountSettings from '../../../../bi/services/accountSettings';

import {
  IFormatChanges,
  IOrderDetailsData,
  ITripVersionAndMarkActualVersion,
  OrderItem,
  TripVersion,
} from '../../../../bi/types/order';
import { TEventValue } from '../../../../bi/services/events/type';
import { ICompanyFunds } from '../../../../bi/types/app';
import { Rights } from '../../../../bi/types/workspace';
import { IPaymentInvoice } from '../../../../bi/services/order/types';

import styles from './index.module.css';

const LABELS = {
  EDIT_PLACEHOLDER: getText('trip:header.nameOfTrip'),
  SEND_TO_EMAIL: getText('trip:header.sendToEmail'),
  PAYMENT_INVOICE: getText('trip:header.paymentInvoice'),
  DOWNLOAD_INVOICE_XLSX: getText('trip:header.downloadInvoiceXlsx'),
  SEND_BY_EMAIL: getText('trip:header.sendByEmail'),
  LIST_OF_CHANGES: getText('trip:header.listOfChanges'),
  CREATE_TRIP: getText('trip:header.createTrip'),
  EDIT_TRIP: getText('trip:header.editTrip'),
  CREATED: getText('trip:header.created'),
  EVENT_APPLICATION: getText('trip:header.applicationEvent'),
  SHOW_APPLICATION: getText('trip:header.showApplication'),
};

const selectDateFormat = (dateAsString: string, withMinutes = false) => {
  const lessThenCurrent = new Date(dateAsString).getFullYear() < new Date().getFullYear();

  if (!withMinutes) {
    return lessThenCurrent ? PATTERN.DATE_WITH_YEAR : PATTERN.DAY_OF_MONTH;
  }

  return lessThenCurrent ? PATTERN.DATE_TIME_WITH_YEAR : PATTERN.DAY_OF_MONTH_WITH_TIME;
};

const getBooking = (user: string, eventDate: string, id: number): IFormatChanges => ({
  id,
  description: LABELS.CREATE_TRIP,
  user,
  eventDate,
});

const checkStatus = (status: number) => status === STATUSES.Changed || status === STATUSES.Canceled;

const filterAndMarkActualVersion = (items: TripVersion[]): ITripVersionAndMarkActualVersion[] => {
  const originalVersionId = items[items.length - 1].Id;
  const filteredItems = items.filter(({ Status }) => checkStatus(Status));

  return filteredItems.map((i, ind) => ({
    ...i,
    originalVersionId,
    isActualVersion: ind === 0,
  }));
};

const getChangesList = (orderItems: OrderItem[]): ITripVersionAndMarkActualVersion[] => orderItems
  .reduce<ITripVersionAndMarkActualVersion[]>((acc, { Versions }) => [...acc, ...filterAndMarkActualVersion(Versions)], [])
  .sort((a, b) => new Date(a.EventDate).getTime() - new Date(b.EventDate).getTime());

const formatChanges = (changes: ITripVersionAndMarkActualVersion[]): IFormatChanges[] => {
  const formattedChanges: IFormatChanges[] = [];

  changes.forEach(({
    Id,
    ServiceType,
    CreatedBy,
    EventDate,
    Description,
    ReturnFunds,
    Surcharges,
    Penalties,
    isActualVersion,
    originalVersionId,
  }) => {
    const changesForItem = [];

    const hasReturnFunds = ReturnFunds && ReturnFunds.length;
    const hasSurcharges = Surcharges && Surcharges.length;
    const hasPenalties = Penalties && Penalties.length;

    if (hasReturnFunds) {
      changesForItem.push({
        id: Id,
        description: ReturnFunds[0].Description,
        user: CreatedBy,
        eventDate: EventDate,
      });
    }

    if (hasSurcharges) {
      changesForItem.push({
        id: Id,
        description: Surcharges[0].Description,
        user: CreatedBy,
        eventDate: EventDate,
      });
    }

    if (hasPenalties) {
      changesForItem.push({
        id: Id,
        description: Penalties[0].Description,
        user: CreatedBy,
        eventDate: EventDate,
      });
    }

    if (changesForItem.length === 0) {
      changesForItem.push({
        id: Id,
        description: <span>{ LABELS.EDIT_TRIP }<br /> { Description }</span>,
        user: CreatedBy,
        eventDate: EventDate,
      });
    }

    if (isActualVersion) {
      const lastItemIndex = changesForItem.length - 1;
      const showActualVersion = ServiceType !== SERVICETYPE.FLIGHT_CERTIFICATE;

      changesForItem[lastItemIndex] = {
        ...changesForItem[lastItemIndex],
        originalVersionId,
        showActualVersion,
      };
    }

    formattedChanges.push(...changesForItem);
  });

  return formattedChanges;
};

interface ITripHeaderProps {
  details: IOrderDetailsData;
  rights: Rights;
  orderService: OrderService;
  companyNameSettings: string;
  CompanyFunds: ICompanyFunds[];
  event: TEventValue;
  tripPlan: ReactNode | null;
  aggregationId: null | number;
  onTripRename(id: number, name: string): void;
  onDownloadOriginalTrip(originalVersionId: number | null): Promise<void>;
  onAddComment(comments: string): void;
  onShowPaymentInvoice(message: string): void;
  onShowPaymentEmailInvoice(): void;
  accountSettingsService: AccountSettings,
}

const TripHeader = ({
  details,
  onTripRename,
  orderService,
  onDownloadOriginalTrip,
  onShowPaymentInvoice,
  onShowPaymentEmailInvoice,
  onAddComment,
  companyNameSettings,
  CompanyFunds,
  rights,
  tripPlan,
  event,
  aggregationId,
  accountSettingsService,
}: ITripHeaderProps) => {
  const [openModal, setOpenModal] = useState<boolean>(false);

  const { Status, OrderItems, UserName, Name, TripId, Date } = details;
  const objCompanyFunds = CompanyFunds.find(({ PaymentType }) => PaymentType === PAYMENT_TYPE.PAYMENT_CLOSING);

  const handleDownloadPaymentInvoice = (format: string) => {
    orderService.getPaymentInvoice(details.Companies[0], details.TripId, format)
      .then((res: IPaymentInvoice) => {
        if (!res.Message) {
          orderService.downloadPaymentInvoice(res.FileLink);
        } else {
          onShowPaymentInvoice(res.Message);
        }
      });
    MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.TRIPS.TRIP_DETAIL_INVOICE_PRESSED);
  };

  const { min, max } = getTripDateBorders(OrderItems);
  const changesOnly: IFormatChanges[] = formatChanges(getChangesList(OrderItems));
  const changesWithBooking: IFormatChanges[] = changesOnly.length
    ? [getBooking(UserName, Date, TripId), ...changesOnly]
    : [];
  const showChangesList = changesWithBooking.length > 0;

  const renderItem = ({
    id,
    user,
    eventDate,
    description,
    originalVersionId = null,
    showActualVersion = false,
  }: IFormatChanges, index: number) => {
    const itemClassNames = [styles.item];

    if (changesWithBooking.length > 1 && index < changesWithBooking.length - 1) {
      itemClassNames.push(styles.divided);
    }

    return (
      <div
        key={ `${id}__${index}` }
        className={ itemClassNames.join(' ') }
      >
        <div>
          <Text type='SEMIBOLD_16' color='white' className={ styles.title }>
            { formatDateWithMinutes(eventDate) }
          </Text>
          <Text className={ styles.title } color='white'>, { user }</Text>
        </div>
        <div className={ styles.description }>
          <Text type='NORMAL_14_130' color='white'>
            { description }
          </Text>
          { showActualVersion && (
            <VoucherLink
              className={ styles.download }
              onDownload={ () => onDownloadOriginalTrip(originalVersionId) }
            />
          ) }
        </div>
      </div>
    );
  };

  const renderContentTooltip = () => (
    <div className={ styles['tooltip-wrapper'] }>
      { changesWithBooking.map(renderItem) }
    </div>
  );

  const renderBtnDownloadPaymentInvoice = () => {
    if ((companyNameSettings !== SMARTWAY) || (rights.Finance !== FINANCEDOCUMENTSRIGHT.Available) || objCompanyFunds) {
      return <div className={ styles.payment }/>;
    }

    return (
      <div className={ styles.payment_invoice }>
        <Dropdown
          onClick={ () => handleDownloadPaymentInvoice(FORMATS.PDF) }
          theme='default'
          renderLabel={ () => (
            <Text
              className={ styles.dropdown_title }
              type='NORMAL_16'
            >
              { LABELS.PAYMENT_INVOICE }
            </Text>
          ) }
        >
          <div className={ styles.content }>
            <Button
              type='textual'
              onClick={ () => handleDownloadPaymentInvoice(FORMATS.XLSX) }
              className={ styles.btn }
            >
              { LABELS.DOWNLOAD_INVOICE_XLSX }
            </Button>
          </div>
          <div className={ styles.content }>
            <Button
              type='textual'
              onClick={ () => onShowPaymentEmailInvoice() }
              className={ styles.btn }
            >
              { LABELS.SEND_BY_EMAIL }
            </Button>
          </div>
        </Dropdown>
      </div>
    );
  };

  const creatorOrChangesHtml = showChangesList ? (
    <Tooltip
      theme='without-foot'
      position='bottom'
      renderContent={ renderContentTooltip }
    >
      <LinkButton theme='large-default'>
        { LABELS.LIST_OF_CHANGES }
      </LinkButton>
    </Tooltip>
  ) : <Text>{ UserName }</Text>;

  const renderPreview = () => (
    <IconButton iconType='menuTrips' iconColor='blue'>
      <Text type='NORMAL_18'>
        { Name }
      </Text>
    </IconButton>
  );

  const renderEvent = () => {
    const { getNoMice } = accountSettingsService;

    const showEventSticker = !isSmartAgent && !aggregationId && !getNoMice();

    if (!event.Id || !details.MICEEventId || !showEventSticker) {
      return null;
    }

    return (
      <div className={ styles.wrapper_event }>
        <div className={ styles.label }>
          <Text type='NORMAL_16'>
            { LABELS.EVENT_APPLICATION }
          </Text>
          <Icon
            color='accent'
            type='searchGroup'
          />
        </div>
        <Text
          type='NORMAL_16'
          className={ styles.name }
        >
          { event.Name }
        </Text>
        <div className={ styles.button }>
          <Button
            type='textual'
            onClick={ () => setOpenModal(!openModal) }
          >
            { LABELS.SHOW_APPLICATION }
          </Button>
        </div>
      </div>
    );
  };

  const eventModal = () => {
    if (!openModal) {
      return null;
    }

    return (
      <EventInfo
        event={ event }
        show={ openModal }
        onChange={ () => setOpenModal(false) }
      />
    );
  };

  return (
    <>
      { eventModal() }
      <div className={ styles.header }>
        <div className={ styles.name }>
          <EditableText
            renderPreview={ renderPreview }
            value={ Name }
            placeholder={ LABELS.EDIT_PLACEHOLDER }
            onChange={ (value) => onTripRename(TripId, value) }
          />
        </div>
        <div className={ styles.trip_plan }>
          <TripComments onAddComment={ onAddComment }/>
          { tripPlan }
        </div>
      </div>
      <div
        className={ styles.wrapper_all }
      >
        <div className={ styles.wrapper } data-qa={ QA_ATTRIBUTES.trips.trip.header }>
          <div className={ styles.details }>
            <div className={ styles.col }>
              <Text className={ styles.dates }>{ formatRangeDateWithSimplicity(min, max) }</Text>
              <StatusMarker status={ Status } qaAttr={ QA_ATTRIBUTES.trips.trip.status }/>
            </div>
            <div className={ styles.col }>
              <Text>{ LABELS.CREATED } { formatDate(Date, selectDateFormat(Date)) }</Text>
              <div className={ styles['last-change'] }>
                { creatorOrChangesHtml }
              </div>
            </div>
            { renderBtnDownloadPaymentInvoice() }
          </div>
        </div>
        { renderEvent() }
      </div>
    </>
  );
};
export default TripHeader;
