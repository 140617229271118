import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';
import { Button, DotLoading, Text } from 'new-ui';

import { changeLanguage, getText } from '../../../i18n';

import { useServices } from '../../bi/context/services';

import { StatusCondition } from '../../components/StatusCondition';
import { AfterSendAction } from './AfterSendAction';

import parseSearchString from '../../bi/utils/convertSearchParams';

import { ExpenseApprovalStatus, QuickApprovalActionTypes, TravelApprovalStatus } from '../../bi/constants/approve';
import ROUTES from '../../bi/constants/routes';

import styles from './styles/index.module.css';

import swLogo from '../../../svg/logos/s-smartway-icon.svg';

interface IApprovalsSchema<Fn extends (value: any) => any = (
  value: ExpenseApprovalStatus | TravelApprovalStatus | QuickApprovalActionTypes
) => () => void> {
  handleSendAction: Fn,
  accept: Parameters<Fn>[0]
  decline: Parameters<Fn>[0],
  question: string
}

const LABELS = {
  QUESTION: {
    MAIN: getText('quickApprove:question.main'),
    TRAVEL_APPROVAL: getText('quickApprove:question.travelApproval'),
    EXPENSE_REPORT_APPROVAL: getText('quickApprove:question.expenseReportApproval'),
  },
  ACCEPT: getText('quickApprove:accept'),
  DECLINE: getText('quickApprove:decline'),
};

const renderLoading = () => (
  <div className={ styles.loading }>
    <DotLoading />
  </div>
);

export const QuickApprovePage = observer(() => {
  const {
    approveService: {
      store: { message },
      networkStore: { getStatus },
      confirmQuickApprove,
      confirmQuickApproveTravelApproval,
      confirmQuickApproveExpenseReportApproval,
    },
    workspaceService,
  } = useServices(['Approve', 'Workspace']);

  const status = getStatus('quickApprove');

  const query = parseSearchString(location.search) as Record<string, string>;

  const mainQuickApprove = (action: QuickApprovalActionTypes) => () => {
    const { id } = query;

    return confirmQuickApprove(id, action);
  };

  const confirmQuickApproveTravelApprovalRequest = (
    action: TravelApprovalStatus,
  ) => () => {
    const { id, userid } = query;

    return confirmQuickApproveTravelApproval(id, userid, action);
  };

  const confirmQuickApproveExpenseReportApprovalRequest = (
    action: ExpenseApprovalStatus,
  ) => () => {
    const { id, userid } = query;

    return confirmQuickApproveExpenseReportApproval(id, userid, action);
  };

  const approvalsSchemas = {
    [ROUTES.QUICK_APPROVES.MAIN]: {
      handleSendAction: mainQuickApprove,
      accept: QuickApprovalActionTypes.Accepted,
      decline: QuickApprovalActionTypes.Declined,
      question: LABELS.QUESTION.MAIN,
    },
    [ROUTES.QUICK_APPROVES.TRAVEL_APPROVAL]: {
      handleSendAction: confirmQuickApproveTravelApprovalRequest,
      accept: TravelApprovalStatus.Accepted,
      decline: TravelApprovalStatus.Declined,
      question: LABELS.QUESTION.TRAVEL_APPROVAL,
    },
    [ROUTES.QUICK_APPROVES.EXPENSE_REPORT_APPROVAL]: {
      handleSendAction: confirmQuickApproveExpenseReportApprovalRequest,
      accept: ExpenseApprovalStatus.Approved,
      decline: ExpenseApprovalStatus.Declined,
      question: LABELS.QUESTION.EXPENSE_REPORT_APPROVAL,
    },
  };

  const {
    handleSendAction,
    accept,
    decline,
    question,
  } = approvalsSchemas[location.pathname] as IApprovalsSchema;

  const history = useHistory();

  const goToSmartDesk = () => {
    history.push({ pathname: ROUTES.SMARTDESK });
  };

  useEffect(() => {
    if (query?.action) {
      handleSendAction(
        query?.action as ExpenseApprovalStatus | TravelApprovalStatus | QuickApprovalActionTypes,
      )();
    }

    if (query?.locale && !workspaceService.get().Rights?.UserId) {
      changeLanguage(query.locale);
    }
  }, []);

  const RenderApproveForm = () => (
    <>
      <Text type='bold_32'>
        { question }
      </Text>
      <div className={ styles.approveButtons }>
        <Button type='textual' onClick={ handleSendAction(decline) }>
          { LABELS.DECLINE }
        </Button>
        <Button type='secondary' onClick={ handleSendAction(accept) }>
          { LABELS.ACCEPT }
        </Button>
      </div>
    </>
  );

  return (
    <div className={ styles.wrap }>
      <div>
        <img
          alt={ 'sw-logo' }
          src={ swLogo }
          onClick={ goToSmartDesk }
          className={ styles.logo }
        />
      </div>
      <div className={ styles.content }>
        <div className={ styles.form }>
          <StatusCondition
            onNever={ RenderApproveForm }
            onLoading={ renderLoading }
            onLoaded={ () =>
              <AfterSendAction
                redirect={ goToSmartDesk }
                message={ message }
              />
            }
            onError={ () =>
              <AfterSendAction
                redirect={ goToSmartDesk }
                message={ message }
                isError
              />
            }
            status={ status }
          />
        </div>
      </div>
    </div>
  );
});
