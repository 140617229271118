import { Moment } from 'moment';

import debounce from '../../api/debounce';
import ROUTES from '../../api/routes';
import Api from '../../api';

import { formatDate, momentObject } from '../../utils/formatDate';
import { preparedAutocompleted } from '../../utils/chartsAnalytics';
import { MainAnalytic } from '../../utils/analytics';
import toDecline from '../../utils/toDecline';

import {
  MENU_KEYS,
  GENERAL_COMMON_SUMMARY,
  GENERAL_COMMON_SUMMARY_RU_OR_ENG,
  FILTERS_FIELDS,
  STRUCTURE_TYPE,
  STRUCTURE_TYPE_FOR_BACKEND,
  TYPE,
  STRUCTURE_TYPE_LOWER,
  CHARTS_ANALYTICS,
  TYPE_SMART_SERVICE_TYPE,
} from '../../constants/chartsAnalytics';
import { PATTERN } from '../../constants/dateFormats';
import SERVICETYPE from '../../constants/serviceType';

import {
  ObjectForUpdate,
  SecondObjectForUpdate,
} from './types';

import { chartsAnalyticsSummaryStore } from './stores/summary';
import { chartsAnalyticsGeneralStore } from './stores/generalStore';
import { chartsAnalyticsStore } from './stores/chartsAnalyticsStore';
import { chartsAnalyticsSmartSummaryStore } from './stores/smart/summary';

const DEBOUNCE_TIME = 200;

type ApiType = Api['chartsAnalytics'];

class ChartsAnalytics {
  api: ApiType;
  chartsAnalyticsStore = chartsAnalyticsStore;
  chartsAnalyticsGeneralStore = chartsAnalyticsGeneralStore;
  chartsAnalyticsSummaryStore = chartsAnalyticsSummaryStore;
  chartsAnalyticsSmartSummaryStore = chartsAnalyticsSmartSummaryStore;

  autocompleteDebounceEmployees: ApiType['getEmployeesAutocomplete'];
  autocompleteDebounceEmployeesForCustom: ApiType['getEmployeesAutocompleteForCustom'];
  autocompleteDebounceDepartments: ApiType['getDepartmentsAutocomplete'];
  autocompleteDebounceDepartmentsForCustom: ApiType['getDepartmentsAutocompleteForCustom'];
  autocompleteDebounceProjects: ApiType['getProjectsAutocomplete'];
  autocompleteDebounceProjectsForCustom: ApiType['getProjectsAutocompleteForCustom'];
  autocompleteDebounceTags: ApiType['getTagsAutocomplete'];
  autocompleteDebounceTagsForCustom: ApiType['getTagsAutocompleteForCustom'];

  constructor(api: Api) {
    this.api = api.chartsAnalytics;

    this.autocompleteDebounceEmployees = debounce(this.api.getEmployeesAutocomplete, DEBOUNCE_TIME);
    this.autocompleteDebounceEmployeesForCustom = debounce(this.api.getEmployeesAutocompleteForCustom, DEBOUNCE_TIME);
    this.autocompleteDebounceDepartments = debounce(this.api.getDepartmentsAutocomplete, DEBOUNCE_TIME);
    this.autocompleteDebounceDepartmentsForCustom = debounce(this.api.getDepartmentsAutocompleteForCustom, DEBOUNCE_TIME);
    this.autocompleteDebounceProjects = debounce(this.api.getProjectsAutocomplete, DEBOUNCE_TIME);
    this.autocompleteDebounceProjectsForCustom = debounce(this.api.getProjectsAutocompleteForCustom, DEBOUNCE_TIME);
    this.autocompleteDebounceTags = debounce(this.api.getTagsAutocomplete, DEBOUNCE_TIME);
    this.autocompleteDebounceTagsForCustom = debounce(this.api.getTagsAutocompleteForCustom, DEBOUNCE_TIME);
  }

  mappedSelectedCompanies = () =>
    this.chartsAnalyticsStore.commonChartsAnalytics.selectedCompanies.map(({ main }) => main);

  getCommonParamsForBackend = () => {
    const {
      commonChartsAnalytics: {
        startDate,
        endDate,
      },
      filters: {
        departments: {
          selected: departmentsSelected,
        },
        projects: {
          selected: projectsSelected,
        },
        employees: {
          selected: employeesSelected,
        },
        analytics: {
          selected: analyticsSelected,
        },
        tags: {
          selected: tagsSelected,
        },
      },
    } = this.chartsAnalyticsStore;

    return {
      startDate: formatDate(startDate, PATTERN.YEARMONTHDAY),
      endDate: formatDate(endDate, PATTERN.YEARMONTHDAY),
      companyIds: this.mappedSelectedCompanies(),
      filters: {
        Employees: employeesSelected,
        Departments: departmentsSelected,
        Projects: projectsSelected,
        CustomAnalytics: analyticsSelected,
        Tags: tagsSelected,
      },
    };
  };

  setCustomAnalyticsToMenu = (values: any) => this.chartsAnalyticsStore.setCustomAnalyticsToMenu(values);

  makeBeforeRequest = () => {
    const { setResetPaginate, updateShowTooltip, type } = this.chartsAnalyticsSummaryStore;

    this.updateSummaryLoading(true);
    const params = this.getCommonParamsForBackend();
    setResetPaginate();
    updateShowTooltip(false);
    this.changeSummaryType(type);

    return params;
  };

  makeBeforeSubPagesRequest = () => {
    const { paginate: { currentPage, itemsPerPage }, subPageInfo } = this.chartsAnalyticsSummaryStore;

    this.updateSummaryLoading(true);

    const params = this.getCommonParamsForBackend();
    const preparedParams = { ...params, filters: { ...params.filters } };

    return {
      ...preparedParams,
      subPageInfo,
      page: currentPage,
      step: itemsPerPage,
    };
  };

  getGeneralParamsForBackend = () => {
    const {
      commonChartsAnalytics: {
        startDate,
        endDate,
        selectedCompanies,
      },
    } = this.chartsAnalyticsStore;

    const companies = (
      selectedCompanies as { main: number, label: string }[]
    ).map(({ main, label }) => ({
      CompanyId: main,
      CompanyName: label,
    }));

    return {
      startDate: formatDate(startDate, PATTERN.YEARMONTHDAY),
      endDate: formatDate(endDate, PATTERN.YEARMONTHDAY),
      companies,
    };
  };

  updateGeneralLoading = (value: boolean) => this.chartsAnalyticsGeneralStore.setLoading(value);

  getGeneral = async () => {
    this.updateGeneralLoading(true);

    try {
      const {
        employees,
        amount,
        amountWithRefunds,
        bookings,
        changes,
        comparisonCards,
        refunds,
        chart: {
          values,
          labels,
          dataTypeName,
          isCommonChart,
        },
        customAnalytics,
      } = await this.api.getGeneral(this.getGeneralParamsForBackend());

      const leftObject = {
        employees,
        amount,
        amountWithRefunds,
      };

      const rightObject = {
        bookings,
        changes,
        refunds,
      };

      const isPrice = (name: string) => name === GENERAL_COMMON_SUMMARY.AMOUNT
        || name === GENERAL_COMMON_SUMMARY.AMOUNT_WITH_REFUNDS;

      const text = (obj: any, name: string) => {
        const label = GENERAL_COMMON_SUMMARY_RU_OR_ENG[name];
        const isDecline = name === GENERAL_COMMON_SUMMARY.EMPLOYEES ||
          name === GENERAL_COMMON_SUMMARY.BOOKINGS ||
          name === GENERAL_COMMON_SUMMARY.CHANGES ||
          name === GENERAL_COMMON_SUMMARY.REFUNDS;

        return isDecline ?
          toDecline(obj[name], label)
          : label;
      };

      const creatorList = (obj: any) => Object.keys(obj).map(name => ({
        id: name,
        string: text(obj, name),
        number: obj[name],
        isPrice: isPrice(name),
      }));

      const leftList = creatorList(leftObject);
      const rightList = creatorList(rightObject);

      this.chartsAnalyticsStore.setAnalyticsList(customAnalytics);
      this.chartsAnalyticsGeneralStore.setLists(leftList, rightList);
      this.chartsAnalyticsGeneralStore.setChart({ values, labels, legends: dataTypeName });
      this.chartsAnalyticsGeneralStore.setCommonChart(isCommonChart);
      this.chartsAnalyticsGeneralStore.setComparisonCards(comparisonCards);

      this.updateGeneralLoading(false);
    } catch {
      this.updateGeneralLoading(false);
    }
  };

  firstPageUpdate = (res: ObjectForUpdate, isAirCompany = false, isCustom = false) => {
    const {
      chart,
      donutChart,
      table: {
        columnNames,
        strings,
      },
      departments,
      employees,
      projects,
      tags,
      customAnalytics,
    } = res;

    this.updateFilters(employees || [], departments, projects, customAnalytics, tags);
    this.chartsAnalyticsSummaryStore.setChart(chart, donutChart, isAirCompany, isCustom);
    this.chartsAnalyticsSummaryStore.setTableInfo(columnNames, strings);
    this.updateSummaryLoading(false);
  };

  secondPageUpdate = (res: SecondObjectForUpdate) => {
    const {
      table: {
        columnNames,
        strings,
      },
      departments,
      employees,
      projects,
      tags,
      customAnalytics,
      countTrips,
    } = res;

    this.updateFilters(employees || [], departments, projects, customAnalytics, tags);
    this.chartsAnalyticsSummaryStore.setCountTrips(countTrips);
    this.chartsAnalyticsSummaryStore.setTrips(strings, columnNames);
    this.updateSummaryLoading(false);
  };

  secondPageCatch = () => {
    this.updateSummaryLoading(false);
    this.setEmployeesList([]);
    this.setProjectsList([]);
    this.setDepartmentsList([]);
    this.chartsAnalyticsSummaryStore.setCountTrips(0);
    this.chartsAnalyticsSummaryStore.setTrips([], []);
  };

  updateSmartPageCatch = () => {
    this.updateSmartSummaryLoading(false);
    this.chartsAnalyticsSmartSummaryStore.setSummaryIndex(null);
    this.chartsAnalyticsSmartSummaryStore.setSummaryIndexes([]);
  };

  updateFilters = (
    employees: number[],
    departments: number[],
    projects: number[],
    customAnalytics: number[],
    tagsFromBack: number[],
  ) => {
    const {
      filtersType,
      filters: {
        employees: emp,
        departments: dep,
        projects: proj,
        analytics,
        tags,
      },
    } = this.chartsAnalyticsStore;

    const defaultUpdate = () => {
      this.chartsAnalyticsStore.setFirstEmployeesList(employees);
      this.chartsAnalyticsStore.setFirstDepartmentsList(departments);
      this.chartsAnalyticsStore.setFirstProjectsList(projects);
      this.chartsAnalyticsStore.setAnalyticsList(customAnalytics);
      this.chartsAnalyticsStore.setFirstTagsList(tagsFromBack);
    };

    const updateEmployees = () => {
      if (emp.selected.length) {
        this.chartsAnalyticsStore.setFirstDepartmentsList(departments);
        this.chartsAnalyticsStore.setFirstProjectsList(projects);
        this.chartsAnalyticsStore.setAnalyticsList(customAnalytics);
        this.chartsAnalyticsStore.setFirstTagsList(tagsFromBack);
      } else {
        defaultUpdate();
      }
    };

    const updateDepartments = () => {
      if (dep.selected.length) {
        this.chartsAnalyticsStore.setFirstEmployeesList(employees);
        this.chartsAnalyticsStore.setFirstProjectsList(projects);
        this.chartsAnalyticsStore.setAnalyticsList(customAnalytics);
        this.chartsAnalyticsStore.setFirstTagsList(tagsFromBack);
      } else {
        defaultUpdate();
      }
    };

    const updateProjects = () => {
      if (proj.selected.length) {
        this.chartsAnalyticsStore.setFirstEmployeesList(employees);
        this.chartsAnalyticsStore.setFirstDepartmentsList(departments);
        this.chartsAnalyticsStore.setAnalyticsList(customAnalytics);
        this.chartsAnalyticsStore.setFirstTagsList(tagsFromBack);
      } else {
        defaultUpdate();
      }
    };

    const updateAnalytics = () => {
      if (analytics.selected.length) {
        this.chartsAnalyticsStore.setFirstEmployeesList(employees);
        this.chartsAnalyticsStore.setFirstDepartmentsList(departments);
        this.chartsAnalyticsStore.setFirstProjectsList(projects);
        this.chartsAnalyticsStore.setFirstTagsList(tagsFromBack);
      } else {
        defaultUpdate();
      }
    };

    const updateTags = () => {
      if (tags.selected.length) {
        this.chartsAnalyticsStore.setFirstEmployeesList(employees);
        this.chartsAnalyticsStore.setFirstDepartmentsList(departments);
        this.chartsAnalyticsStore.setFirstProjectsList(projects);
        this.chartsAnalyticsStore.setAnalyticsList(customAnalytics);
      } else {
        defaultUpdate();
      }
    };

    switch (filtersType) {
      case FILTERS_FIELDS.EMPLOYEES: {
        updateEmployees();

        break;
      }
      case FILTERS_FIELDS.DEPARTMENTS: {
        updateDepartments();

        break;
      }
      case FILTERS_FIELDS.PROJECTS: {
        updateProjects();

        break;
      }
      case FILTERS_FIELDS.ANALYTICS: {
        updateAnalytics();

        break;
      }
      case FILTERS_FIELDS.TAGS: {
        updateTags();

        break;
      }
      default: {
        defaultUpdate();
      }
    }

    this.updateEmployeesList();
    this.updateDepartmentList();
    this.updateProjectsList();
    this.updateTagsList();
  };

  getSmartSummary = async () => {
    this.updateSmartSummaryLoading(true);
    this.getGeneralParamsForBackend();

    try {
      const {
        chart: {
          dates,
          values,
          labels,
          timeliness,
        },
        summaryIndex,
        indexes,
      } = await this.api.getSmartSummary(this.getGeneralParamsForBackend());

      this.chartsAnalyticsSmartSummaryStore.setChart({ dates: dates[0], values, labels, timeliness });
      this.chartsAnalyticsSmartSummaryStore.setSummaryIndex(summaryIndex);
      this.chartsAnalyticsSmartSummaryStore.setSummaryIndexes(indexes);
      this.updateSmartSummaryLoading(false);
    } catch {
      this.updateSmartPageCatch();
    }
  };

  updateSmartIndexTable = async () => {
    const { type, subSmartType } = this.chartsAnalyticsSmartSummaryStore;

    this.chartsAnalyticsSmartSummaryStore.setLoading(true);

    this.getGeneralParamsForBackend();

    const generalParams = this.getGeneralParamsForBackend();
    const { filters } = this.getCommonParamsForBackend();

    const params = {
      ...generalParams,
      filters,
    };

    try {
      const {
        table: {
          columnNames,
          strings,
        },
        customAnalytics,
        departments,
        employees,
        projects,
        tags,
      } = await this.api.getSmartServiceType(params, type, subSmartType);

      this.updateFilters(employees || [], departments, projects, customAnalytics, tags);

      this.chartsAnalyticsSmartSummaryStore.setTableInfo({ labelsHeader: columnNames, strings });
      this.chartsAnalyticsSmartSummaryStore.setLoading(false);
    } catch {
      this.chartsAnalyticsSmartSummaryStore.setLoading(false);
    }
  };

  getSmartServiceType = async () => {
    this.updateSmartSummaryLoading(true);
    this.getGeneralParamsForBackend();

    const generalParams = this.getGeneralParamsForBackend();
    const { filters } = this.getCommonParamsForBackend();

    const params = {
      ...generalParams,
      filters,
    };
    const { type, subSmartType } = this.chartsAnalyticsSmartSummaryStore;

    try {
      const {
        chart: {
          dates,
          values,
          labels,
          timeliness,
        },
        summaryIndex,
        table: {
          columnNames,
          strings,
        },
        customAnalytics,
        departments,
        employees,
        projects,
        tags,
      } = await this.api.getSmartServiceType(params, type, subSmartType);

      this.updateFilters(employees || [], departments, projects, customAnalytics, tags);

      this.chartsAnalyticsSmartSummaryStore.setChart({ dates, values, labels, timeliness });
      this.chartsAnalyticsSmartSummaryStore.setSummaryIndex(summaryIndex);
      this.chartsAnalyticsSmartSummaryStore.setTableInfo({ labelsHeader: columnNames, strings });

      this.updateSmartSummaryLoading(false);
    } catch {
      this.updateSmartPageCatch();
    }
  };

  changeSmartType = (type: string) => this.chartsAnalyticsSmartSummaryStore.setType(type);

  getSmartAirlinePage = async () => {
    this.updateSmartSummaryLoading(true);
    this.getGeneralParamsForBackend();

    try {
      const {
        chart: {
          dates,
          values,
          labels,
        },
        summaryIndex,
        indexes,
      } = await this.api.getSmartAirline(this.getGeneralParamsForBackend());

      this.chartsAnalyticsSmartSummaryStore.setSummaryIndex(summaryIndex);
      this.chartsAnalyticsSmartSummaryStore.setSummaryIndexes(indexes);
      this.chartsAnalyticsSmartSummaryStore.setChart({ dates, values, labels });
      this.updateSmartSummaryLoading(false);
    } catch {
      this.updateSmartPageCatch();
    }
  };

  getSmartAirPageWithTrips = async () => {
    const {
      paginate: { currentPage, itemsPerPage },
      type,
      subSmartType,
      subPageInfo,
    } = this.chartsAnalyticsSmartSummaryStore;
    this.updateSmartSummaryLoading(true);

    const params = this.getCommonParamsForBackend();
    const preparedParams = { ...params, filters: { ...params.filters } };

    const updateParams = {
      ...preparedParams,
      subPageInfo,
      page: currentPage,
      step: itemsPerPage,
    };

    try {
      const {
        countTrips,
        table: {
          columnNames,
          strings,
        },
      } = await this.api.getSmartPageWithTrips(updateParams, type, subSmartType);

      this.chartsAnalyticsSmartSummaryStore.setCountTrips(countTrips);
      this.chartsAnalyticsSmartSummaryStore.setTrips(strings, columnNames);
      this.updateSmartSummaryLoading(false);
    } catch {
      this.chartsAnalyticsSmartSummaryStore.setCountTrips(0);
      this.chartsAnalyticsSmartSummaryStore.setTrips([], []);
      this.updateSmartSummaryLoading(false);
    }
  };

  changePageSmartAirPage = async (page: number) => {
    this.chartsAnalyticsSmartSummaryStore.setPageTrips(page);
    await this.getSmartAirPageWithTrips();
  };

  changeSmartSubPageInfo = (type: string) => this.chartsAnalyticsSmartSummaryStore.setSubPageInfo(type);

  getSmartTrainPageWithTrips = async () => {
    const {
      paginate: { currentPage, itemsPerPage },
      type,
      subSmartType,
      subPageInfo,
    } = this.chartsAnalyticsSmartSummaryStore;

    this.updateSmartSummaryLoading(true);

    const params = this.getCommonParamsForBackend();
    const preparedParams = { ...params, filters: { ...params.filters } };

    const updateParams = {
      ...preparedParams,
      subPageInfo,
      page: currentPage,
      step: itemsPerPage,
    };

    try {
      const {
        countTrips,
        table: {
          columnNames,
          strings,
        },
      } = await this.api.getSmartPageWithTrips(updateParams, type, subSmartType);

      this.chartsAnalyticsSmartSummaryStore.setCountTrips(countTrips);
      this.chartsAnalyticsSmartSummaryStore.setTrips(strings, columnNames);
      this.updateSmartSummaryLoading(false);
    } catch {
      this.chartsAnalyticsSmartSummaryStore.setCountTrips(0);
      this.chartsAnalyticsSmartSummaryStore.setTrips([], []);
      this.updateSmartSummaryLoading(false);
    }
  };

  changePageSmartTrainPage = async (page: number) => {
    this.chartsAnalyticsSmartSummaryStore.setPageTrips(page);
    await this.getSmartTrainPageWithTrips();
  };

  getSmartHotelPageWithTrips = async () => {
    const {
      paginate: { currentPage, itemsPerPage },
      type,
      subSmartType,
      subPageInfo,
    } = this.chartsAnalyticsSmartSummaryStore;

    this.updateSmartSummaryLoading(true);

    const params = this.getCommonParamsForBackend();
    const preparedParams = { ...params, filters: { ...params.filters } };

    const updateParams = {
      ...preparedParams,
      subPageInfo,
      page: currentPage,
      step: itemsPerPage,
    };

    try {
      const {
        countTrips,
        table: {
          columnNames,
          strings,
        },
      } = await this.api.getSmartPageWithTrips(updateParams, type, subSmartType);

      this.chartsAnalyticsSmartSummaryStore.setCountTrips(countTrips);
      this.chartsAnalyticsSmartSummaryStore.setTrips(strings, columnNames);
      this.updateSmartSummaryLoading(false);
    } catch {
      this.chartsAnalyticsSmartSummaryStore.setCountTrips(0);
      this.chartsAnalyticsSmartSummaryStore.setTrips([], []);
      this.updateSmartSummaryLoading(false);
    }
  };

  changePageSmartHotelPage = async (page: number) => {
    this.chartsAnalyticsSmartSummaryStore.setPageTrips(page);
    await this.getSmartHotelPageWithTrips();
  };

  getSmartAeroexpressPageWithTrips = async () => {
    const {
      paginate: { currentPage, itemsPerPage },
      type,
      subSmartType,
      subPageInfo,
    } = this.chartsAnalyticsSmartSummaryStore;

    this.updateSmartSummaryLoading(true);

    const params = this.getCommonParamsForBackend();
    const preparedParams = { ...params, filters: { ...params.filters } };

    const updateParams = {
      ...preparedParams,
      subPageInfo,
      page: currentPage,
      step: itemsPerPage,
    };

    try {
      const {
        countTrips,
        table: {
          columnNames,
          strings,
        },
      } = await this.api.getSmartPageWithTrips(updateParams, type, subSmartType);

      this.chartsAnalyticsSmartSummaryStore.setCountTrips(countTrips);
      this.chartsAnalyticsSmartSummaryStore.setTrips(strings, columnNames);
      this.updateSmartSummaryLoading(false);
    } catch {
      this.chartsAnalyticsSmartSummaryStore.setCountTrips(0);
      this.chartsAnalyticsSmartSummaryStore.setTrips([], []);
      this.updateSmartSummaryLoading(false);
    }
  };

  getSmartTransferPageWithTrips = async () => {
    const {
      paginate: { currentPage, itemsPerPage },
      type,
      subSmartType,
      subPageInfo,
    } = this.chartsAnalyticsSmartSummaryStore;

    this.updateSmartSummaryLoading(true);

    const params = this.getCommonParamsForBackend();
    const preparedParams = { ...params, filters: { ...params.filters } };

    const updateParams = {
      ...preparedParams,
      subPageInfo,
      page: currentPage,
      step: itemsPerPage,
    };

    try {
      const {
        countTrips,
        table: {
          columnNames,
          strings,
        },
      } = await this.api.getSmartPageWithTrips(updateParams, type, subSmartType);

      this.chartsAnalyticsSmartSummaryStore.setCountTrips(countTrips);
      this.chartsAnalyticsSmartSummaryStore.setTrips(strings, columnNames);
      this.updateSmartSummaryLoading(false);
    } catch {
      this.chartsAnalyticsSmartSummaryStore.setCountTrips(0);
      this.chartsAnalyticsSmartSummaryStore.setTrips([], []);
      this.updateSmartSummaryLoading(false);
    }
  };

  changePageSmartAeroexpressPage = async (page: number) => {
    this.chartsAnalyticsSmartSummaryStore.setPageTrips(page);
    await this.getSmartAeroexpressPageWithTrips();
  };

  changePageSmartTransferPage = async (page: number) => {
    this.chartsAnalyticsSmartSummaryStore.setPageTrips(page);
    await this.getSmartTransferPageWithTrips();
  };

  getSmartTrainPage = async () => {
    this.updateSmartSummaryLoading(true);
    this.getGeneralParamsForBackend();

    try {
      const {
        chart: {
          dates,
          values,
          labels,
        },
        summaryIndex,
        indexes,
      } = await this.api.getSmartTrain(this.getGeneralParamsForBackend());

      this.chartsAnalyticsSmartSummaryStore.setSummaryIndex(summaryIndex);
      this.chartsAnalyticsSmartSummaryStore.setSummaryIndexes(indexes);
      this.chartsAnalyticsSmartSummaryStore.setChart({ dates: dates[0], values, labels });
      this.updateSmartSummaryLoading(false);
    } catch {
      this.updateSmartPageCatch();
    }
  };

  getSmartMicePage = async () => {
    this.updateSmartSummaryLoading(true);
    this.getGeneralParamsForBackend();

    try {
      const {
        chart: {
          dates,
          values,
          labels,
        },
        summaryIndex,
        indexes,
      } = await this.api.getSmartMice(this.getGeneralParamsForBackend() as any);

      this.chartsAnalyticsSmartSummaryStore.setSummaryIndex(summaryIndex);
      this.chartsAnalyticsSmartSummaryStore.setSummaryIndexes(indexes);
      this.chartsAnalyticsSmartSummaryStore.setChart({ dates: dates[0], values, labels });
      this.updateSmartSummaryLoading(false);
    } catch {
      this.updateSmartPageCatch();
    }
  };

  getSmartAeroexpressPage = async () => {
    this.updateSmartSummaryLoading(true);
    this.getGeneralParamsForBackend();

    try {
      const {
        chart: {
          dates,
          values,
          labels,
        },
        summaryIndex,
        indexes,
      } = await this.api.getSmartAeroexpress(this.getGeneralParamsForBackend());

      this.chartsAnalyticsSmartSummaryStore.setSummaryIndex(summaryIndex);
      this.chartsAnalyticsSmartSummaryStore.setSummaryIndexes(indexes);
      this.chartsAnalyticsSmartSummaryStore.setChart({ dates: dates[0], values, labels });
      this.updateSmartSummaryLoading(false);
    } catch {
      this.updateSmartPageCatch();
    }
  };

  getSmartTransferPage = async () => {
    this.updateSmartSummaryLoading(true);
    this.getGeneralParamsForBackend();

    try {
      const {
        chart: {
          dates,
          values,
          labels,
        },
        summaryIndex,
        indexes,
      } = await this.api.getSmartTransfer(this.getGeneralParamsForBackend());

      this.chartsAnalyticsSmartSummaryStore.setSummaryIndex(summaryIndex);
      this.chartsAnalyticsSmartSummaryStore.setSummaryIndexes(indexes);
      this.chartsAnalyticsSmartSummaryStore.setChart({ dates: dates[0], values, labels });
      this.updateSmartSummaryLoading(false);
    } catch {
      this.updateSmartPageCatch();
    }
  };

  getSmartHotelPage = async () => {
    this.updateSmartSummaryLoading(true);
    this.getGeneralParamsForBackend();

    try {
      const {
        chart: {
          dates,
          values,
          labels,
        },
        summaryIndex,
        indexes,
      } = await this.api.getSmartHotel(this.getGeneralParamsForBackend());

      this.chartsAnalyticsSmartSummaryStore.setSummaryIndex(summaryIndex);
      this.chartsAnalyticsSmartSummaryStore.setSummaryIndexes(indexes);
      this.chartsAnalyticsSmartSummaryStore.setChart({ dates: dates[0], values, labels });
      this.updateSmartSummaryLoading(false);
    } catch {
      this.updateSmartPageCatch();
    }
  };

  getSmartTaxiPage = async () => {
    this.updateSmartSummaryLoading(true);
    this.getGeneralParamsForBackend();

    try {
      const {
        chart: {
          dates,
          values,
          labels,
        },
        summaryIndex,
        indexes,
      } = await this.api.getSmartTaxi(this.getGeneralParamsForBackend());

      this.chartsAnalyticsSmartSummaryStore.setSummaryIndex(summaryIndex);
      this.chartsAnalyticsSmartSummaryStore.setSummaryIndexes(indexes);
      this.chartsAnalyticsSmartSummaryStore.setChart({ dates: dates[0], values, labels });
      this.updateSmartSummaryLoading(false);
    } catch {
      this.updateSmartPageCatch();
    }
  };

  changeSmartLabel = (label: string) => this.chartsAnalyticsSmartSummaryStore.setLabel(label);

  getSmartTaxiPageWithTrips = async () => {
    const {
      paginate: { currentPage, itemsPerPage },
      type,
      subSmartType,
      subPageInfo,
    } = this.chartsAnalyticsSmartSummaryStore;

    this.updateSmartSummaryLoading(true);

    const params = this.getCommonParamsForBackend();
    const preparedParams = { ...params, filters: { ...params.filters } };

    const updateParams = {
      ...preparedParams,
      subPageInfo,
      page: currentPage,
      step: itemsPerPage,
    };

    try {
      const {
        countTrips,
        table: {
          columnNames,
          strings,
        },
      } = await this.api.getSmartPageWithTrips(updateParams, type, subSmartType);

      this.chartsAnalyticsSmartSummaryStore.setCountTrips(countTrips);
      this.chartsAnalyticsSmartSummaryStore.setTrips(strings, columnNames);
      this.updateSmartSummaryLoading(false);
    } catch {
      this.chartsAnalyticsSmartSummaryStore.setCountTrips(0);
      this.chartsAnalyticsSmartSummaryStore.setTrips([], []);
      this.updateSmartSummaryLoading(false);
    }
  };

  changePageSmartTaxiPage = async (page: number) => {
    this.chartsAnalyticsSmartSummaryStore.setPageTrips(page);
    await this.getSmartTaxiPageWithTrips();
  };

  getTaxiSummary = async () => {
    const params = this.makeBeforeRequest();

    try {
      const res = await this.api.getTaxiSummary(params);

      this.firstPageUpdate(res);
    } catch {
      this.catchUpdate();
    }
  };

  changeTaxiVoucher = (type: string) => this.chartsAnalyticsSummaryStore.setVoucherId(type);

  changeTaxiSmartVoucher = (type: string) => this.chartsAnalyticsSmartSummaryStore.setVoucherId(type);

  getTaxiSummarySubPage = async () => {
    const { paginate: { currentPage, itemsPerPage }, subPageInfo, type } = this.chartsAnalyticsSummaryStore;

    this.updateSummaryLoading(true);

    const params = this.getCommonParamsForBackend();
    const preparedParams = { ...params, filters: { ...params.filters } };

    const updateParams = {
      ...preparedParams,
      subPageInfo,
      page: currentPage,
      step: itemsPerPage,
    };

    try {
      const {
        countTrips,
        employees,
        departments,
        projects,
        customAnalytics,
        table: {
          columnNames,
          strings,
        },
        tags,
      } = await this.api.getTaxiSummarySubPage(updateParams, type);

      this.updateFilters(employees || [], departments, projects, customAnalytics, tags);
      this.chartsAnalyticsSummaryStore.setCountTrips(countTrips);
      this.chartsAnalyticsSummaryStore.setTrips(strings, columnNames);
      this.updateSummaryLoading(false);
    } catch {
      this.chartsAnalyticsSummaryStore.setCountTrips(0);
      this.chartsAnalyticsSummaryStore.setTrips([], []);
      this.updateSummaryLoading(false);
    }
  };

  getTaxiSummarySubDoublePage = async () => {
    const { paginate: { currentPage, itemsPerPage }, voucherId } = this.chartsAnalyticsSummaryStore;

    this.updateSummaryLoading(true);

    const params = this.getCommonParamsForBackend();
    const preparedParams = { ...params, filters: { ...params.filters } };

    const updateParams = {
      ...preparedParams,
      voucherId,
      page: currentPage,
      step: itemsPerPage,
    };

    try {
      const {
        countTrips,
        table: {
          columnNames,
          strings,
        },
        employees,
        departments,
        projects,
        customAnalytics,
        tags,
      } = await this.api.getTaxiSubPageVoucher(updateParams);

      this.updateFilters(employees || [], departments, projects, customAnalytics, tags);
      this.chartsAnalyticsSummaryStore.setCountTrips(countTrips);
      this.chartsAnalyticsSummaryStore.setTrips(strings, columnNames);
      this.updateSummaryLoading(false);
    } catch {
      this.chartsAnalyticsSummaryStore.setCountTrips(0);
      this.chartsAnalyticsSummaryStore.setTrips([], []);
      this.updateSummaryLoading(false);
    }
  };

  changeVoucher = (id: number) => this.chartsAnalyticsSummaryStore.setVoucherId(id);

  getTaxiDirectionsSubDoublePage = async () => {
    const { paginate: { currentPage, itemsPerPage }, voucherId } = this.chartsAnalyticsSummaryStore;

    this.updateSummaryLoading(true);

    const params = this.getCommonParamsForBackend();
    const preparedParams = { ...params, filters: { ...params.filters } };

    const updateParams = {
      ...preparedParams,
      voucherId,
      page: currentPage,
      step: itemsPerPage,
    };

    try {
      const {
        countTrips,
        table: {
          columnNames,
          strings,
        },
        employees,
        departments,
        projects,
        customAnalytics,
        tags,
      } = await this.api.getTaxiSubPageVoucher(updateParams);

      this.updateFilters(employees || [], departments, projects, customAnalytics, tags);
      this.chartsAnalyticsSummaryStore.setCountTrips(countTrips);
      this.chartsAnalyticsSummaryStore.setTrips(strings, columnNames);
      this.updateSummaryLoading(false);
    } catch {
      this.chartsAnalyticsSummaryStore.setCountTrips(0);
      this.chartsAnalyticsSummaryStore.setTrips([], []);
      this.updateSummaryLoading(false);
    }
  };

  getTaxiSmartVoucher = async () => {
    const {
      paginate: { currentPage, itemsPerPage },
      subSmartType,
      subPageInfo,
    } = this.chartsAnalyticsSmartSummaryStore;

    this.updateSmartSummaryLoading(true);

    const params = this.getCommonParamsForBackend();
    const preparedParams = { ...params, filters: { ...params.filters } };

    const updateParams = {
      ...preparedParams,
      subPageInfo,
      page: currentPage,
      step: itemsPerPage,
    };

    try {
      const {
        countTrips,
        trips,
        employees,
        departments,
        projects,
        customAnalytics,
        columnNames,
        tags,
      } = await this.api.getSmartPageWithTrips(updateParams, STRUCTURE_TYPE_LOWER.TAXI_OPERATION, subSmartType);

      this.updateFilters(employees || [], departments, projects, customAnalytics, tags);
      this.chartsAnalyticsSmartSummaryStore.setCountTrips(countTrips);
      this.chartsAnalyticsSmartSummaryStore.setTrips(trips, columnNames);
      this.updateSmartSummaryLoading(false);
    } catch {
      this.chartsAnalyticsSmartSummaryStore.setCountTrips(0);
      this.chartsAnalyticsSmartSummaryStore.setTrips([], []);
      this.updateSmartSummaryLoading(false);
    }
  };

  changePageTaxiSummary = async (page: number) => {
    this.chartsAnalyticsSummaryStore.setPageTrips(page);
    await this.getTaxiSummarySubPage();
  };

  getTaxiDirections = async () => {
    const params = this.makeBeforeRequest();

    try {
      const res = await this.api.getTaxiDirections(params);

      this.firstPageUpdate(res);
    } catch {
      this.catchUpdate();
    }
  };

  getTaxiDirectionsSubPage = async () => {
    const { type } = this.chartsAnalyticsSummaryStore;
    const params = this.makeBeforeSubPagesRequest();

    try {
      const res = await this.api.getTaxiDirectionsSubPage(params, type);

      this.secondPageUpdate(res);
    } catch {
      this.secondPageCatch();
    }
  };

  changePageTaxiDirections = async (page: number) => {
    this.chartsAnalyticsSummaryStore.setPageTrips(page);
    await this.getTaxiDirectionsSubPage();
  };

  catchUpdate = () => {
    this.chartsAnalyticsSummaryStore.setTableInfo([], []);
    this.setEmployeesList([]);
    this.setProjectsList([]);
    this.setDepartmentsList([]);
    this.updateSummaryLoading(false);
  };

  getAirlineSummary = async () => {
    const params = this.makeBeforeRequest();

    try {
      const res = await this.api.getAirlineSummary(params);

      this.firstPageUpdate(res);
    } catch {
      this.catchUpdate();
    }
  };

  getSummarySubPage = async () => {
    const params = this.makeBeforeSubPagesRequest();

    try {
      const res = await this.api.getAirlineSummarySubPage(params);

      this.secondPageUpdate(res);
    } catch {
      this.secondPageCatch();
    }
  };

  changePageAirlineSummary = async (page: number) => {
    this.chartsAnalyticsSummaryStore.setPageTrips(page);
    await this.getSummarySubPage();
  };

  changeSummaryType = (type: string) => this.chartsAnalyticsSummaryStore.setType(type);

  changeSubPageInfo = (type: string) => this.chartsAnalyticsSummaryStore.setSubPageInfo(type);

  updateSummaryLoading = (value: boolean) => this.chartsAnalyticsSummaryStore.setLoading(value);

  updateSmartSummaryLoading = (value: boolean) => this.chartsAnalyticsSmartSummaryStore.setLoading(value);

  changePageAirlineDirections = async (page: number) => {
    this.chartsAnalyticsSummaryStore.setPageTrips(page);
    await this.getDirectionsSubPage();
  };

  getAirlineDirections = async () => {
    const params = this.makeBeforeRequest();

    try {
      const res = await this.api.getAirlineDirections(params);

      this.firstPageUpdate(res);
    } catch {
      this.catchUpdate();
    }
  };

  getDirectionsSubPage = async () => {
    const params = this.makeBeforeSubPagesRequest();

    try {
      const res = await this.api.getAirlineDirectionsSubPage(params);

      this.secondPageUpdate(res);
    } catch {
      this.secondPageCatch();
    }
  };

  getAirlineCompanies = async () => {
    const params = this.makeBeforeRequest();

    try {
      const res = await this.api.getAirlineCompanies(params);

      this.firstPageUpdate(res, true);
    } catch {
      this.catchUpdate();
    }
  };

  getCompaniesSubPage = async () => {
    const params = this.makeBeforeSubPagesRequest();

    try {
      const res = await this.api.getAirlineCompaniesSubPage(params);

      this.secondPageUpdate(res);
    } catch {
      this.secondPageCatch();
    }
  };

  changePageAirlineCompanies = async (page: number) => {
    this.chartsAnalyticsSummaryStore.setPageTrips(page);
    await this.getCompaniesSubPage();
  };

  getAirlineEarliness = async () => {
    const params = this.makeBeforeRequest();

    try {
      const res = await this.api.getAirlineEarliness(params);

      this.firstPageUpdate(res, true);
    } catch {
      this.catchUpdate();
    }
  };

  getEarlinessSubPage = async () => {
    const params = this.makeBeforeSubPagesRequest();

    try {
      const res = await this.api.getAirlineEarlinessSubPage(params);

      this.secondPageUpdate(res);
    } catch {
      this.secondPageCatch();
    }
  };

  changePageAirlineEarliness = async (page: number) => {
    this.chartsAnalyticsSummaryStore.setPageTrips(page);
    await this.getEarlinessSubPage();
  };

  updateLoading = (value: boolean) => this.chartsAnalyticsStore.setLoading(value);

  preparedDates = (date: any) => {
    this.chartsAnalyticsStore.setPreparedDates(date);
    this.updateLoading(false);
  };

  preparedCompanies = (companies: any[]) => this.chartsAnalyticsStore.setPreparedCompanies(companies);

  setCustomAnalytics = (values: number[]) => this.chartsAnalyticsStore.setCustomAnalytics(values);

  setMenu = (values: string[]) => this.chartsAnalyticsStore.setMenu(values);

  setShowDepartments = (value: boolean) => this.chartsAnalyticsStore.setShowDepartmentsToMenu(value);

  getShowDepartmentsMenu = () => this.chartsAnalyticsStore.getShowDepartmentsMenu();

  changeStartDate = (value: Moment) => {
    MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.ANALYTICS.CHANGED_DATE);

    return this.chartsAnalyticsStore.setStartDate(value);
  };

  changeEndDate = (value: Moment) => {
    MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.ANALYTICS.CHANGED_DATE);

    return this.chartsAnalyticsStore.setEndDate(value);
  };

  changeDates = (startDate: Moment, endDate: Moment) => {
    this.changeStartDate(startDate);
    this.changeEndDate(endDate);
  };

  setCachedDates = (toDefault = false) => {
    const {
      commonChartsAnalytics: {
        startDate,
        endDate,
      },
    } = this.chartsAnalyticsStore;

    if (!toDefault) {
      return this.chartsAnalyticsStore.setCachedDates({ startDate, endDate });
    }

    return this.chartsAnalyticsStore.setCachedDates(null);
  };

  updateDatesByChart = (barValue: any, dates: any) => {
    const { index } = barValue;

    this.setCachedDates();

    const startDate = momentObject(dates[index].startDate);
    const endDate = momentObject(dates[index].endDate);

    this.changeDates(startDate, endDate);
  };

  changeSelectedCompanies = (values: string[] | any) => this.chartsAnalyticsStore.setSelectedCompanies(values);

  getTrainSummary = async () => {
    const params = this.makeBeforeRequest();

    try {
      const res = await this.api.getTrainSummary(params);

      this.firstPageUpdate(res);
    } catch {
      this.catchUpdate();
    }
  };

  getTrainSummarySubPage = async () => {
    const params = this.makeBeforeSubPagesRequest();

    try {
      const res = await this.api.getTrainSummarySubPage(params);

      this.secondPageUpdate(res);
    } catch {
      this.secondPageCatch();
    }
  };

  changePageAeroexpressSummary = async (page: number) => {
    this.chartsAnalyticsSummaryStore.setPageTrips(page);
    await this.getAeroexpressSummarySubPage();
  };

  getAeroexpressSummary = async () => {
    const params = this.makeBeforeRequest();

    try {
      const res = await this.api.getAeroexpressSummary(params);

      this.firstPageUpdate(res);
    } catch {
      this.catchUpdate();
    }
  };

  getAeroexpressSummarySubPage = async () => {
    const params = this.makeBeforeSubPagesRequest();

    try {
      const res = await this.api.getAeroexpressSummarySubPage(params);

      this.secondPageUpdate(res);
    } catch {
      this.secondPageCatch();
    }
  };

  getAeroexpressDirections = async () => {
    const params = this.makeBeforeRequest();

    try {
      const res = await this.api.getAeroexpressDirections(params);

      this.firstPageUpdate(res);
    } catch {
      this.catchUpdate();
    }
  };

  getAeroexpressDirectionsSubPage = async () => {
    const params = this.makeBeforeSubPagesRequest();

    try {
      const res = await this.api.getAeroexpressDirectionsSubPage(params);

      this.secondPageUpdate(res);
    } catch {
      this.secondPageCatch();
    }
  };

  changePageAeroexpressDirections = async (page: number) => {
    this.chartsAnalyticsSummaryStore.setPageTrips(page);
    await this.getAeroexpressDirectionsSubPage();
  };

  getTransferSummary = async () => {
    const params = this.makeBeforeRequest();

    try {
      const res = await this.api.getTransferSummary(params);

      this.firstPageUpdate(res);
    } catch {
      this.catchUpdate();
    }
  };

  getMiceSummary = async () => {
    const params = this.makeBeforeRequest();

    try {
      const res = await this.api.getMiceSummary(params as any);

      this.firstPageUpdate(res, false, false);
    } catch {
      this.catchUpdate();
    }
  };

  getTransferSummarySubPage = async () => {
    const params = this.makeBeforeSubPagesRequest();

    try {
      const res = await this.api.getTransferSummarySubPage(params);

      this.secondPageUpdate(res);
    } catch {
      this.secondPageCatch();
    }
  };

  getMiceSummarySubPage = async () => {
    const params = this.makeBeforeSubPagesRequest();

    try {
      const res = await this.api.getMiceSummarySubPage(params as any);

      this.secondPageUpdate(res);
    } catch {
      this.secondPageCatch();
    }
  };

  changePageTransferSummary = async (page: number) => {
    this.chartsAnalyticsSummaryStore.setPageTrips(page);
    await this.getTransferSummarySubPage();
  };

  changePageMiceSummary = async (page: number) => {
    this.chartsAnalyticsSummaryStore.setPageTrips(page);
    await this.getMiceSummarySubPage();
  };

  getTransferDirections = async () => {
    const params = this.makeBeforeRequest();

    try {
      const res = await this.api.getTransferDirections(params);

      this.firstPageUpdate(res);
    } catch {
      this.catchUpdate();
    }
  };

  getTransferDirectionsSubPage = async () => {
    const params = this.makeBeforeSubPagesRequest();

    try {
      const res = await this.api.getTransferDirectionsSubPage(params);

      this.secondPageUpdate(res);
    } catch {
      this.secondPageCatch();
    }
  };

  changePageTransferDirections = async (page: number) => {
    this.chartsAnalyticsSummaryStore.setPageTrips(page);
    await this.getTransferDirectionsSubPage();
  };

  changePageTrainSummary = async (page: number) => {
    this.chartsAnalyticsSummaryStore.setPageTrips(page);
    await this.getTrainSummarySubPage();
  };

  getTrainDirections = async () => {
    const params = this.makeBeforeRequest();

    try {
      const res = await this.api.getTrainDirections(params);

      this.firstPageUpdate(res);
    } catch {
      this.catchUpdate();
    }
  };

  getTrainDirectionsSubPage = async () => {
    const params = this.makeBeforeSubPagesRequest();

    try {
      const res = await this.api.getTrainDirectionsSubPage(params);

      this.secondPageUpdate(res);
    } catch {
      this.secondPageCatch();
    }
  };

  changePageTrainDirections = async (page: number) => {
    this.chartsAnalyticsSummaryStore.setPageTrips(page);
    await this.getTrainDirectionsSubPage();
  };

  getHotelSummary = async () => {
    const params = this.makeBeforeRequest();

    try {
      const res = await this.api.getHotelSummary(params);

      this.firstPageUpdate(res);
    } catch {
      this.catchUpdate();
    }
  };

  getHotelSummarySubPage = async () => {
    const params = this.makeBeforeSubPagesRequest();

    try {
      const res = await this.api.getHotelSummarySubPage(params);

      this.secondPageUpdate(res);
    } catch {
      this.secondPageCatch();
    }
  };

  changePageHotelSummary = async (page: number) => {
    this.chartsAnalyticsSummaryStore.setPageTrips(page);
    await this.getHotelSummarySubPage();
  };

  getHotelDirections = async () => {
    const params = this.makeBeforeRequest();

    try {
      const res = await this.api.getHotelDirections(params);

      this.firstPageUpdate(res);
    } catch {
      this.catchUpdate();
    }
  };

  getHotelDirectionsSubPage = async () => {
    const params = this.makeBeforeSubPagesRequest();

    try {
      const res = await this.api.getHotelDirectionsSubPage(params);

      this.secondPageUpdate(res);
    } catch {
      this.secondPageCatch();
    }
  };

  changePageHotelDirections = async (page: number) => {
    this.chartsAnalyticsSummaryStore.setPageTrips(page);
    await this.getHotelDirectionsSubPage();
  };

  getHotelPopular = async () => {
    const params = this.makeBeforeRequest();

    try {
      const res = await this.api.getHotelPopular(params);

      this.firstPageUpdate(res);
    } catch {
      this.catchUpdate();
    }
  };

  getHotelPopularSubPage = async () => {
    const params = this.makeBeforeSubPagesRequest();

    try {
      const res = await this.api.getHotelPopularSubPage(params);

      this.secondPageUpdate(res);
    } catch {
      this.secondPageCatch();
    }
  };

  changePageHotelPopular = async (page: number) => {
    this.chartsAnalyticsSummaryStore.setPageTrips(page);
    await this.getHotelPopularSubPage();
  };

  getStructureServiceType = async () => {
    const params = this.makeBeforeRequest();

    try {
      const res = await this.api.getStructureServiceType(params);

      this.firstPageUpdate(res);
    } catch {
      this.catchUpdate();
    }
  };

  getStructureServiceTypeSubPage = async () => {
    const { type } = this.chartsAnalyticsSummaryStore;
    const params = this.makeBeforeSubPagesRequest();

    const preparedParams = {
      ...params,
      type,
    };

    try {
      const res = await this.api.getStructureServiceTypeSubPage(preparedParams);

      this.secondPageUpdate(res);
    } catch (err) {
      this.secondPageCatch();
    }
  };

  changePageStructureServiceType = async (page: number) => {
    this.chartsAnalyticsSummaryStore.setPageTrips(page);
    await this.getStructureServiceTypeSubPage();
  };

  getStructureDepartments = async () => {
    const params = this.makeBeforeRequest();

    try {
      const res = await this.api.getStructureDepartments(params);

      this.firstPageUpdate(res, false, true);
    } catch {
      this.catchUpdate();
    }
  };

  setShowFilterDepartment = (value: boolean) => this.chartsAnalyticsStore.setShowFilterDepartment(value);

  setShowFilterProject = (value: boolean) => this.chartsAnalyticsStore.setShowFilterProject(value);

  changeStructureCustomAnalyticValueId = (id: number[] | any) => this.chartsAnalyticsSummaryStore.setAnalyticValueId(id);

  setRouteId = (id: number) => this.chartsAnalyticsStore.setRouteId(id);

  changeStructureDepartmentIds = (ids: number[]) => this.chartsAnalyticsSummaryStore.setDepartmentIds(ids);

  getStructureDepartmentsSubPage = async () => {
    const { depIds } = this.chartsAnalyticsSummaryStore;

    const params = this.makeBeforeSubPagesRequest();
    const preparedParams = { ...params, filters: { ...params.filters, Departments: depIds } };

    try {
      const {
        chart,
        donutChart,
        table: {
          columnNames,
          strings,
        },
        employees,
        projects,
        customAnalytics,
        tags,
      } = await this.api.getStructureServiceType(preparedParams);

      this.updateFilters(employees || [], [], projects, customAnalytics, tags);
      this.chartsAnalyticsStore.setShowFilterDepartment(true);
      this.chartsAnalyticsSummaryStore.setChart(chart, donutChart);
      this.chartsAnalyticsSummaryStore.setTableInfo(columnNames, strings);
      this.updateSummaryLoading(false);
    } catch {
      this.catchUpdate();
    }
  };

  getStructureDepartmentsDoubleSubPage = async () => {
    const { paginate: { currentPage, itemsPerPage }, type, depIds } = this.chartsAnalyticsSummaryStore;

    this.updateSummaryLoading(true);
    const params = this.getCommonParamsForBackend();

    const updateParams = {
      ...params,
      type,
      exclude: false,
      page: currentPage,
      step: itemsPerPage,
      filters: { ...params.filters, Departments: depIds },
    };

    try {
      const res = await this.api.getStructureServiceTypeSubPage(updateParams);

      const {
        countTrips,
        table: {
          columnNames,
          strings,
        },
        employees,
        projects,
        customAnalytics,
        tags,
      } = res;

      this.updateFilters(employees || [], [], projects, customAnalytics, tags);
      this.chartsAnalyticsStore.setShowFilterDepartment(true);
      this.chartsAnalyticsSummaryStore.setCountTrips(countTrips);
      this.chartsAnalyticsSummaryStore.setTrips(strings, columnNames);
      this.updateSummaryLoading(false);
    } catch {
      this.chartsAnalyticsSummaryStore.setCountTrips(0);
      this.chartsAnalyticsSummaryStore.setTrips([], []);
      this.updateSummaryLoading(false);
    }
  };

  changePageStructureDepartments = async (page: number) => {
    this.chartsAnalyticsSummaryStore.setPageTrips(page);
    await this.getStructureDepartmentsDoubleSubPage();
  };

  getStructureProjects = async () => {
    const params = this.makeBeforeRequest();

    try {
      const res = await this.api.getStructureProjects(params);

      this.firstPageUpdate(res, false, true);
    } catch {
      this.catchUpdate();
    }
  };

  changeStructureProjectIds = (ids: number[]) => this.chartsAnalyticsSummaryStore.setProjectIds(ids);

  getStructureProjectsSubPage = async () => {
    const { projectIds } = this.chartsAnalyticsSummaryStore;

    const params = this.makeBeforeSubPagesRequest();

    const preparedParams = { ...params, filters: { ...params.filters, Projects: projectIds } };

    try {
      const {
        chart,
        donutChart,
        table: {
          columnNames,
          strings,
        },
        employees,
        departments,
        customAnalytics,
        tags,
      } = await this.api.getStructureServiceType(preparedParams);

      this.updateFilters(employees || [], departments, [], customAnalytics, tags);
      this.chartsAnalyticsStore.setShowFilterProject(true);
      this.chartsAnalyticsSummaryStore.setChart(chart, donutChart);
      this.chartsAnalyticsSummaryStore.setTableInfo(columnNames, strings);
      this.updateSummaryLoading(false);
    } catch {
      this.catchUpdate();
    }
  };

  getStructureProjectsDoubleSubPage = async () => {
    const { paginate: { currentPage, itemsPerPage }, type, projectIds } = this.chartsAnalyticsSummaryStore;

    this.updateSummaryLoading(true);
    const params = this.getCommonParamsForBackend();

    const updateParams = {
      ...params,
      type,
      exclude: false,
      page: currentPage,
      step: itemsPerPage,
      filters: { ...params.filters, Projects: projectIds },
    };

    try {
      const res = await this.api.getStructureServiceTypeSubPage(updateParams);

      const {
        countTrips,
        table: {
          columnNames,
          strings,
        },
        employees,
        departments,
        customAnalytics,
        tags,
      } = res;

      this.updateFilters(employees || [], departments, [], customAnalytics, tags);
      this.chartsAnalyticsStore.setShowFilterProject(true);
      this.chartsAnalyticsSummaryStore.setCountTrips(countTrips);
      this.chartsAnalyticsSummaryStore.setTrips(strings, columnNames);
      this.updateSummaryLoading(false);
    } catch {
      this.chartsAnalyticsSummaryStore.setCountTrips(0);
      this.chartsAnalyticsSummaryStore.setTrips([], []);
      this.updateSummaryLoading(false);
    }
  };

  getStructureCustomAnalytics = async () => {
    const params = this.makeBeforeRequest();

    try {
      const { id, customAnalytics } = this.chartsAnalyticsStore;
      // @ts-ignore
      const { Name } = customAnalytics.find(({ Id: analyticId }) => analyticId === Number(id));

      const res = await this.api.getStructureCustomAnalytics(params, id);

      this.firstPageUpdate(res, false, true);
      this.chartsAnalyticsSummaryStore.setTitle(Name);
    } catch {
      this.catchUpdate();
    }
  };

  getStructureCustomAnalyticsSubPage = async () => {
    this.updateSummaryLoading(true);

    try {
      const { analyticIds } = this.chartsAnalyticsSummaryStore;
      const params = this.getCommonParamsForBackend();
      const preparedParams = { ...params, filters: { ...params.filters, CustomAnalytics: analyticIds } };

      const {
        chart,
        donutChart,
        table: {
          columnNames,
          strings,
        },
        departments,
        employees,
        projects,
        tags,
      } = await this.api.getStructureServiceType(preparedParams);

      this.updateFilters(employees || [], departments, projects, [], tags);
      this.chartsAnalyticsSummaryStore.setChart(chart, donutChart);
      this.chartsAnalyticsSummaryStore.setTableInfo(columnNames, strings);
      this.updateSummaryLoading(false);
    } catch {
      this.chartsAnalyticsSummaryStore.setTableInfo([], []);
      this.updateSummaryLoading(false);
    }
  };

  getStructureCustomAnalyticsDoubleSubPage = async () => {
    const { paginate: { currentPage, itemsPerPage }, type, analyticIds } = this.chartsAnalyticsSummaryStore;

    this.updateSummaryLoading(true);
    const params = this.getCommonParamsForBackend();

    const updateParams = {
      ...params,
      type,
      exclude: false,
      page: currentPage,
      step: itemsPerPage,
      filters: { ...params.filters, CustomAnalytics: analyticIds },
    };

    try {
      const res = await this.api.getStructureServiceTypeSubPage(updateParams);

      const {
        countTrips,
        table: {
          columnNames,
          strings,
        },
        employees,
        projects,
        departments,
        tags,
      } = res;

      this.updateFilters(employees || [], departments, projects, [], tags);
      this.chartsAnalyticsSummaryStore.setCountTrips(countTrips);
      this.chartsAnalyticsSummaryStore.setTrips(strings, columnNames);
      this.updateSummaryLoading(false);
    } catch {
      this.chartsAnalyticsSummaryStore.setCountTrips(0);
      this.chartsAnalyticsSummaryStore.setTrips([], []);
      this.updateSummaryLoading(false);
    }
  };

  changePageStructureCustomAnalytics = async (page: number) => {
    this.chartsAnalyticsSummaryStore.setPageTrips(page);
    await this.getStructureCustomAnalyticsDoubleSubPage();
  };

  changePageStructureProjects = async (page: number) => {
    this.chartsAnalyticsSummaryStore.setPageTrips(page);
    await this.getStructureProjectsDoubleSubPage();
  };

  resetStore = () => this.chartsAnalyticsStore.setResetStore();

  resetFilters = () => this.chartsAnalyticsStore.setResetFilters();

  resetPaginate = () => {
    this.chartsAnalyticsSummaryStore.setResetPaginate();
    this.chartsAnalyticsSmartSummaryStore.setResetPaginate();
  };

  setFiltersType = (value: string) => this.chartsAnalyticsStore.setFiltersType(value);

  getInfo = (active: string, id?: string) => ({
    [MENU_KEYS.GENERAL]: this.getGeneral,
    [MENU_KEYS.SMART.COMMON.FIRST]: this.getSmartSummary,
    [MENU_KEYS.SMART.COMMON.SECOND.AIRLINE]: this.getSmartAirlinePage,
    [MENU_KEYS.SMART.COMMON.SECOND.TRAIN]: this.getSmartTrainPage,
    [MENU_KEYS.SMART.COMMON.SECOND.AEROEXPRESS]: this.getSmartAeroexpressPage,
    [MENU_KEYS.SMART.COMMON.SECOND.TRANSFER]: this.getSmartTransferPage,
    [MENU_KEYS.SMART.COMMON.SECOND.MICE]: this.getSmartMicePage,
    [MENU_KEYS.SMART.COMMON.SECOND.HOTEL]: this.getSmartHotelPage,
    [MENU_KEYS.SMART.COMMON.SECOND.TAXI]: this.getSmartTaxiPage,
    [MENU_KEYS.SMART.COMMON.THIRD.AIRLINE]: this.getSmartServiceType,
    [MENU_KEYS.SMART.COMMON.THIRD.TRAIN]: this.getSmartServiceType,
    [MENU_KEYS.SMART.COMMON.THIRD.HOTEL]: this.getSmartServiceType,
    [MENU_KEYS.SMART.COMMON.THIRD.TAXI]: this.getSmartServiceType,
    [MENU_KEYS.SMART.COMMON.THIRD.TRANSFER]: this.getSmartServiceType,
    [MENU_KEYS.SMART.COMMON.THIRD.MICE]: this.getSmartServiceType,
    [MENU_KEYS.SMART.COMMON.FOURTH.AIRLINE]: this.getSmartAirPageWithTrips,
    [MENU_KEYS.SMART.COMMON.FOURTH.TRAIN]: this.getSmartTrainPageWithTrips,
    [MENU_KEYS.SMART.COMMON.FOURTH.HOTEL]: this.getSmartHotelPageWithTrips,
    [MENU_KEYS.SMART.COMMON.FOURTH.TAXI]: this.getSmartTaxiPageWithTrips,
    [MENU_KEYS.SMART.COMMON.FOURTH.AEROEXPRESS]: this.getSmartAeroexpressPageWithTrips,
    [MENU_KEYS.SMART.COMMON.FOURTH.TRANSFER]: this.getSmartTransferPageWithTrips,
    [MENU_KEYS.SMART.COMMON.FOURTH.MICE]: this.getSmartTransferPageWithTrips,
    [MENU_KEYS.SMART.AIRLINE.FIRST]: this.getSmartAirlinePage,
    [MENU_KEYS.SMART.AIRLINE.SECOND]: this.getSmartServiceType,
    [MENU_KEYS.SMART.AIRLINE.THIRD]: this.getSmartServiceType,
    [MENU_KEYS.SMART.TRAIN.FIRST]: this.getSmartTrainPage,
    [MENU_KEYS.SMART.AEROEXPRESS.FIRST]: this.getSmartAeroexpressPage,
    [MENU_KEYS.SMART.TRANSFER.FIRST]: this.getSmartTransferPage,
    [MENU_KEYS.SMART.MICE.FIRST]: this.getSmartMicePage,
    [MENU_KEYS.SMART.TRAIN.SECOND]: this.getSmartServiceType,
    [MENU_KEYS.SMART.TRAIN.THIRD]: this.getSmartServiceType,
    [MENU_KEYS.SMART.HOTEL.FIRST]: this.getSmartHotelPage,
    [MENU_KEYS.SMART.HOTEL.SECOND]: this.getSmartServiceType,
    [MENU_KEYS.SMART.HOTEL.THIRD]: this.getSmartServiceType,
    [MENU_KEYS.SMART.TAXI.FIRST]: this.getSmartTaxiPage,
    [MENU_KEYS.SMART.TAXI.SECOND]: this.getSmartServiceType,
    [MENU_KEYS.SMART.TAXI.THIRD]: this.getSmartServiceType,
    [MENU_KEYS.SMART.TRANSFER.SECOND]: this.getSmartServiceType,
    [MENU_KEYS.SMART.MICE.SECOND]: this.getSmartServiceType,
    [MENU_KEYS.SMART.TRANSFER.THIRD]: this.getSmartServiceType,
    [MENU_KEYS.SMART.MICE.THIRD]: this.getSmartServiceType,
    [MENU_KEYS.AIRLINE.SUMMARY]: this.getAirlineSummary,
    [MENU_KEYS.AIRLINE.SUMMARY_NESTED]: this.getSummarySubPage,
    [MENU_KEYS.AIRLINE.DIRECTIONS]: this.getAirlineDirections,
    [MENU_KEYS.AIRLINE.DIRECTIONS_NESTED]: this.getDirectionsSubPage,
    [MENU_KEYS.AIRLINE.COMPANIES]: this.getAirlineCompanies,
    [MENU_KEYS.AIRLINE.COMPANIES_NESTED]: this.getCompaniesSubPage,
    [MENU_KEYS.AIRLINE.EARLINESS]: this.getAirlineEarliness,
    [MENU_KEYS.AIRLINE.EARLINESS_NESTED]: this.getEarlinessSubPage,
    [MENU_KEYS.TRAIN.SUMMARY]: this.getTrainSummary,
    [MENU_KEYS.TRAIN.SUMMARY_NESTED]: this.getTrainSummarySubPage,
    [MENU_KEYS.TRAIN.DIRECTIONS]: this.getTrainDirections,
    [MENU_KEYS.TRAIN.DIRECTIONS_NESTED]: this.getTrainDirectionsSubPage,
    [MENU_KEYS.AEROEXPRESS.SUMMARY]: this.getAeroexpressSummary,
    [MENU_KEYS.AEROEXPRESS.SUMMARY_NESTED]: this.getAeroexpressSummarySubPage,
    [MENU_KEYS.AEROEXPRESS.DIRECTIONS]: this.getAeroexpressDirections,
    [MENU_KEYS.AEROEXPRESS.DIRECTIONS_NESTED]: this.getAeroexpressDirectionsSubPage,
    [MENU_KEYS.TRANSFER.SUMMARY]: this.getTransferSummary,
    [MENU_KEYS.TRANSFER.SUMMARY_NESTED]: this.getTransferSummarySubPage,
    [MENU_KEYS.TRANSFER.DIRECTIONS]: this.getTransferDirections,
    [MENU_KEYS.TRANSFER.DIRECTIONS_NESTED]: this.getTransferDirectionsSubPage,
    [MENU_KEYS.MICE.SUMMARY]: this.getMiceSummary,
    [MENU_KEYS.MICE.SUMMARY_NESTED]: this.getMiceSummarySubPage,
    [MENU_KEYS.HOTEL.SUMMARY]: this.getHotelSummary,
    [MENU_KEYS.HOTEL.SUMMARY_NESTED]: this.getHotelSummarySubPage,
    [MENU_KEYS.HOTEL.DIRECTIONS]: this.getHotelDirections,
    [MENU_KEYS.HOTEL.DIRECTIONS_NESTED]: this.getHotelDirectionsSubPage,
    [MENU_KEYS.HOTEL.POPULAR]: this.getHotelPopular,
    [MENU_KEYS.HOTEL.POPULAR_NESTED]: this.getHotelPopularSubPage,
    [MENU_KEYS.TAXI.SUMMARY]: this.getTaxiSummary,
    [MENU_KEYS.TAXI.SUMMARY_NESTED]: this.getTaxiSummarySubPage,
    [MENU_KEYS.TAXI.SUMMARY_NESTED_DOUBLE]: this.getTaxiSummarySubDoublePage,
    [MENU_KEYS.TAXI.DIRECTIONS]: this.getTaxiDirections,
    [MENU_KEYS.TAXI.DIRECTIONS_NESTED]: this.getTaxiDirectionsSubPage,
    [MENU_KEYS.STRUCTURE.SERVICE_TYPE]: this.getStructureServiceType,
    [MENU_KEYS.STRUCTURE.SERVICE_TYPE_NESTED]: this.getStructureServiceTypeSubPage,
    [MENU_KEYS.STRUCTURE.DEPARTMENTS]: this.getStructureDepartments,
    [MENU_KEYS.STRUCTURE.DEPARTMENTS_NESTED]: this.getStructureDepartmentsSubPage,
    [MENU_KEYS.STRUCTURE.DEPARTMENTS_NESTED_SUB]: this.getStructureDepartmentsDoubleSubPage,
    [MENU_KEYS.STRUCTURE.PROJECTS]: this.getStructureProjects,
    [MENU_KEYS.STRUCTURE.PROJECTS_NESTED]: this.getStructureProjectsSubPage,
    [MENU_KEYS.STRUCTURE.PROJECTS_NESTED_SUB]: this.getStructureProjectsDoubleSubPage,
    [CHARTS_ANALYTICS(id)]: this.getStructureCustomAnalytics,
    [MENU_KEYS.STRUCTURE.CUSTOM_ANALYTICS_NESTED]: this.getStructureCustomAnalyticsSubPage,
    [MENU_KEYS.STRUCTURE.CUSTOM_ANALYTICS_NESTED_SUB]: this.getStructureCustomAnalyticsDoubleSubPage,
  })[active];

  setEmployeesList = (values: any) => {
    const employeesList = values.map(({ employeeId, name, patronymic, surname }:
    { employeeId: number, name: string, patronymic: string, surname: string }) =>
      ({ value: employeeId, label: `${name} ${patronymic} ${surname}` }));
    this.chartsAnalyticsStore.setEmployeesList(employeesList);
  };

  setEmployeesSelected = (values: number[]) => this.chartsAnalyticsStore.setEmployeesSelected(values);

  setProjectsList = (values: any) => {
    const projectsList = values.map(({ projectId, projectName }: { projectId: number, projectName: string }) =>
      ({ value: projectId, label: projectName }));
    this.chartsAnalyticsStore.setProjectsList(projectsList);
  };

  setProjectsSelected = (values: number[]) => this.chartsAnalyticsStore.setProjectsSelected(values);

  setDepartmentsList = (values: any) => {
    const departmentsList = values.map(({ departmentId, departmentName }: {
      departmentId: number,
      departmentName: string
    }) =>
      ({ value: departmentId, label: departmentName }));
    this.chartsAnalyticsStore.setDepartmentsList(departmentsList);
  };

  setAnalytics = (values: number[], selected: number[]) => {
    this.setCustomAnalyticsList(values);
    this.setAnalyticsSelected(selected);
  };

  setCustomAnalyticsList = (values: number[]) => this.chartsAnalyticsStore.setAnalyticsList(values);

  setAnalyticsSelected = (values: number[]) => this.chartsAnalyticsStore.setAnalyticsSelected(values);

  updateEmployeesList = () => {
    const {
      filters: {
        employees: { selected: employeeSelected, list: employeeList, firstEmployees },
        employeesAutocomplete: { list: employeesAutocompleteList },
      }, autocompleteEmployeesQuery,
    } = this.chartsAnalyticsStore;

    const allEmployee = employeeList.concat(employeesAutocompleteList);
    const list = autocompleteEmployeesQuery ? employeesAutocompleteList : firstEmployees
      .map(({ id, name, patronymic, surname }) => ({ value: id, label: `${name} ${patronymic} ${surname}` }));

    this.chartsAnalyticsStore.setEmployeesList(preparedAutocompleted(employeeSelected, list, allEmployee));
  };

  updateDepartmentList = () => {
    const {
      filters: {
        departments: { selected: departmentsSelected, list: departmentList, firstDepartments },
        departmentsAutocomplete: { list: departmentAutocompleteList },
      }, autocompleteDepartmentsQuery,
    } = this.chartsAnalyticsStore;

    const allDepartments = departmentList.concat(departmentAutocompleteList);
    const list = autocompleteDepartmentsQuery ? departmentAutocompleteList : firstDepartments
      .map(({ id, name }) => ({ value: id, label: name }));

    this.chartsAnalyticsStore.setDepartmentsList(preparedAutocompleted(departmentsSelected, list, allDepartments));
  };

  updateProjectsList = () => {
    const {
      filters: {
        projects: { selected: projectsSelected, list: projectsList, firstProjects },
        projectsAutocomplete: { list: projectsAutocompleteList },
      }, autocompleteProjectsQuery,
    } = this.chartsAnalyticsStore;

    const allProjects = projectsList.concat(projectsAutocompleteList);

    const list = autocompleteProjectsQuery ? projectsAutocompleteList : firstProjects
      .map(({ id, name }) => ({ value: id, label: name }));

    this.chartsAnalyticsStore.setProjectsList(preparedAutocompleted(projectsSelected, list, allProjects));
  };

  setAutocompleteEmployeesQuery = (value: string) => this.chartsAnalyticsStore.setAutocompleteEmployeesQuery(value);

  setAutocompleteDepartmentsQuery = (value: string) => this.chartsAnalyticsStore.setAutocompleteDepartmentsQuery(value);

  setAutocompleteProjectsQuery = (value: string) => this.chartsAnalyticsStore.setAutocompleteProjectsQuery(value);

  setAutocompleterForBackend = (type: string) => {
    let finderType = type.toLowerCase() === SERVICETYPE.TAXI.toLowerCase() ? SERVICETYPE.TAXI_VOUCHER_FROM_BACK : type;

    const renamer = (t: string) => (t === STRUCTURE_TYPE.OTHER ? STRUCTURE_TYPE_FOR_BACKEND.OTHER : t);

    switch (type) {
      case STRUCTURE_TYPE_FOR_BACKEND.ALL_SERVICE: {
        const { type: Type } = this.chartsAnalyticsSummaryStore;

        if (Type) finderType = renamer(Type);

        break;
      }
      case STRUCTURE_TYPE_FOR_BACKEND.DEPARTMENTS: {
        const { type: DepartmentsType } = this.chartsAnalyticsSummaryStore;

        if (DepartmentsType) finderType = renamer(DepartmentsType);

        break;
      }
      case STRUCTURE_TYPE_FOR_BACKEND.PROJECTS: {
        const { type: projectType } = this.chartsAnalyticsSummaryStore;

        if (projectType) finderType = renamer(projectType);

        break;
      }
      case STRUCTURE_TYPE_FOR_BACKEND.TAGS: {
        const { type: tagType } = this.chartsAnalyticsSummaryStore;

        if (tagType) finderType = renamer(tagType);

        break;
      }
      case STRUCTURE_TYPE_FOR_BACKEND.CUSTOM_ANALYTICS: {
        const { type: AnalyticsType } = this.chartsAnalyticsSummaryStore;

        if (AnalyticsType) finderType = renamer(AnalyticsType);

        break;
      }
    }

    return finderType;
  };

  setAutocompleteEmployees = (query: string, type: string) => {
    const { id: analyticId } = this.chartsAnalyticsStore;

    const params = this.getCommonParamsForBackend();
    const preparedParams = {
      ...params,
      filters: {
        Employees: [],
        Departments: params.filters.Departments,
        Projects: params.filters.Projects,
        CustomAnalytics: params.filters.CustomAnalytics,
        Tags: params.filters.Tags,
      },
    };

    const taxiType = type === TYPE.TAXI ? STRUCTURE_TYPE.TAXI_VOUCHER : type;

    const autocompleteEmployees = type === STRUCTURE_TYPE_FOR_BACKEND.CUSTOM_ANALYTICS ?
      this.autocompleteDebounceEmployeesForCustom(query.trim(), preparedParams, this.setAutocompleterForBackend(type), analyticId as number) :
      this.autocompleteDebounceEmployees(query.trim(), preparedParams, this.setAutocompleterForBackend(taxiType));

    autocompleteEmployees.then((res: any) => {
      const updateRes = res.length ? res.map(({ id, name, patronymic, surname }: {
        id: number,
        name: string,
        patronymic: string,
        surname: string
      }) =>
        ({ value: id, label: `${name} ${patronymic} ${surname}` })) : [];
      this.setAutocompleteEmployeesQuery(query);
      this.chartsAnalyticsStore.setEmployeesAutocompleteList(updateRes);
      this.updateEmployeesList();
    })
      .catch(() => {
        this.setAutocompleteEmployeesQuery(query);
        this.chartsAnalyticsStore.setEmployeesAutocompleteList([]);
        this.updateEmployeesList();
      });
  };

  setAutocompleteDepartments = (query: string, type: string) => {
    const { id: analyticId } = this.chartsAnalyticsStore;

    const params = this.getCommonParamsForBackend();
    const preparedParams = {
      ...params,
      filters: {
        Employees: params.filters.Employees,
        Projects: params.filters.Projects,
        CustomAnalytics: params.filters.CustomAnalytics,
        Tags: params.filters.Tags,
        Departments: [],
      },
    };

    const autocompleteDepartments = type === STRUCTURE_TYPE_FOR_BACKEND.CUSTOM_ANALYTICS ?
      this.autocompleteDebounceDepartmentsForCustom(query.trim(), preparedParams, this.setAutocompleterForBackend(type), analyticId as number) :
      this.autocompleteDebounceDepartments(query.trim(), preparedParams, this.setAutocompleterForBackend(type));

    autocompleteDepartments.then((res: any) => {
      const updateRes = res.length ? res.map(({ id, name }: { id: number, name: string }) =>
        ({ value: id, label: name })) : [];
      this.setAutocompleteDepartmentsQuery(query);
      this.chartsAnalyticsStore.setDepartmentsAutocompleteList(updateRes);
      this.updateDepartmentList();
    })
      .catch(() => {
        this.setAutocompleteDepartmentsQuery(query);
        this.chartsAnalyticsStore.setDepartmentsAutocompleteList([]);
        this.updateDepartmentList();
      });
  };

  setAutocompleteProjects = async (query: string, type: string) => {
    const { id: analyticId } = this.chartsAnalyticsStore;

    const params = this.getCommonParamsForBackend();
    const preparedParams = {
      ...params,
      filters: {
        Employees: params.filters.Employees,
        Departments: params.filters.Departments,
        CustomAnalytics: params.filters.CustomAnalytics,
        Tags: params.filters.Tags,
        Projects: [],
      },
    };

    const autocompleteProjects = type === STRUCTURE_TYPE_FOR_BACKEND.CUSTOM_ANALYTICS ?
      this.autocompleteDebounceProjectsForCustom(query.trim(), preparedParams, this.setAutocompleterForBackend(type), analyticId as number) :
      this.autocompleteDebounceProjects(query.trim(), preparedParams, this.setAutocompleterForBackend(type));

    autocompleteProjects.then((res: any) => {
      const updateRes = res.length ? res.map(({ id, name }: { id: number, name: string }) =>
        ({ value: id, label: name })) : [];
      this.setAutocompleteProjectsQuery(query);
      this.chartsAnalyticsStore.setProjectsAutocompleteList(updateRes);
      this.updateProjectsList();
    })
      .catch(() => {
        this.setAutocompleteProjectsQuery(query);
        this.chartsAnalyticsStore.setProjectsAutocompleteList([]);
        this.updateProjectsList();
      });
  };

  setDepartmentsSelected = (values: number[]) => this.chartsAnalyticsStore.setDepartmentsSelected(values);

  setAutocompleteTagsQuery = (value: string) => this.chartsAnalyticsStore.setAutocompleteTagsQuery(value);

  updateTagsList = () => {
    const {
      filters: {
        tags: { selected: tagsSelected, list: tagsList, firstTags },
        tagsAutocomplete: { list: tagsAutocompleteList },
      }, autocompleteTagsQuery,
    } = this.chartsAnalyticsStore;

    const allTags = tagsList.concat(tagsAutocompleteList);

    const list = autocompleteTagsQuery ? tagsAutocompleteList : firstTags
      .map(({ id, name }) => ({ value: id, label: name }));

    this.chartsAnalyticsStore.setTagsList(preparedAutocompleted(tagsSelected, list, allTags));
  };

  setAutocompleteTags = async (query: string, type: string) => {
    const { id: analyticId } = this.chartsAnalyticsStore;

    const params = this.getCommonParamsForBackend();
    const preparedParams = {
      ...params,
      filters: {
        Employees: params.filters.Employees,
        Departments: params.filters.Departments,
        CustomAnalytics: params.filters.CustomAnalytics,
        Projects: params.filters.Projects,
        Tags: [],
      },
    };

    const autocompleteTags = type === STRUCTURE_TYPE_FOR_BACKEND.CUSTOM_ANALYTICS ?
      this.autocompleteDebounceTagsForCustom(query.trim(), preparedParams, this.setAutocompleterForBackend(type), analyticId as number) :
      this.autocompleteDebounceTags(query.trim(), preparedParams, this.setAutocompleterForBackend(type));

    autocompleteTags.then((res: string[]) => {
      this.setAutocompleteTagsQuery(query);
      this.chartsAnalyticsStore.setTagsAutocompleteList(res);
      this.updateTagsList();
    })
      .catch(() => {
        this.setAutocompleteTagsQuery(query);
        this.chartsAnalyticsStore.setTagsAutocompleteList([]);
        this.updateTagsList();
      });
  };

  setTagsSelected = (values: number[]) => this.chartsAnalyticsStore.setTagsSelected(values);

  setDownloadXlsLoading = (value: boolean) => this.chartsAnalyticsStore.setDownloadXlsLoading(value);

  downloadAnalyticsReportXls = async (route: string, updateParams: any) => {
    this.setDownloadXlsLoading(true);

    try {
      await this.api.downloadAnalyticsReport(route, updateParams);
      this.setDownloadXlsLoading(false);
    } catch {
      this.setDownloadXlsLoading(false);
    }
  };

  setSmartSubType = (value: string) => this.chartsAnalyticsSmartSummaryStore.setSmartSubType(value);

  downloadSmartXls = () => {
    const params = this.getCommonParamsForBackend();
    const { companies } = this.getGeneralParamsForBackend();
    const { type, subSmartType } = this.chartsAnalyticsSmartSummaryStore;

    const updateParams = {
      ...params,
      companies,
      type,
    };

    const analyticType = ({
      [TYPE_SMART_SERVICE_TYPE.TIMELINESS]: MainAnalytic.ACTIONS.ANALYTICS.SPENDING_DOWNLOAD_XLSX_ADVANCE_PAYMENT,
      [TYPE_SMART_SERVICE_TYPE.RETURNS]: MainAnalytic.ACTIONS.ANALYTICS.SPENDING_DOWNLOAD_XLSX_TICKET_RETURNS,
      [TYPE_SMART_SERVICE_TYPE.TRAVEL_POLICIES_USING]: MainAnalytic.ACTIONS.ANALYTICS.SPENDING_DOWNLOAD_XLSX_TRAVEL_APPROVAL,
      [TYPE_SMART_SERVICE_TYPE.SMART_HOTEL_USING]: MainAnalytic.ACTIONS.ANALYTICS.SPENDING_DOWNLOAD_XLSX_SMART,
      [TYPE_SMART_SERVICE_TYPE.CONTRACT_3D_USING]: MainAnalytic.ACTIONS.ANALYTICS.SPENDING_DOWNLOAD_XLSX_CORPORATE_PROGRAM,
      [TYPE_SMART_SERVICE_TYPE.TRAVELLER_BONUS_USING]: MainAnalytic.ACTIONS.ANALYTICS.SPENDING_DOWNLOAD_XLSX_PERSONAL_BONUSES,
    })[subSmartType];

    MainAnalytic.sendAmplitude(analyticType, { service: type });

    return this.api.downloadAnalyticsReport(ROUTES.CHARTS_ANALYTICS.DOWNLOADS.SMART.DOWNLOAD(type, subSmartType), updateParams);
  };

  downloadSubSmartXls = () => {
    const params = this.getCommonParamsForBackend();
    const { companies } = this.getGeneralParamsForBackend();
    const { subSmartType, type, subPageInfo } = this.chartsAnalyticsSmartSummaryStore;

    const updateParams = {
      ...params,
      companies,
      subPageInfo,
    };

    return this.api.downloadAnalyticsReport(ROUTES.CHARTS_ANALYTICS.DOWNLOADS.SMART.DOWNLOAD_SUB(type, subSmartType), updateParams);
  };

  changePageTaxiVoucher = async (page: number) => {
    this.chartsAnalyticsSmartSummaryStore.setPageTrips(page);
    await this.getTaxiSmartVoucher();
  };

  downloadXls = (active: string) => {
    const params = this.getCommonParamsForBackend();
    const [activeType] = active.split('-');
    const prepareActive = activeType.toUpperCase();
    // @ts-ignore
    const menuKeys = MENU_KEYS[prepareActive];
    const [activeKind, analId] = active.split('/');
    const { subPageInfo, type, voucherId } = this.chartsAnalyticsSummaryStore;

    const getActive = Object.keys(menuKeys).find(key => menuKeys[key] === activeKind);
    // @ts-ignore
    const route = analId ? ROUTES.CHARTS_ANALYTICS.DOWNLOADS[prepareActive][getActive](analId) : ROUTES.CHARTS_ANALYTICS.DOWNLOADS[prepareActive][getActive];

    let updateParams = {
      ...params,
    };

    switch (activeKind) {
      case MENU_KEYS.AIRLINE.SUMMARY_NESTED:
      case MENU_KEYS.AIRLINE.DIRECTIONS_NESTED:
      case MENU_KEYS.AIRLINE.COMPANIES_NESTED:
      case MENU_KEYS.AIRLINE.EARLINESS_NESTED:
      case MENU_KEYS.TRAIN.SUMMARY_NESTED:
      case MENU_KEYS.TRAIN.DIRECTIONS_NESTED:
      case MENU_KEYS.HOTEL.SUMMARY_NESTED:
      case MENU_KEYS.HOTEL.DIRECTIONS_NESTED:
      case MENU_KEYS.HOTEL.POPULAR_NESTED:
      case MENU_KEYS.TAXI.SUMMARY_NESTED:
      case MENU_KEYS.TAXI.DIRECTIONS_NESTED:
      case MENU_KEYS.AEROEXPRESS.DIRECTIONS_NESTED:
      case MENU_KEYS.AEROEXPRESS.SUMMARY_NESTED:
      case MENU_KEYS.TRANSFER.DIRECTIONS_NESTED:
      case MENU_KEYS.TRANSFER.SUMMARY_NESTED:
      case MENU_KEYS.MICE.SUMMARY_NESTED: {
        updateParams = {
          ...params,
          // @ts-ignore
          subPageInfo,
        };

        break;
      }
      case MENU_KEYS.TAXI.SUMMARY_NESTED_DOUBLE:
      case MENU_KEYS.TAXI.DIRECTIONS_NESTED_DOUBLE: {
        updateParams = {
          ...params,
          // @ts-ignore
          voucherId,
        };

        break;
      }
      case MENU_KEYS.STRUCTURE.SERVICE_TYPE_NESTED: {
        updateParams = {
          ...params,
          // @ts-ignore
          type,
        };

        break;
      }
      case MENU_KEYS.STRUCTURE.DEPARTMENTS_NESTED: {
        const { depIds } = this.chartsAnalyticsSummaryStore;

        updateParams = {
          ...params,
          filters: {
            ...params.filters,
            Departments: depIds,
          },
        };

        break;
      }
      case MENU_KEYS.STRUCTURE.DEPARTMENTS_NESTED_SUB: {
        const { depIds } = this.chartsAnalyticsSummaryStore;

        updateParams = {
          ...params,
          // @ts-ignore
          type,
          filters: {
            ...params.filters,
            Departments: depIds,
          },
        };

        break;
      }
      case MENU_KEYS.STRUCTURE.PROJECTS_NESTED: {
        const { projectIds } = this.chartsAnalyticsSummaryStore;

        updateParams = {
          ...params,
          filters: {
            ...params.filters,
            Projects: projectIds,
          },
        };

        break;
      }
      case MENU_KEYS.STRUCTURE.PROJECTS_NESTED_SUB: {
        const { projectIds } = this.chartsAnalyticsSummaryStore;

        updateParams = {
          ...params,
          // @ts-ignore
          type,
          filters: {
            ...params.filters,
            Projects: projectIds,
          },
        };

        break;
      }
      case MENU_KEYS.STRUCTURE.CUSTOM_ANALYTICS_NESTED: {
        const { analyticIds } = this.chartsAnalyticsSummaryStore;

        updateParams = {
          ...params,
          filters: {
            ...params.filters,
            CustomAnalytics: analyticIds,
          },
        };

        break;
      }
      case MENU_KEYS.STRUCTURE.CUSTOM_ANALYTICS_NESTED_SUB: {
        const { analyticIds } = this.chartsAnalyticsSummaryStore;

        updateParams = {
          ...params,
          // @ts-ignore
          type,
          filters: {
            ...params.filters,
            CustomAnalytics: analyticIds,
          },
        };

        break;
      }
    }

    return this.downloadAnalyticsReportXls(route, updateParams);
  };
}

export default ChartsAnalytics;
