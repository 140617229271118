import React from 'react';
import { observer } from 'mobx-react';
import { Datepicker, MultiSelect, Text } from 'new-ui';
import { useRouteMatch } from 'react-router-dom';

import { Moment } from 'moment';
import { useStores } from '../../../../bi/context';
import { MOBX_STORES } from '../../../../bi/context/stores';

import { getText } from '../../../../../i18n';

import { defaultPattern } from '../../../../bi/utils/formatDate';

import styles from './styles.module.css';

const LABELS = {
  HEADER: getText('chartsAnalytics:analytic'),
};

interface ChartAnalyticsDatesProps {
  onSelectCompanies(values: any): void,
  onChangeStartDate(value: string | Moment | null): void,
  onChangeEndDate(value: string | Moment | null): void,
  getInfo(tabId: string): void,
}

interface MatchParams {
  tabId: string
}

const ChartAnalyticsDates = observer(({
  onSelectCompanies,
  onChangeStartDate,
  onChangeEndDate,
  getInfo,
}: ChartAnalyticsDatesProps) => {
  const {
    chartsAnalyticsStore: {
      commonChartsAnalytics: {
        startDate,
        endDate,
        minDate,
        maxDate,
        selectedCompanies,
        companies,
      },
    },
  } = useStores([MOBX_STORES.CHARTS_ANALYTICS_STORE]);

  const { params: { tabId } } = useRouteMatch<MatchParams>();

  const handleChangeSelectedCompanies = (values: any) => {
    if (!values.length) return;

    onSelectCompanies(values);
    getInfo(tabId);
  };

  const handleChangeStartDate = (value: string | Moment | null) => {
    onChangeStartDate(value);
    getInfo(tabId);
  };

  const handleChangeEndDate = (value: string | Moment | null) => {
    onChangeEndDate(value);
    getInfo(tabId);
  };

  const renderHeaderCompanies = () => (companies.length === 1 ? (
    <div className={ styles.company }>
      <Text type='NORMAL_16' className={ styles.company_text }>
        { companies[0].label }
      </Text>
    </div>
  ) : (
    <div className={ styles.companies }>
      <MultiSelect
        className={ styles.multi_select_border }
        itemClassName={ styles.multi_select_item }
        list={ companies }
        values={ selectedCompanies }
        onChange={ handleChangeSelectedCompanies }
      />
    </div>
  ));

  return (
    <div className={ styles.wrapper_header }>
      <Text type='bold_32' className={ styles.header_text }>
        { LABELS.HEADER }
      </Text>
      <div className={ styles.filters }>
        <div className={ styles.dates }>
          <Datepicker
            type='date'
            inputTheme='open'
            inputClassName={ styles.input_wrap }
            value={ startDate }
            min={ minDate }
            max={ maxDate }
            format={ defaultPattern }
            onChange={ value => handleChangeStartDate(value) }
            isDuration
            durationDates={ [startDate, endDate] }
          />
          &mdash;
          <Datepicker
            type='date'
            inputTheme='open'
            direction='to'
            inputClassName={ styles.input_wrap }
            value={ endDate }
            min={ startDate }
            max={ maxDate }
            format={ defaultPattern }
            onChange={ value => handleChangeEndDate(value) }
            isDuration
            durationDates={ [startDate, endDate] }
          />
        </div>
        { renderHeaderCompanies() }
      </div>
    </div>
  );
});

export { ChartAnalyticsDates };
