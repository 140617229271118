// @ts-nocheck
import React, { useState, useRef, useEffect } from 'react';
import { observer } from 'mobx-react';
import { Text, PROPS, Datepicker, Input, Select, IconButton } from 'new-ui';

import { getText } from '../../../../i18n';

import { useStores } from '../../../bi/context';
import { MOBX_STORES } from '../../../bi/context/stores';

import TravelApproval from '../../../bi/services/travelApproval';

import { defaultPattern, momentObject } from '../../../bi/utils/formatDate';
import { findMatchingElement } from '../../../bi/utils/travelApproval';
import { getEmployeeCompaniesWithDepartments } from '../../../bi/utils/approval';

import { FIELD_APPROVAL_REQUEST } from '../../../bi/constants/approve';

import { NumberInput } from '../../../components/NumberInput';
import { FieldLabel } from '../../../components/FieldLabel';
import { TripTags } from '../../../components/TripTags';
import AnalyticsSelect from '../../../components/AnalyticsSelect';

import { ApprovalHistoryPopUp } from './ApprovalHistoryPopUp';
import { SelectCity } from './SelectCity';
import SelectEmployee from './SelectEmployee';

import { WorkspaceService } from '../../../bi/types/workspace';
import { ITripTagsService } from '../../bi/types/tripTags';
import { IFeatureFlagsService } from '../../bi/types/featureFlags';
import { IAccountSettingsService } from '../../bi/types/accountSettings';
import { UserSessionService } from '../../bi/types/userSession';

import styles from '../styles/index.module.css';

const ADD_ITEM = {
  label: getText('components:travelApproval.other'),
  value: null,
};

const LABELS = {
  ADD_EMPLOYEE: getText('components:travelApproval.addEmployee'),
  ADD_INTERMEDIATE_CITY: getText('components:travelApproval.addIntermediateCity'),
};

const FIELDS_KEYS = {
  DestinationCity: 'IsRequiredDestinationCity',
  Dates: 'IsRequiredDates',
  Budget: 'IsRequiredBudget',
  Purpose: 'IsRequiredPurpose',
  Comment: 'IsRequiredComment',
};

const {
  ICON: {
    TYPES: {
      PLUS: {
        ROUND,
      },
    },
  },
  ICON_BUTTON: {
    THEMES: {
      DEFAULT_RED,
    },
  },
  SELECT: {
    THEMES: {
      DEFAULT_BORDER,
    },
  },
  DATEPICKER: {
    TYPES: {
      DATE,
    },
    DIRECTIONS: {
      TO,
    },
  },
} = PROPS;

interface GeneralBlockProps {
  region: object,
  travelApproval: object,
  approvalPopUp: object,
  applicationSchemeTemplate: object,
  purposes: object[],
  employeesWithRights: object[],
  readOnly: boolean,
  isMulti: boolean,
  isNew: boolean,
  disabledField: boolean,
  disabledToUpdateField: boolean,
  employeeId: number,
  getEmployees: () => void,
  onAddEmployee: () => any,
  onRemoveEmployee: any,
  onAddEmployeeCompany: any,
  userSessionService: UserSessionService,
  customAnalyticsService: object,
  hotelsService: object,
  accountSettingsService: IAccountSettingsService,
  workspaceService: WorkspaceService,
  tripTagsService: ITripTagsService,
  featureFlagsService: IFeatureFlagsService,
  travelApprovalService: TravelApproval,
  onAddCustomAnalytic: (id: number, value: string) => void,
  onAddProject: (projectId: number | null, employeeId: number) => void,
  qaAttr?: object,
}

const GeneralBlock = observer(({
  region,
  travelApproval,
  approvalPopUp,
  applicationSchemeTemplate,
  purposes,
  employeesWithRights,
  readOnly,
  isMulti,
  isNew,
  disabledField,
  disabledToUpdateField,
  employeeId,
  getEmployees,
  onAddEmployee,
  onRemoveEmployee,
  onAddEmployeeCompany,
  userSessionService,
  customAnalyticsService,
  hotelsService,
  accountSettingsService,
  workspaceService,
  tripTagsService,
  featureFlagsService,
  travelApprovalService,
  onAddCustomAnalytic,
  onAddProject,
  qaAttr,
}: GeneralBlockProps) => {
  const [isShowSelect, setIsShowSelect] = useState<boolean>(true);
  const [projects, setProjects] = useState([]);
  const inputRef = useRef();
  const suggestRef = useRef();

  const { travelApprovalStore } = useStores([MOBX_STORES.TRAVEL_APPROVAL_STORE]);

  useEffect(() => {
    const { Employees, Purpose } = travelApproval;
    const isInputPurpose = isNew || (typeof Purpose === 'object' && !Purpose?.Name) ? true : !!purposes.find(({ label }) => label === Purpose?.Name);

    setIsShowSelect(isInputPurpose);

    const foundEmployee = employeesWithRights.find(({ Id }) => Id === employeeId) || null;

    const { projects: userProjects = [] } = userSessionService.store.getState();

    setProjects(userProjects);

    if (!Employees.length && foundEmployee && readOnly) {
      onAddEmployee(foundEmployee, 0).then(() => {
        const employeeCompaniesWithDepartments = getEmployeeCompaniesWithDepartments(foundEmployee.Companies);

        onAddEmployeeCompany(foundEmployee.Id, employeeCompaniesWithDepartments[0].value);
      });
    }
  }, []);

  const isRequiredField = (fieldLabel) => applicationSchemeTemplate[fieldLabel];
  const handleFocus = () => inputRef.current.focus();

  const handleChangeBlur = () => {
    const { Purpose } = travelApproval;

    if (!Purpose) {
      setIsShowSelect(true);
    }
  };

  const handleChangeSelect = (purposeValue) => {
    const { purposeChange } = travelApprovalService;

    if (purposeValue === ADD_ITEM.value) {
      setIsShowSelect(false);
    }

    if (!purposeValue && !isShowSelect) {
      setIsShowSelect(true);
    }

    return purposeChange(purposeValue);
  };

  const renderTripTags = () => {
    const { Tags: selectedTripTags } = travelApproval;
    const { store: { accountSettings: { hideTagsInCart } } } = accountSettingsService;

    if (hideTagsInCart) {
      return null;
    }

    return (
      <TripTags
        noeditable={ disabledField }
        tripTagsService={ tripTagsService }
        selectedTags={ selectedTripTags }
        onChangeTags={ travelApprovalService.setTripTags }
        store={ tripTagsService.store }
        workspaceService={ workspaceService }
      />
    );
  };

  const renderAnalytics = () => {
    const { Analytics } = travelApproval;
    const { setCustomAnalytic, unSetCustomAnalytic } = travelApprovalService;
    const { store: { sortedCustomAnalytics } } = customAnalyticsService;

    const onlyNewAnalytics = featureFlagsService.getOnlyNewAnalytics();
    let alreadyHighlighted = false;

    return sortedCustomAnalytics.map((analytics) => {
      const customAnalyticsValue = analytics?.Values.find(({ Id }) => Analytics.includes(Id));

      const highlighted = !alreadyHighlighted && analytics.Required && !customAnalyticsValue;

      if (highlighted) {
        alreadyHighlighted = true;
      }

      return (
        <div key={ analytics.Id }>
          <AnalyticsSelect
            highlighted={ highlighted }
            onlyNewAnalytics={ onlyNewAnalytics }
            value={ customAnalyticsValue }
            readonly={ disabledField }
            analytics={ analytics }
            onSet={ setCustomAnalytic }
            onUnset={ unSetCustomAnalytic }
            onAdd={ onAddCustomAnalytic }
            displaySearchIcon
          />
        </div>
      );
    });
  };

  const renderData = () => {
    const { changeStartDate, changeEndDate } = travelApprovalService;
    const { StartDate, EndDate, MinDate } = travelApproval;

    const isRequired = isRequiredField(FIELDS_KEYS.Dates);
    const normalizedStartDate = StartDate ? momentObject(StartDate) : null;
    const normalizedEndDate = EndDate ? momentObject(EndDate) : null;
    const normalizedMinDate = momentObject(MinDate);
    const normalizedMinEndDate = normalizedStartDate || normalizedMinDate;
    const dateDisabled = disabledField ? styles.disabled_field : '';

    return (
      <div>
        <FieldLabel text={ FIELD_APPROVAL_REQUEST.DATES } mustHave={ isRequired } />
        <div className={ `${styles.dates}` }>
          <Datepicker
            type={ DATE }
            disabled={ disabledField }
            inputClassName={ `${styles.input} ${styles.input_start_date}` }
            wrapperClassName={ styles.from }
            value={ normalizedStartDate }
            min={ normalizedMinDate }
            format={ defaultPattern }
            onChange={ changeStartDate }
            isDuration
            durationDates={ [normalizedStartDate, normalizedEndDate] }
            qaAttr={ qaAttr?.date.from || '' }
          />
          <div className={ `${styles.date} ${dateDisabled}` }>—</div>
          <Datepicker
            type={ DATE }
            disabled={ disabledField }
            direction={ TO }
            inputClassName={ `${styles.input} ${styles.input_end_date}` }
            wrapperClassName={ styles.to }
            value={ normalizedEndDate }
            min={ normalizedMinEndDate }
            format={ defaultPattern }
            onChange={ changeEndDate }
            isDuration
            durationDates={ [normalizedStartDate, normalizedEndDate] }
            qaAttr={ qaAttr?.date.to || '' }
          />
        </div>
      </div>
    );
  };

  const renderCity = () => {
    const {
      setSuggestValue,
      addIntermediateCity,
      deleteIntermediateCity,
      setRemoveSuggestValue,
    } = travelApprovalService;
    const { autocompleteSchemeHotel } = hotelsService;
    const isRequired = isRequiredField(FIELDS_KEYS.DestinationCity);

    return (
      <SelectCity
        travelApproval={ travelApproval }
        region={ region }
        suggestRef={ suggestRef }
        isRequired={ isRequired }
        onSuggestSelected={ setSuggestValue }
        onDeleteSelected={ setRemoveSuggestValue }
        onGetSuggests={ autocompleteSchemeHotel }
        disabledField={ disabledField }
        onAddIntermediateCity={ addIntermediateCity }
        onDeleteIntermediateCity={ deleteIntermediateCity }
        qaAttrCity={ qaAttr?.city || '' }
      />
    );
  };

  const renderBudget = () => {
    const { Budget } = travelApproval;
    const { inputBudgetChange } = travelApprovalService;

    const isRequired = isRequiredField(FIELDS_KEYS.Budget);
    const budgetDisabled = disabledField ? styles.disabled_field : '';

    return (
      <div>
        <FieldLabel text={ FIELD_APPROVAL_REQUEST.BUDGET_TRIP } mustHave={ isRequired } />
        <NumberInput
          value={ Budget }
          onChange={ inputBudgetChange }
          className={ `${styles.budget_input} ${budgetDisabled}` }
          onRender={ () => {} }
          disabled={ disabledField }
          qaAttr={ qaAttr?.budget || '' }
        />
      </div>
    );
  };

  const renderSelectItem = ({ label, value: v }) => {
    if (v === ADD_ITEM.value) {
      return (
        <div className={ styles.add }>
          <div className={ styles.add_label }>{ADD_ITEM.label}</div>
        </div>
      );
    }

    return label;
  };

  const renderPurposeField = () => {
    const { Purpose } = travelApproval;

    const newPurposes = purposes.concat(ADD_ITEM);
    const purposeDisabled = disabledField ? styles.disabled_field : '';
    const currentPurpose = Number.isInteger(Purpose) ? Purpose : findMatchingElement(Purpose, purposes);
    const inputPurpose = typeof Purpose === 'object' ? Purpose?.Name : Purpose;

    return isShowSelect && !disabledField ? (
      <Select
        value={ currentPurpose }
        theme={ DEFAULT_BORDER }
        items={ newPurposes }
        alwaysIncluded={ [ADD_ITEM.value] }
        renderItem={ renderSelectItem }
        onChange={ handleChangeSelect }
        qaAttr={ qaAttr?.purposeSelect || '' }
      />
    ) : (
      <Input
        autoFocus
        className={ `${styles.budget_input} ${purposeDisabled}` }
        value={ inputPurpose }
        ref={ inputRef }
        disabled={ disabledField }
        onChange={ handleChangeSelect }
        onFocus={ handleFocus }
        onBlur={ handleChangeBlur }
      />
    );
  };

  const renderPurpose = () => {
    const isRequired = isRequiredField(FIELDS_KEYS.Purpose);

    return (
      <div>
        <FieldLabel text={ FIELD_APPROVAL_REQUEST.PURPOSE } mustHave={ isRequired }/>
        { renderPurposeField() }
      </div>
    );
  };

  const renderEmployee = () => {
    const { projectsEmployees, projectEditable, travelApproval: { Status } } = travelApprovalStore;

    const { Employees } = travelApproval;
    const selectEmployeeHtml = (item, index) => (
      <SelectEmployee
        index={ index }
        number={ index + 1 }
        key={ `employee_${item.Id}_${index}` }
        items={ Employees }
        item={ Object.keys(item).length !== 0 ? item : null }
        disabledField={ disabledField }
        updateLock={ disabledToUpdateField }
        isMulti={ isMulti }
        getEmployees={ getEmployees }
        onAddCompany={ onAddEmployeeCompany }
        onAddEmployee={ employee => onAddEmployee(employee, index) }
        onRemoveEmployee={ employee => onRemoveEmployee(employee, index) }
        onAddProject={ onAddProject }
        projects={ projects }
        projectEditable={ projectEditable }
        employees={ projectsEmployees }
        approvalStatus={ Status }
      />
    );

    return Employees.length ? (
      <div className={ styles.employee }>
        { Employees.map(selectEmployeeHtml) }
      </div>
    ) : (
      <div className={ styles.employee }>
        <SelectEmployee
          index={ 0 }
          number={ 1 }
          items={ Employees }
          disabledField={ disabledField }
          isMulti={ isMulti }
          getEmployees={ getEmployees }
          onAddEmployee={ employee => onAddEmployee(employee, 0) }
          onRemoveEmployee={ employee => onRemoveEmployee(employee, 0) }
          onAddCompany={ onAddEmployeeCompany }
          onAddProject={ onAddProject }
          projects={ projects }
          projectEditable={ projectEditable }
          employees={ projectsEmployees }
          approvalStatus={ Status }
        />
      </div>
    );
  };

  const renderEmployees = () => {
    const { projectEditable, projectsEmployees } = travelApprovalStore;
    const { Employees, Status } = travelApproval;
    const { addMoreEmployee } = travelApprovalService;
    const finderEmployee = employeesWithRights.find(({ Id }) => Id === employeeId) || null;

    const addButton = !!Employees.length && !readOnly && !disabledToUpdateField && (
      <IconButton
        iconType={ ROUND }
        className={ styles.button }
        theme={ DEFAULT_RED }
        onClick={ addMoreEmployee }
      >
        { LABELS.ADD_EMPLOYEE }
      </IconButton>
    );

    return finderEmployee && readOnly && Employees.length && !disabledToUpdateField ? (
      <div className={ styles.employee }>
        <SelectEmployee
          readOnly
          index={ 0 }
          number={ 1 }
          item={ Employees[0] }
          isMulti={ isMulti }
          onAddCompany={ onAddEmployeeCompany }
          projects={ projects }
          onAddProject={ onAddProject }
          projectEditable={ projectEditable }
          employees={ projectsEmployees }
          disabledField={ disabledField }
          approvalStatus={ Status }
        />
      </div>
    ) : (
      <div className={ styles.main }>
        { renderEmployee() }
        { addButton }
      </div>
    );
  };

  const renderComment = () => {
    const { commentChange } = travelApprovalService;
    const { Comment } = travelApproval;

    const isRequired = isRequiredField(FIELDS_KEYS.Comment);
    const commentDisabled = disabledToUpdateField ? styles.disabled_field : '';

    return (
      <div>
        <FieldLabel text={ FIELD_APPROVAL_REQUEST.COMMENT } mustHave={ isRequired }/>
        <Input
          value={ Comment }
          onChange={ commentChange }
          disabled={ disabledToUpdateField }
          className={ commentDisabled }
          qaAttr={ qaAttr?.comment || '' }
        />
      </div>
    );
  };

  const { Approves } = travelApproval;

  return (
    <>
      <div className={ styles.general_block_header }>
        <Text type={ 'bold_24' }>
          { FIELD_APPROVAL_REQUEST.GENERAL }
        </Text>
        <ApprovalHistoryPopUp travelApproval={ Approves } approvalPopUp={ approvalPopUp }/>
      </div>
      <div className={ styles.general_panel }>
        { renderCity() }
        { renderData() }
        { renderPurpose() }
        { renderBudget() }
        { renderComment() }
        { renderEmployees() }
        { renderAnalytics() }
        { renderTripTags() }
      </div>
    </>
  );
});

export default GeneralBlock;
