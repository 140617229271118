// @ts-nocheck
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { Button, Tooltip, Text, Textarea, PROPS } from 'new-ui';
import { getText } from '../../../i18n';

import { WarningMessage } from '../../components/WarningMessage';
import { DateInSearch } from '../../components/DateInSerch';
import { BackToTripLink } from '../../components/BackToTripLink';

import { parseSearchString } from '../../bi/utils/convertSearchParams';
import { MainAnalytic } from '../../bi/utils/analytics';
import { isSmartAgent } from '../../bi/utils/env';

import SERVICETYPE, { SMART_AGENT_SERVICE_TYPE } from '../../bi/constants/serviceType';
import ROUTES from '../../bi/constants/routes';

import styles from './index.module.css';

const LABELS = {
  PLACEHOLDER: getText('search:additional.placeholder'),
  BUTTON: getText('search:additional.send'),
  MAIN_TEXT: getText('search:additional.mainText'),
  SECONDARY_TEXT: getText('search:additional.secondaryText'),
  DEMO: getText('search:additional.demo'),
  MSG_FOR_INTERCOM: getText('search:additional.msgForIntercom'),
  DEMO_SMARTAGENT: getText('search:additional.demoSmartagent'),
  ORDER_FOR_A_TRIP: getText('components:trip.orderForATrip'),
};

export default class AdditionalServiceSearchPage extends Component {
  static propTypes = {
    chatService: PropTypes.object.isRequired,
    workspaceService: PropTypes.object.isRequired,
    title: PropTypes.string.isRequired,
    mainText: PropTypes.string.isRequired,
    mainTextTwo: PropTypes.string.isRequired,
    analyticValue: PropTypes.string,
    analyticPropOnSend: PropTypes.string,
    secondaryText: PropTypes.string.isRequired,
    placeHolder: PropTypes.string.isRequired,
    history: PropTypes.object.isRequired,
    requireMessage: PropTypes.string,
    productAnalyticsProtocol: PropTypes.object.isRequired,
    featureFlagsService: PropTypes.object.isRequired,
    accountSettings: PropTypes.object.isRequired,
  };

  static defaultProps = {
    mainText: LABELS.MAIN_TEXT,
    secondaryText: LABELS.SECONDARY_TEXT,
    placeHolder: LABELS.PLACEHOLDER,
    requireMessage: null,
  };

  constructor(props) {
    super(props);

    const searchParams = parseSearchString(props.history.location.search);

    this.state = {
      message: '',
      isLoading: false,
      tripId: searchParams?.tripId || '',
      tripDate: searchParams?.tripDate || '',
      tripName: searchParams?.tripName || '',
    };
  }

  componentDidMount() {
    const {
      analyticValue,
      history,
      history: {
        location: {
          pathname,
        },
      },
      featureFlagsService: {
        getShowElementForSmartagent,
      },
      accountSettings,
    } = this.props;

    const { searchSettings } = accountSettings.getAccountSettings();

    if (isSmartAgent) {
      MainAnalytic.sendAmplitude(
        MainAnalytic.ACTIONS.SMART_AGENT.MAIN_SEARCH_OPENED,
        { SAservice: SMART_AGENT_SERVICE_TYPE.VIP_HALL },
      );
    }

    if (analyticValue) {
      MainAnalytic.sendAmplitude(analyticValue);
    }

    if ((isSmartAgent
      && ((getShowElementForSmartagent() && pathname === ROUTES.SEARCH.VIP)
      || (!searchSettings.includes(SERVICETYPE.VIP_HALL))))) {
      history.push({
        pathname: ROUTES.SMARTDESK,
      });
    }
  }

  componentDidUpdate() {
    const { tripId, tripDate, tripName } = this.state;

    const searchParams = parseSearchString(this.props.history.location.search);

    const searchTripId = searchParams?.tripId || '';
    const searchTripDate = searchParams?.tripDate || '';
    const searchTripName = searchParams?.tripName || '';

    if (searchTripId !== tripId || searchTripDate !== tripDate || searchTripName !== tripName) {
      this.setState({
        tripId: searchParams?.tripId || '',
        tripDate: searchParams?.tripDate || '',
        tripName: searchParams?.tripName || '',
      });
    }
  }

  handleChangeLoading = value => this.setState({
    isLoading: value,
  });

  handleChange = value => this.setState({
    message: value,
  });

  handleSend = () => {
    const {
      title,
      chatService,
      productAnalyticsProtocol,
      requireMessage,
      analyticPropOnSend,
    } = this.props;
    const { tripId, message } = this.state;

    const preparedMessage = this.props.requireMessage ? `${requireMessage} ${message}` : message;
    const tripIdMsg = tripId ? `${LABELS.MSG_FOR_INTERCOM} ${tripId}. ${preparedMessage}` : preparedMessage;

    this.handleChangeLoading(true);

    chatService.requestAdditionalService(tripIdMsg)
      .then(() => {
        this.handleChangeLoading(false);
        this.handleChange('');

        chatService.show();
        productAnalyticsProtocol.clickIntercom();

        MainAnalytic.send(MainAnalytic.CATEGORY.ADDITIONALSERVICES, MainAnalytic.ACTIONS.ADDITIONALSERVICES.ORDER, { label: title });
      })
      .catch(() => {
        this.handleChangeLoading(false);
      });

    if (analyticPropOnSend) {
      MainAnalytic.sendAmplitudeArrayArgs(
        MainAnalytic.ACTIONS.SEARCH.SEARCH_RESULTS_SENT_BUTTON_PRESSED(analyticPropOnSend),
      );
    }
  };

  renderTooltipBox = () => {
    const label = isSmartAgent ? LABELS.DEMO_SMARTAGENT : LABELS.DEMO;

    return (
      <Text
        color={ PROPS.TEXT.COLORS.WHITE }
        type={ PROPS.TEXT.TYPES.NORMAL_14_130 }
        className={ styles.tooltip_box }
      >
        { label }
      </Text>
    );
  };

  renderWarningMessage = this.props.featureFlagsService.getTripRuleCustomMessage() ? <WarningMessage /> : null;

  renderTitle = (title: string) => {
    const { featureFlagsService: { getShowElementForSmartagent } } = this.props;

    if (isSmartAgent && getShowElementForSmartagent()) {
      return (
        <div className={ styles.wrapper_title }>
          <Text
            type={ PROPS.TEXT.TYPES.BOLD_20 }
            className={ styles.title }
          >
            { title }
          </Text>
        </div>
      );
    }

    return (
      <Text
        type='bold_32'
        className={ styles.title }
      >
        { title }
      </Text>
    );
  };

  render() {
    const {
      title,
      workspaceService,
      mainText,
      mainTextTwo,
      secondaryText,
      placeHolder,
      featureFlagsService: {
        getShowElementForSmartagent,
      },
    } = this.props;
    const { message, isLoading, tripId, tripName, tripDate } = this.state;

    const isDemo = workspaceService.isDemo;

    const titleByAddingTrip = tripId ? LABELS.ORDER_FOR_A_TRIP : title;

    const wrapper = isSmartAgent && getShowElementForSmartagent()
      ? styles.wrapper
      : `${styles.wrapper} ${styles.wrapper_sw}`;

    const dateForAddingTrip = tripId ? (
      <div className={ styles.date }>
        <DateInSearch name={ tripName } date={ tripDate }/>
      </div>
    ) : null;

    return (
      <div className={ wrapper }>
        <BackToTripLink tripId={ tripId } />
        { this.renderTitle(titleByAddingTrip) }
        { dateForAddingTrip }
        <Text type={ PROPS.TEXT.TYPES.NORMAL_16_140 }>
          { mainText }
        </Text>
        <Text type={ PROPS.TEXT.TYPES.NORMAL_16_140 }>
          { mainTextTwo }
        </Text>
        <Text type={ PROPS.TEXT.TYPES.NORMAL_16_140 }>
          { secondaryText }
        </Text>
        <Textarea
          noneResize
          className={ styles.message }
          placeholder={ placeHolder }
          value={ message }
          debounceMs={ 0 }
          onChange={ this.handleChange }
        />
        <div className={ styles.actions }>
          { this.renderWarningMessage }
          <Tooltip
            show={ isDemo }
            renderContent={ this.renderTooltipBox }
          >
            <Button
              type={ PROPS.BUTTON.TYPES.SECONDARY }
              disabled={ message.length < 1 || isDemo }
              loading={ isLoading }
              onClick={ this.handleSend }
            >
              {LABELS.BUTTON}
            </Button>
          </Tooltip>
        </div>
      </div>
    );
  }
}
