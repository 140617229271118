// @ts-nocheck
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Button, Datepicker, Select, Text, PROPS } from 'new-ui';
import { getText } from '../../../../../i18n';

import createDateFromTo from '../../../../bi/utils/createDateFromTo';
import { getMoment, isSameOrAfter } from '../../../../bi/utils/formatDate';

import { generateTravelers } from '../../../../bi/constants/travelers';
import { QA_ATTRIBUTES } from '../../../../bi/constants/attributesForTests';

import styles from '../../styles/dialog/airline.module.css';

const LABELS = {
  TITLE: getText('favorites:dialogs.airline.title'),
  START_SEARCH: getText('favorites:dialogs.airline.startSearch'),
  CANCEL: getText('common:undo'),
  THERE: getText('favorites:dialogs.airline.there'),
  BACK: getText('favorites:dialogs.airline.back'),
  TRAVELLERS: getText('favorites:dialogs.airline.travellers'),
};

const FIELDS = {
  FROM: 'dateFrom',
  TO: 'dateTo',
};

const TRAVELERS = generateTravelers(9);

const createState = () => {
  const currentDate = getMoment();
  const { start, end, minStart } = createDateFromTo(currentDate);

  return {
    dateFrom: start,
    dateTo: end,
    minFrom: minStart,
    travelers: TRAVELERS[0].value,
  };
};

class AirlineSearchStartDialog extends Component {
  static propTypes = {
    isBothSide: PropTypes.bool,
    onSearch: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    qaAttrs: PropTypes.object,
  };

  static defaultProps = {
    qaAttrs: {
      wrapper: '',
      header: '',
      date: '',
      passengers: '',
      buttons: {
        startSearch: '',
        cancel: '',
      },
    },
  };

  state = {
    ...createState(),
  };

  handleChangeState = data => this.setState(state => ({
    ...state,
    ...data,
  }));

  handleChangeDate = (field, value) => {
    if (field === FIELDS.FROM) {
      const { dateTo } = this.state;
      const { end, minStart } = createDateFromTo(value);

      const dateBack = isSameOrAfter(value, dateTo)
        ? end
        : dateTo;

      return this.handleChangeState({
        dateFrom: value,
        dateTo: dateBack,
        minFrom: minStart,
      });
    }

    return this.handleChangeState({ [field]: value });
  };

  render() {
    const { dateFrom, dateTo, minFrom, travelers } = this.state;
    const { isBothSide, onSearch, onClose, qaAttrs } = this.props;

    const backDateHtml = isBothSide && (
      <Datepicker
        placeholder={ LABELS.BACK }
        inputClassName={ styles.input }
        wrapperClassName={ styles.back }
        value={ dateTo }
        min={ dateFrom }
        onChange={ value => this.handleChangeDate(FIELDS.TO, value) }
      />
    );

    return (
      <div className={ styles.wrapper }>
        <Text type={ PROPS.TEXT.TYPES.BOLD_20 } qaAttr={ qaAttrs.header }>
          { LABELS.TITLE }
        </Text>
        <div className={ styles.dates }>
          <Datepicker
            placeholder={ LABELS.THERE }
            inputClassName={ styles.input }
            wrapperClassName={ styles.there }
            value={ dateFrom }
            min={ minFrom }
            onChange={ value => this.handleChangeDate(FIELDS.FROM, value) }
            qaAttr={ qaAttrs.date }
            qaAttrNextMonth={ QA_ATTRIBUTES.favorites.chooseDateDialog.checkInNextMonth }
          />
          { backDateHtml }
        </div>
        <Select
          withLabel
          theme={ PROPS.SELECT.THEMES.DEFAULT_BORDER }
          className={ styles.select }
          value={ travelers }
          placeholder={ LABELS.TRAVELLERS }
          items={ TRAVELERS }
          onChange={ value => this.handleChangeState({ travelers: value }) }
          qaAttr={ qaAttrs.passengers }
        />
        <div className={ styles.action }>
          <Button
            onClick={ () => onSearch({ dateFrom, dateTo, travelers, withNumber: true }) }
            qaAttr={ qaAttrs.buttons.startSearch }
          >
            { LABELS.START_SEARCH }
          </Button>
          <Button
            className={ styles.cancel }
            type={ PROPS.BUTTON.TYPES.TEXTUAL }
            onClick={ onClose }
            qaAttr={ qaAttrs.buttons.cancel }
          >
            { LABELS.CANCEL }
          </Button>
        </div>
      </div>
    );
  }
}

export default AirlineSearchStartDialog;
