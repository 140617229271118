import React from 'react';
import { observer } from 'mobx-react';
import { Button, DotLoading, Text } from 'new-ui';
import { CellComponent } from 'new-ui/src/components/UniversalTable/types';

import { useServices } from '../../../../../bi/context/services';

import { StatusCondition } from '../../../../../components/StatusCondition';

import { LABELS } from './consts';

import { LoadingFields } from '../../../../../bi/services/signature';
import { LoadingStatus } from '../../../../../bi/services/utils/network/types';
import { DocumentType } from '../../../../../bi/services/employee/types';
import { IDocumentInfo } from '../../../../../bi/services/signature/types';

import styles from './styles.module.css';

interface IDocumentNameCellBaseProps {
  value: string
  onDownload: () => void
  onShowDoc: () => void
  docDownload?: LoadingStatus
  isThisDoc?: boolean
}

interface ISSDNameCellProps {
  onShowDocument: (documentId: string, employeeIds: number) => void
  onDownloadDocument: (documentId: string, employeeIds: number) => void
}

export const DocumentNameCellBase = observer(({
  value,
  onDownload,
  onShowDoc,
  isThisDoc = false,
  docDownload = LoadingStatus.NEVER,
}: IDocumentNameCellBaseProps) => {
  const DownloadButton = () => (
    <Button type='textual' onClick={ onDownload }>
      { LABELS.DOWNLOAD }
    </Button>
  );

  const Component = isThisDoc ? DotLoading : DownloadButton;

  return (
    <Text type='NORMAL_16_140' className={ styles.documentNameCell }>
      <div className={ styles.documentNameCellName }>
        { value }
      </div>
      <div className={ styles.documentNameCellActions }>
        <Button type='textual' onClick={ onShowDoc }>
          { LABELS.SHOW }
        </Button>
        <StatusCondition
          status={ docDownload }
          onLoading={ Component }
          fallback={ DownloadButton }
        />
      </div>
    </Text>
  );
});

export const ESDNameCell: CellComponent<IDocumentInfo, 'name'> = ({ row, fieldValue }) => {
  const { signatureService } = useServices(['Signature']);

  const docDownload = signatureService.netStore.getStatus(LoadingFields.downloadDocument);
  const isThisDoc = signatureService.enhancedSignatureStore.downloadDocIds.includes(row.documentId);

  const handleShowDoc = () => signatureService.callDocumentPreview(row.documentId);

  const handleDownload = () => signatureService.downloadDocument(row.documentId);

  return <DocumentNameCellBase
    value={ fieldValue }
    onDownload={ handleDownload }
    onShowDoc={ handleShowDoc }
    docDownload={ docDownload }
    isThisDoc={ isThisDoc }
  />;
};

export const SSDNameCell: CellComponent<DocumentType, 'name', ISSDNameCellProps> = ({
  row, fieldValue, onShowDocument, onDownloadDocument,
}) => {
  const handleShowDoc = () => onShowDocument(row.documentId, row.employeeIds[0]);

  const handleDownload = () => onDownloadDocument(row.documentId, row.employeeIds[0]);

  return <DocumentNameCellBase
    value={ fieldValue }
    onDownload={ handleDownload }
    onShowDoc={ handleShowDoc }
  />;
};
