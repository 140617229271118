import React, { useEffect, useState } from 'react';
import { E164Number } from 'libphonenumber-js';
import { observer } from 'mobx-react';
import { Input, Button, LinkButton, Text, Checkbox, Dialog } from 'new-ui';

import { getText } from '../../../../../../../i18n';

import { useStores } from '../../../../../../bi/context';
import { MOBX_STORES } from '../../../../../../bi/context/stores';

import BonusProgramService from '../../../../../../bi/services/bonusProgram';

import { InputPhone } from '../../../../../../components/InputPhone';
import { FieldLabel } from '../../../../../../components/FieldLabel';
import { ExternalLink } from '../../../../../../components/ExternalLink';

import { isEmail } from '../../../../../../bi/utils/validation';

import styles from './index.module.css';

const LABELS = {
  REGISTRATION: getText('settings:bonusProgram.promo.registration.title'),
  REGISTRATION_FAILED: getText('settings:bonusProgram.promo.registration.registrationFailed'),
  DESCRIPTION: getText('settings:bonusProgram.promo.registration.description'),
  DESCRIPTION_CONTINUE: getText('settings:bonusProgram.promo.registration.descriptionContinue'),
  ALREADY_REGISTER: getText('settings:bonusProgram.promo.registration.alreadyRegister'),
  PERFECT: getText('settings:bonusProgram.promo.registration.perfect'),
  CLOSE: getText('settings:bonusProgram.promo.registration.close'),
  EMAIL: getText('settings:bonusProgram.promo.registration.email'),
  TELEPHONE: getText('settings:bonusProgram.promo.registration.phone'),
  FIRSTNAME: getText('settings:bonusProgram.promo.registration.firstName'),
  LASTNAME: getText('settings:bonusProgram.promo.registration.lastName'),
  BACK: getText('settings:bonusProgram.promo.registration.back'),
  TOURISM_ERROR_MODAL: {
    DESCRIPTION: getText('settings:bonusProgram.promo.registration.tourismErrorModal.description'),
    RULES: getText('settings:bonusProgram.promo.registration.tourismErrorModal.rules'),
    PROGRAM: getText('settings:bonusProgram.promo.registration.tourismErrorModal.program'),
    IF_ERROR: getText('settings:bonusProgram.promo.registration.tourismErrorModal.ifError'),
    TRAVEL_HELPER: getText('settings:bonusProgram.promo.registration.tourismErrorModal.travelHelper'),
  },
  IN_ERROR_MODAL: {
    ALREADY: (inn: string | undefined) => getText('settings:bonusProgram.promo.registration.innErrorModal.already', { inn }),
    CODE_MEMBER: getText('settings:bonusProgram.promo.registration.innErrorModal.codeMember'),
    HERE: getText('settings:bonusProgram.promo.registration.innErrorModal.here'),
    DESCRIPTION: getText('settings:bonusProgram.promo.registration.innErrorModal.description'),
    FREE_OR_EMAIL: getText('settings:bonusProgram.promo.registration.innErrorModal.freeOrEmail'),
  },
  SUCCESS_MODAL: {
    CONGRATULATIONS: getText('settings:bonusProgram.promo.registration.successModal.congratulations'),
    SUCCESS: getText('settings:bonusProgram.promo.registration.successModal.success'),
    ON_EMAIL: getText('settings:bonusProgram.promo.registration.successModal.onEmail'),
    LINK_WITH_ACCESS: getText('settings:bonusProgram.promo.registration.successModal.linkWithAccess'),
  },
  EMAIL_WARNING_MODAL: {
    ALREADY: getText('settings:bonusProgram.promo.registration.emailWarningModal.already'),
    YOU_MAY_PUT_CODE: getText('settings:bonusProgram.promo.registration.emailWarningModal.youMayPutCode'),
    HERE: getText('settings:bonusProgram.promo.registration.emailWarningModal.here'),
    ALREADY_NO_CORPOTATE: getText('settings:bonusProgram.promo.registration.emailWarningModal.alreadyNoCorporate'),
    ATTENTION: getText('settings:bonusProgram.promo.registration.emailWarningModal.attention'),
  },
  ACCEPTABLE_CHECKBOX: {
    ACCEPT_WITH: getText('settings:bonusProgram.promo.registration.acceptableCheckbox.acceptWith'),
    RULES_AND_CONDITIONS: getText('settings:bonusProgram.promo.registration.acceptableCheckbox.rulesAndConditions'),
    S7_PROGRAM: getText('settings:bonusProgram.promo.registration.acceptableCheckbox.s7Program'),
  },
};

const ERRORLABELS = {
  PHONE: getText('settings:bonusProgram.promo.registration.errorLabels.phone'),
  EMAIL: getText('settings:bonusProgram.promo.registration.errorLabels.email'),
};

const ERRORTYPES = {
  INN: 'InnRegisteredError',
  TOURISM: 'TourismError',
  UNEXPECTED: 'UnexpectedError',
};

interface IRegistration {
  bonusProgramService: BonusProgramService,
  onRegistrationSuccess(): void,
  handleOpenChat(): void,
  handleBackToAddingCode(): void,
  handleBack(): void,
  userEmail: string,
  userPhone: string,
}

const Registration = observer(({
  bonusProgramService,
  onRegistrationSuccess,
  handleOpenChat,
  handleBackToAddingCode,
  handleBack,
  userEmail: defaultUserEmail,
  userPhone: defaultUserPhone,
}: IRegistration) => {
  const { bonusProgramStore } = useStores([MOBX_STORES.BONUS_PROGRAM]);
  const {
    registration: {
      Error,
      Inn,
      Company,
      loading,
    },
    email: {
      Corporate,
      Individual,
    },
  } = bonusProgramStore;

  const [userEmail, setUserEmail] = useState(defaultUserEmail);
  const [userPhone, setUserPhone] = useState<E164Number | string>(defaultUserPhone);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [acceptedRules, setAcceptedRules] = useState(false);
  const [showSuccessRegModal, setShowSuccessRegModal] = useState(false);
  const [showErrRegModal, setShowErrRegModal] = useState(false);
  const [phoneIsValid, setPhoneIsValid] = useState(true);
  const [emailIsValid, setEmailIsValid] = useState(false);

  const handleCheckEmail = () => {
    const isValid = isEmail(userEmail);

    setEmailIsValid(isValid);

    if (!userEmail || !isValid) {
      return;
    }

    const data = {
      Email: userEmail,
    };

    bonusProgramService.checkEmail(data);
  };

  useEffect(handleCheckEmail, [bonusProgramService, userEmail]);

  const handleSendRegistration = async () => {
    const data = {
      Email: userEmail,
      Phone: userPhone as string,
      FirstName: firstName,
      LastName: lastName,
    };

    try {
      await bonusProgramService.bonusRegistration(data);
    } catch {
      // TODO: handling?
    }

    const { store: { registration: { Error: updatedErr, error } } } = bonusProgramService;

    if (!updatedErr && !error) {
      setShowSuccessRegModal(true);

      return;
    }

    if (updatedErr === ERRORTYPES.UNEXPECTED || error) {
      return; // В этом случае не показываем никакой ошибки, но создается чатик интерком.
    }

    setShowErrRegModal(true);
  };

  const handleOpenIntercom = () => {
    setShowErrRegModal(false);

    handleOpenChat();
  };

  const renderTourismError = () => (
    <>
      <Text className={ styles.description }>
        { LABELS.TOURISM_ERROR_MODAL.DESCRIPTION }
        {' '}
        <ExternalLink
          link='https://www.s7.ru/home/about/rules/s7profi-rules.dot'
          text={ LABELS.TOURISM_ERROR_MODAL.RULES }
        />
        {' '}
        { LABELS.TOURISM_ERROR_MODAL.PROGRAM }
      </Text>
      <Text
        className={ styles.description }
        type='NORMAL_16_140'
      >
        { LABELS.TOURISM_ERROR_MODAL.IF_ERROR }
        {' '}
        <LinkButton onClick={ handleOpenIntercom }>
          { LABELS.TOURISM_ERROR_MODAL.TRAVEL_HELPER }
        </LinkButton>
        {'.'}
      </Text>
    </>
  );

  const renderInnError = () => (
    <div>
      <Text className={ styles.description }>
        {Company} { LABELS.IN_ERROR_MODAL.ALREADY(Inn) }
      </Text>
      <Text className={ styles.description }>
        { LABELS.IN_ERROR_MODAL.CODE_MEMBER }
        <LinkButton onClick={ handleBackToAddingCode }>
          { LABELS.IN_ERROR_MODAL.HERE }
        </LinkButton>
        {'.'}
      </Text>
      <Text className={ styles.description }>
        { LABELS.IN_ERROR_MODAL.DESCRIPTION } <b>8 800 500 5077</b>
        { LABELS.IN_ERROR_MODAL.FREE_OR_EMAIL }<b>corp.support@s7.ru</b>
      </Text>
    </div>
  );

  const renderErrorRegistrationModal = () => {
    const MODALTYPES = {
      [ERRORTYPES.TOURISM]: renderTourismError(),
      [ERRORTYPES.INN]: renderInnError(),
      [ERRORTYPES.UNEXPECTED]: null,
    };

    const errorTitle = Error === ERRORTYPES.TOURISM ? LABELS.REGISTRATION_FAILED : LABELS.ALREADY_REGISTER;
    const content = Error && MODALTYPES[Error];

    return (
      <Dialog
        show={ showErrRegModal }
        onChange={ () => setShowErrRegModal(false) }
      >
        <div className={ styles.modal }>
          <Text
            type='bold_24'
            className={ styles.title }
          >
            { errorTitle }
          </Text>
          { content }
          <div className={ styles.actions }>
            <Button
              className={ styles['success-button'] }
              type='secondary'
              onClick={ () => setShowErrRegModal(false) }
            >
              { LABELS.CLOSE }
            </Button>
          </div>
        </div>
      </Dialog>
    );
  };

  const successRegistrationModal = (
    <Dialog
      outsideClickClosing={ false }
      show={ showSuccessRegModal }
      onChange={ () => setShowSuccessRegModal(false) }
    >
      <div className={ styles.modal }>
        <Text
          type='bold_24'
          className={ styles.title }
        >
          { LABELS.SUCCESS_MODAL.CONGRATULATIONS }
        </Text>
        <Text className={ styles.description }>
          { LABELS.SUCCESS_MODAL.SUCCESS }
        </Text>
        <Text className={ styles.description }>
          { LABELS.SUCCESS_MODAL.ON_EMAIL }
          {' '}
          <b>{ userEmail }</b>
          {' '}
          { LABELS.SUCCESS_MODAL.LINK_WITH_ACCESS }
        </Text>
        <div className={ styles.actions }>
          <Button
            className={ styles['success-button'] }
            type='secondary'
            onClick={ onRegistrationSuccess }
          >
            { LABELS.PERFECT }
          </Button>
        </div>
      </div>
    </Dialog>
  );

  const renderInput = (label: string, value: string, onChange: (v: string) => void) => (
    <div className={ styles['wrap-input'] }>
      <FieldLabel text={ label } />
      <Input
        value={ value }
        onChange={ onChange }
      />
    </div>
  );

  const renderEmailWarning = () => {
    if (Corporate) {
      return (
        <Text
          color='red'
          type='NORMAL_12'
          className={ styles['email-warning'] }
        >
          { LABELS.EMAIL_WARNING_MODAL.ALREADY } <br/>
          { LABELS.EMAIL_WARNING_MODAL.YOU_MAY_PUT_CODE }
          {' '}
          <LinkButton
            theme='red'
            onClick={ handleBackToAddingCode }
          >
            <Text
              type='NORMAL_12'
              color='red'
            >
              { LABELS.EMAIL_WARNING_MODAL.HERE }
            </Text>
          </LinkButton>
        </Text>
      );
    }

    return (
      <Text
        color='gray'
        type='NORMAL_12'
        className={ styles['email-warning'] }
      >
        { LABELS.EMAIL_WARNING_MODAL.ALREADY_NO_CORPOTATE } <br/>
        { LABELS.EMAIL_WARNING_MODAL.ATTENTION }
      </Text>
    );
  };

  const renderInputContent = () => {
    const emailWarningHtml = (Individual || Corporate) && renderEmailWarning();

    const errorEmail = !emailIsValid ? ERRORLABELS.EMAIL : '';
    const phoneError = !phoneIsValid ? ERRORLABELS.PHONE : '';

    return (
      <>
        <div className={ styles['wrap-input'] }>
          <FieldLabel text={ LABELS.EMAIL }/>
          <Input
            value={ userEmail }
            onChange={ setUserEmail }
            onBlur={ handleCheckEmail }
            error={ errorEmail }
          />
        </div>
        { emailWarningHtml }
        <div className={ styles['wrap-input'] }>
          <FieldLabel text={ LABELS.TELEPHONE } />
          <InputPhone
            value={ userPhone as string }
            onBlur={ setPhoneIsValid }
            onChange={ setUserPhone }
            error={ phoneError }
          />
        </div>
        { renderInput(LABELS.FIRSTNAME, firstName, setFirstName) }
        { renderInput(LABELS.LASTNAME, lastName, setLastName) }
      </>
    );
  };

  const acceptableCheckbox = (
    <Checkbox
      className={ styles.checkbox }
      value={ acceptedRules }
      onChange={ setAcceptedRules }
    >
      { LABELS.ACCEPTABLE_CHECKBOX.ACCEPT_WITH }
      {' '}
      <ExternalLink
        link='https://www.s7.ru/home/about/rules/s7profi-rules.dot'
        text={ LABELS.ACCEPTABLE_CHECKBOX.RULES_AND_CONDITIONS }
      />
      {' '}
      { LABELS.ACCEPTABLE_CHECKBOX.S7_PROGRAM }
    </Checkbox>
  );

  const renderMainActionButtons = () => {
    const isNotDisabled =
      !!userEmail && !!userPhone && !!firstName && !!lastName && !!acceptedRules && phoneIsValid && !Corporate && emailIsValid;

    return (
      <div className={ styles.actions }>
        <Button
          type='secondary'
          disabled={ !isNotDisabled }
          loading={ loading }
          onClick={ handleSendRegistration }
        >
          { LABELS.REGISTRATION }
        </Button>
        <LinkButton
          className={ styles.secondary }
          theme='blue-without-border'
          onClick={ handleBack }
        >
          <Text
            color='accent'
            type='NORMAL_14'
          >
            { LABELS.BACK }
          </Text>
        </LinkButton>
      </div>
    );
  };

  return (
    <>
      <Text
        type='NORMAL_16_140'
        className={ styles.description }
      >
        { LABELS.DESCRIPTION } <br/>
        { LABELS.DESCRIPTION_CONTINUE }
      </Text>
      { renderInputContent() }
      { acceptableCheckbox }
      { renderMainActionButtons() }
      { successRegistrationModal }
      { renderErrorRegistrationModal() }
    </>
  );
});

export { Registration };
