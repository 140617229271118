// @ts-nocheck
import { SERVICETYPE, SERVICETYCASE } from '../../constants/serviceType';
import Store from '../../store';

import ACTION from './action';

const EMPTYCART = 'Корзина пуста';
const NODATA = 'Нет данных';

const createNewState = {
  progress: 0,
  bookingId: null,
  services: null,
  errors: [],
  status: 'InProgress',
  statuses: [],
  startDate: '',
  cart: {},
  cartItemByAnalogRooms: {},
  errorMessages: [],
};

const correctServiceName = (service, msg) => type => `${SERVICETYCASE[service](type)} ${msg}`;

const getFullServiceName = (service) => {
  const fullName = service.Employees.map(employee => `${employee.Employee.Surname} ${employee.Employee.Name}`);

  let serviceName = '';
  switch (service.ServiceType) {
    case SERVICETYPE.AIR: {
      const data = JSON.parse(service.Data);
      const places = data.Routes
        .map(route => `${route.Segments[0].DepartureCity}-${route.Segments[route.Segments.length - 1].ArrivalCity}`);
      serviceName = `${places.join('; ')}`;
      break;
    }
    case SERVICETYPE.HOTEL: {
      const data = JSON.parse(service.Data);
      serviceName = `${data.hotel.Name}`;
      break;
    }
    case SERVICETYPE.TRAIN: {
      const data = JSON.parse(service.Data);
      serviceName = `${data.StationDepart} ${data.StationArrive}`;
      break;
    }
    case SERVICETYPE.TRANSFER: {
      const data = JSON.parse(service.Data);
      serviceName = `${data.StartPlace.Name} ${data.EndPlace.Name}`;
      break;
    }
  }

  return correctServiceName(service.ServiceType, `${serviceName} ${fullName.join(' ')}`);
};

const reducer = (action, state) => {
  let newState = null;

  switch (action.type) {
    case ACTION.UPDATECART: {
      newState = {
        ...state,
        cart: action.payload,
      };

      break;
    }
    case ACTION.LOADCART: {
      if (!action.payload.Items.length) {
        newState = {
          ...state,
          errors: [
            ...state.errors,
            { Message: EMPTYCART },
          ],
        };
      } else {
        const services = action.payload.Items.map(item => ({ value: getFullServiceName(item) }));

        newState = {
          ...state,
          ...createNewState,
          services,
          errors: [],
          totalService: services.length,
          cartItems: action.payload.Items,
          cart: action.payload,
        };
      }

      break;
    }
    case ACTION.UPDATESTATUSV2: {
      newState = {
        ...state,
        status: action.payload.CartBookingStatus,
        statuses: action.payload.Items,
        trip: action.payload.Trip,
        trips: action.payload.Trips,
        startDate: action.payload.StartDate,
      };

      break;
    }
    case ACTION.STARTBOKING: {
      if (action.payload) {
        newState = {
          ...state,
          bookingId: action.payload.bookId,
          errorMessages: action.payload.errorMessages,
        };
      } else {
        const err = action.err || NODATA;
        newState = {
          ...state,
          errors: [
            ...state.errors,
            { Message: err },
          ],
        };
      }

      break;
    }
    case ACTION.CONTINUEBOOKING: {
      newState = {
        ...state,
        errorMessages: action.payload.errorMessages,
      };

      break;
    }
    case ACTION.UPDATEERROR: {
      newState = {
        ...state,
        errors: [
          ...state.errors,
          ...action.payload,
        ],
      };

      break;
    }
    case ACTION.RESET: {
      newState = {
        ...createNewState,
      };

      break;
    }
    case ACTION.LOADANALOGROOMS: {
      const { cartItemId, analogRooms } = action.payload;

      const preparedAnalogRooms = list => list
        .sort(({ Price: first }, { Price: second }) => first - second)
        .map((listItem, index) => ({
          ...listItem,
          checked: index === 0,
        }));

      const cartItemByAnalogRooms = {
        ...state.cartItemByAnalogRooms,
        [cartItemId]: [...preparedAnalogRooms(analogRooms)],
      };

      newState = {
        ...state,
        cartItemByAnalogRooms,
      };

      break;
    }
    case ACTION.UPDATEANALOGROOMS: {
      const { roomId, cartItemId, value } = action.payload;
      const { cartItemByAnalogRooms } = state;

      let mappedAnalogRooms = cartItemByAnalogRooms[cartItemId];

      Object.keys(cartItemByAnalogRooms).forEach((id) => {
        if (Number(id) === cartItemId) {
          mappedAnalogRooms = cartItemByAnalogRooms[id].map((item) => ({
            ...item,
            checked: item.Guid === roomId ? value : !value,
          }));
        }
      });

      newState = {
        ...state,
        cartItemByAnalogRooms: {
          ...state.cartItemByAnalogRooms,
          [cartItemId]: [...mappedAnalogRooms],
        },
      };

      break;
    }

    default: {
      newState = state;

      break;
    }
  }

  return newState;
};

const createStore = () => new Store(reducer, createNewState);

export default createStore;
