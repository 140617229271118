// @ts-nocheck
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Prompt } from 'react-router-dom';
import { observer } from 'mobx-react';
import { Select, Datepicker, Text, PROPS, BackLink } from 'new-ui';
import { getText } from '../../../i18n';

import OperationsTable from '../../components/OperationsTable';

import { isSmartAgent } from '../../bi/utils/env';

import { PAYMENT } from '../../bi/constants/payment';

import { withStores } from '../../bi/context';
import { MOBX_STORES } from '../../bi/context/stores';

import styles from './styles/transactions.module.css';

const LABELS = {
  TITLE: getText('reports:transactions.title'),
  BACK_LINK: getText('reports:transactions.back'),
};

@withStores([MOBX_STORES.CLOUD_PAYMENTS])
@observer
class TransactionReport extends Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    reportService: PropTypes.object.isRequired,
    notificationService: PropTypes.object.isRequired,
    workspaceService: PropTypes.object.isRequired,
    paymentService: PropTypes.object.isRequired,
    stores: PropTypes.shape({
      cloudPaymentsStore: PropTypes.object.isRequired,
    }),
  };

  constructor(props) {
    super(props);

    const { items, total, more, startDate, endDate, maxDate } = props.reportService.getOperations();
    const { Multi, Companies, CompanyName, Email, CompanyInn, CompanyId, IsDemo } = props.workspaceService.get();

    const companiesItems = Multi ? Companies.filter(({ IsShowReporting }) => IsShowReporting).map(value => ({
      label: value.ShortCompanyName || value.CompanyName,
      value: value.CompanyId,
    })) : [];

    const currentCompany = parseInt(props.match.params.companyId, 10);
    const showFinanceDetails = this.showFinanceDetails(Companies, currentCompany);

    this.state = {
      loading: true,
      companiesItems,
      currentCompany,
      total,
      items,
      more,
      startDate,
      endDate,
      maxDate,
      showFinanceDetails,
      companyName: CompanyName,
      companyEmail: Email,
      companyInn: CompanyInn,
      companyId: CompanyId,
      companies: Companies,
      isDemo: IsDemo,
      multi: Multi,
    };
  }

  componentDidMount() {
    const { reportService } = this.props;

    this.unsubscribeFn = reportService.subscribeOperations(this.updateOperations);

    const { startDate, endDate, currentCompany } = this.state;

    reportService.loadTotalOperationsByDate(currentCompany, startDate, endDate);
  }

  componentDidUpdate() {
    window.onbeforeunload = this.props.stores.cloudPaymentsStore.cantLeave ? (e) => {
      e.preventDefault();

      return '';
    } : undefined;
  }

  componentWillUnmount() {
    if (this.unsubscribeFn) this.unsubscribeFn();

    window.onbeforeunload = undefined;
  }

  updateOperations = ({ loading, items, total, more }) => {
    this.setState({
      loading,
      items,
      total,
      more,
    });
  };

  loadOperations = () => {
    const { startDate, endDate, currentCompany } = this.state;

    this.props.reportService.loadTotalOperationsByDate(currentCompany, startDate, endDate);
  };

  handleLoadOperations = () => {
    this.setState({
      loading: true,
    }, this.loadOperations());
  };

  handleChangeDate = (field, value) => {
    const currentValue = this.state[field];

    if (currentValue && !currentValue.isSame(value)) {
      this.setState({ [field]: value }, this.handleLoadOperations);
    }
  };

  handleChangeCompanyId = currentCompany => this.setState({ currentCompany }, this.handleLoadOperations);

  showFinanceDetails = (companies, companyId) => {
    const company = companies.find(item => item.CompanyId === companyId);

    return !!company && !!company.ShowFinanceDetails;
  };

  handleChangeShowFinanceDetails = (companyId) => {
    const { companies } = this.state;

    this.setState({
      showFinanceDetails: this.showFinanceDetails(companies, companyId),
    });
  };

  handleChangeCompany = (value) => {
    this.handleChangeCompanyId(value);
    this.handleChangeShowFinanceDetails(value);
  };

  renderCompanySelect() {
    const { companiesItems, currentCompany } = this.state;

    if (!companiesItems?.length) {
      return null;
    }

    return (
      <div className={ styles.select_wrap }>
        <Select
          className={ styles.select }
          items={ companiesItems }
          value={ currentCompany }
          onChange={ this.handleChangeCompany }
          theme={ PROPS.SELECT.THEMES.DEFAULT_LARGE }
          excludeValue
          smooth
        />
      </div>
    );
  }

  renderDatePickers = () => {
    const { startDate, endDate, maxDate } = this.state;

    return (
      <div className={ styles.calendars_wrap }>
        <Datepicker
          value={ startDate }
          onChange={ (value) => this.handleChangeDate('startDate', value) }
          maxDate={ maxDate }
          className={ styles.startDate }
          inputTheme={ PROPS.DATEPICKER.INPUT_THEMES.OPEN }
          inputClassName={ styles.input_wrap }
          isDuration
          durationDates={ [startDate, endDate] }
        />
        &mdash;
        <Datepicker
          value={ endDate }
          onChange={ (value) => this.handleChangeDate('endDate', value) }
          minDate={ startDate }
          maxDate={ maxDate }
          className={ styles.endDate }
          inputTheme={ PROPS.DATEPICKER.INPUT_THEMES.OPEN }
          inputClassName={ styles.input_wrap }
          isDuration
          durationDates={ [startDate, endDate] }
        />
      </div>
    );
  };

  render() {
    const { reportService, notificationService, paymentService, stores: { cloudPaymentsStore: { cantLeave } } } = this.props;
    const {
      items,
      total,
      loading,
      companiesItems,
      currentCompany,
      showFinanceDetails,
      companyEmail,
      companyName,
      companyInn,
      isDemo,
      companies,
      multi,
    } = this.state;

    const companiesHtml = this.renderCompanySelect();
    const datePickersHtml = this.renderDatePickers();

    return (
      <div className={ styles.wrap }>
        <Prompt
          when={ cantLeave }
          message={ PAYMENT.EXITMESSAGE }
        />
        <BackLink
          link={ '/reports/main' }
          text={ LABELS.BACK_LINK }
          alternativeDesign={ isSmartAgent }
        />
        <div className={ styles.main }>
          <Text type={ PROPS.TEXT.TYPES.BOLD_32 } className={ styles.title }>{LABELS.TITLE}</Text>
          { datePickersHtml }
          { companiesHtml }
        </div>
        <OperationsTable
          multi={ multi }
          items={ items }
          total={ total }
          loading={ loading }
          currentCompany={ currentCompany }
          depositInvoice={ false }
          reportService={ reportService }
          paymentService={ paymentService }
          notRenderDetails
          sendNotification={ opts => notificationService.send(opts) }
          showFinanceDetails={ showFinanceDetails }
          companyEmail={ companyEmail }
          companyName={ companyName }
          companyInn={ companyInn }
          companiesItems={ companiesItems }
          isDemo={ isDemo }
          allCompanies={ companies }
        />
      </div>
    );
  }
}

export default TransactionReport;
