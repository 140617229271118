import { UUID } from 'node:crypto';

import { api } from '../../apiV2';

import { enhancedSignatureStore } from './store';

import NetworkStatesStore from '../utils/network/networkStatesStore';
import { bound, withIM } from '../utils/dectrators';

import { EnhancedSignatureModalTypes } from './types';

export enum LoadingFields {
  loadEnhancedDocList,
  sendToEmail,
  sendToGov,
  downloadReport,
  checkGovIntegration,
  govOauthCall,
  static,
  employeeAutocomplete,
  callDocumentPreview,
  downloadDocument,
}

export class Signature {
  netStore = new NetworkStatesStore<LoadingFields>({ contextRecord: true });

  enhancedSignatureStore = enhancedSignatureStore;

  @bound
  @withIM((o) => o.netStore.withLoaderFlow(LoadingFields.loadEnhancedDocList))
  async loadEnhancedDocList() {
    const signList = await api.signature.getList(
      this.enhancedSignatureStore.listInputParams,
    );
    this.enhancedSignatureStore.setSignListData(signList);
  }

  @bound
  @withIM((o) => o.netStore.withLoaderFlow(LoadingFields.sendToEmail))
  async sendToEmail(email: string) {
    const docId = this.enhancedSignatureStore.sendDocId;

    if (docId) {
      await api.signature.sendToEmail(
        email,
        docId,
      );
    }
  }

  @bound
  @withIM((o) => o.netStore.withLoaderFlow(LoadingFields.sendToGov))
  async sendToGov() {
    await api.signature.sendToGov(
      this.enhancedSignatureStore.selectedIds,
    );
  }

  @bound
  @withIM((o) => o.netStore.withLoaderFlow(LoadingFields.downloadReport))
  async downloadReport() {
    const employeeId = this.enhancedSignatureStore.listInputParams.employeeId;

    if (employeeId) {
      await api.signature.downloadReport(
        employeeId,
      );
    }
  }

  @bound
  @withIM((o) => o.netStore.withLoaderFlow(LoadingFields.checkGovIntegration))
  async checkGovIntegration() {
    const isEnabled = await api.signature.getIntegration();
    this.enhancedSignatureStore.setIntegrationEnabled(isEnabled);
  }

  @bound
  @withIM((o) => o.netStore.withLoaderFlow(LoadingFields.govOauthCall))
  async govOauthCall() {
    window.location.href = await api.signature.getLink(window.location.href);
  }

  @bound
  @withIM(o => o.netStore.withAbortRequestAxios(LoadingFields.employeeAutocomplete))
  @withIM(o => o.netStore.withErrorBoundary(LoadingFields.employeeAutocomplete))
  async employeeAutocomplete(query: string, onlyAdult = true) {
    const autocomplete = await api
      .employee
      .autocomplete(
        query.trim(), onlyAdult, this.netStore.getAbortTokenAxios(LoadingFields.employeeAutocomplete),
      );

    return this.enhancedSignatureStore.setAutocompleteEmployeeList(autocomplete);
  }

  @bound
  @withIM(o => o.netStore.withLoaderFlow(LoadingFields.callDocumentPreview))
  async callDocumentPreview(docId: UUID) {
    this.enhancedSignatureStore.openModal(EnhancedSignatureModalTypes.docPreview);

    const docs = await api.signature.downloadDocPreview(docId);
    this.enhancedSignatureStore.setPreviewDocs(docs);
  }

  @bound
  @withIM(o => o.netStore.withLoaderFlowCoroutine(LoadingFields.downloadDocument))
  async downloadDocument(docId: UUID) {
    this.enhancedSignatureStore.switchDownloadDocID(docId);
    await api.signature.downloadDoc(docId);
    this.enhancedSignatureStore.switchDownloadDocID(docId);
  }
}
