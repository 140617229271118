const ACTION = {
  UPDATEHEADER: 'update.header',
  UPDATEHEADERPARTIAL: 'update.header.partial',
  UPDATEAPPSTATE: 'app.state',
  LOGIN: 'login',
  SHOWRECONNECT: 'show.reconnect.dialog',
  HIDERECONNECT: 'hide.reconnect.dialog',
};

export default ACTION;
