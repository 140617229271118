// @ts-nocheck
import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { PageLoader } from 'new-ui';

import { getText } from '../../../../../../i18n';

import { useStores } from '../../../../../bi/context';
import { MOBX_STORES } from '../../../../../bi/context/stores';

import ROUTES from '../../../../../bi/constants/routes';

import { ChartAnalyticsNoResult } from '../../noResult';
import { TwoCharts } from '../../common/firstPages/twoBarCharts';

const LABELS = {
  TICKETS: getText('chartsAnalytics:commonChart.booking'),
};

const ChartAnalyticsStructureProjects = observer(({ chartsAnalyticsService }) => {
  const {
    chartsAnalyticsSummaryStore,
    chartsAnalyticsStore: { filtersType, filters: { employees, departments, projects, analytics, tags } },
  } = useStores([MOBX_STORES.CHARTS_ANALYTICS_SUMMARY_STORE, MOBX_STORES.CHARTS_ANALYTICS_STORE]);

  const {
    chart,
    donutChart,
    tableInfo: {
      labelsHeader,
      content,
    },
    loading,
  } = chartsAnalyticsSummaryStore;

  const { getStructureProjects, setShowFilterProject } = chartsAnalyticsService;

  useEffect(() => {
    getStructureProjects(filtersType);

    return () => setShowFilterProject(false);
  }, [
    getStructureProjects,
    setShowFilterProject,
    filtersType,
    employees.selected,
    departments.selected,
    projects.selected,
    analytics.selected,
    tags.selected,
  ]);

  const history = useHistory();

  const redirectToSubPage = () => history.push(ROUTES.CHARTS_ANALYTICS.STRUCTURE.PROJECTS_NESTED);

  const handleOpenNestedTablePage = (checkedId, index) => {
    const ids = [];

    if (index === 5) {
      content.forEach(({ id }, ind) => {
        if (ind >= index) ids.push(id);
      });
    } else {
      ids.push(checkedId);
    }

    chartsAnalyticsService.changeStructureProjectIds(ids);
    redirectToSubPage();
  };

  if (loading) {
    return <PageLoader />;
  }

  if (!chart.values.length && !content.length) {
    return <ChartAnalyticsNoResult />;
  }

  return (
    <TwoCharts
      labelDoughnut={ LABELS.TICKETS }
      chart={ chart }
      donutChart={ donutChart }
      labelsHeader={ labelsHeader }
      legend={ labelsHeader }
      content={ content }
      chartsAnalyticsService={ chartsAnalyticsService }
      redirectToSubPage={ handleOpenNestedTablePage }
    />
  );
});

ChartAnalyticsStructureProjects.propTypes = {
  chartsAnalyticsService: PropTypes.object.isRequired,
};

export { ChartAnalyticsStructureProjects };
