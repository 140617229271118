import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';
import { PageLoader } from 'new-ui';

import { getText } from '../../../../../../../i18n';

import { useStores } from '../../../../../../bi/context';
import { MOBX_STORES } from '../../../../../../bi/context/stores';

import ChartsAnalytics from '../../../../../../bi/services/chartsAnalytics';

import { CommonWithSubSections } from './commonWithSubSections';

import { MainAnalytic } from '../../../../../../bi/utils/analytics';

import SERVICETYPE from '../../../../../../bi/constants/serviceType';
import { TYPE_SMART_SERVICE_TYPE } from '../../../../../../bi/constants/chartsAnalytics';

const LABELS = {
  TOOLTIP: getText('chartsAnalytics:menuItems.smart.indexChart.tooltip.taxi.one'),
};

interface TaxiPageSubSectionsProps {
  chartsAnalyticsService: ChartsAnalytics,
  path: string
}

const TaxiPageSubSections = observer(({ chartsAnalyticsService, path }: TaxiPageSubSectionsProps) => {
  const {
    chartsAnalyticsSmartSummaryStore,
    chartsAnalyticsSmartSummaryStore: {
      loading,
    },
    chartsAnalyticsStore: {
      filtersType,
    },
  } = useStores([
    MOBX_STORES.CHARTS_ANALYTICS_SMART_SUMMARY_STORE,
    MOBX_STORES.CHARTS_ANALYTICS_STORE,
  ]);

  const {
    getSmartTaxiPage,
  } = chartsAnalyticsService;

  useEffect(() => {
    getSmartTaxiPage();
  }, [getSmartTaxiPage, filtersType]);

  const history = useHistory();

  if (loading) {
    return <PageLoader />;
  }

  const handleOpen = ({ type, label }: { type: string, label: string }) => {
    const analyticType = ({
      [TYPE_SMART_SERVICE_TYPE.TIMELINESS]: MainAnalytic.ACTIONS.ANALYTICS.SPENDING_TAXI_ADVANCE_PAYMENT_TICKET_OPENED,
      [TYPE_SMART_SERVICE_TYPE.RETURNS]: MainAnalytic.ACTIONS.ANALYTICS.SPENDING_TAXI_TICKET_RETURNS_OPENED,
      [TYPE_SMART_SERVICE_TYPE.TRAVEL_POLICIES_USING]: MainAnalytic.ACTIONS.ANALYTICS.SPENDING_TAXI_TRAVEL_APPROVAL_OPENED,
    })[type];

    MainAnalytic.sendAmplitude(analyticType);
    chartsAnalyticsService.changeSmartType(SERVICETYPE.TAXI_VOUCHER_FROM_BACK);
    chartsAnalyticsService.setSmartSubType(type);
    chartsAnalyticsService.changeSmartLabel(label);

    return history.push(path);
  };

  return (
    <CommonWithSubSections
      typeStore={ chartsAnalyticsSmartSummaryStore }
      onPageChange={ handleOpen }
      type={ SERVICETYPE.TAXI_VOUCHER }
      tooltip={ LABELS.TOOLTIP }
    />
  );
});

export { TaxiPageSubSections };
