import React, { useEffect } from 'react';
import { Collapse, Checkbox, Text, Input, Stars, BackLink, Icon } from 'new-ui';
import { getText } from '../../../../../i18n';

import { FilterTravelPolicy } from '../../../../components/FilterTravelPolicy';
import { PriceSlider } from '../../../../components/PriceSlider';
import { FilterPanel } from '../../../../components/FilterPanel';

import { onlyFractionalNumbersOrEmpty } from '../../../../bi/utils/text';
import MainAnalytic from '../../../../bi/utils/analytics/main';
import { isSmartAgent } from '../../../../bi/utils/env';

import { QA_ATTRIBUTES } from '../../../../bi/constants/attributesForTests';
import { RATING_RU } from '../../../../bi/constants/hotelsSearch';
import { INPUT_TYPES } from '../../../../bi/constants/app';

import ACTION from '../../../../bi/services/hotels/action';

import { Amenities, Filters, IDisplayFilters, IHotelsFilters } from '../../../../bi/services/hotels/types';

import { ITravelPolicyListItem } from '../../../../bi/types/travelPolicy';

import styles from './style.module.css';

const LABELS = {
  COST_PER_NIGHT: getText('hotels:regionResult.filters.labels.costPerNight'),
  TRAVEL_POLICY: getText('hotels:regionResult.filters.labels.travelPolicy'),
  FAVORITE: getText('hotels:regionResult.filters.labels.favorite'),
  STARS: getText('hotels:regionResult.filters.labels.stars'),
  HOTEL_CHAIN: getText('hotels:regionResult.filters.labels.hotelChain'),
  RATING: getText('hotels:regionResult.filters.labels.rating'),
  TYPE: getText('hotels:regionResult.filters.labels.type'),
  AMENITIES: getText('hotels:regionResult.filters.labels.amenities'),
  NAME_OF_HOTEL: getText('hotels:regionResult.filters.labels.nameOfHotel'),
  DISTANCE_TO_CENTER: getText('hotels:regionResult.filters.labels.distanceToCenter'),
  DISTANCE_TO_POINT: getText('hotels:regionResult.filters.labels.distanceToPoint'),
  MEAL_INCLUDED: getText('hotels:regionResult.filters.mealIncluded'),
  FREE_CANCELLATION: getText('hotels:regionResult.filters.freeCancellation'),
  ONLY_ONLINE: getText('hotels:regionResult.filters.onlyOnline'),
  RECOMMENDED: getText('hotels:regionResult.filters.recommended'),
  RECOMMENDED_ROOM: getText('hotels:regionResult.filters.recommendedRoom'),
  HOTELS_IS_MATCH: getText('hotels:regionResult.filters.hotelsIsMatch'),
  RECOMMENDED_SMART_HOTEL: getText('hotels:regionResult.filters.recommendedSmartHotel'),
  HOTELS_IS_MATCH_SMART_HOTEL: getText('hotels:regionResult.filters.hotelsIsMatchSmartHotel'),
  ONLY_FAVORITE: getText('hotels:regionResult.filters.onlyFavorite'),
  REQUEST: getText('hotels:regionResult.filters.request'),
  TO: getText('hotels:regionResult.item.to'),
  KM: getText('components:hotelItem.km'),
  WORK_WITH_TAX: getText('hotels:regionResult.filters.workWithTax'),
  RECOMMENDED_ROOM_DESC: getText('hotels:regionResult.filters.recommendedRoomDesc'),
};

const ANALYTICS_NAME = {
  YES: 'yes',
  NO: 'no',
};

interface HotelsSearchFilterProps {
  filters: Filters,
  updateFilters(type: string, value: any): void,
  amenitiesRu: any,
  typeRu: any,
  travelPolicyList: ITravelPolicyListItem[],
  isAnyFavorite: boolean,
  unavailableTravelPolicy: boolean,
  hasOneSmart?: boolean,
  hasOneGuaranteed?: boolean,
  requestId?: number,
  aggregationId: number | null,
  isFiltersHotelsInMicroservice: boolean,
  displayFilters: IDisplayFilters | null,
  hotelsFilters: IHotelsFilters,
  radiusCustom: boolean,
}

const HotelsSearchFilter = ({
  typeRu,
  amenitiesRu,
  travelPolicyList,
  updateFilters,
  unavailableTravelPolicy,
  requestId = 0,
  aggregationId,
  hasOneSmart = false,
  hasOneGuaranteed = false,
  isAnyFavorite,
  filters: {
    stars,
    rating,
    type,
    amenities,
    travelPolicyList: filterTravelPolicyList,
    selectedTravelPolicy: filterSelectedTravelPolicy,
    radius,
    price,
    hasCancellation,
    smartHotel,
    priceGuaranteed,
    favoriteId,
    online,
    breakfast,
    hotelName,
    hasVat,
  },
  isFiltersHotelsInMicroservice,
  displayFilters,
  hotelsFilters,
  radiusCustom,
}: HotelsSearchFilterProps) => {
  const updateSmartFilter = (value: boolean) => updateFilters(ACTION.UPDATESMARTHOTELFILTER, value);

  const updatePriceFilter = (value: number[]) => updateFilters(ACTION.UPDATEPRICEFILTER, value);

  const updatePriceGuaranteedFilter = (value: boolean) => {
    MainAnalytic.sendFirebase(MainAnalytic.ACTIONS.HOTELS.FILTER_PRICEGUARANTEE, {
      checkbox: value ? ANALYTICS_NAME.YES : ANALYTICS_NAME.NO,
    });

    updateFilters(ACTION.UPDATE_PRICE_GUARANTEED_FILTER, value);
  };

  const updateRecommendedHotels = (value: boolean) => updateFilters(ACTION.UPDATE_RECOMMEND_HOTELS, value);

  useEffect(() => {
    if (isSmartAgent) {
      updateRecommendedHotels(false);
    }
  }, []);

  const updateHotelName = (value: string) => updateFilters(ACTION.UPDATEHOTELNAMEFILTER, value);

  const updateStarsFilter = (checkboxType: string, value: boolean) => updateFilters(ACTION.UPDATESTARSFILTER, { type: checkboxType, value });

  const updateFavoriteFilter = (value: boolean) => {
    MainAnalytic.sendFirebase(MainAnalytic.ACTIONS.HOTELS.FILTER_ONLY_FAVOURITES, {
      checkbox: value ? ANALYTICS_NAME.YES : ANALYTICS_NAME.NO,
    });

    updateFilters(ACTION.UPDATEFAVORITEFILTER, value);
  };

  const updateAmenitiesFilter = (checkboxType: string, value: boolean) => updateFilters(ACTION.UPDATEAMENITIESFILTER, { type: checkboxType, value });

  const updateCancellationFilter = (value: boolean) => {
    MainAnalytic.sendFirebase(MainAnalytic.ACTIONS.HOTELS.FILTER_FREE_CANCELLATION, {
      checkbox: value ? ANALYTICS_NAME.YES : ANALYTICS_NAME.NO,
    });

    updateFilters(ACTION.UPDATECANCELLATIONFILTER, value);
  };

  const updateTypeFilter = (checkboxType: string, value: boolean) => updateFilters(ACTION.UPDATETYPEFILTER, { type: checkboxType, value });

  const updateHasVatFilter = (value: boolean) => updateFilters(ACTION.HAS_VAT_FILTERS, value);

  const updateOnlineFilter = (value: boolean) => {
    MainAnalytic.sendFirebase(MainAnalytic.ACTIONS.HOTELS.FILTER_ONLY_ONLINE, {
      checkbox: value ? ANALYTICS_NAME.YES : ANALYTICS_NAME.NO,
    });

    updateFilters(ACTION.UPDATE_ONLINE_FILTER, value);
  };

  const updateBreakfastFilter = (value: boolean) => {
    MainAnalytic.sendFirebase(MainAnalytic.ACTIONS.HOTELS.FILTER_BREAKFAST, {
      checkbox: value ? ANALYTICS_NAME.YES : ANALYTICS_NAME.NO,
    });

    updateFilters(ACTION.UPDATE_BREAKFAST_FILTER, value);
  };

  const updateRatingFilter = (checkboxType: string, value: boolean) => updateFilters(ACTION.UPDATERATINGFILTER, { type: checkboxType, value });

  const updateRadiusFilter = (value: string, custom = false) => {
    if (!value) return;

    updateFilters(ACTION.UPDATE_RADIUS_FILTER, { value: parseFloat(value), custom });
  };

  const renderStarsCheckbox = () => {
    if (isFiltersHotelsInMicroservice && displayFilters) {
      const { Hotel: { Stars: filterStars } } = hotelsFilters;
      const { Stars: countingFilterStars } = displayFilters;

      if (!countingFilterStars) return null;

      const filterStarsKeys = Object.keys(countingFilterStars).reverse();

      return filterStarsKeys.map((star, index) => {
        const selectedStars = filterStars && filterStars.find(selectedStar => selectedStar === +star);

        return (
          <div data-qa={ `${QA_ATTRIBUTES.hotels.result.filters.stars}-${index}` } className={ styles.stars } key={ index }>
            <Checkbox
              key={ star }
              // @ts-ignore
              id={ `check_stars_${index}` }
              value={ !!selectedStars }
              contentClassName={ styles['checkbox-content'] }
              onChange={ (value: boolean) => updateStarsFilter(star, value) }
            >
              <Stars count={ +star } className={ styles.imgs }/>
              <Text type='NORMAL_14' className={ styles.count }>{ `${countingFilterStars[star]}` }</Text>
            </Checkbox>
          </div>
        );
      });
    }

    const checkboxName = Object.keys(stars).reverse();

    return checkboxName.map((checkbox, index) => (
      <div data-qa={ `${QA_ATTRIBUTES.hotels.result.filters.stars}-${index}` } className={ styles.stars } key={ index }>
        <Checkbox
          key={ checkbox }
          // @ts-ignore
          id={ `check_stars_${index}` }
          value={ stars[checkbox].selected }
          contentClassName={ styles['checkbox-content'] }
          onChange={ (value: boolean) => updateStarsFilter(checkbox, value) }
        >
          <Stars count={ +checkbox } className={ styles.imgs }/>
          <Text type='NORMAL_14' className={ styles.count }>{ stars[checkbox].count }</Text>
        </Checkbox>
      </div>
    ));
  };

  const renderRatingCheckbox = () => {
    if (isFiltersHotelsInMicroservice && displayFilters) {
      const { Hotel: { Rating: filterRating } } = hotelsFilters;
      const { Rating: countingFilterRating } = displayFilters;

      if (!countingFilterRating) return null;

      const filterRatingKeys = Object.keys(countingFilterRating).reverse();

      return filterRatingKeys.map((checkbox, index) => {
        const selectedRating = filterRating && filterRating.find(value => value === +checkbox);

        return (
          <div data-qa={ `${QA_ATTRIBUTES.hotels.result.filters.rating}-${index}` } className={ styles.rating } key={ checkbox }>
            <Checkbox
              value={ !!selectedRating }
              onChange={ (value: boolean) => updateRatingFilter(checkbox, value) }
              contentClassName={ styles['checkbox-content'] }
            >
              <Text type='NORMAL_14'>{ RATING_RU[Number(checkbox)] }</Text>
              <Text type='NORMAL_14' className={ styles.count }>{ `${countingFilterRating[checkbox]}` }</Text>
            </Checkbox>
          </div>
        );
      });
    }

    return Object.keys(rating).sort((a, b) => Number(b) - Number(a)).map((checkbox, index) => (
      <div data-qa={ `${QA_ATTRIBUTES.hotels.result.filters.rating}-${index}` } className={ styles.rating } key={ checkbox }>
        <Checkbox
          value={ rating[Number(checkbox)] }
          onChange={ (value: boolean) => updateRatingFilter(checkbox, value) }
          contentClassName={ styles['checkbox-content'] }
        >
          <Text type='NORMAL_14'>{ RATING_RU[Number(checkbox)] }</Text>
        </Checkbox>
      </div>
    ));
  };

  const renderTypeCheckbox = () => {
    if (isFiltersHotelsInMicroservice && displayFilters) {
      const { Hotel: { Type } } = hotelsFilters;
      const { Type: countingFilterTypes } = displayFilters;

      if (!countingFilterTypes) return null;

      const filterTypeKeys = Object.keys(countingFilterTypes);

      return filterTypeKeys.map((filterType, index) => {
        const selectedType = Type && Type.find(checkobox => checkobox === filterType);

        return (
          <div data-qa={ `${QA_ATTRIBUTES.hotels.result.filters.types}-${index}` } className={ styles.type } key={ index }>
            <Checkbox
              key={ filterType }
              // @ts-ignore
              id={ `check_hotel_type_${index}` }
              value={ !!selectedType }
              onChange={ (value: boolean) => updateTypeFilter(filterType, value) }
              contentClassName={ styles['checkbox-content'] }
            >
              <Text type='NORMAL_14'>{ typeRu[filterType] }</Text>
              <Text className={ styles.count } type='NORMAL_14'>{ `${countingFilterTypes[filterType]}` }</Text>
            </Checkbox>
          </div>
        );
      });
    }

    const checkboxName = Object.keys(type);

    return checkboxName.map((checkbox, index) =>
      (<div data-qa={ `${QA_ATTRIBUTES.hotels.result.filters.types}-${index}` } className={ styles.type } key={ index }>
        <Checkbox
          key={ checkbox }
          // @ts-ignore
          id={ `check_hotel_type_${index}` }
          value={ type[checkbox].selected }
          onChange={ (value: boolean) => updateTypeFilter(checkbox, value) }
          contentClassName={ styles['checkbox-content'] }
        >
          <Text type='NORMAL_14'>{ typeRu[checkbox] }</Text>
          <Text className={ styles.count } type='NORMAL_14'>{ type[checkbox].count }</Text>
        </Checkbox>
      </div>),
    );
  };

  const renderAmenitiesCheckbox = () => {
    if (isFiltersHotelsInMicroservice && displayFilters) {
      const { Hotel: { Amenities: amenitiesFilter } } = hotelsFilters;
      const { Amenities: countingFilterAmenities } = displayFilters;

      if (!countingFilterAmenities) return null;

      const filterAmenitiesKeys = Object.keys(countingFilterAmenities);

      return filterAmenitiesKeys.map((checkbox, index) => {
        const selectedAmenities = amenitiesFilter && amenitiesFilter.find(value => value === checkbox);

        return (
          <div className={ styles.amenities } key={ index }>
            <Checkbox
              key={ checkbox }
              value={ !!selectedAmenities }
              onChange={ (value: boolean) => updateAmenitiesFilter(checkbox, value) }
              contentClassName={ styles['checkbox-content'] }
            >
              <Text type='NORMAL_14'>{ amenitiesRu[checkbox] }</Text>
              <Text className={ styles.count } type='NORMAL_14'>{ `${countingFilterAmenities[checkbox]}` }</Text>
            </Checkbox>
          </div>
        );
      });
    }

    const checkboxName = Object.keys(amenities);

    return checkboxName.map((checkbox, index) =>
      (<div className={ styles.amenities } key={ index }>
        <Checkbox
          key={ checkbox }
          value={ amenities[checkbox as keyof Amenities] as boolean }
          onChange={ (value: boolean) => updateAmenitiesFilter(checkbox, value) }
          contentClassName={ styles['checkbox-content'] }
        >
          <Text type='NORMAL_14'>{ amenitiesRu[checkbox] }</Text>
        </Checkbox>
      </div>),
    );
  };

  const renderTravelPolitics = () => {
    const { Rate: { TravelPolicy } } = hotelsFilters;

    const selectedTP = isFiltersHotelsInMicroservice ? TravelPolicy : filterSelectedTravelPolicy;

    if (filterTravelPolicyList.length > 1) {
      return (
        <div className={ styles.block }>
          <Collapse
            opened
            label={ LABELS.TRAVEL_POLICY }
          >
            <FilterTravelPolicy
              list={ filterTravelPolicyList }
              travelPolicyList={ travelPolicyList }
              readOnly={ unavailableTravelPolicy }
              selected={ selectedTP }
              onChangeCheckbox={ (value: any) => updateFilters('filters.travelPolicy', { value }) }
              qaAttrCheckbox={ QA_ATTRIBUTES.hotels.result.filters.travelPolicy }
            />
          </Collapse>
        </div>
      );
    }

    return null;
  };

  const renderBackToRequestButton = () => (requestId > 0
    ? <BackLink link={ `/requests/${requestId}` } text={ LABELS.REQUEST } alternativeDesign={ isSmartAgent }/>
    : ''
  );

  const renderDistanceFromCenter = () => {
    const { custom = false, value } = radius;
    const { Hotel: { Proximity: { Radius } } } = hotelsFilters;

    const radiusValue = isFiltersHotelsInMicroservice ? Radius : value;
    const radiusCustomValue = isFiltersHotelsInMicroservice ? radiusCustom : custom;
    const pointOrCenter = radiusCustomValue ? LABELS.DISTANCE_TO_POINT : LABELS.DISTANCE_TO_CENTER;

    return (
      <div className={ styles.block }>
        <Collapse
          opened
          label={ pointOrCenter }
        >
          <div className={ styles.distance }>
            <Text type='NORMAL_14' >{ LABELS.TO }</Text>
            <Input
              type={ INPUT_TYPES.NUMBER }
              qaAttr={ QA_ATTRIBUTES.hotels.result.filters.distance }
              subText={ LABELS.KM }
              className={ styles.distance_input }
              value={ radiusValue || '' }
              withValidation
              validateFn={ (prop: string) => Number(prop) > 100 || !onlyFractionalNumbersOrEmpty(prop) }
              onChange={ (newValue: string) => updateRadiusFilter(newValue, radiusCustomValue) }
              useTimeout={ false }
            />
          </div>
        </Collapse>
      </div>
    );
  };

  const renderPricePerNight = () => {
    const { Rate: { PriceFrom } } = hotelsFilters;

    const priceMinFrom = isFiltersHotelsInMicroservice && displayFilters ? displayFilters.PriceFrom : price.border.from;
    const priceMaxTo = isFiltersHotelsInMicroservice && displayFilters ? displayFilters.PriceTo : price.border.to;
    const priceStart = isFiltersHotelsInMicroservice ? PriceFrom : price.from;

    return (
      <div className={ styles.block }>
        <Collapse
          opened
          label={ LABELS.COST_PER_NIGHT }
        >
          <PriceSlider
            min={ priceMinFrom }
            max={ priceMaxTo }
            start={ priceStart }
            end={ price.to }
            onSlide={ updatePriceFilter }
            qaAttrPriceMin={ QA_ATTRIBUTES.hotels.result.filters.priceMin }
            qaAttrPriceMax={ QA_ATTRIBUTES.hotels.result.filters.priceMax }
          />
        </Collapse>
      </div>
    );
  };

  const renderRefundability = () => {
    const { Rate: { HasCancellation } } = hotelsFilters;
    const currentValue = isFiltersHotelsInMicroservice ? !!HasCancellation : hasCancellation;

    return (
      <div className={ `${styles.block} ${styles.checkbox_wrap}` }>
        <Checkbox
          // @ts-ignore
          id={ 'has_cancellation' }
          value={ currentValue }
          onChange={ updateCancellationFilter }
        >
          <Text qaAttr={ QA_ATTRIBUTES.hotels.result.filters.refundability } type='NORMAL_14_130'>
            {LABELS.FREE_CANCELLATION}
          </Text>
        </Checkbox>
      </div>
    );
  };

  const renderSmartHotels = () => {
    if (isSmartAgent) return null;

    if (isFiltersHotelsInMicroservice && !displayFilters?.HasSmartHotel) return null;

    if (!isFiltersHotelsInMicroservice && (!hasOneSmart || !aggregationId)) return null;

    const { Hotel: { SmartHotel } } = hotelsFilters;
    const currentValue = isFiltersHotelsInMicroservice ? SmartHotel : smartHotel;

    return (
      <div className={ `${styles.block} ${styles.smart}` }>
        <Checkbox
          value={ currentValue }
          onChange={ updateSmartFilter }
        >
          <Text
            qaAttr={ QA_ATTRIBUTES.hotels.result.filters.recommended }
            className={ styles.text }
            type='NORMAL_14_130'
          >
            { LABELS.RECOMMENDED_SMART_HOTEL }
          </Text>
        </Checkbox>
        <div className={ styles.icon }>
          <Icon
            data-qa={ QA_ATTRIBUTES.hotels.result.hotelIcon }
            type='smartHotel'
            alternativeDesign={ isSmartAgent }
          />
        </div>
        <Text
          type='NORMAL_12'
          className={ styles.hint }
        >
          { LABELS.HOTELS_IS_MATCH_SMART_HOTEL }
        </Text>
      </div>
    );
  };

  const renderPriceGuaranteed = () => {
    if (isFiltersHotelsInMicroservice && !displayFilters?.HasPriceGuaranteed) {
      return null;
    }

    if (!isFiltersHotelsInMicroservice && (!hasOneGuaranteed || aggregationId || isSmartAgent)) {
      return null;
    }

    const { Hotel: { PriceGuaranteed } } = hotelsFilters;
    const currentValue = isFiltersHotelsInMicroservice ? PriceGuaranteed : priceGuaranteed;

    return (
      <div className={ `${styles.block} ${styles.smart}` }>
        <Checkbox
          value={ currentValue }
          onChange={ updatePriceGuaranteedFilter }
        >
          <Text
            qaAttr={ QA_ATTRIBUTES.hotels.result.filters.recommended }
            type='NORMAL_14_130'
          >
            { LABELS.RECOMMENDED }
          </Text>
        </Checkbox>
        <Text
          type='NORMAL_12'
          className={ styles.hint }
        >
          { LABELS.HOTELS_IS_MATCH }
        </Text>
      </div>
    );
  };

  const renderFavoriteHotels = () => {
    if (isFiltersHotelsInMicroservice && !displayFilters?.HasFavorite) return null;

    if (!isFiltersHotelsInMicroservice && (isSmartAgent || !isAnyFavorite)) return null;

    const { Hotel: { Favorite } } = hotelsFilters;
    const currentValue = isFiltersHotelsInMicroservice ? Favorite : favoriteId;

    return (
      <div className={ styles.block }>
        <Collapse
          opened
          label={ LABELS.FAVORITE }
        >
          <Checkbox
            className={ styles.favorite }
            // @ts-ignore
            id={ 'check_favorite_filter' }
            value={ currentValue }
            onChange={ updateFavoriteFilter }
          >
            <Text type='NORMAL_14' qaAttr={ QA_ATTRIBUTES.hotels.result.filters.favorites }>
              { LABELS.ONLY_FAVORITE }
            </Text>
          </Checkbox>
        </Collapse>
      </div>
    );
  };

  const renderVat = () => {
    if (isFiltersHotelsInMicroservice) return null;

    return (
      <div className={ `${styles.block} ${styles.vat} ${styles.smart}` }>
        <Checkbox
          value={ hasVat }
          onChange={ updateHasVatFilter }
        >
          <Text type='NORMAL_14'>{ LABELS.WORK_WITH_TAX }</Text>
        </Checkbox>
      </div>
    );
  };

  const renderOnline = () => {
    const { Rate: { Online } } = hotelsFilters;
    const currentValue = isFiltersHotelsInMicroservice ? !!Online : online;

    return (
      <div className={ `${styles.block} ${styles.checkbox_wrap}` }>
        <Checkbox
        // @ts-ignore
          id={ 'check_online' }
          value={ currentValue }
          onChange={ updateOnlineFilter }
        >
          <Text type='NORMAL_14' qaAttr={ QA_ATTRIBUTES.hotels.result.filters.online }>
            { LABELS.ONLY_ONLINE }
          </Text>
        </Checkbox>
      </div>
    );
  };

  const renderRecommendRoom = () => {
    const { Rate: { Recommended } } = hotelsFilters;

    if (!isFiltersHotelsInMicroservice || isSmartAgent) return null;

    return (
      <div className={ `${styles.block} ${styles.checkbox_wrap} ${styles.smart}` }>
        <Checkbox
          value={ Recommended }
          onChange={ updateRecommendedHotels }
        >
          <Text
            type='NORMAL_14_130'
          >
            { LABELS.RECOMMENDED_ROOM }
          </Text>
        </Checkbox>
        <Text
          type='NORMAL_12'
          className={ styles.hint }
        >
          { LABELS.RECOMMENDED_ROOM_DESC }
        </Text>
      </div>
    );
  };

  const renderBreakfast = () => {
    const { Rate: { Breakfast } } = hotelsFilters;
    const currentValue = isFiltersHotelsInMicroservice ? !!Breakfast : breakfast;

    return (
      <div className={ `${styles.block} ${styles.checkbox_wrap}` }>
        <Checkbox
        // @ts-ignore
          id={ 'check_breakfast' }
          value={ currentValue }
          onChange={ updateBreakfastFilter }
        >
          <Text type='NORMAL_14' qaAttr={ QA_ATTRIBUTES.hotels.result.filters.breakfast } >
            { LABELS.MEAL_INCLUDED }
          </Text>
        </Checkbox>
      </div>
    );
  };

  const renderStars = () => (
    <div className={ styles.block }>
      <Collapse
        opened
        label={ LABELS.STARS }
      >
        { renderStarsCheckbox() }
      </Collapse>
    </div>
  );

  const renderRating = () => (
    <div className={ styles.block }>
      <Collapse
        opened
        label={ LABELS.RATING }
      >
        { renderRatingCheckbox() }
      </Collapse>
    </div>
  );

  const renderType = () => (
    <div className={ styles.block }>
      <Collapse
        label={ LABELS.TYPE }
      >
        { renderTypeCheckbox() }
      </Collapse>
    </div>
  );

  const renderAmenities = () => (
    <div className={ styles.block }>
      <Collapse
        opened={ false }
        label={ LABELS.AMENITIES }
      >
        { renderAmenitiesCheckbox() }
      </Collapse>
    </div>
  );

  const renderHotelName = () => {
    const { Hotel: { Name } } = hotelsFilters;

    const inputValue = isFiltersHotelsInMicroservice ? Name : hotelName;

    return (
      <div className={ `${styles.block} ${styles.name}` }>
        <Input
          qaAttr={ QA_ATTRIBUTES.hotels.result.filters.input }
          className={ styles.input }
          isCleansing
          useTimeout={ false }
          value={ inputValue }
          placeholder={ LABELS.NAME_OF_HOTEL }
          onChange={ value => updateHotelName(value) }
        />
      </div>
    );
  };

  return (
    <div className={ styles.wrap }>
      { renderBackToRequestButton() }
      <FilterPanel>
        <div className={ styles.blocks }>
          { renderDistanceFromCenter() }
          { renderPricePerNight() }
          { renderBreakfast() }
          { renderRefundability() }
          { renderOnline() }
          { renderRecommendRoom() }
          { renderVat() }
          { renderSmartHotels() }
          { renderPriceGuaranteed() }
          { renderFavoriteHotels() }
          { renderHotelName() }
          { renderTravelPolitics() }
          { renderStars() }
          { renderRating() }
          { renderType() }
          { renderAmenities() }
        </div>
      </FilterPanel>
    </div>
  );
};

export { HotelsSearchFilter };
