import * as React from 'react';
import { getText } from '../../../../../../../i18n';

import specialFoodSvg from '../../images/special-food.svg';
import comfortableSeatSvg from '../../images/comfortable-seat.svg';
import additionalBaggageSvg from '../../images/additional-baggage.svg';
import businessUpgradeSvg from '../../images/business-upgrade.svg';

import MoneyFormat from '../../../../../../bi/utils/money';

import styles from './index.module.css';

const LABELS = {
  SPECIAL_FOOD: getText('settings:bonusProgram.promo.features.specialFood'),
  COMFORTABLE_SEAT: getText('settings:bonusProgram.promo.features.comfortableSeat'),
  BEGGAGE: getText('settings:bonusProgram.promo.features.additionalBaggage'),
  BUSINESS_UPGRADE: getText('settings:bonusProgram.promo.features.businessUpgrade'),
  FROM: getText('settings:bonusProgram.promo.features.from'),
  POINTS: getText('settings:bonusProgram.promo.features.points'),
};

const ITEMS: Array<{
  points: number,
  icon: string,
  text: string,
}> = [
  {
    points: 150,
    icon: specialFoodSvg,
    text: LABELS.SPECIAL_FOOD,
  },
  {
    points: 300,
    icon: comfortableSeatSvg,
    text: LABELS.COMFORTABLE_SEAT,
  },
  {
    points: 800,
    icon: additionalBaggageSvg,
    text: LABELS.BEGGAGE,
  },
  {
    points: 2500,
    icon: businessUpgradeSvg,
    text: LABELS.BUSINESS_UPGRADE,
  },
];

const Feature = () => {
  const features = ITEMS.map(({ text, points, icon }, key) => (
    <div key={ key } className={ styles.item }>
      <div className={ styles.points }>
        { LABELS.FROM }
        <br />
        <strong>{MoneyFormat.money(points, false)}</strong>
        <br />
        { LABELS.POINTS }
      </div>
      <div className={ styles.icon_wrap }>
        <img className={ styles.icon } src={ icon } alt={ text } />
      </div>
      <div className={ styles.text }>
        {text}
      </div>
    </div>
  ));

  return (
    <div className={ styles.wrap }>
      { features }
    </div>
  );
};

export { Feature };
