// @ts-nocheck
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Dialog, Text, Button, ItemPanel, PROPS } from 'new-ui';

import { getText } from '../../../../../../i18n';

import Timer from '../../../../../components/Timer';
import { AddresserInfoItem } from '../../../../../components/AddresserInfoItem';
import {
  ReturnPurchaseButton,
  ConsistentCartDeleteButton,
  ApproveStatusListItem,
} from '../../ApproveStatuses/approveStatuses';

import { formatDate, isBeforeDate, getDateDescriptor } from '../../../../../bi/utils/formatDate';
import { cartHasAirUnderageByProviders } from '../../../../../bi/utils/cart';

import { CONSISTENTCARTMODES } from '../../../../../bi/constants/approve';
import { SERVICETYPE } from '../../../../../bi/constants/serviceType';
import { PATTERN } from '../../../../../bi/constants/dateFormats';

import styles from './styles/index.module.css';

const {
  TEXT: {
    TYPES: {
      NORMAL_14,
    },
  },
} = PROPS;

export const LABELS = {
  COMMENTS: getText('cart:consistentCartItem.comments'),
  COMMENT_HISTORY: getText('cart:consistentCartItem.commentHistory'),
  TIME_FOR_RESERVE_CANCELLATION: getText('cart:consistentCartItem.timeForReserveCancellation'),
  APPROVAL_CKR: getText('approve:approvalsCKR'),
};

const filterNil = list => list.filter(item => item !== null && item !== undefined);

class ConsistentCartItem extends Component {
  static propTypes = {
    type: PropTypes.string.isRequired,
    mode: PropTypes.string,
    item: PropTypes.object.isRequired,
    readonly: PropTypes.bool,
    showApproversCKR: PropTypes.bool.isRequired,
    checkUnderage: PropTypes.bool.isRequired,
    onDelete: PropTypes.func.isRequired,
    onPurchase: PropTypes.func.isRequired,
    onClick: PropTypes.func.isRequired,
    onTimeIsUp: PropTypes.func.isRequired,
    qaAttr: PropTypes.string,
  };

  static defaultProps = {
    readonly: false,
    mode: CONSISTENTCARTMODES.VIEWER,
    qaAttr: '',
  };

  state = {
    showComments: false,
    loading: false,
  };

  componentWillUnmount() {
    if (this.state.loading) {
      this.toggleLoading();
    }
  }

  toggleLoading = () => this.setState({
    loading: !this.state.loading,
  });

  handleDelete = (item, airToNote) => {
    this.toggleLoading();
    this.props.onDelete(item, airToNote);
  };

  renderHeader = () => {
    const { type, item, readonly, showApproversCKR } = this.props;
    const renderConsistentCartDeleteButton = !readonly && !showApproversCKR ? (
      <ConsistentCartDeleteButton item={ item } type={ type } onDelete={ this.handleDelete } />
    ) : null;

    return (
      <div className={ styles.header }>
        <Text type={ PROPS.TEXT.TYPES.NORMAL_18 }>{ item.name }</Text>
        { renderConsistentCartDeleteButton }
      </div>
    );
  };

  renderPurchaseButton = () => {
    const { type, onPurchase, item, checkUnderage } = this.props;
    const haveFouls = !!item.fouls && !!item.fouls.length > 0;

    const isUnderage = checkUnderage && cartHasAirUnderageByProviders(item.sources);

    return (
      <ReturnPurchaseButton
        type={ type }
        item={ item }
        onPurchase={ onPurchase }
        haveFouls={ haveFouls }
        hasUnderage={ isUnderage }
      />
    );
  };

  renderApproveList = () => {
    const { item: { approve, isOffline } } = this.props;

    const sortedByDateApprovers = approve.sort((a, b) => {
      if (isBeforeDate(a.CreatedDate, b.CreatedDate)) {
        return -1;
      }

      return 0;
    });

    return sortedByDateApprovers.map(({ Resolution, Name, Email, CreatedDate }, i) => {
      const { showApproversCKR } = this.props;

      const preparedEmail = showApproversCKR ? LABELS.APPROVAL_CKR : Email;

      return <ApproveStatusListItem
        key={ `${Email}_${i}` }
        email={ preparedEmail }
        name={ Name }
        date={ getDateDescriptor(CreatedDate).toLowerCase() }
        resolution={ Resolution }
        showApproversCKR={ showApproversCKR }
        isOffline={ isOffline }
      />;
    });
  };

  setShowComments = value => this.setState({ showComments: value });

  renderAddresserComment = ({ Messages, Email }) => {
    const addresserComment = Messages.find(message => message.Email !== Email && message.Comment);

    return (
      addresserComment &&
      <Text type={ PROPS.TEXT.TYPES.NORMAL_16_140 } className={ styles.comment }>{formatDate(addresserComment.Date, PATTERN.FULLDATEWITHTIME)}, {addresserComment.Name} - {addresserComment.Comment}</Text>
    );
  };

  renderApproverComment = ({ Messages, Email, Id }, i) => {
    let messageHtml = null;

    Messages.forEach((message) => {
      if (message.Email === Email && message.Comment) {
        messageHtml = (
          <Text type={ PROPS.TEXT.TYPES.NORMAL_16_140 } className={ styles.comment } key={ `${Id}_${i}` }>
            { formatDate(message.Date, PATTERN.FULLDATEWITHTIME)}, { message.Name } - { message.Comment }
          </Text>
        );
      }
    });

    return messageHtml;
  };

  renderComments = () => {
    const { item: { approve } } = this.props;
    const { showComments } = this.state;

    const approversCommentsListHtml = filterNil(approve.map(({ Messages, Id, Email }, ind) => (
      <div key={ `${Email}_${ind}` }>
        { this.renderApproverComment({ Messages, Email, Id }) }
      </div>
    )));

    const addresserCommentsListHtml = filterNil(approve.map(({ Messages, Email }, ind) => (
      <div key={ `${Email}_${ind}` }>
        { this.renderAddresserComment({ Messages, Email }) }
      </div>
    )));

    const addresserCommentHtml = addresserCommentsListHtml[0];

    if (!approversCommentsListHtml.length || !addresserCommentsListHtml.length) {
      return null;
    }

    return (
      <div className={ styles.comments } onClick={ (e) => e.stopPropagation() }>
        <Button
          type={ PROPS.BUTTON.TYPES.TEXTUAL }
          onClick={ (e) => {
            e.stopPropagation();
            this.setShowComments(true);
          } }
        >
          {LABELS.COMMENTS}
        </Button>
        <Dialog
          show={ showComments }
          showClosing
          onChange={ this.setShowComments }
        >
          <div className={ styles['dialog-content'] }>
            <Text type={ PROPS.TEXT.TYPES.BOLD_20 } className={ styles['dialog-header'] }>{LABELS.COMMENT_HISTORY}</Text>
            <>
              { addresserCommentHtml }
              { approversCommentsListHtml }
            </>
          </div>
        </Dialog>
      </div>
    );
  };

  renderAddresser = () => {
    const { item: { approve: [firstItem] }, item } = this.props;
    const { Messages: [addresser] } = firstItem;

    const date = getDateDescriptor(addresser.Date).toLowerCase();

    return (
      <AddresserInfoItem
        email={ addresser.Email }
        name={ addresser.Name }
        date={ date }
        isOffline={ item.isOffline }
      />
    );
  };

  renderApproveBlock = () => (
    this.props.mode === CONSISTENTCARTMODES.VIEWER ? this.renderApproveList() : this.renderAddresser()
  );

  renderTimer = () => {
    const {
      item,
      readonly,
      onTimeIsUp,
    } = this.props;

    let minReservedItem = item.sources && item.sources.length && item.sources.length > 0
      ? item.sources.find(cartItem => cartItem.IsReserved && !!cartItem.BookDeadline && cartItem.ServiceType === SERVICETYPE.AIR)
      : null;

    if (minReservedItem) {
      item.sources.forEach((sourceItem) => {
        if (sourceItem.BookDeadline
          && sourceItem.IsReserved
          && sourceItem.ServiceType === SERVICETYPE.AIR
          && isBeforeDate(sourceItem.BookDeadline, minReservedItem.BookDeadline)) {
          minReservedItem = sourceItem;
        }
      });
    }

    return !!minReservedItem && !readonly ? (
      <div className={ styles.timer }>
        <Text type={ NORMAL_14 }>
          {LABELS.TIME_FOR_RESERVE_CANCELLATION}
        </Text>
        &nbsp;
        <Timer
          deadLine={ minReservedItem.BookDeadline }
          currentTime={ item.serverTime }
          onTimeIsUp={ () => onTimeIsUp(minReservedItem) }
          className={ styles.time }
        />
      </div>
    ) : null;
  };

  render() {
    const { item, onClick, readonly, qaAttr } = this.props;
    const { loading } = this.state;

    return (
      <ItemPanel
        renderHeader={ this.renderHeader }
        className={ styles.wrapper }
        loading={ loading }
      >
        <div className={ styles.body } onClick={ () => onClick(item) } data-qa={ qaAttr }>
          <div>
            { this.renderApproveBlock() }
            { this.renderComments() }
          </div>
          <div className={ styles.right }>
            {
              !readonly && (
                <div className={ styles.purchase }>
                  { this.renderPurchaseButton() }
                </div>
              )
            }
            { this.renderTimer() }
          </div>
        </div>
      </ItemPanel>
    );
  }
}

export { ConsistentCartItem };
