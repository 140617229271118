import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { DotLoading } from 'new-ui';

import { useServices } from '../../../../../../bi/context/services';

import { AuthButton } from './AuthButton';
import { StatusCondition } from '../../../../../../components/StatusCondition';

import { LoadingFields } from '../../../../../../bi/services/signature';

export const GovServiceAuth = observer(() => {
  const { signatureService, workspaceService } = useServices(['Signature', 'Workspace']);

  const status = signatureService.netStore.getStatus(LoadingFields.checkGovIntegration);

  useEffect(() => {
    if (!workspaceService.isAdmin) {
      signatureService.checkGovIntegration();
    }
  }, []);

  const AuthCondition = signatureService.enhancedSignatureStore.integrationEnabled
    ? React.Fragment
    : AuthButton;

  return (
    <StatusCondition
      status={ status }
      onLoading={ DotLoading }
      onNever={ React.Fragment }
      fallback={ AuthCondition }
    />
  );
});
