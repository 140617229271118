import moment, { Moment } from 'moment';

import { MomentInput } from 'moment/moment';
import parseUnix from '../../utils/parseDateTime';
import trimTimezone from '../../utils/trimTimezone';
import MoneyFormat from '../../utils/money';

import { DATEFORMATS, PATTERN } from '../../constants/dateFormats';

const CASES = [2, 0, 1, 1, 1, 2];

class Format {
  money = (value: number, withSymbol = false) => MoneyFormat.money(value, withSymbol);

  moneyWithDecimal = (value: number, withSymbol = false) => MoneyFormat.moneyWithDecimal(value, withSymbol);

  date = (value: MomentInput, pattern = DATEFORMATS.DATE) => moment(value).format(pattern);

  dateObject = (value: MomentInput) => moment(value);

  calendar = (value: MomentInput) => moment(value).calendar();

  // Возвращает 'позавчера/вчера/сегодня/завтра + HH:mm' или DD.MM.YYYY
  getDateDescriptor = (date: MomentInput) => {
    const today = moment();
    const formattedDate = moment(date);

    if (today.diff(formattedDate, 'days') === 0) {
      return this.calendar(date);
    }

    if (today.diff(formattedDate, 'days') === 1) {
      return `вчера в ${this.time(date)}`;
    }

    return this.date(date);
  };

  time = (value: MomentInput, pattern = DATEFORMATS.TIME) => moment(value).format(pattern);

  trimTimeZone = (date: MomentInput) => {
    if (typeof date === 'string') {
      return trimTimezone(date);
    }

    return date;
  };

  addTime = (time: MomentInput, amountOfTime: moment.unitOfTime.DurationConstructor, unitOfTime: number | string = 'm') =>
    moment(time).add(amountOfTime, unitOfTime);

  subtractDate = (startTime: MomentInput, endTime: Moment) => moment(endTime.diff(startTime));

  dateWithoutMoment = (value: MomentInput, pattern = DATEFORMATS.DATE) => parseUnix(value).format(pattern);

  toDecline = (number: number, titles: (string | number)[]) => {
    const ind = (number % 100 > 4 && number % 100 < 20) ?
      2 : CASES[(number % 10 < 5) ? number % 10 : 5];

    return titles[ind];
  };

  endPreviousDate = (value: MomentInput) =>
    moment(value).subtract(1, 'd').endOf('d').format(PATTERN.FULL_TIME_WITH_DATE);

  isMoment = (value: any) => moment.isMoment(value);

  isValid = (value: MomentInput) => moment(value).isValid();
}

export default Format;
