import { AccountSettingsProps } from '../services/accountSettings/types';
import { IFeeConstruction } from '../services/settings/types';

interface NewState extends AccountSettingsProps {
  displayTripsEmployee: (string | boolean)[],
}

const displayTripsEmployeeMapper = (accountSettings: AccountSettingsProps) => {
  const newState: NewState = { ...accountSettings, displayTripsEmployee: [] };

  const updateState = (item: (string | boolean)[]) => {
    if (item[1]) {
      newState.displayTripsEmployee.push(item[0]);
    }
  };

  Object.entries(accountSettings.displayTripsEmployee).map(updateState);

  return newState;
};

const searchSettingsMapper = (searchSettings: string[]) => [...Object.keys(searchSettings)
  .filter((item: any) => !searchSettings[item])
  .map(el => el[0].toUpperCase() + el.slice(1))];

const replaceItem = (arr: IFeeConstruction[], newItem: IFeeConstruction) => {
  const index = arr.findIndex((item) => item.ServiceType === newItem.ServiceType);

  if (index !== -1) {
    const updatedArr = [...arr];
    updatedArr[index] = newItem;

    return updatedArr;
  }

  return arr;
};

export { searchSettingsMapper, displayTripsEmployeeMapper, replaceItem };
