import React from 'react';
import { Button, Text } from 'new-ui';
import { CellComponent } from 'new-ui/src/components/UniversalTable/types';

import { useServices } from '../../../../../bi/context/services';

import { isValueComputeEnum } from '../../../../../bi/utils/common';

import { LABELS } from './consts';

import { EnhancedSignatureModalTypes, IDocumentInfo, SigningStatus } from '../../../../../bi/services/signature/types';
import { DocumentType } from '../../../../../bi/services/employee/types';

import styles from './styles.module.css';

interface IDocumentActionsCellBaseProps {
  onClick: () => void
  hide?: boolean
}

interface ISSDActionsCellProps {
  onClick: (documentId: string, employeeId: number[]) => void;
}

export const DocumentActionsCellBase = (
  { hide, onClick }: IDocumentActionsCellBaseProps,
) => {
  if (hide) return null;

  return (
    <Button
      className={ styles.noWrap }
      onClick={ onClick }
    >
      <Text type='NORMAL_14_130' color='white'>
        { LABELS.SEND_TO_EMAIL }
      </Text>
    </Button>
  );
};

export const EnhancedSignatureDocumentActionsCell: CellComponent<IDocumentInfo, 'documentId'> = (props) => {
  const {
    signatureService: {
      enhancedSignatureStore: { setSendDocId, openModal },
    },
  } = useServices(['Signature']);

  const isStatus = isValueComputeEnum(props.row.signingStatus, SigningStatus);

  const hide = !(isStatus.SimpleSigned || isStatus.EnhancedSigned);

  const handleClick = () => {
    setSendDocId(props.fieldValue);
    openModal(EnhancedSignatureModalTypes.docToEmailInput);
  };

  return (
    <DocumentActionsCellBase
      onClick={ handleClick }
      hide={ hide }
    />
  );
};

export const SimpleSignatureDocumentActionsCell: CellComponent<DocumentType, 'documentId', ISSDActionsCellProps> =
  (props) => {
    const handleClick = () => {
      props.onClick(props.fieldValue, props.row.employeeIds);
    };

    return (
      <DocumentActionsCellBase
        onClick={ handleClick }
        hide={ props.row.signingStatus !== 1 }
      />
    );
  };
