// @ts-nocheck
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { PROPS, Text, PageLoader, EmptyPanel } from 'new-ui';
import { getText } from '../../../i18n';

import { stringifySearchParams } from '../../bi/utils/convertSearchParams';
import { MainAnalytic } from '../../bi/utils/analytics';
import { getDataForEmptyPanel } from '../../bi/utils/searchPanel';
import { isSmartAgent } from '../../bi/utils/env';

import {
  SERVICETYPE,
  SERVICETYPERUMN,
} from '../../bi/constants/serviceType';
import ROUTES from '../../bi/constants/routes';
import { QA_ATTRIBUTES } from '../../bi/constants/attributesForTests';
import { STICKY } from '../../bi/constants/stickySearch';

import { FilterTabs } from '../../components/FilterTabs';
import StickySearchPanel from '../../components/StickySearchPanel';

import TransferItem from './components/transfer';
import TrainItem from './components/train';
import HotelItem from './components/hotel';
import AirlineItem from './components/airline';
import FavoriteItemWrap from './components/wrap';

import styles from './styles/favorites.module.css';

const {
  ICON: {
    TYPES: {
      EMPTY_STATE_BIG_ICONS: { FAVORITE },
    },
  },
  TEXT: {
    TYPES: { BOLD_32 },
  },
} = PROPS;

const LABELS = {
  HEADER: getText('favorites:title'),
  QUICK_SEARCH: getText('favorites:fastSearch'),
  LOADING: getText('favorites:loader'),
  EMPTY: getText('favorites:empty'),
  REMOVE: getText('favorites:notifications.remove'),
};

const FAVORITE_ITEM = {
  [SERVICETYPE.AIR]: AirlineItem,
  [SERVICETYPE.TRAIN]: TrainItem,
  [SERVICETYPE.TRANSFER]: TransferItem,
  [SERVICETYPE.HOTEL]: HotelItem,
};

const FAVORITE_ITEM_QA_ATTRS = {
  [SERVICETYPE.AIR]: QA_ATTRIBUTES.favorites.airline,
  [SERVICETYPE.TRAIN]: QA_ATTRIBUTES.favorites.train,
  [SERVICETYPE.HOTEL]: QA_ATTRIBUTES.favorites.hotel,
};

@observer
class Favorites extends Component {
  static propTypes = {
    store: PropTypes.object.isRequired,
    favoritesService: PropTypes.object.isRequired,
    notificationService: PropTypes.object.isRequired,
    transferService: PropTypes.object.isRequired,
    airlineService: PropTypes.object.isRequired,
    trainService: PropTypes.object.isRequired,
    hotelService: PropTypes.object.isRequired,
    featureFlagsService: PropTypes.object.isRequired,
    accountSettingsService: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    uiSettingsProtocol: PropTypes.object.isRequired,
  };

  isNoBookingTaxi = this.props.accountSettingsService.getBookingTaxi();

  componentDidMount() {
    this.props.favoritesService.load();
  }

  getOtherProps = type => {
    if (type === SERVICETYPE.TRAIN) {
      return {
        trainService: this.props.trainService,
      };
    }

    return null;
  };

  handleGoToSearch = (favorite, data, Hash) => {
    let service = null;
    let uri = null;

    switch (favorite.Type) {
      case SERVICETYPE.AIR: {
        service = this.props.airlineService;
        uri = ROUTES.SEARCH.AIR;
        break;
      }
      case SERVICETYPE.HOTEL: {
        service = this.props.hotelService;
        uri = ROUTES.SEARCH.HOTEL_FAVORITE(Hash);
        break;
      }
      case SERVICETYPE.TRAIN: {
        service = this.props.trainService;
        uri = ROUTES.SEARCH.TRAIN_RESULTS;
        break;
      }
      case SERVICETYPE.TRANSFER: {
        service = this.props.transferService;
        uri = ROUTES.SEARCH.TRANSFER;
        break;
      }
    }

    if (service) {
      let searchParams;
      service.setNewSearch();

      switch (favorite.Type) {
        case SERVICETYPE.HOTEL: {
          searchParams = {
            ...service.setSearchHotelFavoriteObject(data),
            is_favorite: true,
          };
          break;
        }
        case SERVICETYPE.TRANSFER: {
          searchParams = {
            ...service.getSearchObjectByFavorites(favorite.Item),
            is_favorite: true,
          };
          break;
        }

        default: {
          searchParams = {
            ...service.getSearchObject(favorite.Item, data),
            is_favorite: true,
          };
          break;
        }
      }

      const searchString = stringifySearchParams(searchParams);
      this.props.history.push({
        pathname: uri,
        search: searchString,
      });

      MainAnalytic.send(MainAnalytic.CATEGORY.FAVORITES, MainAnalytic.ACTIONS.FAVORITES.SEARCH, { label: SERVICETYPERUMN[favorite.Type] });
    }
  };

  handleRemoveItem = (favoriteId, favorite) => {
    this.props.favoritesService.remove(favoriteId).then(() => {
      this.props.notificationService.send({
        message: LABELS.REMOVE,
        level: 'success',
        qaAttr: QA_ATTRIBUTES.favorites.notification,
      });

      MainAnalytic.send(MainAnalytic.CATEGORY.FAVORITES, MainAnalytic.ACTIONS.FAVORITES.REMOVEFROMFAVORITES, { label: SERVICETYPERUMN[favorite.Type] });
    });
  };

  handleOpenDialog = type => {
    MainAnalytic.send(MainAnalytic.CATEGORY.FAVORITES, MainAnalytic.ACTIONS.FAVORITES.GOTOSEARCH, { label: SERVICETYPERUMN[type] });
  };

  renderEmptyPage = () => {
    const { uiSettingsProtocol: { searchServiceTypeForMenu } } = this.props;

    return (
      <EmptyPanel
        searchMenuItems={ getDataForEmptyPanel(searchServiceTypeForMenu, this.isNoBookingTaxi) }
        title={ LABELS.EMPTY }
        iconType={ FAVORITE }
        alternativeDesign={ isSmartAgent }
        qaAttr={ QA_ATTRIBUTES.favorites.empty }
        qaAttrSearchPanel={ QA_ATTRIBUTES.favorites.searchPanel }
      />
    );
  };

  renderItems = () =>
    this.props.store.favorites.map(favorite => {
      const { Type, Id, Item, Hash } = favorite;
      const FavoriteItem = FAVORITE_ITEM[Type];
      const qaAttrs = FAVORITE_ITEM_QA_ATTRS[Type];

      const dialogProps =
        Type === SERVICETYPE.AIR
          ? {
            isBothSide: Item.Routes.length > 1,
          }
          : null;

      return (
        <FavoriteItemWrap
          key={ Id }
          id={ Id }
          type={ Type }
          dialogProps={ dialogProps }
          onSearch={ data =>
            this.handleGoToSearch(favorite, data, Hash)
          }
          onRemoveItem={ () => this.handleRemoveItem(Id, favorite) }
          onOpenDialog={ this.handleOpenDialog }
          qaAttrChooseDates={ QA_ATTRIBUTES.favorites.chooseDates }
          qaAttrDelete={ QA_ATTRIBUTES.favorites.delete }
          qaAttrDeleteDialog={ QA_ATTRIBUTES.favorites.deleteDialog }
          qaAttrDialog={ QA_ATTRIBUTES.favorites.chooseDateDialog }
          qaAttrIcon={ QA_ATTRIBUTES.favorites.wrapIcon }
          { ...this.getOtherProps(Type) }
        >
          <FavoriteItem favorite={ favorite } qaAttrs={ qaAttrs } />
        </FavoriteItemWrap>
      );
    });

  renderFilters = () => {
    const {
      store: { tabs, currentTab },
      favoritesService: { setCurrentTab },
    } = this.props;

    return (
      <FilterTabs
        tabs={ tabs }
        currentTab={ currentTab }
        onClick={ setCurrentTab }
        qaAttr={ QA_ATTRIBUTES.favorites.filter }
      />
    );
  };

  renderSearch = () => {
    const {
      store: { tabs, searchInput },
      favoritesService: { setSearchInput },
    } = this.props;

    return (
      <StickySearchPanel
        adjustment={ STICKY.ADJUSTMENT }
        initial={ tabs.length > 2 ? 313 : 228 }
        style={ STICKY.STYLES }
        query={ searchInput }
        placeholder={ LABELS.QUICK_SEARCH }
        onChangeInput={ setSearchInput }
        qaAttr={ QA_ATTRIBUTES.favorites.search }
      />
    );
  };

  render() {
    const {
      store: { loading, cache, favorites, tabs },
    } = this.props;

    if (loading) {
      return <PageLoader text={ LABELS.LOADING } />;
    }

    if (!cache.length && !favorites.length) {
      return this.renderEmptyPage();
    }

    const filtersHtml = !!tabs.length && this.renderFilters();
    const searchHtml = !!cache.length && this.renderSearch();

    return (
      <div className={ styles.wrapper }>
        <Text
          type={ BOLD_32 }
          className={ styles.header }
          qaAttr={ QA_ATTRIBUTES.favorites.header }
        >
          {LABELS.HEADER}
        </Text>
        {filtersHtml}
        {searchHtml}
        <div className={ styles.list }>{this.renderItems()}</div>
      </div>
    );
  }
}

export default Favorites;
