import React from 'react';
import { Checkbox, Text } from 'new-ui';

import { getText } from '../../../../../../../i18n';

import ACTION from '../../../../../../bi/services/hotels/action';
import { HotelFilters } from '../../../../../../bi/services/hotels/types';

import { QA_ATTRIBUTES } from '../../../../../../bi/constants/attributesForTests';

import styles from './styles/index.module.css';

const LABELS = {
  ONLINE: getText('hotels:regionResult.filters.onlyOnline'),
  BREAKFAST: getText('hotels:regionResult.filters.mealIncluded'),
  HASCANCELLATION: getText('hotels:regionResult.filters.freeCancellation'),
};

interface FiltersProps {
  filters: HotelFilters,
  disabled: boolean
  updateFilters(field: string, value: { filterName: string, value: boolean }): void,
}

const Filters = ({ filters, updateFilters, disabled }: FiltersProps) => {
  const updateOnlineFilter = (value: boolean) => updateFilters(ACTION.UPDATEHOTELFILTER, { filterName: 'online', value });

  const updateBreakfastFilter = (value: boolean) => updateFilters(ACTION.UPDATEHOTELFILTER, { filterName: 'breakfast', value });

  const updateCancellationFilter = (value: boolean) => updateFilters(ACTION.UPDATEHOTELFILTER, { filterName: 'hasCancellation', value });

  const renderOnline = () => (
    <div className={ styles.block }>
      <Checkbox
        // @ts-ignore
        id={ 'check_online' }
        value={ filters.online }
        onChange={ updateOnlineFilter }
        disabled={ disabled }
      >
        <Text qaAttr={ QA_ATTRIBUTES.hotels.current.online } type='NORMAL_14'>
          { LABELS.ONLINE }
        </Text>
      </Checkbox>
    </div>
  );

  const renderBreakfast = () => (
    <div className={ styles.block }>
      <Checkbox
        // @ts-ignore
        id={ 'check_breakfast' }
        value={ filters.breakfast }
        onChange={ updateBreakfastFilter }
        disabled={ disabled }
      >
        <Text qaAttr={ QA_ATTRIBUTES.hotels.current.breakfast } type='NORMAL_14'>
          {LABELS.BREAKFAST}
        </Text>
      </Checkbox>
    </div>
  );

  const renderRefundability = () => (
    <div className={ styles.block }>
      <Checkbox
        // @ts-ignore
        id={ 'has_cancellation' }
        value={ filters.hasCancellation }
        onChange={ updateCancellationFilter }
        disabled={ disabled }
      >
        <Text qaAttr={ QA_ATTRIBUTES.hotels.current.hasCancellation } type='NORMAL_14_130'>
          {LABELS.HASCANCELLATION}
        </Text>
      </Checkbox>
    </div>
  );

  return (
    <div className={ styles.wrapper } >
      <div className={ styles.blocks } >
        {renderOnline()}
        {renderBreakfast()}
        {renderRefundability()}
      </div>
    </div>
  );
};

export default Filters;
