// @ts-nocheck
import React, { useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { Button, Dialog, Price, PROPS, Text, Tooltip } from 'new-ui';
import { clsx } from 'clsx';

import { getText } from '../../../../../i18n';

import { useStores } from '../../../../bi/context';
import { MOBX_STORES } from '../../../../bi/context/stores';
import { useServices } from '../../../../bi/context/services';

import { sendStatOnOpenApproveDialog, sendStatOnSendToApprove } from '../../../../bi/services/cart/approveAnalytic';

import { ApprovalSchemeCartDialog } from '../../../../components/ApprovalSchemeCartDialog';
import ApproveDialog from '../../../../components/ApproveDialog';
import { CartActionsCodeInput } from './components/CartActionsCodeInput';

import getAccountId from '../../../../bi/utils/getAccountId';
import { getPriceAllCertificates } from '../../../../bi/utils/airline';
import { checkAllSteps } from '../../../../bi/utils/approval';
import {
  cartHasAirUnderageByProviders,
  getCartByStatus,
  getFieldWithTA,
  getUnlockTravelApproval,
  getUsersNotValid,
} from '../../../../bi/utils/cart';
import { MainAnalytic } from '../../../../bi/utils/analytics';

import { CART_APPROVE_TYPES, CART_STATUS, CARTACTIONTYPES } from '../../../../bi/constants/cart';
import { QA_ATTRIBUTES } from '../../../../bi/constants/attributesForTests';

import { LoadingFields } from '../../../../bi/services/approvalSchemes';

import styles from './styles.module.css';

const {
  BUTTON: { TYPES: { SECONDARY } },
  TEXT: { TYPES: { NORMAL_14_130 }, COLORS: { WHITE } },
} = PROPS;

const TOOLTIPTEXTS = {
  DEFAULT: getText('cart:tooltipTexts.default'),
  APPROVE: getText('cart:tooltipTexts.approve'),
  REASONS: getText('cart:tooltipTexts.reasons'),
  UNAVAILABLE: getText('cart:tooltipTexts.unavailable'),
  FOULS: getText('cart:tooltipTexts.fouls'),
  DEMO: getText('cart:tooltipTexts.demo'),
  MANDATORYPROJECT: getText('cart:tooltipTexts.mandatoryProject'),
  APPROVEMANDATORYPROJECT: getText('cart:tooltipTexts.approveMandatoryProjects'),
  DISABLEDACCOUNT: getText('cart:tooltipTexts.disabledAccount'),
  VERIFICATIONCODE: getText('cart:tooltipTexts.verificationCode'),
  AIR_ADULT: getText('cart:tooltipTexts.airAdult'),
  CUSTOM_ANALYTICS: getText('cart:tooltipTexts.customAnalytics'),
  TRAVEL_APPROVAL_SCHEME: getText('components:travelApproval.travelApprovalRequest'),
  CHOOSE_VALID_USER: getText('cart:tooltipTexts.chooseValidUser'),
  TRANSFERS: getText('cart:tooltipTexts.transfersValidation'),
};

const LABELS = {
  SEND_TO_APPROVE: getText('cart:sendToApprove'),
  SEND_TO_REQUEST: getText('cart:sendToRequest'),
  PURCHASE: getText('cart:purchase'),
  BOOK_TRIP: getText('cart:bookTrip'),
  SELF_APPROVE: {
    BUTTON: getText('cart:purchase'),
    TEXT: getText('cart:selfApprove'),
  },
};

const CART_ACTION_BUTTONS_BY_RIGHTS = {
  UNLIMITED: 'UNLIMITED',
  ONLYAFTERAPPROVE: 'ONLYAFTERAPPROVE',
  UNAVAILABLE: 'UNAVAILABLE',
  TRAVELPOLICY: 'TRAVELPOLICY',
  LIMITED: 'LIMITED',
  UNDEFINED: 'UNDEFINED',
  APPROVALSCHEME: 'APPROVALSCHEME',
  TRAVEL_APPROVAL: 'TRAVELAPPROVAL',
};

const CartActions = observer(({
  cart,
  carts,
  verificationCode,
  hasVerificationCodeFlag,
  verificationCodeValue,
  handleCheckCode,
  mandatoryProject,
  analyticsLoading,
  underage,
  customAnalyticsService,
  workspaceData,
  workspaceService,
  employeeService,
  userSessionService,
  handlePurchaseCart,
  cartService,
  isTravelApproval,
  setLoading,
  handleToRequestPurchaseCart,
  accountSettingsService,
  isAgregatorCKR,
}) => {
  const {
    transferStore,
    travelApprovalsStore,
  } = useStores([MOBX_STORES.TRANSFER_STORE, MOBX_STORES.TRAVEL_APPROVALS]);

  const {
    featureFlagsService,
    approvalSchemesService,
    travelApprovalService,
    travelApprovalService: { travelApprovalsStore: { validateLoadingEmployee } },
    approvalSchemesService: { settingSchemeStore: { approverListFact }, netStore },
  } = useServices(['ApprovalSchemes', 'FeatureFlags', 'TravelApproval']);

  useEffect(() => () => {
    netStore.clearAll();
    travelApprovalService.netStore.clearAll();
  }, []);

  const sideLoading = netStore.getIsSomeStatuses([
    LoadingFields.deleteEmployee,
    LoadingFields.oneC,
    LoadingFields.setEmployee,
    LoadingFields.editProject,
    LoadingFields.editCompany,
    LoadingFields.validateCart,
    LoadingFields.getFactApprovers,
    LoadingFields.loadScheme,
    LoadingFields.recalculateEmployee,
  ], netStore.loadingStatuses.LOADING);

  const ffList = featureFlagsService.getFeatureFlags();

  const [tpShowModal, setTpShowModal] = useState(false);
  const [approvalSchemeDialog, setApprovalSchemeDialog] = useState(false);

  const [approversList, setApproversList] = useState([]);
  const [approveChosenApprovers, setChosenApprovers] = useState([]);
  const [approveShowModal, setApproveShowModal] = useState(false);
  const [approveComment, setApproveComment] = useState('');

  const unsubscribeUsersFn = useRef(() => {});
  const unsubscribeFn = useRef(() => {});

  const accountId = getAccountId();
  const employeeId = useRef(workspaceData.Rights.EmployeeId);
  const login = useRef(workspaceData.Rights.Login);
  const tpActionsType = useRef(workspaceData.IsDemo
    ? CART_ACTION_BUTTONS_BY_RIGHTS.UNAVAILABLE
    : CART_ACTION_BUTTONS_BY_RIGHTS[workspaceData.Rights.BuyTripPersonal.toUpperCase()]);

  const bookByPassengerScheme = accountSettingsService.store.accountSettings.bookByPassengerScheme;

  const loadApprovalSchemes = () => {
    approvalSchemesService.loadList();
    approvalSchemesService.loadAdmins();
  };

  useEffect(() => {
    loadApprovalSchemes();

    return () => {
      unsubscribeUsersFn.current();
      unsubscribeFn.current();
    };
  }, []);

  const { price, unlock, sources, fouls, project } = cart;
  const { hasVerificationCode, autoVerification, verificationCodeIsTrue } = verificationCode;
  const { enabled: enabledAttr, disabled: disabledAttr } = QA_ATTRIBUTES.cart.paymentButton;
  const { buttonApprove, buttonDisabled } = QA_ATTRIBUTES.cart.approval.scheme;

  const getQaAttrButton = (disabled) => (disabled ? disabledAttr : enabledAttr);

  const getQaAttrButtonApprove = (disabled) => (disabled ? buttonDisabled : buttonApprove);

  const filteredApprovers = (list, loginParam) => list.filter(({ Email }) => Email !== loginParam);

  const setApproveDialog = value => {
    setApproveShowModal(value);
    setApproveComment('');
  };

  const changeApprove = approversListParam => {
    setApproversList(approversListParam);
    setChosenApprovers(approversListParam.length === 1 ? [approversListParam[0].Email] : []);
    setApproveDialog(true);
  };

  const handleApproveError = async (err) => {
    setApproveDialog(false);

    if (!err || !err.response || !err.response.body || !err.response.body.ReservationFailedItems) {
      return;
    }

    cartService.updateReservationFailedItems(err.response.body.ReservationFailedItems);
  };

  const handleApproversListChange = (email, chooseOne = false) => {
    const currentChosenApprovers = [...approveChosenApprovers];
    const index = currentChosenApprovers.indexOf(email);

    if (chooseOne) {
      return setChosenApprovers([email]);
    }

    if (index !== -1) {
      currentChosenApprovers.splice(index, 1);

      return setChosenApprovers(currentChosenApprovers);
    }

    currentChosenApprovers.push(email);

    return setChosenApprovers(currentChosenApprovers);
  };

  const handleSendToApprove = async (model, isPreSteps, requestId, CanSkipApproval) => {
    if (CanSkipApproval) return handlePurchaseCart(cart);

    MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.CART.SEND_TO_APPROVE_BUTTON);

    const { CartId, StepsSettings, ApprovalComment, Comment } = model;

    const comment = ApprovalComment || Comment;

    const getApproverName = ({ Name, Email }) => (Name === Email ? Email : `${Name} - ${Email}`);

    sendStatOnSendToApprove(approveChosenApprovers, comment, approversList, getApproverName);

    if (hasVerificationCodeFlag && !verificationCode.autoVerification) {
      await cartService.saveVerificationCode(CartId, verificationCodeValue);
    }

    setLoading(true);

    return cartService.sendToApprove({
      CartId,
      Users: approveChosenApprovers,
      Message: {
        Comment: comment,
      },
      StepsSettings,
      Type: CART_APPROVE_TYPES.DEFAULT,
    },
    isPreSteps,
    requestId)
      .then(() => cartService.load(accountId))
      .catch(handleApproveError);
  };

  const handlePurchaseCartClick = (cartParams) => {
    handlePurchaseCart(cartParams);
    MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.CART.CART_PAY);
  };

  const emptyComment = (comment) => {
    const {
      store: {
        accountSettings: {
          forceTripApproveComment,
        },
      },
    } = accountSettingsService;

    return forceTripApproveComment && !comment.trim();
  };

  const prepareCartActionsDialog = (type, cartParam, header) => {
    switch (type) {
      case CARTACTIONTYPES.DEFAULT: {
        return (
          <Dialog
            show={ tpShowModal }
            onChange={ setTpShowModal }
            showClosing
          >
            <div className={ styles['dialog-content'] }>
              { !!header && (<Text type={ PROPS.TEXT.TYPES.BOLD_20_130 }>{ header }</Text>) }
              <div className={ styles.actions }>
                <Button
                  onClick={ () => handlePurchaseCartClick(cartParam) }
                  type={ PROPS.BUTTON.TYPES.SECONDARY }
                >
                  { LABELS.YES }
                </Button>
                <Button
                  onClick={ () => setTpShowModal(false) }
                  type={ PROPS.BUTTON.TYPES.TEXTUAL_MEDIUM }
                  className={ styles.cancel }
                >
                  { LABELS.NO }
                </Button>
              </div>
            </div>
          </Dialog>
        );
      }
      case CARTACTIONTYPES.APPROVE: {
        const approve = {
          approversList,
          comment: approveComment,
          chosenApprovers: approveChosenApprovers,
        };

        return (
          <ApproveDialog
            show={ approveShowModal }
            cartId={ cart.id }
            header={ header }
            approve={ approve }
            updateApproveComment={ setApproveComment }
            handleApproversListChange={ handleApproversListChange }
            onClose={ setApproveDialog }
            handleSendToApprove={ () => handleSendToApprove({ CartId: cart.id, Comment: approveComment }) }
            emptyComment={ emptyComment }
            showApproversCKR={ isAgregatorCKR }
          />
        );
      }
      case CARTACTIONTYPES.APPROVALSCHEME: {
        if (!approvalSchemeDialog) return null;

        const chosenApprovedRequestId = travelApprovalsStore.chosenApprovedRequest?.Id;

        const toggleSchemeApproveDialog = () => setApprovalSchemeDialog(prevApprovalSchemeDialog => !prevApprovalSchemeDialog);

        return (
          <ApprovalSchemeCartDialog
            workspaceService={ workspaceService }
            userSessionService={ userSessionService }
            approvalSchemesService={ approvalSchemesService }
            cart={ cart }
            isTravelApproval={ isTravelApproval }
            showDialog={ approvalSchemeDialog }
            toggleShowDialog={ toggleSchemeApproveDialog }
            handleSendToApprove={ handleSendToApprove }
            emptyComment={ emptyComment }
            chosenApprovedRequestId={ chosenApprovedRequestId }
            approveTexts={ LABELS.SELF_APPROVE }
            showApproversCKR={ isAgregatorCKR }
          />
        );
      }
    }

    return null;
  };

  const getUnsetRequiredAnalyticsNames = () => {
    const { store: { requiredCustomAnalytics } } = customAnalyticsService;

    const normalCart = getCartByStatus(carts)(CART_STATUS.NORMAL);

    if (!normalCart) {
      return [];
    }

    const { userAnalytics = [], sources: sourcesCart = [] } = normalCart;

    const [requiredTripAnalytics = [], requiredServiceAnalytics = []] = requiredCustomAnalytics;
    const names = [];

    requiredServiceAnalytics.forEach(({ Name, Values = [] }) => {
      const isSetForEveryService = sourcesCart.every(({ UserAnalytics = [] }) => Values.some(({ Id: analyticsValueId }) => UserAnalytics.includes(analyticsValueId)));

      if (isSetForEveryService) {
        return;
      }

      names.push(Name);
    });

    requiredTripAnalytics.forEach(({ Name, Values = [] }) => {
      const isSetForTrip = Values.some(({ Id: analyticsValueId }) => userAnalytics.includes(analyticsValueId));

      if (isSetForTrip) {
        return;
      }

      names.push(Name);
    });

    return names;
  };

  const prepareCartActionsTooltip = (type, foulsParam, unlockParam = true, reasons = true, unlockProject, hasAirWithAdult = false) => {
    const { validationTransferFields } = transferStore;
    const { IsDemo } = workspaceData;

    const disabledAccount = workspaceService.isDisabledAccount;
    const allUnlock = mandatoryProject ? unlockParam && unlockProject : unlockParam;

    const unsetRequiredAnalyticsNames = getUnsetRequiredAnalyticsNames();

    let typeText = null;
    let reasonsText = null;
    let foulsText = null;
    let demoText = null;
    let codeText = null;
    let underageText = null;
    let customAnalyticsText = null;
    let transfersText = null;

    if (!disabledAccount) {
      typeText = !allUnlock && TOOLTIPTEXTS[type];
      reasonsText = !reasons && TOOLTIPTEXTS.REASONS;
      foulsText = !!(foulsParam && foulsParam.length) && TOOLTIPTEXTS.FOULS;
      demoText = IsDemo && TOOLTIPTEXTS.DEMO;
      codeText = hasVerificationCodeFlag.current && unlockParam && TOOLTIPTEXTS.VERIFICATIONCODE;
      underageText = hasAirWithAdult && unlockParam && TOOLTIPTEXTS.AIR_ADULT;
      transfersText = validationTransferFields && TOOLTIPTEXTS.TRANSFERS;
      customAnalyticsText = !!unsetRequiredAnalyticsNames.length
        && !typeText
        && !demoText
        && !reasonsText
        && !foulsText
        && !codeText
        && !underageText
        && !transfersText
        && `${TOOLTIPTEXTS.CUSTOM_ANALYTICS} ${unsetRequiredAnalyticsNames.join(', ')}`;
    }

    const disabledAccountText = disabledAccount && TOOLTIPTEXTS.DISABLEDACCOUNT;
    const unavailableText = (
      !typeText &&
      !demoText &&
      !reasonsText &&
      !foulsText &&
      !disabledAccountText &&
      !codeText &&
      !underageText &&
      !transfersText &&
      !customAnalyticsText
    ) && TOOLTIPTEXTS.UNAVAILABLE;

    const defaultText = text => (
      <Text
        color={ WHITE }
        type={ NORMAL_14_130 }
        qaAttr={ QA_ATTRIBUTES.cart.buttonTooltip }
      >
        { text }
      </Text>
    );

    const typeContent = typeText && defaultText(typeText);
    const demoContent = demoText && defaultText(demoText);
    const reasonsContent = reasonsText && defaultText(reasonsText);
    const foulsContent = foulsText && defaultText(foulsText);
    const unavailableContent = unavailableText && defaultText(unavailableText);
    const disabledAccountContent = disabledAccountText && defaultText(disabledAccountText);
    const codeContent = codeText && defaultText(codeText);
    const underageContent = underageText && defaultText(underageText);
    const customAnalyticsContent = customAnalyticsText && defaultText(customAnalyticsText);
    const transfersContent = transfersText && defaultText(transfersText);

    return (
      <div className={ styles['tooltip-content'] }>
        { typeContent }
        { demoContent }
        { reasonsContent }
        { foulsContent }
        { unavailableContent }
        { disabledAccountContent }
        { codeContent }
        { underageContent }
        { customAnalyticsContent }
        { transfersContent }
      </div>
    );
  };

  const verifyCustomAnalytics = ({ userAnalytics = [], sources: sourcesParam = [] }) => {
    const { store: { requiredCustomAnalytics } } = customAnalyticsService;

    const [requiredTripAnalytics, requiredServiceAnalytics] = requiredCustomAnalytics;

    const tripAnalyticsAreValid = requiredTripAnalytics.every(({ Values }) =>
      Values.some(({ Id: analyticsValueId }) => userAnalytics.includes(analyticsValueId)),
    );

    const serviceAnalyticsAreValid = sourcesParam.every(({ UserAnalytics = [] }) =>
      requiredServiceAnalytics.every(({ Values }) =>
        Values.some(({ Id: analyticsValueId }) => UserAnalytics.includes(analyticsValueId)),
      ),
    );

    return tripAnalyticsAreValid && serviceAnalyticsAreValid;
  };

  const getAllApprovers = () => employeeService.getAllApprovers().then((allApprovers) => {
    changeApprove(filteredApprovers(allApprovers, login.current));
  });

  const prepareSendToApproveDialog = async () => {
    sendStatOnOpenApproveDialog(cart);

    try {
      const list = await employeeService.getApproversForUser(employeeId.current);

      if (!list.length) {
        return getAllApprovers();
      }

      return changeApprove(filteredApprovers(list, login.current));
    } catch {
      return getAllApprovers();
    }
  };

  const renderCartActionsButton = () => {
    const { chosenApprovedRequest } = travelApprovalsStore;
    const { validationTransferFields } = transferStore;

    const reasonsMatched = sources.every(source => (Object.keys(source.TravelPolicy).length ? source.Reason && !!source.Reason.length : true));
    const unlockProject = mandatoryProject ? project : true;
    const unlockCode = hasVerificationCodeFlag ? (verificationCodeIsTrue || autoVerification) : true;
    const matchTP = sources.every(source => !Object.keys(source.TravelPolicy).length);
    const hasAirWithAdult = cartHasAirUnderageByProviders(sources);
    const gLoading = travelApprovalService.netStore.getIsStatus('assignRequest', netStore.loadingStatuses.LOADING)
      || analyticsLoading
      || sideLoading
      || validateLoadingEmployee
      || underage.loading;

    const requiredCustomAnalyticsAreSet = verifyCustomAnalytics(cart);

    const renderToolitpWithButton = ({
      field,
      disabled,
      action,
      label,
      dialogLabel = '',
    }) => (
      <>
        <Tooltip
          show={ disabled || hasAirWithAdult }
          renderContent={ () => prepareCartActionsTooltip(field, fouls, unlock, true, unlockProject, hasAirWithAdult) }
        >
          <Button
            type={ SECONDARY }
            onClick={ () => action(cart) }
            disabled={ disabled }
            loading={ gLoading }
            qaAttr={ getQaAttrButton(disabled) }
            qaAttrLoading={ QA_ATTRIBUTES.cart.paymentButton.loading }
          >
            { label }
          </Button>
        </Tooltip>
        { prepareCartActionsDialog(CARTACTIONTYPES.DEFAULT, cart, dialogLabel) }
      </>
    );

    const onlyAfterApprove = () => {
      const disabled = !unlock || !reasonsMatched || (fouls && fouls.length !== 0) || !unlockProject || !unlockCode || !requiredCustomAnalyticsAreSet || validationTransferFields;
      const fieldApprove = !unlockProject ? CARTACTIONTYPES.APPROVEMANDATORYPROJECT : CARTACTIONTYPES.APPROVE;

      return (
        <>
          <Tooltip
            renderContent={ () => prepareCartActionsTooltip(fieldApprove, fouls, unlock, reasonsMatched, unlockProject, hasAirWithAdult) }
            show={ disabled }
          >
            <Button
              type={ SECONDARY }
              onClick={ () => prepareSendToApproveDialog(cart) }
              disabled={ disabled }
              loading={ gLoading }
              qaAttr={ getQaAttrButton(disabled) }
              qaAttrLoading={ QA_ATTRIBUTES.cart.paymentButton.loading }
            >
              { LABELS.SEND_TO_APPROVE }
            </Button>
          </Tooltip>
          { prepareCartActionsDialog(CARTACTIONTYPES.APPROVE, cart, LABELS.SEND_TO_APPROVE) }
        </>
      );
    };

    const unLimitedCart = (travelApproveRoles = true) => {
      const unlockTravelApproval = isTravelApproval ? travelApproveRoles && unlock : unlock;
      const disabled = !unlockTravelApproval || !unlockProject || !unlockCode || !requiredCustomAnalyticsAreSet || !travelApproveRoles || validationTransferFields;
      const field = !unlockProject ? CARTACTIONTYPES.MANDATORYPROJECT : CARTACTIONTYPES.DEFAULT;
      let label = LABELS.PURCHASE;

      if (hasAirWithAdult) {
        label = LABELS.SEND_TO_REQUEST;
      }

      if (isAgregatorCKR) {
        label = LABELS.BOOK_TRIP;
      }

      const action = hasAirWithAdult ? handleToRequestPurchaseCart : handlePurchaseCartClick;

      return renderToolitpWithButton({ field, disabled, action, label, travelApproveRoles, dialogLabel: LABELS.DISRUPTED_TP_DIALOG_HEADER_UNLIMITED });
    };

    const afterApprovalScheme = (isApprovedRequest = true) => {
      const { validateApprovedRequest } = travelApprovalsStore;
      const {
        store: {
          list: {
            value,
            loading: approvalSchemeLoading,
          },
        },
      } = approvalSchemesService;

      const usersNotValid = getUsersNotValid(isTravelApproval, sources, chosenApprovedRequest);
      const unlockTravelApproval = getUnlockTravelApproval(isTravelApproval, isApprovedRequest, unlock, usersNotValid);
      const normalCart = getCartByStatus(carts)(CART_STATUS.NORMAL);

      const disabled = !unlockTravelApproval || !reasonsMatched || (fouls && fouls.length !== 0) || !unlockProject || !unlockCode || !requiredCustomAnalyticsAreSet || validationTransferFields;
      const field = !unlockProject ? CARTACTIONTYPES.MANDATORYPROJECT : CARTACTIONTYPES.DEFAULT;
      const fieldWithTA = getFieldWithTA(isTravelApproval, field, isApprovedRequest, usersNotValid);

      const approvalSchemeIdUser = workspaceService.get().ApprovalSchemeId;
      const specApprovalScheme = approverListFact?.SchemeId;
      const specCanSkipApproval = approverListFact?.CanSkipApproval;
      const approvalSchemeId = bookByPassengerScheme ? specApprovalScheme : approvalSchemeIdUser;
      const userId = workspaceService.get().Rights.UserId;

      const appliedApprovalScheme = value ? value.find(({ Id }) => Id === approvalSchemeId) : null;
      const checkPreSteps = checkAllSteps(appliedApprovalScheme?.PreSteps, userId);
      const checkSteps = checkAllSteps(appliedApprovalScheme?.Steps, userId);
      const stepsApproverUser = !appliedApprovalScheme?.Steps.length || checkSteps;
      const isUserApprover = isTravelApproval && checkPreSteps && stepsApproverUser;
      const validCartAndNoSelectSteps = validateApprovedRequest.Valid && stepsApproverUser;
      const unlockEmployeeInCartWithTP = normalCart.sources.find(i => Object.keys(i.TravelPolicy).length) || null;
      const checkUnlockEmployeeInCartWithTP = unlockEmployeeInCartWithTP ? !Object.keys(unlockEmployeeInCartWithTP).length : true;

      if ((isApprovedRequest
        && (specCanSkipApproval || (
          unlockTravelApproval
            && (isUserApprover || validCartAndNoSelectSteps)
            && checkUnlockEmployeeInCartWithTP
        ))
      )) {
        return unLimitedCart(specCanSkipApproval ? true : chosenApprovedRequest);
      }

      return (
        <>
          <Tooltip
            show={ disabled }
            renderContent={ () => prepareCartActionsTooltip(fieldWithTA, fouls, unlockTravelApproval, reasonsMatched, unlockProject) }
          >
            <Button
              loading={ approvalSchemeLoading || gLoading }
              type={ PROPS.BUTTON.TYPES.SECONDARY }
              disabled={ disabled }
              onClick={ () => setApprovalSchemeDialog(true) }
              qaAttr={ getQaAttrButtonApprove(disabled) }
              qaAttrLoading={ QA_ATTRIBUTES.cart.approval.scheme.buttonLoading }
            >
              { LABELS.SEND_TO_APPROVE }
            </Button>
          </Tooltip>
          { prepareCartActionsDialog(CARTACTIONTYPES.APPROVALSCHEME, cart) }
        </>
      );
    };

    switch (tpActionsType.current) {
      case CART_ACTION_BUTTONS_BY_RIGHTS.LIMITED: {
        const disabled = !unlock || !reasonsMatched || !unlockProject || !unlockCode || !requiredCustomAnalyticsAreSet;
        const field = !unlockProject ? CARTACTIONTYPES.MANDATORYPROJECT : CARTACTIONTYPES.DEFAULT;
        const label = hasAirWithAdult ? LABELS.SEND_TO_REQUEST : LABELS.PURCHASE;
        const action = hasAirWithAdult ? handleToRequestPurchaseCart : handlePurchaseCartClick;

        return renderToolitpWithButton({ field, disabled, action, label });
      }
      case CART_ACTION_BUTTONS_BY_RIGHTS.UNLIMITED: {
        return unLimitedCart();
      }
      case CART_ACTION_BUTTONS_BY_RIGHTS.TRAVELPOLICY: {
        const disabled = !unlock || !reasonsMatched || (fouls && fouls.length !== 0) || !unlockProject || !unlockCode || !requiredCustomAnalyticsAreSet || validationTransferFields;
        const field = !unlockProject ? CARTACTIONTYPES.MANDATORYPROJECT : CARTACTIONTYPES.DEFAULT;
        const fieldApprove = !unlockProject ? CARTACTIONTYPES.APPROVEMANDATORYPROJECT : CARTACTIONTYPES.APPROVE;
        const label = hasAirWithAdult ? LABELS.SEND_TO_REQUEST : LABELS.PURCHASE;
        const action = hasAirWithAdult ? handleToRequestPurchaseCart : handlePurchaseCartClick;

        const content = matchTP ? renderToolitpWithButton({ field, disabled, action, label }) : (
          <>
            <Tooltip
              renderContent={ () => prepareCartActionsTooltip(fieldApprove, fouls, unlock, reasonsMatched, unlockProject) }
              show={ disabled }
            >
              <Button
                type={ PROPS.BUTTON.TYPES.SECONDARY }
                onClick={ () => prepareSendToApproveDialog() }
                disabled={ disabled }
                loading={ gLoading }
                qaAttr={ getQaAttrButton(disabled) }
                qaAttrLoading={ QA_ATTRIBUTES.cart.paymentButton.loading }
              >
                { LABELS.SEND_TO_APPROVE }
              </Button>
            </Tooltip>
            { prepareCartActionsDialog(CARTACTIONTYPES.APPROVE, cart, LABELS.DISRUPTED_TP_DIALOG_HEADER) }
          </>
        );

        return (
          content
        );
      }
      case CART_ACTION_BUTTONS_BY_RIGHTS.ONLYAFTERAPPROVE: {
        return onlyAfterApprove();
      }
      case CART_ACTION_BUTTONS_BY_RIGHTS.UNAVAILABLE:
      case CART_ACTION_BUTTONS_BY_RIGHTS.UNDEFINED: {
        return (
          <Tooltip renderContent={ () => prepareCartActionsTooltip(CARTACTIONTYPES.UNAVAILABLE) }>
            <Button
              type={ PROPS.BUTTON.TYPES.SECONDARY }
              onClick={ () => {} }
              disabled
              loading={ gLoading }
            >
              { LABELS.PURCHASE }
            </Button>
          </Tooltip>
        );
      }
      case CART_ACTION_BUTTONS_BY_RIGHTS.APPROVALSCHEME: {
        return afterApprovalScheme(chosenApprovedRequest);
      }
    }

    return null;
  };

  const renderPriceHtml = () => (
    ffList.Acqruiring ? null : (
      <Price
        qaAttr={ QA_ATTRIBUTES.cart.price }
        value={ price + getPriceAllCertificates(sources) }
        marginSmall
        type={ PROPS.TEXT.TYPES.BOLD_24 }
        className={ styles.price }
      />
    ));

  const codeInput =
    hasVerificationCodeFlag &&
    hasVerificationCode &&
    !autoVerification &&
    unlock &&
    <CartActionsCodeInput
      verificationCodeValue={ verificationCodeValue }
      verificationCode={ verificationCode }
      handleCheckCode={ handleCheckCode }
    />;

  const wrapper = clsx(
    {
      [styles['cart-actions-via-payments-method']]: ffList.Acqruiring,
      [styles['cart-actions']]: !ffList.Acqruiring,
    },
  );

  return (
    <div className={ wrapper }>
      { codeInput }
      { renderPriceHtml() }
      { renderCartActionsButton() }
    </div>
  );
});

CartActions.propTypes = {
  cart: PropTypes.object.isRequired,
  verificationCode: PropTypes.object.isRequired,
  hasVerificationCodeFlag: PropTypes.bool.isRequired,
  verificationCodeValue: PropTypes.string.isRequired,
  handleCheckCode: PropTypes.func.isRequired,
  mandatoryProject: PropTypes.bool.isRequired,
  analyticsLoading: PropTypes.bool.isRequired,
  underage: PropTypes.object.isRequired,
  workspaceData: PropTypes.object.isRequired,
  workspaceService: PropTypes.object.isRequired,
  employeeService: PropTypes.object.isRequired,
  userSessionService: PropTypes.object.isRequired,
  carts: PropTypes.array.isRequired,
  handlePurchaseCart: PropTypes.func.isRequired,
  approvalSchemesService: PropTypes.object.isRequired,
  cartService: PropTypes.object.isRequired,
  isTravelApproval: PropTypes.bool.isRequired,
  ffList: PropTypes.object.isRequired,
  setLoading: PropTypes.func.isRequired,
  handleToRequestPurchaseCart: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
};

export { CartActions };
