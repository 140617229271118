// @ts-nocheck
import { getText } from '../../../../i18n';

import CONFIG from '../../../../config';
import Store from './intercom/store';
import ACTION from './intercom/action';

import { SERVICETYPE } from '../../constants/serviceType';
import { DATEFORMATS, PATTERN } from '../../constants/dateFormats';
import { AEROEXPRESS_TARIFF_MAP } from '../aeroexpress/const';
import COUNTRIES from '../../constants/countries';

import createFromToAirTicket from '../../utils/createFromToAirTicket';
import createNewDateString from '../../utils/createNewDateString';
import CarrotQuest from './carrotQuest';
import Intercom from './intercom';
import { dateUtcFormat, defaultPattern, formatDate, hoursAndMinutesPattern } from '../../utils/formatDate';
import Favicon from '../../utils/favicon';
import trimTimezone from '../../utils/trimTimezone';

const LABELS = {
  TITLE: getText('services:chat.title'),
  CANCEL_ORDER: abroad => getText('services:chat.cancelOrder', { abroad }),
  CALCULATION_ORDER: getText('services:chat.calculationOrder'),
  EDIT_ORDER: abroad => getText('services:chat.editOrder', { abroad }),
  NEW_DATES: getText('services:chat.newDates'),
  EVENT: getText('services:chat.event'),
  AIR: getText('services:chat.air'),
  HOTEL: (orderId, oldDate, newDate, category, employee, guestCount, price) => getText('services:chat.hotel', { orderId, oldDate, newDate, category, employee, guestCount, price }),
  HOTEL_NAME: (name, city) => getText('services:chat.hotelName', { name, city }),
  TRANSFER: getText('services:chat.transfer'),
  TRAIN: getText('services:chat.train'),
  VIP_HALL: getText('services:chat.vipHall'),
  PERIOD_FROM: getText('services:chat.periodFrom'),
  PERIOD_TO: getText('services:chat.periodTo'),
  ALL_TIME: getText('services:chat.allTime'),
  MESSAGE: getText('services:chat.message'),
  FREE_CANCELLATION: (date: string) => getText('hotels:hotelResult.item.generalList.freeCancellation', { date }),
  FREE_CANCELLATION_FOREIGN: (date: string) => getText('hotels:hotelResult.item.generalList.freeCancellationForeign', { date }),
  ABROAD: getText('services:chat.abroad'),
  AEROEXPRESS: getText('services:chat.aeroexpress'),
};

class ChatDecorator {
  constructor(api) {
    this.store = Store();
    this.currentUser = null;
    this.chat = new Intercom(api, this.store);
    this.globalAPI = api;
    this.api = api.intercomChat;
    this.isCarrotQuestChat = false;

    this.title = document.title;
  }

  get() {
    return this.store.getState();
  }

  subscribe = (cb) => this.store.subscribe(cb);

  getChat = async (isDemo, isCarrotQuestChat) => {
    try {
      this.store.dispatch({ type: ACTION.CHANGECHAT, payload: isCarrotQuestChat });
      this.isCarrotQuestChat = isCarrotQuestChat && !isDemo;

      if (this.isCarrotQuestChat) this.setNewChat();
    } catch (e) {
      this.store.dispatch({ type: ACTION.CHANGECHAT, payload: false });
    }
  };

  setNewChat = () => {
    this.chat = new CarrotQuest(this.globalAPI, this.store);
    this.api = this.globalAPI.carrotQuestChat;
  };

  hasDisabledUser(user) {
    return CONFIG.INTERCOM.DISABLEDUSER.includes(user);
  }

  openSession(user, { companyName, companyId }, directoryName) {
    this.currentUser = user;
    this.chat.openSession(user, { companyName, companyId }, directoryName);
  }

  closeSession() {
    this.chat.closeSession();
  }

  updateSession() {
    this.chat.updateSession();
  }

  show = () => {
    this.chat.show();
  };

  sendRequestOffline = (data) => {
    this.store.dispatch({ type: ACTION.STARTSENDINGMESSAGETOADMIN });

    return this.api.sendRequestOffline(data)
      .then(() => setTimeout(() => {
        this.chat.showMessages();
      }, 1000))
      .finally(() => this.store.dispatch({ type: ACTION.ENDSENDINGMESSAGETOADMIN }));
  };

  sendTypeForAirline = id => this.api.sendType(id);

  sendMessageToAdmin = (user, msg, note = '', supressWidget = false) => this.chat.sendMessageToAdmin(user, msg, note, supressWidget);

  createMessageByOrder(order, newDateRange) {
    let mainText;
    const actualVersion = order.ActualVersion;
    const employee = actualVersion.Employees.map(em => `${em.Surname} ${em.Name}`).join(',');
    const data = JSON.parse(actualVersion.JsonData);

    const checkinDate = data.CustomCheckInDate
      ? formatDate(actualVersion.CheckinDate, PATTERN.DAY_MONTH_TIME_WITHOUT_SECONDS)
      : formatDate(actualVersion.CheckinDate, DATEFORMATS.DAY_MONTH);

    const checkoutDate = data.CustomCheckOutDate
      ? formatDate(actualVersion.CheckoutDate, PATTERN.DATE_TIME_WITHOUT_SECONDS)
      : formatDate(actualVersion.CheckoutDate, DATEFORMATS.DATE);

    const oldDateRange = `${checkinDate}-${checkoutDate}`;

    switch (actualVersion.ServiceType) {
      case SERVICETYPE.AIR: {
        const newDate = newDateRange ? `\n${LABELS.NEW_DATES}: ${createNewDateString(newDateRange)}` : '';
        const ticketNum = data.TicketsExtended && data.TicketsExtended.length ? data.TicketsExtended[0].Num : '';
        const fromTo = createFromToAirTicket(data);
        mainText = `${LABELS.AIR} ${employee}, ${ticketNum}, ${oldDateRange}, ${fromTo} ${newDate}`;
        break;
      }
      case SERVICETYPE.HOTEL: {
        const newDate = newDateRange ? `\n${LABELS.NEW_DATES}: ${createNewDateString(newDateRange, false)}` : '';
        const hotelName = data.Hotel.Name;
        const hotelCity = actualVersion.City;
        const hotelLabel = LABELS.HOTEL_NAME(hotelName, hotelCity);
        mainText = `
          \n${hotelLabel}
          \n${LABELS.HOTEL(order.Id, oldDateRange, newDate, data.Room.Category, employee, data.GuestCount, data.PriceDetails && data.PriceDetails.Total)}
        `;

        break;
      }
      case SERVICETYPE.TRANSFER: {
        mainText = `${LABELS.TRANSFER} ${actualVersion.City} ${employee}, ${oldDateRange}`;
        break;
      }
      case SERVICETYPE.TRAIN: {
        mainText = `${LABELS.TRAIN} ${data.StationDepart} - ${data.StationArrive} ${employee}, ${oldDateRange}`;
        break;
      }
      case SERVICETYPE.AEROEXPRESS: {
        const { Trip: { TariffType } } = data;
        mainText = `${LABELS.AEROEXPRESS} (${AEROEXPRESS_TARIFF_MAP[TariffType]}) ${employee}, ${oldDateRange}`;
        break;
      }
      case SERVICETYPE.CUSTOM: {
        const newDate = newDateRange ? `\n${LABELS.NEW_DATES}: ${createNewDateString(newDateRange)}` : '';
        mainText = `${data.Name} ${employee}, ${oldDateRange} ${newDate}`;
        break;
      }
      case SERVICETYPE.EVENT: {
        const newDate = newDateRange ? `\n${createNewDateString(newDateRange)}` : '';

        mainText = `
          \n${LABELS.EVENT}: ${data.Name}
          \n${newDate}
          ------------------
        `;

        break;
      }
      case SERVICETYPE.VIP_HALL: {
        mainText = `${LABELS.VIP_HALL} ${actualVersion.City} ${employee}, ${oldDateRange}`;
        break;
      }
    }

    return mainText;
  }

  createNoteByOrder = ({ ActualVersion: { JsonData, ServiceType, CompanyId } }, accountId, tripId) => {
    if (ServiceType === SERVICETYPE.HOTEL) {
      const { ProviderName, ReservationNumber } = JSON.parse(JsonData);

      const boLink = `${CONFIG.BACKOFFICE}/account/${accountId}/company/${CompanyId}/trip/${tripId}`;

      return `\n${ProviderName}  <a href='${boLink}'>${ReservationNumber}</a>`;
    }

    return '';
  };

  createMessageByHotelOrders = (orders, newDateRange) => {
    if (orders[0].ActualVersion.ServiceType !== SERVICETYPE.HOTEL) return null;

    let mainText = '';

    orders.forEach(order => {
      const actualVersion = order.ActualVersion;
      const employee = actualVersion.Employees.map(em => `${em.Surname} ${em.Name}`).join(',');
      const data = JSON.parse(actualVersion.JsonData);

      const checkinDate = data.CustomCheckInDate
        ? formatDate(actualVersion.CheckinDate, PATTERN.DAY_MONTH_TIME_WITHOUT_SECONDS)
        : formatDate(actualVersion.CheckinDate, DATEFORMATS.DAY_MONTH);

      const checkoutDate = data.CustomCheckOutDate
        ? formatDate(actualVersion.CheckoutDate, PATTERN.DATE_TIME_WITHOUT_SECONDS)
        : formatDate(actualVersion.CheckoutDate, DATEFORMATS.DATE);

      const oldDateRange = `${checkinDate}-${checkoutDate}`;

      const newDate = newDateRange ? `\n${LABELS.NEW_DATES}: ${createNewDateString(newDateRange, false)}` : '';

      // Текст в чат отправляется без отсутпов, что бы они работали, нужна такая запись
      mainText += `
        \n${LABELS.HOTEL(order.Id, oldDateRange, newDate, data.Room.Category, employee, data.GuestCount, data.PriceDetails && data.PriceDetails.Total)}
      `;
    });

    return mainText;
  };

  requestOnChangeByHotelOrders = (orders, text = '', accountId, tripId) => {
    const mainText = this.createMessageByHotelOrders(orders);
    const note = this.createNoteByOrder(orders[0], accountId, tripId);

    const hotelName = JSON.parse(orders[0].ActualVersion.JsonData).Hotel.Name;
    const hotelCity = orders[0].ActualVersion.City;
    const hotelLabel = LABELS.HOTEL_NAME(hotelName, hotelCity);
    const data = JSON.parse(orders[0].ActualVersion.JsonData);
    const { CountryCode } = data.Hotel;
    const abroad = CountryCode && CountryCode !== COUNTRIES.RU.SHORTNAME
      ? LABELS.ABROAD
      : '';

    // Текст в чат отправляется без отсутпов, что бы они работали, нужна такая запись
    const msg = mainText
      ? `
        ${LABELS.EDIT_ORDER(abroad)}:\n${hotelLabel}
        \n${mainText}
        \n${text}
      `
      : null;

    if (msg) {
      return this.sendMessageToAdmin(this.currentUser, msg, note);
    }

    return Promise.reject();
  };

  requestOnChangeByOrder(order, text = '') {
    let abroad = '';

    if (order.ActualVersion.ServiceType === SERVICETYPE.HOTEL) {
      const data = JSON.parse(order.ActualVersion.JsonData);
      const { CountryCode } = data.Hotel;
      abroad = CountryCode && CountryCode !== COUNTRIES.RU.SHORTNAME
        ? LABELS.ABROAD
        : '';
    }

    const mainText = this.createMessageByOrder(order);
    const note = this.createNoteByOrder(order);

    const msg = mainText ? `${LABELS.EDIT_ORDER(abroad)} ${order.Id}:\n${mainText}\n\n${text}` : null;

    if (msg) {
      return this.sendMessageToAdmin(this.currentUser, msg, note);
    }

    return Promise.reject();
  }

  requestCancelByOrder(order, text = '', label: string = LABELS.CANCEL_ORDER) {
    let abroad = '';

    if (order.ActualVersion.ServiceType === SERVICETYPE.HOTEL) {
      const data = JSON.parse(order.ActualVersion.JsonData);
      const { CountryCode } = data.Hotel;
      abroad = CountryCode && CountryCode !== COUNTRIES.RU.SHORTNAME
        ? LABELS.ABROAD
        : '';
    }

    const mainText = this.createMessageByOrder(order);
    const abroadMsg = typeof label === 'string' ? label : label(abroad);
    // Текст в чат отправляется без отсутпов, что бы они работали, нужна такая запись
    const msg = mainText
      ? `
        ${abroadMsg} ${order.Id}:
        ${mainText}
        ${text}
      `
      : null;

    if (msg) {
      return this.sendMessageToAdmin(this.currentUser, msg);
    }

    return Promise.reject();
  }

  requestCalculationByOrder(order, text = '') {
    const mainText = this.createMessageByOrder(order);
    const msg = mainText ? `${LABELS.CALCULATION_ORDER} ${order.Id}:\n${mainText}\n\n${text}` : null;

    if (msg) {
      return this.sendMessageToAdmin(this.currentUser, msg);
    }

    return Promise.reject();
  }

  requestFlightCertificate(tripItemId) { // common
    if (CONFIG.INTERCOM.SWITCHON || window.carrotquest) {
      this.store.dispatch({ type: ACTION.STARTSENDINGMESSAGETOADMIN });

      return this.api.sendMessageByFlightCertificate(tripItemId)
        .then(() => setTimeout(() => {
          this.chat.showMessages();
        }, 1000))
        .finally(() => this.store.dispatch({ type: ACTION.ENDSENDINGMESSAGETOADMIN }));
    }

    return null;
  }

  requestAdditionalService(description) {
    return this.sendMessageToAdmin(this.currentUser, description);
  }

  requestError(errorMsg) {
    if (errorMsg) {
      this.sendMessageToAdmin(this.currentUser, errorMsg);
    }
  }

  requestReservation({
    hotel,
    room: {
      Price,
      Name,
      IsContract,
      RateId,
      Rates,
    },
    checkin,
    checkout,
    adult,
    bedType,
    customCheckin,
    customCheckout,
    travellersCount,
    roomCount,
    comment,
    arrayEmployees,
    employeesId,
  }) {
    const {
      CancellationPolicy: { Refundable, DeadLine },
      Meal: { Included },
      Name: rateName,
    } = Rates.find((item) => item.RateId === RateId);

    const checkinDate = customCheckin
      ? formatDate(customCheckin, defaultPattern)
      : formatDate(checkin, defaultPattern);
    const checkoutDate = customCheckout
      ? formatDate(customCheckout, defaultPattern)
      : formatDate(checkout, defaultPattern);
    const early = customCheckin ? formatDate(customCheckin, hoursAndMinutesPattern) : '';
    const late = customCheckout ? formatDate(customCheckout, hoursAndMinutesPattern) : '';
    const totalPrice = Price?.toString() ?? '';
    const defaultBedType = 0;
    const newBedType = travellersCount === 2 && adult === 2 ? bedType : defaultBedType;
    let refundDescription = '';
    const deadLine = DeadLine ? dateUtcFormat(trimTimezone(DeadLine), PATTERN.DAY_OF_MONTH_TIME) : '';

    if (IsContract && Refundable) {
      refundDescription = hotel.CountryCode !== COUNTRIES.RU.SHORTNAME
        ? LABELS.FREE_CANCELLATION_FOREIGN(deadLine)
        : LABELS.FREE_CANCELLATION(deadLine);
    }

    const request = {
      rateId: RateId,
      classificator: hotel.ClassificatorId,
      hotelname: hotel.Name,
      hoteladdress: hotel.Address,
      roomname: IsContract ? rateName : Name,
      roomcount: roomCount,
      person: travellersCount,
      persontype: adult,
      checkin: checkinDate,
      checkout: checkoutDate,
      early,
      late,
      price: totalPrice,
      isContract: IsContract,
      hasBreakfast: Included,
      hasFreeCancellation: Refundable,
      refundDescription,
      comment,
      bedType: newBedType,
      employeesId,
      employees: arrayEmployees,
      countryCode: hotel.CountryCode,
      hotelId: hotel.Id,
    };

    return this.sendRequestOffline(request);
  }

  createFakeIntercomObj() {
    this.chat.createFakeIntercomObj();
  }

  updateCountChange = (directoryName) => {
    let action = 'remove';

    if (this.windowState === 'blur') {
      action = 'set';
    }

    this.updateFavicon(action, directoryName);
  };

  updateFavicon = (action = 'set', directoryName) => {
    if (action === 'set') {
      Favicon.setDot(directoryName);

      document.title = LABELS.TITLE;
    } else {
      Favicon.removeDot();
      document.title = this.title;
    }
  };
}

export default ChatDecorator;
