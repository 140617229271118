import { checkRuPassport, checkUaWithDomesticPassport } from '../../../utils/documents';

import COUNTRIES from '../../../constants/countries';
import PASSPORTS from '../../../constants/passport';
import DOCUMENTSFIELDS from '../../../constants/documentsFields';

import { IDocumentUser, IPassport, IPassportView } from '../types';

const DOCUMENT_FIELDS_TO_VALIDATE = {
  DomesticPassport: [
    DOCUMENTSFIELDS.NUMBER,
    DOCUMENTSFIELDS.SURNAME,
    DOCUMENTSFIELDS.NAME,
    DOCUMENTSFIELDS.LASTNAME,
    DOCUMENTSFIELDS.FIRSTNAME,
  ],
  BirthCertificate: [
    DOCUMENTSFIELDS.NUMBER,
    DOCUMENTSFIELDS.SURNAME,
    DOCUMENTSFIELDS.NAME,
  ],
  ForeignPassport: [
    DOCUMENTSFIELDS.NUMBER,
    DOCUMENTSFIELDS.SURNAME,
    DOCUMENTSFIELDS.NAME,
    DOCUMENTSFIELDS.DUEDATE,
  ],
  ForeignPassportUA: [
    DOCUMENTSFIELDS.NUMBER,
    DOCUMENTSFIELDS.SURNAME,
    DOCUMENTSFIELDS.NAME,
  ],
  TravelPolicy: [
    DOCUMENTSFIELDS.TRAVEL_POLICY,
  ],
};

const validateIsNotEmpty = (field: string, doc: IPassport) => (
  field === DOCUMENTSFIELDS.DUEDATE ? !!doc[field] : !!doc[field] && !!doc[field].trim().length
);

const mapDocumentToRequiredFields = ({ Type }: { Type: string }, code: string) => {
  if (checkUaWithDomesticPassport(code, Type)) {
    return DOCUMENT_FIELDS_TO_VALIDATE.ForeignPassportUA;
  }

  if (code !== COUNTRIES.RU.SHORTNAME || Type === PASSPORTS.FOREIGN_PASSPORT.TYPE) {
    return DOCUMENT_FIELDS_TO_VALIDATE.ForeignPassport;
  }

  if (Type === PASSPORTS.BIRTH_CERTIFICATE.TYPE) {
    return DOCUMENT_FIELDS_TO_VALIDATE.BirthCertificate;
  }

  return DOCUMENT_FIELDS_TO_VALIDATE.DomesticPassport;
};

const validateDocumentFields = (
  document: IDocumentUser | IPassport,
  code: string,
) => {
  if (!document || !code) return false;

  const documentFields = mapDocumentToRequiredFields((document as IDocumentUser), code);

  const isNotEmpty = documentFields.some(field => validateIsNotEmpty(field, (document as IPassport)));

  return isNotEmpty ? documentFields.every(field => validateIsNotEmpty(field, (document as IPassport))) : true;
};

type TInvalid = {
  type: string,
  errors: {
    someFieldsEmpty: boolean,
  },
};

const getInvalidDocumentsTypes = (
  code: string,
  documents: IDocumentUser[],
  documentsTypes: IPassportView,
  mayHavePassport: boolean,
) => {
  if (!documentsTypes) return [];

  const { ForeignPassport, BirthCertificate, DomesticPassport } = documentsTypes;

  return documents.reduce((acc: TInvalid[], document: IDocumentUser) => {
    const { Type } = document;

    const isRuPassport = checkRuPassport(code, Type);

    if (mayHavePassport && Type === BirthCertificate) {
      return acc;
    }

    if (!mayHavePassport && Type === DomesticPassport) {
      return acc;
    }

    if (
      isRuPassport
      || Type === ForeignPassport
      || code !== COUNTRIES.RU.SHORTNAME
      || Type === BirthCertificate
    ) {
      const isValid = validateDocumentFields(document, code);

      return isValid ? acc : [
        ...acc,
        {
          type: Type,
          errors: {
            someFieldsEmpty: true,
          },
        },
      ];
    }

    return acc;
  }, []);
};

export { getInvalidDocumentsTypes as default, validateDocumentFields };
