import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { Text, LinkButton, Button, Checkbox, Tooltip, Icon } from 'new-ui';

import { getText } from '../../../../../i18n';

import { RuDocument } from './components/RuDocument';
import { DomesticOrForeignDocument } from './components/DomesticOrForeign';
import { DomesticOrForeignCyrillicDocument } from './components/DomesticOrForeignCyrillic';
import { BirthCertificate } from './components/BirthCertificate';

import { checkRuPassport } from '../../../../bi/utils/documents';
import { isSmartAgent } from '../../../../bi/utils/env';

import { LANGUAGES } from '../../../../bi/constants/employee';
import { QA_ATTRIBUTES } from '../../../../bi/constants/attributesForTests';

import { IEmployees, IErrorEmployee, IPassportUser, IPassportView } from '../../../../bi/services/employee/types';

import styles from './index.module.css';

interface IDocumentName {
  [key: string]: string
}

const DOCUMENT_NAME: IDocumentName = {
  RuPassport: getText('settings:employees.employee.steps.documents.names.ruPassport'),
  ForeignPassport: getText('settings:employees.employee.steps.documents.names.foreignPassport'),
  DomesticPassport: getText('settings:employees.employee.steps.documents.names.domesticPassport'),
  BirthCertificate: getText('settings:employees.employee.steps.documents.names.birthCertificate'),
};

const LABELS = {
  DELETE_DOCUMENT: getText('settings:employees.employee.steps.documents.deleteDocuments'),
  CHANGE_DOCUMENT: getText('components:changeAirTrip.changeDocument.changeDocument'),
  AGREEMENT: getText('components:changeAirTrip.changeDocument.agreement'),
  AGREEMENT_SA: getText('components:changeAirTrip.changeDocument.agreementSA'),
  SAVE: getText('components:changeAirTrip.save'),
  CANCEL: getText('components:changeAirTrip.cancel'),
  FILL_RULES_SENTENCE: getText('settings:employees.employee.steps.documents.domesticOrForeign.fillRulesSentence'),
  FILL_RULES_SENTENCE_AFTER: getText('settings:employees.employee.steps.documents.domesticOrForeign.fillRulesSentenceAfter'),
  FILL_RULES: getText('settings:employees.employee.steps.documents.domesticOrForeign.fillRules', { returnObjects: true }) as unknown as string[],
};

const {
  employee: { documents },
  changeTrip: { air: { byPassport: { editMode, approvalEdit, cancelEdit, saveEdit } } },
} = QA_ATTRIBUTES;

interface IDocumentForm {
  cyrillicPassport: number,
  employee: IEmployees,
  error: IErrorEmployee,
  document: IPassportUser,
  documentType: IPassportView,
  onResetErrors(field: string, field2?: string): void,
  onValidationInput(field: string, passport?: string): void,
  onInputChange(value: string, type: string): void,
  onBlurInput(value: string, type: string): void,
  onRuNumberChange(value: string): void,
  onCertificateNumberChange(field: string): void;
  onIntNumberChange(value: string): void
  onDomesticNumberChange(value: string): void
  onDateChange(type: string, value: string | moment.Moment | null): void
  onDeleteDocument(index?: number): void,
  mayHavePassport: boolean,
  index: number,
  isAirTripChange?: boolean,
  isValid?: boolean,
  disabledAirTripChange?: boolean,
  loadingAirTripChange?: boolean,
  onBackAirTripChange?(): void,
  agreementAirTripChange?: boolean,
  isAddNewForeignDocument?: boolean,
  setDisabledAirTripChange?(value: boolean): void,
  setAgreementAirTripChange?(value: boolean): void,
  onSaveAirTripChange?(): void,
}

const DocumentForm = ({
  cyrillicPassport,
  employee,
  error,
  document,
  documentType,
  onResetErrors,
  onValidationInput,
  onInputChange,
  onBlurInput,
  onRuNumberChange,
  onCertificateNumberChange,
  onIntNumberChange,
  onDomesticNumberChange,
  onDateChange,
  onDeleteDocument,
  mayHavePassport,
  index,
  isAirTripChange = false,
  isValid = true,
  disabledAirTripChange = false,
  loadingAirTripChange = false,
  onBackAirTripChange = () => {},
  agreementAirTripChange = false,
  isAddNewForeignDocument = false,
  setDisabledAirTripChange = () => {},
  setAgreementAirTripChange = () => {},
  onSaveAirTripChange = () => {},
}: IDocumentForm) => {
  const [stylesWrapper, setStylesWrapper] = useState([styles.wrap]);

  const disabledFieldsNamesChangeAirTrip = useRef((isAirTripChange || disabledAirTripChange) && !isAddNewForeignDocument);

  useEffect(() => {
    if (isAirTripChange) {
      setStylesWrapper(prev => [...prev, styles.wrap_changeAirTrip]);
    }

    if (isAddNewForeignDocument) {
      setStylesWrapper(prev => [...prev, styles.wrap_second_state]);
    }
  }, []);

  const { Code } = employee;
  const { Type } = document;

  const isDomestic = Type === documentType.DomesticPassport;
  const isRuPassport = checkRuPassport(Code, Type);
  const isBirthCertificate = Type === documentType.BirthCertificate;

  const handelVisibleAction = () => {
    setStylesWrapper(prev => [...prev, styles.wrap_second_state]);

    setTimeout(() => setDisabledAirTripChange(false), 400);
  };

  const handelCancelEdit = () => {
    setStylesWrapper(stylesWrapper.filter(style => style !== styles.wrap_second_state));
    setDisabledAirTripChange(true);
    setAgreementAirTripChange(false);
    onBackAirTripChange();
  };

  const handelSaveEdit = async () => {
    await onSaveAirTripChange();
    setStylesWrapper(stylesWrapper.filter(style => style !== styles.wrap_second_state));
  };

  const renderRuDocument = () => (
    <RuDocument
      document={ document }
      error={ error }
      onResetErrors={ onResetErrors }
      onValidationInput={ onValidationInput }
      onInputChange={ onInputChange }
      onBlurInput={ onBlurInput }
      onRuNumberChange={ onRuNumberChange }
      isAirTripChange={ isAirTripChange }
      disabled={ disabledAirTripChange }
      disabledFieldsNamesChangeAirTrip={ disabledFieldsNamesChangeAirTrip.current }
    />
  );

  const renderBirthCertificate = () => (
    <BirthCertificate
      document={ document }
      error={ error }
      onResetErrors={ onResetErrors }
      onValidationInput={ onValidationInput }
      onInputChange={ onInputChange }
      onBlurInput={ onBlurInput }
      onCertificateNumberChange={ onCertificateNumberChange }
      disabled={ disabledAirTripChange }
      disabledFieldsNamesChangeAirTrip={ disabledFieldsNamesChangeAirTrip.current }
    />
  );

  const renderDomesticOrForeignDocument = () => {
    const changeNumberFn = isDomestic ? onDomesticNumberChange : onIntNumberChange;

    return (
      <DomesticOrForeignDocument
        isDomestic={ isDomestic }
        document={ document }
        error={ error }
        onResetErrors={ onResetErrors }
        onValidationInput={ onValidationInput }
        onInputChange={ onInputChange }
        onBlurInput={ onBlurInput }
        onNumberChange={ changeNumberFn }
        onDateChange={ onDateChange }
        employeeCode={ Code }
        index={ index }
        disabled={ disabledAirTripChange }
        disabledFieldsNamesChangeAirTrip={ disabledFieldsNamesChangeAirTrip.current }
      />
    );
  };

  const renderForeignCyrillicDocument = () => {
    const changeNumberFn = isDomestic ? onDomesticNumberChange : onIntNumberChange;
    const showTransliterate = (cyrillicPassport !== 0 && cyrillicPassport === document.Id)
      || (cyrillicPassport === 0);

    return (
      <DomesticOrForeignCyrillicDocument
        isDomestic={ isDomestic }
        document={ document }
        error={ error }
        onResetErrors={ onResetErrors }
        onValidationInput={ onValidationInput }
        onInputChange={ (value, field) => onInputChange(value, field) }
        onBlurInput={ (value, field) => onBlurInput(value, field) }
        onNumberChange={ changeNumberFn }
        onDateChange={ onDateChange }
        employeeCode={ Code }
        index={ index }
        showTransliterate={ showTransliterate }
        disabled={ disabledAirTripChange }
        disabledFieldsNamesChangeAirTrip={ disabledFieldsNamesChangeAirTrip.current }
      />
    );
  };

  const renderDocument = () => {
    if (isRuPassport) {
      return renderRuDocument();
    }

    if (isBirthCertificate) {
      return renderBirthCertificate();
    }

    if (Code !== LANGUAGES.RU) {
      return renderForeignCyrillicDocument();
    }

    return renderDomesticOrForeignDocument();
  };

  const documentName = isRuPassport ? DOCUMENT_NAME.RuPassport : DOCUMENT_NAME[Type];

  const deleteDocumentHtml = !isAirTripChange && (
    <LinkButton
      tabIndex={ -1 }
      theme='blue-without-border'
      onClick={ onDeleteDocument }
      qaAttr={ `${documents.deletePassport}-${Type}` }
    >
      { LABELS.DELETE_DOCUMENT }
    </LinkButton>
  );

  const changeDocumentHtml = isAirTripChange && disabledAirTripChange && (
    <LinkButton
      tabIndex={ -1 }
      theme='blue-without-border'
      onClick={ handelVisibleAction }
      qaAttr={ `${editMode}-${Type}` }
    >
      { LABELS.CHANGE_DOCUMENT }
    </LinkButton>
  );

  const renderForeignHelper = Type === documentType.ForeignPassport ? (
    <Tooltip renderContent={ () => (
      <Text className={ styles.fillRulesSentence } color='white'>
        { LABELS.FILL_RULES_SENTENCE }
        <div className={ styles.fillRules }>
          { LABELS.FILL_RULES.map((i, key) => (
            <Text key={ key } color='white'>
              {`- ${i}`}
            </Text>
          )) }
        </div>
        { LABELS.FILL_RULES_SENTENCE_AFTER }
      </Text>
    ) }
    >
      <Icon type='info' color='blue' />
    </Tooltip>
  ) : null;

  const titleHtml = (
    <Text
      type='bold_18'
      className={ styles.headerTitle }
      qaAttr={ `${documents.passport}-${Type}` }
    >
      { documentName }
      { renderForeignHelper }
    </Text>
  );

  const renderAction = () => {
    const disabledButton = !agreementAirTripChange || !isValid;

    const cancelButton = !isAddNewForeignDocument && (
      <Button
        type='textual'
        onClick={ handelCancelEdit }
        qaAttr={ cancelEdit }
      >
        {LABELS.CANCEL}
      </Button>
    );

    if (!isAirTripChange) {
      return null;
    }

    if (disabledAirTripChange) {
      return null;
    }

    const agreementText = isSmartAgent ? LABELS.AGREEMENT_SA : LABELS.AGREEMENT;

    return (
      <div className={ styles.action }>
        <div className={ styles.check }>
          <Checkbox
            className={ styles.check }
            value={ agreementAirTripChange }
            onChange={ () => setAgreementAirTripChange(!agreementAirTripChange) }
            qaAttr={ approvalEdit }
          >
            { agreementText }
          </Checkbox>
          &nbsp;
          <Tooltip
            renderContent={ () => (
              <Text
                className={ styles.tooltip }
                color='white'
              >
                { employee.Email }
              </Text>
            ) }
          >
            <LinkButton className={ styles.name }>
              <Text>
                {employee.Surname} {employee.Name} {employee.Patronymic}.
              </Text>
            </LinkButton>
          </Tooltip>
        </div>
        <div className={ styles.buttons }>
          <Button
            className={ styles.save }
            disabled={ disabledButton }
            loading={ loadingAirTripChange }
            type='secondary'
            onClick={ handelSaveEdit }
            qaAttr={ saveEdit }
          >
            {LABELS.SAVE}
          </Button>
          {cancelButton}
        </div>
      </div>
    );
  };

  const conflictDocuments =
    (!mayHavePassport && isRuPassport) || (mayHavePassport && isBirthCertificate);

  return !conflictDocuments && (
    <div className={ stylesWrapper.join(' ') }>
      <div className={ styles.header }>
        { titleHtml }
        { deleteDocumentHtml }
        { changeDocumentHtml }
      </div>
      <div className={ styles.document }>
        { renderDocument() }
      </div>
      { renderAction() }
    </div>
  );
};

export { DocumentForm };
