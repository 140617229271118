import React from 'react';

import { Text } from 'new-ui';
import { getText } from '../../../../../i18n';

import { TrainLevel } from './components/TrainLevel';

import {
  IFreePlace,
  ISelectedPlace,
  ISection,
  ICarJsonData,
  ICompartSection,
  ILevel,
  IPlace,
} from '../../../../bi/types/train';

import styles from './index.module.css';

interface ITrainCar {
  number: string;
  twoFloors: boolean;
  freePlaces: IFreePlace[],
  handleSelectedPlace: (place: IPlace) => void,
  handleSelectedSection: (section: ISection) => void,
  selectedPlaces: ISelectedPlace[],
  compartmentSections: ICompartSection[],
  entireCompartment: boolean,
  differentTrains: boolean,
  choiceInitiaPlace: () => void,
  children?: React.ReactNode;
  isSapsanFamily: boolean,
  carJSON: ICarJsonData,
  carImage: string[],
}

const LABELS = {
  FLOOR: getText('trains:carScheme.floor'),
};

const TrainCar: React.FC<ITrainCar> = ({
  number,
  twoFloors,
  freePlaces,
  handleSelectedPlace,
  handleSelectedSection,
  selectedPlaces,
  compartmentSections,
  entireCompartment,
  differentTrains,
  choiceInitiaPlace,
  children = null,
  isSapsanFamily,
  carJSON,
  carImage,
}) => {
  const renderTrain = (): JSX.Element[] => (
    carJSON.levels.map((level: ILevel, ind: number) => {
      const floorsHtml = twoFloors && (
        <Text
          type='NORMAL_16'
          className={ styles.floor }
        >
          { ind + 1 }
          {' '}
          { LABELS.FLOOR }
        </Text>
      );

      return (
        <div className={ styles.train } key={ ind }>
          { floorsHtml }
          <TrainLevel
            key={ ind }
            level={ level }
            number={ number }
            type={ carJSON.type }
            freePlaces={ freePlaces }
            compartmentSections={ compartmentSections }
            choiceInitiaPlace={ choiceInitiaPlace }
            selectedPlaces={ selectedPlaces }
            handleSelectedPlace={ handleSelectedPlace }
            onSelectedSection={ handleSelectedSection }
            isSapsanFamily={ isSapsanFamily }
            differentTrains={ differentTrains }
            twoFloors={ twoFloors }
            entireCompartment={ entireCompartment }
            carImage={ carImage[ind] }
          />
        </div>
      );
    })
  );

  return (
    <div className={ styles.car }>
      { children }
      { renderTrain() }
    </div>
  );
};

export { TrainCar };
