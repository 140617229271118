import { MAP_CAR_CLASS_TITLE } from '../constants/transfer';

import { PrepareRateType, RatesType, PrepareTransferPlacesType } from '../types/transfer';

interface PlaceType {
  Address: string,
  Type: string,
  Coordinates: {
    Latitude: string,
    Longitude: string,
  }
}

const prepareRates = (rates: RatesType[]): PrepareRateType[] => rates.flatMap(({
  Class,
  Models,
  Capacity,
  FavoriteId,
  Suggestion,
}) => Suggestion.map(({
  RateId,
  IncludeTablet,
  Price,
  CancellationTime,
  AllowableTime,
  AdditionalServices,
  TravelPolicy,
}) => ({
  Title: MAP_CAR_CLASS_TITLE[Class],
  Class,
  Models,
  Capacity,
  RateId,
  IncludeTablet,
  Price: Number(Price),
  CancellationTime,
  AllowableTime,
  AdditionalServices,
  FavoriteId,
  TravelPolicy: {
    Apply: TravelPolicy ? TravelPolicy.Apply : false,
    Errors: TravelPolicy ? TravelPolicy.Errors : {},
  },
  AlreadyInCart: false,
})));

const prepareTransferPlaces = ({ Address, Coordinates, Type }: PlaceType): PrepareTransferPlacesType => ({
  value: Address,
  coordinates: {
    lat: Coordinates.Latitude,
    lon: Coordinates.Longitude,
  },
  selected: {
    Address,
    Coordinates: {
      Latitude: Coordinates.Latitude,
      Longitude: Coordinates.Longitude,
    },
    Type,
  },
});

export { prepareRates, prepareTransferPlaces };
