import COUNTRIES from '../constants/countries';
import PASSPORTS from '../constants/passport';

import { isSmartAgent } from './env';

const INVOICE = 'Счет фактура';

const checkUaWithDomesticPassport = (employeeCode: string, Type: string) => (
  employeeCode === COUNTRIES.UA.SHORTNAME && Type === PASSPORTS.DOMESTIC_PASSPORT.TYPE
);

const checkRuPassport = (code: string, type: string) => code === COUNTRIES.RU.SHORTNAME && type === PASSPORTS.DOMESTIC_PASSPORT.TYPE;

const invoiceSmartAgent = (DocName: string) => isSmartAgent && (DocName.slice(0, 12) === INVOICE);

export {
  checkRuPassport,
  invoiceSmartAgent,
  checkUaWithDomesticPassport,
  checkUaWithDomesticPassport as default,
};
