import React from 'react';
import { Text, DotLoading, LinkButton, Tooltip } from 'new-ui';
import { getText } from '../../../../../i18n';

import { QA_ATTRIBUTES } from '../../../../bi/constants/attributesForTests';

import { convertAvailableRightsToText, convertUnavailableRightsToText } from '../../../../bi/utils/rights';
import { isSmartAgent } from '../../../../bi/utils/env';

import { ListRightsTexts } from '../ListRightsText';

import {
  IApprovalShemas,
  IApprovalShemasERA,
  IFieldRights,
  IStringData,
  UsersType,
  UsersTypeLigth,
} from '../../../../bi/services/employee/types';

import styles from './index.module.css';

const LABELS = {
  CAN: getText('settings:employees.employee.steps.access.can'),
  CANT: getText('settings:employees.employee.steps.access.cant'),
  EDIT: getText('settings:employees.employee.steps.access.edit'),
  GET_ACCESS: getText('settings:employees.employee.steps.access.getAccess'),
  ONLY_ADULTS: getText('settings:employees.employee.steps.access.onlyAdults'),
  ONLY_ADULTS_SA: getText('settings:employees.employee.steps.access.onlyAdultsSA'),
  NEED_TO_FILL_GENERAL: getText('settings:employees.employee.steps.access.needToFillGeneral'),
};

const { employee: { documents: { access } } } = QA_ATTRIBUTES;

type res = {
  label: string;
  id: string;
};

export interface IAccessStep {
  chosenApprovalERScheme: number;
  chosenApprovalScheme: number;
  chosenTP: string;
  disabledLink: boolean;
  email: string;
  employeeIsUnderage: boolean;
  loading: boolean;
  selfRights: boolean;
  showExpenseReportApprove: boolean;
  showReports: boolean;
  showRightsBlock: boolean;
  rights: IFieldRights,
  travelPolicies: {
    value: string,
    label: string,
  }[],
  approvalSchemes: IApprovalShemasERA[],
  allApproveUsers: IStringData[],
  viewApprovers: string[],
  viewExpenseReportApprovers: string[],
  approveERUsers: UsersType[],
  approvalERSchemes: IApprovalShemas[],
  onChangeApproveERUsers(value: string[]): void,
  onChangeApprovalERScheme(value: number | boolean | string): void,
  onOpenRights(): void,
  onChangeApprovalScheme(value: string): void,
  onChangeTravelPolicy(id: string | number): void,
  onChangeViewApprovers(value: string[]): void,
}

const AccessStep = ({
  email,
  showReports,
  showExpenseReportApprove,
  rights,
  loading,
  showRightsBlock,
  disabledLink,
  employeeIsUnderage,
  selfRights,
  travelPolicies,
  approvalSchemes,
  allApproveUsers,
  viewApprovers,
  viewExpenseReportApprovers,
  chosenTP,
  chosenApprovalScheme,
  approveERUsers,
  approvalERSchemes,
  chosenApprovalERScheme,
  onChangeApproveERUsers,
  onChangeApprovalERScheme,
  onOpenRights,
  onChangeApprovalScheme,
  onChangeTravelPolicy,
  onChangeViewApprovers,
}: IAccessStep) => {
  const renderListRights = (list: res[], label: string, qaAttr: string) => {
    const {
      buyTripAccount,
      buyTravelPolicy,
      buyAfterApprove,
      buyApprovalScheme,
      createERApprovalScheme,
      createERAfterApprove,
    } = rights;

    const travelPolicy: UsersTypeLigth | undefined | null =
      !!travelPolicies.length && !!chosenTP.length ? travelPolicies.find(({ value }: { value: string }) => value === chosenTP) : null;

    const canEdit = label === LABELS.CAN && !selfRights;

    return (
      <>
        <Text
          qaAttr={ qaAttr }
          type='SEMIBOLD_16'
        >
          { label }
        </Text>
        <ListRightsTexts
          canEdit={ canEdit }
          rights={ list }
          email={ email }
          buyApprovalScheme={ buyApprovalScheme }
          buyTravelPolicy={ buyTravelPolicy }
          travelPolicy={ travelPolicy }
          approvalScheme={ chosenApprovalScheme }
          approveUsers={ viewApprovers }
          viewExpenseReportApprovers={ viewExpenseReportApprovers }
          allApproveUsers={ allApproveUsers }
          buyTripAccount={ buyTripAccount }
          buyAfterApprove={ buyAfterApprove }
          travelPolicies={ travelPolicies }
          approvalSchemes={ approvalSchemes }
          approveERUsers={ approveERUsers }
          approvalERSchemes={ approvalERSchemes }
          chosenApprovalERScheme={ chosenApprovalERScheme }
          createERApprovalScheme={ createERApprovalScheme }
          createERAfterApprove={ createERAfterApprove }
          onChangeTravelPolicy={ onChangeTravelPolicy }
          onChangeApprovalScheme={ onChangeApprovalScheme }
          onChangeViewApprovers={ onChangeViewApprovers }
          onChangeApproveERUsers={ onChangeApproveERUsers }
          onChangeApprovalERScheme={ onChangeApprovalERScheme }
        />
      </>
    );
  };

  const renderEditRights = () => (
    <LinkButton
      className={ styles.link }
      onClick={ onOpenRights }
      theme='blue-without-border'
      qaAttr={ access.edit }
    >
      { LABELS.EDIT }
    </LinkButton>
  );

  const renderRights = () => {
    const preparedRights = {
      ...rights,
      showReports,
      showExpenseReportApprove,
      isSmartAgent,
    };

    // @ts-ignore
    const available = convertAvailableRightsToText(preparedRights);
    // @ts-ignore
    const unavailable = convertUnavailableRightsToText(preparedRights);

    const availableHtml = !!available.length && renderListRights(available, LABELS.CAN, access.userCan);
    const unavailableHtml = !!unavailable.length && renderListRights(unavailable, LABELS.CANT, access.userCant);
    const editRightsHtml = !selfRights && renderEditRights();

    return (
      <div className={ styles.rights }>
        { availableHtml }
        <div className={ styles.cant }>
          { unavailableHtml }
        </div>
        { editRightsHtml }
      </div>
    );
  };

  if (loading) {
    return <DotLoading />;
  }

  if (showRightsBlock && rights && !employeeIsUnderage) {
    return renderRights();
  }

  const onlyAdultsText = isSmartAgent ? LABELS.ONLY_ADULTS_SA : LABELS.ONLY_ADULTS;

  const tooltipText = employeeIsUnderage
    ? onlyAdultsText
    : LABELS.NEED_TO_FILL_GENERAL;

  return (
    <div className={ styles.link }>
      <Tooltip
        show={ disabledLink }
        renderContent={ () => (
          <Text
            color='white'
            className={ styles.tooltip }
            type='NORMAL_14_130'
          >
            { tooltipText }
          </Text>
        ) }
      >
        <LinkButton
          tabIndex={ -1 }
          disabled={ disabledLink }
          onClick={ onOpenRights }
          theme='large-default'
          qaAttr={ access.getAccess }
        >
          { LABELS.GET_ACCESS }
        </LinkButton>
      </Tooltip>
    </div>
  );
};

export { AccessStep };
