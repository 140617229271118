// @ts-nocheck
import React from 'react';
import PropTypes from 'prop-types';
import { PropTypes as MobxPropTypes } from 'mobx-react';
import { Button, Icon, IconButton, PROPS, Select, Text } from 'new-ui';

import { getText } from '../../../../i18n';

import { getEmployeeFullNameWithSimplicity } from '../../../bi/utils/employees';
import { checkAddCompany, getEmployeeCompaniesWithDepartments } from '../../../bi/utils/approval';
import { isDepartmentId } from '../../../bi/utils/validation';

import { EmployeeSuggest } from '../../../components/EmployeeSuggest';
import ProjectSelect from '../../../components/EmployeeList/ProjectSelect';

import { QA_ATTRIBUTES } from '../../../bi/constants/attributesForTests';
import { COMPANY_DEPARTMENT_DIVIDER } from '../../../bi/constants/cart';
import { ApproveStatus } from '../../../bi/services/travelApproval/consts';

import styles from './styles/styles.module.css';

const {
  TEXT: {
    COLORS: { GRAY },
    TYPES: { NORMAL_16_130, SEMIBOLD_16 },
  },
  BUTTON: { TYPES: { TEXTUAL } },
  ICON: { TYPES: { CLOSE_BUTTONS: { DEFAULT }, ADD_EMPLOYEE } },
} = PROPS;

const LABELS = {
  CHOOSE_COMPANY: getText('components:travelApproval.selectCompany'),
  CHOOSE_DEPARTMENT: getText('components:travelApproval.selectDepartment'),
  CHOOSE_EMPLOYEE: getText('components:travelApproval.selectEmployee'),
  PROJECT_LABEL: getText('components:employeeList.project.label'),
  PROJECT_NOT_SELECTED: getText('components:employeeList.project.notIncluded'),
};

class SelectEmployee extends React.Component {
  static propTypes = {
    item: PropTypes.object,
    items: PropTypes.oneOfType([
      PropTypes.array,
      MobxPropTypes.observableArray,
    ]).isRequired,
    readOnly: PropTypes.bool,
    disabledField: PropTypes.bool,
    getEmployees: PropTypes.func,
    onAddEmployee: PropTypes.func,
    onRemoveEmployee: PropTypes.func,
    onAddCompany: PropTypes.func,
    number: PropTypes.number.isRequired,
    index: PropTypes.number.isRequired,
    isMulti: PropTypes.bool.isRequired,
    projects: PropTypes.array.isRequired,
    onAddProject: PropTypes.func.isRequired, // review name
    projectEditable: PropTypes.func.isRequired,
    employees: PropTypes.func,
    approvalStatus: PropTypes.string.isRequired,
    updateLock: PropTypes.boolean,
  };

  static defaultProps = {
    item: null,
    items: [],
    readOnly: false,
    disabledField: false,
    getEmployees: () => {},
    onAddEmployee: () => {},
    onRemoveEmployee: () => {},
    onAddCompany: () => {},
    projects: [],
    employees: [],
  };

  constructor(props) {
    super(props);

    const { item, projects } = props;

    const employee = item || null;
    const selectedProject = item?.ProjectId;

    this.state = {
      hasAdd: false,
      inputValue: '',
      employee,
      projects,
      showSelectProject: false,
      selectedProject,
    };
  }

  componentDidUpdate(prevProps) {
    const { item, projects } = this.props;

    if (prevProps.item !== item) {
      this.setState({
        employee: item,
      });
    }

    if (prevProps.projects !== projects) {
      this.setState({
        projects,
      });
    }
  }

  handleChangeCompany = (value) => {
    const { onAddCompany } = this.props;
    onAddCompany(this.state.employee.Id, value);
  };

  prepareCompanyAndDepartmentValue = ({ CompanyId }, DepartmentId) => {
    if (DepartmentId) {
      return `${CompanyId}${COMPANY_DEPARTMENT_DIVIDER}${DepartmentId}`;
    }

    return CompanyId;
  };

  handleSwitchEmpty = () => {
    this.setState({
      hasAdd: !this.state.hasAdd,
    });
  };

  handleInputChange = value => this.handleFetchRequest(value);

  handleFetchRequest = (value) => {
    const { items, getEmployees } = this.props;

    return getEmployees(value, false).then(res =>
      res.filter(employee =>
        !items.some(item => item.Id === employee.Id),
      ));
  };

  handleAddEmployee = (suggestion) => {
    const {
      onAddEmployee,
      onAddCompany,
      isMulti,
      index,
    } = this.props;

    return onAddEmployee(suggestion).then(() => {
      const { CompanyId, Departments } = suggestion.Companies[0];

      if (checkAddCompany(isMulti, suggestion.Companies.length, index)) {
        if (Departments?.length === 1) {
          const departmentId = Departments[0].Id;
          onAddCompany(suggestion.Id, `${CompanyId}${COMPANY_DEPARTMENT_DIVIDER}${departmentId}`);
        } else {
          onAddCompany(suggestion.Id, CompanyId);
        }
      }

      this.setState({
        inputValue: this.handleGetValue(suggestion),
        hasAdd: false,
      });
    });
  };

  handleGetValue = (suggestion) => {
    if (!suggestion.addNewEmployee) {
      return getEmployeeFullNameWithSimplicity(suggestion, true);
    }

    return null;
  };

  handleRemoveEmployee = () => {
    const {
      onRemoveEmployee,
    } = this.props;
    const { employee } = this.state;

    onRemoveEmployee(employee);
  };

  handleChangeProject = (projectId, employeeId) => {
    const { onAddProject } = this.props;

    onAddProject(projectId, employeeId);
    this.showSelectProjectFn();
    this.handleSelectProject(projectId);
  };

  handleClearProject = (employeeId) => {
    const { onAddProject } = this.props;

    onAddProject(null, employeeId);
    this.handleSelectProject(null);
  };

  showSelectProjectFn = () => {
    if (!this.props.disabledField) this.setState({ showSelectProject: !this.state.showSelectProject });
  };

  handleSelectProject = (projectId) => this.setState({ selectedProject: projectId });

  renderProject = () => {
    const {
      projects,
      employee: { ProjectId, Id: employeeId },
      showSelectProject,
      selectedProject: editableProject,
    } = this.state;
    const { projectEditable, employees, disabledField, updateLock } = this.props;

    if (!projects.length) return null;

    const employeeProjectId = employees?.length ? employees.find(({ Id }) => Id === employeeId)?.ProjectId : ProjectId;
    const projectIdForFound = employeeProjectId ?? ProjectId;
    let selectedProject = projects.find(({ Id }) => projectIdForFound === Id);

    if (!projectEditable[employeeId]) {
      return (
        <div className={ styles['space-between'] }>
          <Text color={ 'gray' }>{LABELS.PROJECT_LABEL}: </Text>{ selectedProject?.Name }
        </div>
      );
    }

    if (disabledField && updateLock) {
      const label = selectedProject ? selectedProject?.Name : LABELS.PROJECT_NOT_SELECTED;

      return (
        <div className={ styles['space-between'] }>
          <Text color={ 'gray' }>{LABELS.PROJECT_LABEL}: { label }</Text>
        </div>
      );
    }

    if (showSelectProject) {
      return (
        <ProjectSelect
          projects={ projects }
          onSelect={ (projectId) => this.handleChangeProject(projectId, employeeId) }
          showAdd={ false }
          projectName={ selectedProject?.Name }
        />
      );
    }

    selectedProject = projects.find(({ Id }) => editableProject === Id);
    const label = editableProject ? selectedProject?.Name : LABELS.PROJECT_NOT_SELECTED;

    const deleteProjectButton = editableProject && !disabledField && (
      <IconButton
        iconType={ DEFAULT }
        onClick={ () => this.handleClearProject(employeeId) }
        className={ styles.remove }
      />
    );

    return (
      <div className={ styles['space-between'] }>
        <Text color={ 'gray' }>{LABELS.PROJECT_LABEL}:</Text>
        <Button
          className={ styles.button }
          onClick={ this.showSelectProjectFn }
          type={ 'textual' }
        >
          &nbsp;{ label }
        </Button>
        { deleteProjectButton }

      </div>
    );
  };

  renderCompanyName = companyName => (
    <Text color={ GRAY } className={ styles.company }>
      { companyName }
    </Text>
  );

  renderSingleCompany = ({ ShortCompanyName, CompanyName, Departments }, list, value) => {
    const { disabledField } = this.props;

    if (Departments.length <= 1) {
      return this.renderCompanyName(list[0].label);
    }

    const selectClassNames = [styles.company];

    if (!isDepartmentId(value) && list.length > 1) {
      selectClassNames.push(styles.highlight);
    }

    if (Departments.length && !disabledField) {
      return (
        <div className={ selectClassNames.join(' ') }>
          <Select
            placeholder={ LABELS.CHOOSE_DEPARTMENT }
            qaAttrLabel={ QA_ATTRIBUTES.approvalRequest.department.choose }
            excludeValue
            value={ value }
            items={ list }
            onChange={ this.handleChangeCompany }
            className={ styles.select }
          />
        </div>
      );
    }

    const defaultCompanyName = ShortCompanyName || CompanyName;

    if (defaultCompanyName) return this.renderCompanyName(defaultCompanyName);

    return <div className={ styles['employee-company'] } />;
  };

  renderNumber = () => {
    const { number } = this.props;

    return (
      <Text className={ styles.number } color={ GRAY } type={ NORMAL_16_130 }>
        { number }.
      </Text>
    );
  };

  renderCompanies() {
    const { disabledField } = this.props;
    const { employee: { Companies: employeeCompanies, CompanyId, DepartmentId } } = this.state;

    const employeeCompaniesWithDepartments = getEmployeeCompaniesWithDepartments(employeeCompanies);

    const selectCompany = CompanyId;
    const selectDepartment = DepartmentId;
    const currentItem = employeeCompanies.find(item => item.CompanyId === selectCompany) || null;
    const value = currentItem ? this.prepareCompanyAndDepartmentValue(currentItem, selectDepartment) : null;

    if (disabledField) {
      let companyName = null;
      let departmentName = null;

      if (currentItem) {
        companyName = currentItem?.ShortCompanyName || currentItem?.CompanyName;
      }

      if (DepartmentId && currentItem) {
        departmentName = currentItem?.Departments?.find(({ Id }) => Id === DepartmentId);
      }

      const name = CompanyId && DepartmentId ? `${companyName}(${departmentName?.Name})` : companyName;

      return this.renderCompanyName(name);
    }

    if (employeeCompanies && employeeCompanies.length === 1) {
      return this.renderSingleCompany(employeeCompanies[0], employeeCompaniesWithDepartments, value);
    }

    return (
      <div className={ styles.company }>
        <Select
          placeholder={ LABELS.CHOOSE_COMPANY }
          excludeValue
          value={ value }
          qaAttrLabel={ QA_ATTRIBUTES.approvalRequest.company.choose }
          items={ employeeCompaniesWithDepartments }
          onChange={ this.handleChangeCompany }
          className={ styles.select }
        />
      </div>
    );
  }

  renderItem = () => {
    const { readOnly, disabledField, approvalStatus, updateLock } = this.props;
    const { employee } = this.state;

    const deleteContent = !updateLock
      && !readOnly
      && !disabledField
      && approvalStatus === ApproveStatus.waitingApprove
      && (
        <IconButton
          onClick={ this.handleRemoveEmployee }
          iconType={ DEFAULT }
          className={ styles.remove }
        />
      );

    const employeeContent = (
      <div className={ styles.employee }>
        <div className={ styles.employee_name }>
          { this.renderNumber() }
          <Text type={ SEMIBOLD_16 }>
            { this.handleGetValue(employee) }
          </Text>
          { deleteContent }
        </div>
      </div>);

    const companiesContent = this.renderCompanies();
    const projectsContent = this.renderProject();

    const content = companiesContent ? (
      <div className={ styles['two-rows'] }>
        <div className={ styles['space-between'] }>
          { employeeContent }
          { companiesContent }
          { projectsContent }
        </div>
      </div>
    ) : (
      <div className={ styles['two-rows'] }>
        <div className={ styles['space-between'] }>
          { employeeContent }
          { projectsContent }
        </div>
      </div>
    );

    return (
      <div className={ styles['employee-container'] }>
        { content }
      </div>
    );
  };

  renderEnterEmployee = () => {
    const { inputValue } = this.state;

    return (
      <div className={ styles['enter-container'] }>
        { this.renderNumber() }
        <div className={ styles.suggest }>
          <EmployeeSuggest
            onFetch={ this.handleInputChange }
            onSelect={ this.handleAddEmployee }
            value={ inputValue }
            autoFocus
            withFetching
            qaAttrInputEmployee={ QA_ATTRIBUTES.approvalRequest.selectEmployee.input }
            qaAttrFirstEl={ QA_ATTRIBUTES.approvalRequest.selectEmployee.firstEl }
            qaAttrSelectEmployeeLoading={ QA_ATTRIBUTES.approvalRequest.selectEmployee.loading }
          />
        </div>
      </div>
    );
  };

  renderContent = () => {
    const { hasAdd, employee } = this.state;

    if (employee) {
      return this.renderItem();
    }

    if (hasAdd) {
      return this.renderEnterEmployee();
    }

    return (
      <div className={ styles['default-container'] }>
        { this.renderNumber() }
        <div className={ styles['add-employee'] }>
          <Button
            onClick={ this.handleSwitchEmpty }
            type={ TEXTUAL }
            className={ styles.button }
            qaAttr={ QA_ATTRIBUTES.approvalRequest.selectEmployee.button }
          >
            <Icon type={ ADD_EMPLOYEE } className={ styles.icon } />
            { LABELS.CHOOSE_EMPLOYEE }
          </Button>
        </div>
      </div>
    );
  };

  render() {
    return (
      <div className={ styles.wrapper }>
        { this.renderContent() }
      </div>
    );
  }
}

export default SelectEmployee;
