import React, { useEffect, useState } from 'react';
import { SidePanel, Text, NoResults, Button, Icon, DotLoading } from 'new-ui';
import { observer } from 'mobx-react';

import { getText } from '../../../../../../i18n';

import { useServices } from '../../../../../bi/context/services';
import { useStores } from '../../../../../bi/context';
import { MOBX_STORES } from '../../../../../bi/context/stores';

import { AirlineSeatMap } from '../../../../../components/AirlineSeatMap';

import { getEmployeeFullNameWithSimplicity } from '../../../../../bi/utils/employees';
import parseJSON from '../../../../../bi/utils/parseJsonString';

import { AIRLINE_PROVIDERS } from '../../../../../bi/constants/airline';
import { QA_ATTRIBUTES } from '../../../../../bi/constants/attributesForTests';

import { PropertyType, SeatType } from '../../../../../components/AirlineSeatMap/types/types';
import { AirSegment } from '../../../../../bi/types/airline';
import { IEmployees } from '../../../../../bi/services/employee/types';

import styles from '../styles.module.css';

interface SidePanelAdditionalServiceProps {
  segments: AirSegment[];
  segmentSelected: { segmentId: number, number: number } | null;
  showPanel: boolean;
  loading: boolean;
  currentItem: any;
  toggleHadler(): void;
  cartId: number,
}

const LABELS = {
  CHOOSE_SEAT: getText('cart:airlineAdditionalService.seats.chooseSeat'),
  FLIGHT: getText('cart:airlineAdditionalService.seats.flight'),
  NOT_CHOOSEN: getText('cart:airlineAdditionalService.seats.notChoosen'),
  CONFIRM_BUTTON: getText('cart:airlineAdditionalService.seats.confirmButton'),
  LOADER: getText('cart:airlineAdditionalService.seats.loader'),
};

const SidePanelAdditionalService = observer(({
  segments,
  segmentSelected,
  showPanel,
  loading,
  currentItem,
  toggleHadler,
  cartId,
}: SidePanelAdditionalServiceProps) => {
  const { airlineSeatsService, cartService } = useServices(['AirlineSeats', 'Cart']);
  const { airlineSeatsStore } = useStores([MOBX_STORES.AIRLINE_SEATS]);
  const [employeeSelected, setEmployeeSelected] = useState<number | null>(airlineSeatsService.getEmployeeIdWithoutSeat(currentItem.Employees));
  const [submitLoading, setSubmitLoading] = useState<boolean>(false);

  useEffect(() => {
    setEmployeeSelected(airlineSeatsService.getEmployeeIdWithoutSeat(currentItem.Employees));
  }, [airlineSeatsStore.selectedPlaces, airlineSeatsStore.currentSegment]);

  const currentSegment = segments.find(({ ID }) => ID === segmentSelected?.segmentId);

  if (!currentSegment || !segmentSelected) return null;

  const { DepartureCity, ArrivalCity, ID } = currentSegment;

  const handleSelect = (seat: SeatType): void => {
    const { setSelectedPlace, setCurrentSegmentIndex } = airlineSeatsService;
    const { currentSegment: airlineSeatsStoreCurrentSegment } = airlineSeatsStore;

    const isAvailable = seat.Properties.includes('availability');

    if (!isAvailable || !airlineSeatsStoreCurrentSegment) {
      return;
    }

    setCurrentSegmentIndex(ID);
    setSelectedPlace(ID, seat, employeeSelected);

    setEmployeeSelected(airlineSeatsService.getEmployeeIdWithoutSeat(currentItem.Employees));
  };

  const checkDataAndLoad = async () => {
    const { arrayInfo } = airlineSeatsStore;

    await cartService.load();

    setSubmitLoading(false);

    if (!arrayInfo) {
      toggleHadler();
    }
  };

  const sumbit = async (): Promise<void> => {
    const {
      selectedPlaces,
      currentSegment: airlineSeatsStoreCurrentSegment,
      segments: airlineSeatsStoreSegments,
    } = airlineSeatsStore;

    const { bookSeats } = airlineSeatsService;

    const { Id } = currentItem;

    setSubmitLoading(true);

    const { SavedPriceItemId } = airlineSeatsStoreSegments.find(({ SegmentId }) => SegmentId === airlineSeatsStoreCurrentSegment.SegmentId) || { SavedPriceItemId: '' };

    const preparedSegmentsWithPlaces = Array
      .from(selectedPlaces)
      .reduce((acc: { SeatNumber: string, TravellerId: number }[], [, Places]) => [
        ...acc,
        ...Places.map(({ seat: { Column, Row }, travellerId }) => ({
          SeatNumber: `${Row}${Column}`,
          TravellerId: travellerId,
        })),
      ], []);

    const requestBody = {
      SavedPriceItemId,
      SegmentSeats: preparedSegmentsWithPlaces,
    };

    await bookSeats(
      Id,
      airlineSeatsStoreCurrentSegment.SegmentId,
      cartId,
      requestBody,
    );

    checkDataAndLoad();
  };

  const deletePlace = (employeeId: number) => {
    airlineSeatsService.clearPlace({ employeeId });

    setEmployeeSelected(airlineSeatsService.getEmployeeIdWithoutSeat(currentItem.Employees));
  };

  const renderEmployee = (employee: IEmployees) => {
    const selectedSeats = airlineSeatsService.getSelectedSeatsForCurrentSegment();

    const selectedSeatForCurrentEmployee = selectedSeats.find(({ travellerId }) => travellerId === employee.Id);

    const label = selectedSeatForCurrentEmployee
      ? `${selectedSeatForCurrentEmployee.seat.Row}${selectedSeatForCurrentEmployee.seat.Column}`
      : LABELS.NOT_CHOOSEN;
    const name = getEmployeeFullNameWithSimplicity(employee, true);

    const getCloseIcon = () => {
      if (selectedSeatForCurrentEmployee) {
        return (
          <Icon
            color='black'
            onClick={ () => deletePlace(employee.Id) }
            className={ styles['delete-person'] }
            type='closeButtonSmall'
          />
        );
      }

      return null;
    };

    const renderPrice = () => (selectedSeatForCurrentEmployee?.seat.Price ? (
      <Text type='bold_16'>+ {selectedSeatForCurrentEmployee?.seat.Price} ₽</Text>
    ) : null);

    const actieEmployee = employeeSelected === employee.Id ? styles['employee-active'] : styles['employee-not-active'];

    return (
      <div onClick={ () => setEmployeeSelected(employee.Id) } className={ `${actieEmployee} ${styles['employee-wrapper']}` }>
        <Text type='NORMAL_16'>{name}</Text>
        <div className={ styles.places }>
          <div className={ styles['left-side'] }>
            <Text type='bold_16'>{label}</Text>
            { getCloseIcon() }
          </div>
          { renderPrice() }
        </div>
      </div>
    );
  };

  const renderRightPanel = () => {
    const renderEmployees = currentItem.Employees.map(({ Employee }: { Employee: IEmployees }) => renderEmployee(Employee));

    return (
      <>
        { renderEmployees }
        <Button
          className={ styles.submit }
          loading={ submitLoading }
          onClick={ sumbit }
          type='secondary'
        >
          { LABELS.CONFIRM_BUTTON }
        </Button>
      </>
    );
  };

  const renderScheme = () => {
    const {
      currentSegment: airlineSeatsStoreCurrentSegment,
      segments: airlineSeatsStoreSegments,
      arrayInfo,
    } = airlineSeatsStore;

    const checkingResult =
      !airlineSeatsStoreSegments.length ||
      !airlineSeatsStoreCurrentSegment ||
      !airlineSeatsStoreCurrentSegment.Rows;

    if (checkingResult) {
      return <NoResults />;
    }

    const { ProviderName: providerName = null } = parseJSON(currentItem.Data);

    const selectedSeats = airlineSeatsService.getSelectedSeatsForCurrentSegment();

    const providersList = [AIRLINE_PROVIDERS.POBEDA];
    const propertiesToHideInLegend: PropertyType[] = providersList.includes(providerName)
      ? ['notRecline']
      : [];
    const withIndexNumbers = currentItem.Employees.length > 1;
    const employeeIdList = currentItem.Employees.map(({ Employee: { Id } }: { Employee: { Id: number } }) => Id);

    return (
      <AirlineSeatMap
        numbersOnSeats={ withIndexNumbers }
        employeeIdList={ employeeIdList }
        employeeSelected={ employeeSelected }
        onSelect={ handleSelect }
        arrayInfo={ arrayInfo }
        selectedSeats={ selectedSeats }
        data={ airlineSeatsStoreCurrentSegment }
        propertiesToHideInLegend={ propertiesToHideInLegend }
        renderRightPanel={ renderRightPanel }
        qaAttrRow={ QA_ATTRIBUTES.cart.air.seats.row }
        qaAttrUnselectedSeat={ QA_ATTRIBUTES.cart.air.seats.unselected }
      />
    );
  };

  const renderLoading = () => (
    <div className={ styles.loading }>
      <DotLoading text={ LABELS.LOADER } />
    </div>
  );

  const renderContent = () => {
    if (loading) return renderLoading();

    return (
      <div className={ styles['side-panel'] }>
        <Text type='bold_32'>{ LABELS.CHOOSE_SEAT }</Text>
        <Text className={ styles['cities-wrapper'] }>
          { LABELS.FLIGHT } {segmentSelected.number}.
            &nbsp;
          <span className={ styles.cities }>{DepartureCity} — {ArrivalCity}</span>
        </Text>
        { renderScheme() }
      </div>
    );
  };

  return (
    <SidePanel
      show={ showPanel }
      onClose={ toggleHadler }
    >
      { renderContent() }
    </SidePanel>
  );
});

export { SidePanelAdditionalService };
