import { SmartdeskStore, SmartdeskStoreType } from './store';

import { PATTERN } from '../../constants/dateFormats';

class Smartdesk {
  api: any;
  xhr: any;
  store: SmartdeskStoreType;

  constructor(api: any) {
    this.api = api.trips;
    this.xhr = null;
    this.store = SmartdeskStore;
  }

  getTripsInCalendar = (params: any) => {
    let lDate = params.startDate;
    let rDate = params.endDate;

    if (typeof lDate === 'object') {
      lDate = lDate.format(PATTERN.YEARMONTHDAY);
    }

    if (typeof rDate === 'object') {
      rDate = rDate.format(PATTERN.YEARMONTHDAY);
    }

    return this.api.getTripsInCalendar({ startDate: lDate, endDate: rDate })
      .then(this.store.setTripsInCalendar);
  };

  setLoading = (value: boolean) => this.store.setLoading(value);

  setSearchSmartdesk = (value: boolean): void => this.store.setSearchSmartdesk(value);

  getReservationNotepad = async () => {
    this.store.setIsNotepad(false);

    try {
      const res = await this.api.getReservationNotepad();

      this.store.getReservationNotepad(res);

      this.store.setIsNotepad(true);
    } catch (e) {
      this.store.setIsNotepad(false);
    }
  };

  getTripsSmartagentPanel = async () => {
    this.store.setIsTrips(false);

    try {
      const res = await this.api.getTripsSmartagentPanel();

      this.store.setTrips(res);

      this.store.setIsTrips(true);
    } catch (e) {
      this.store.setIsTrips(false);
    }
  };
}

export default Smartdesk;
