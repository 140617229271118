import React from 'react';
import { Text, Button, Price, Table } from 'new-ui';

import { getText } from '../../../../../i18n';

import styles from './index.module.css';

const LABEL = {
  EMPLOYEE_PERSONAL_ACCOUNT: getText('constants:settings.employeePersonalAccount'),
  AVAILABLE_BALANCE: getText('constants:settings.availableBalance'),
  TOP_UP_CARD: getText('constants:settings.topUpCard'),
  RETURN_TO_CARD: getText('constants:settings.returnMoneyToCard'),
  USER_TRANSACTIONS: getText('constants:settings.userTransactions'),
  TABLE_HEADERS: {
    SERVICE: getText('constants:settings.transactionsHistoryTable.service'),
    TRANSACTION_TYPE: getText('constants:settings.transactionsHistoryTable.transactionType'),
    AMOUNT: getText('constants:settings.transactionsHistoryTable.amount'),
    DATE: getText('constants:settings.transactionsHistoryTable.date'),
  },
};

const EmployeePersonalAccount = () => {
  const renderPersonalFunds = () => (
    <div className={ styles.personal_funds_container }>
      <div className={ styles.column }>
        <Text type={ 'bold_20' }> { LABEL.AVAILABLE_BALANCE } </Text>
        <Price
          value={ 1234 }
          type={ 'bold_16' }
        />
        <Button>
          {LABEL.TOP_UP_CARD}
        </Button>
      </div>
      <div className={ styles.column }>
        <Text type={ 'NORMAL_14_130' } color={ 'blue' } className={ styles.link }> { LABEL.RETURN_TO_CARD } </Text>
      </div>
    </div>
  );

  const renderTransactionsList = () => (
    <div>
      <Text type={ 'bold_20' }> { LABEL.USER_TRANSACTIONS} </Text>
    </div>
  );

  return (
    <div className={ styles.wrapper }>
      <Text type={ 'bold_24' }> { LABEL.EMPLOYEE_PERSONAL_ACCOUNT } </Text>
      { renderPersonalFunds() }
      { renderTransactionsList() }
      <Table labelsHeader={ [LABEL.TABLE_HEADERS.SERVICE, LABEL.TABLE_HEADERS.TRANSACTION_TYPE, LABEL.TABLE_HEADERS.AMOUNT, LABEL.TABLE_HEADERS.DATE] }/>
    </div>
  );
};

export { EmployeePersonalAccount };
