import React, { FC, useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Button, Select, Text } from 'new-ui';
import { getText } from '../../../../../i18n';

import DialogSpecifiedReport from './dialog';

import { isSmartAgent } from '../../../../bi/utils/env';
import { MainAnalytic } from '../../../../bi/utils/analytics';

import { PAYMENT_TYPE } from '../../../../bi/constants/payment';

import styles from '../../styles/report-operations.module.css';

interface HeaderProps {
  companiesItems:{
    label: string,
    value: number,
  }[],
  currentCompany: number,
  more: boolean,
  companiesBool: boolean,
  isSpecifiedReport: boolean,
  onDownload: (data:{ StartDate: string, EndDate: string }) => Promise<void>,
  onChangeCompany:(value: number) => void,
  onShowRequestActDialog: () => void,
  onShowDetailsDialog: () => void,
  bankDetails: string,
  paymentType: string | number,
}

const POST_PAYMENT = 3;

const LABELS = {
  LABEL_LINK: getText('reports:operations.header.linkLabel'),
  LINK_TEXT: getText('reports:operations.header.linkText'),
  SPECIFIED_REPORT: getText('reports:operations.header.specifiedReport'),
  TITLE: getText('reports:operations.header.title'),
  REQUEST_ACT: getText('reports:operations.header.requestAct'),
  PAYMENT_DETAILS: getText('reports:operations.header.paymentDetails'),
};

const Header: FC<HeaderProps> = ({
  companiesBool,
  currentCompany,
  more,
  isSpecifiedReport,
  onDownload,
  companiesItems,
  onChangeCompany,
  onShowRequestActDialog,
  onShowDetailsDialog,
  paymentType,
}) => {
  const [showDialog, setShowDialog] = useState<boolean>(false);

  useEffect(() => {
    if (companiesItems.length) {
      onChangeCompany(companiesItems[0]?.value);
    }
  }, [companiesItems.length]);

  const handleDownloadSpecifiedReport = (data: { StartDate: string, EndDate: string }) => onDownload(data);

  const handleShowDialog = () => {
    onShowRequestActDialog();
    MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.REPORTING.ACT_PRESSED);
  };

  const handleClickAllOperations = () => MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.REPORTING.ALL_OPERATIONS);

  const dialogHtml = showDialog && (
    <DialogSpecifiedReport
      onDownload={ handleDownloadSpecifiedReport }
      onClose={ () => setShowDialog(false) }
    />
  );

  const moreHtml = more && (
    <div className={ styles.more }>
      <Text type='NORMAL_16' color='gray' className={ styles.label }>
        {LABELS.LABEL_LINK}
      </Text>
      <NavLink to={ `/reports/transactions/${currentCompany}` }>
        <Button type='textual' onClick={ handleClickAllOperations }>
          {LABELS.LINK_TEXT}
        </Button>
      </NavLink>
    </div>
  );

  const companiesHtml = companiesBool && (
    <div className={ styles.select_wrapper }>
      <Select
        theme='default-large'
        items={ companiesItems }
        value={ currentCompany }
        onChange={ onChangeCompany }
        className={ styles.select }
        excludeValue
        smooth
      />
    </div>
  );

  const flagDetailsHtml = paymentType === PAYMENT_TYPE.POST_PAYMENT || paymentType === POST_PAYMENT;

  const titleDetailsHtml = () => {
    if (!flagDetailsHtml || isSmartAgent) {
      return null;
    }

    return (
      <div
        className={ styles.specific_button }
      >
        <Button
          type='primary-outline'
          onClick={ onShowDetailsDialog }
        >
          { LABELS.PAYMENT_DETAILS }
        </Button>
      </div>
    );
  };

  const renderSpecificButton = () => {
    if (!isSpecifiedReport) {
      return null;
    }

    return (
      <Button
        className={ styles.specific_button }
        onClick={ () => setShowDialog(true) }
        type='primary-outline'
      >
        { LABELS.SPECIFIED_REPORT }
      </Button>
    );
  };

  const titleHtml = (
    <div className={ styles.title }>
      <Text type='bold_32' >{LABELS.TITLE}</Text>
      <div
        className={ styles.wrapper_specific }
      >
        { renderSpecificButton() }
        { titleDetailsHtml() }
        <Button onClick={ handleShowDialog }>{ LABELS.REQUEST_ACT }</Button>
      </div>
    </div>
  );

  const renderNormalHeader = () => (
    <div className={ styles.header }>
      { titleHtml }
      { companiesHtml }
      { moreHtml }
    </div>
  );

  return (
    <>
      { renderNormalHeader() }
      { dialogHtml }
    </>
  );
};

export { Header };
