import React, { useEffect, useState } from 'react';
import { Icon, LinkButton, Text } from 'new-ui';

import { getText } from '../../../../../i18n';

import { useServices } from '../../../../bi/context/services';

import { LoadingStatus } from '../../../../bi/services/utils/network/types';

import styles from './styles.module.css';

import tgIcon from '../../../../../svg/logos/telegram.svg';

const LABELS = {
  NEED_VOUCHER_ACCESS: getText('smartdesk:telegram:needVoucherAccess'),
  CONNECT_TELEGRAM: getText('smartdesk:telegram:connectTelegram'),
};

export const LinkTgAccount = () => {
  const {
    employeeService,
    workspaceService,
    uiSettingsService,
  } = useServices(['Employee', 'Workspace', 'UiSettings']);

  const isLoaded = employeeService.networkStore.getIsStatus('getTgLink', LoadingStatus.LOADED);
  const employeeId = workspaceService.get().Rights.EmployeeId;
  const isAdmin = workspaceService.isAdmin;
  const isTgLinked = employeeService.get().isTgLinked;

  const [isClosed, setIsClosed] = useState(false);

  useEffect(() => {
    if (!uiSettingsService.store.isWhiteLabel && employeeId !== 0) {
      employeeService.loadLinkedTgAccount(employeeId);
    }
  }, [employeeId]);

  const handleOpenTg = () => {
    employeeService.openTgLink(employeeId);
  };

  if (!isLoaded || isTgLinked || isClosed || isAdmin) {
    return <div className={ styles.empty }/>;
  }

  return (
    <div className={ styles.wrap }>
      <div className={ styles.container }>
        <img src={ tgIcon } alt={ 'telegram' } />
        <div className={ styles.info }>
          <Text type='NORMAL_16_140' className={ styles.text }>
            { LABELS.NEED_VOUCHER_ACCESS }
          </Text>
          <LinkButton theme='blue' onClick={ handleOpenTg }>
            <Text type='NORMAL_16_140' className={ styles.text }>
              { LABELS.CONNECT_TELEGRAM }
            </Text>
          </LinkButton>
        </div>
        <div>
          <div
            onClick={ () => setIsClosed(true) }
            className={ styles.cross }
          >
            <Icon type={ 'closeButton' } />
          </div>
        </div>
      </div>
    </div>
  );
};
