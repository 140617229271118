import { NotificationsManager } from 'new-ui';

import { history } from '../../../../utils/history';

import { ApproveStatus, TravelApprovalType } from '../../services/travelApproval/consts';
import { APPROVAL_STATUS_PAGE } from '../../constants/travelApproval';
import { LABELS } from '../../../page/ApprovalRequest';
import ROUTES from '../../constants/routes';

import { ServiceReturnType } from '../../context/services';

export class ApprovalRequest {
  services: ServiceReturnType;

  constructor(services: ServiceReturnType) {
    this.services = services;
  }

  loadApproveRequestExists = async () => {
    const id = this.services.Workspace.get().ApprovalSchemeId;

    await this.services.TravelApproval.getApproveRequestExists(id);
  };

  setTravelApprovalStatusPage = (approvalScheme: TravelApprovalType | null) => {
    if (!approvalScheme) {
      return this.services.TravelApproval.setTravelApproval();
    }

    const { UserId } = this.services.Workspace.rights;
    const { Approves, Status } = approvalScheme;

    this.services.TravelApproval.setTravelApproval(approvalScheme);
    const isApprover = Status !== ApproveStatus.accepted &&
      ((!!UserId && !!Approves.find(approve => approve.UserId === UserId)) || this.services.Workspace.isAdmin);

    if (isApprover) {
      return this.services.TravelApproval.setTravelApprovalStatusPage(APPROVAL_STATUS_PAGE.APPROVER);
    }

    return this.services.TravelApproval.setTravelApprovalStatusPage(
      this.services.TravelApproval.getStatusFromBackend(Status),
    );
  };

  loadRequest = async (id: number) => {
    this.services.TravelApproval.updateApprovalLoading(true);

    try {
      const approvalScheme = await this.services.TravelApproval.getRequest(id);
      const { Employees } = approvalScheme;
      const employeesIds = Employees.map(({ Id }) => Id as number);

      await this.services.TravelApproval.loadEmployeeCards(employeesIds);
      await this.services.TravelApproval.loadTravelApprovalsPopUp(approvalScheme);

      this.setTravelApprovalStatusPage(approvalScheme);

      this.services.TravelApproval.updateApprovalLoading(false);
    } catch (e) {
      this.setTravelApprovalStatusPage(null);
      this.services.TravelApproval.updateApprovalLoading(false);

      NotificationsManager.addNotification({
        value: LABELS.REQUEST_NOT_FOUND,
        type: 'error',
      });
      history.push(ROUTES.SMARTDESK);
    }
  };

  approveRequest = async (field: string, comment: string) => {
    this.services.TravelApproval.setLoadingSendButton(true);

    const { AccountEmail } = this.services.Workspace;
    const { Approves } = this.services.TravelApproval.travelApprovalStore.travelApproval;

    const approver = Approves.find(({ Email, Resolution }) => Email === AccountEmail && Resolution !== ApproveStatus.accepted);
    const id = approver ? approver.Id as number : null;

    const data = {
      Id: id as number,
      Resolution: field,
      ApprovalComment: comment,
    };

    try {
      if (id !== null) {
        await this.services.TravelApproval.updateApprovalRequest(comment, id);
        await this.services.TravelApproval.approveRequest(data);
      }

      this.services.TravelApproval.setLoadingSendButton(false);
    } catch (e) {
      this.services.TravelApproval.setLoadingSendButton(false);
    }
  };

  get approveRequestExists() {
    return this.services.Workspace.isPreSchemeFromRights && this.services.TravelApproval.travelApprovalsStore.requestExists;
  }
}
