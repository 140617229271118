import React, { Component, ReactNode } from 'react';
import { Button, Text, Icon, Tooltip, PageLoader, EmptyPanel, IconType } from 'new-ui';
import { RouteComponentProps } from 'react-router-dom';
import { getText } from '../../../i18n';

import { SERVICETYPE } from '../../bi/constants/serviceType';
import { NoteSearchPanel } from './components/NoteSearchPanel';
import NoteItem from './components/NoteItem';

import AdditionalSearch from './components/AdditionalSearch';
import FilterEmptyPanel from './components/FilterEmptyPanel/FilterEmptyPanel';
import { FilterTabs } from '../../components/FilterTabs';
import { WarningBlock } from '../../components/WarningBlock';

import ROUTES from '../../bi/constants/routes';
import ANIMATION from '../../bi/constants/animation';
import { QA_ATTRIBUTES } from '../../bi/constants/attributesForTests';
import { FILTERS_TYPES } from '../../bi/constants/note';
import Notepad from '../../bi/services/notepad';
import Notification from '../../bi/services/notification';
import ReportService from '../../bi/services/report';
import FormatService from '../../bi/services/format';
import UserSessionService from '../../bi/services/userSession';
import HotelsService from '../../bi/services/hotels';
import WorkspaceService from '../../bi/services/workspace';
import TripTagsService from '../../bi/services/tripTags';
import AirlineService from '../../bi/services/airline';
import TrainService from '../../bi/services/trains';
import FavoritesService from '../../bi/services/favorites';
import FeatureFlags from '../../bi/services/featureFlags';
import AccountSettingsService from '../../bi/services/accountSettings';
import PopupsProtocol from '../../bi/protocols/popups';
import { UiSettingsProtocol } from '../../bi/protocols/uiSettings';

import {
  iconByNoteType,
  iconByServiceType,
  preparedFiltersType,
} from '../../bi/utils/note';
import { MainAnalytic } from '../../bi/utils/analytics';
import parseJsonString from '../../bi/utils/parseJsonString';
import { getMultiTripIds } from '../../bi/utils/common';
import getAccountId from '../../bi/utils/getAccountId';
import { getDataForEmptyPanel } from '../../bi/utils/searchPanel';
import { isSmartAgent } from '../../bi/utils/env';
import { s7BonusCardWarning } from '../../bi/utils/cart';

import { INotepadStore, IPrepareNoteItem, IResponseGetNotes } from '../../bi/services/notepad/types';
import { Tab } from '../../bi/types/shared';

import styles from './index.module.css';

const TIMEOUT = 1000;

const LABELS = {
  OPEN_NOTE: getText('note:openNote'),
  EMPTY: getText('note:empty'),
  TITLE: getText('note:title'),
  NON_ACTUAL: getText('note:nonActual'),
  REMOVE_ALL: getText('note:removeAll'),
  PLACE_IS_TAKEN: getText('note:placeIsTaken'),
  REMOVE_AVAILABLE: getText('note:tooltip.removeAvailable'),
  BONUS_CARD_S7_WARNING: getText('cart:s7BonusCardWarning'),
  SERVICETYPE: {
    AIR: getText('note:serviceType.air'),
    HOTEL: getText('note:serviceType.hotel'),
    TRAIN: getText('note:serviceType.train'),
    TRANSFER: getText('note:serviceType.transfer'),
    DEFAULT: getText('note:serviceType.default'),
  },
  NOTIFICATION: {
    MOVE_TO_CART: {
      SUCCESS: {
        ONE: (serviceType: string) => getText('note:notifications.moveToCart.success.one', { serviceType }),
        MANY: getText('note:notifications.moveToCart.success.many'),
      },
      ERROR: {
        ONE: getText('note:notifications.moveToCart.error.one'),
        MANY: getText('note:notifications.moveToCart.error.many'),
      },
    },
  },
  REMOVE: {
    ONE: (serviceType: string) => getText('note:notifications.remove.one', { serviceType }),
    MANY: getText('note:notifications.remove.many'),
    ALL: (serviceType: string) => getText('note:notifications.remove.all', { serviceType }),
  },
  SEND_TO_APPROVE: (email: string) => getText('note:notifications.sendToApprove', { email }),
};

interface NotePageProps {
  notepadService: Notepad;
  notificationService: Notification;
  reportService: ReportService;
  formatService: FormatService;
  userSessionService: UserSessionService;
  hotelsService: HotelsService;
  workspaceService: WorkspaceService;
  tripTagsService: TripTagsService;
  airlineService: AirlineService;
  trainService: TrainService;
  favoritesService: FavoritesService;
  accountSettingsService: AccountSettingsService;
  popupsProtocol: PopupsProtocol;
  uiSettingsProtocol: UiSettingsProtocol;
  history: RouteComponentProps['history'];
  aggregationId: number;
  featureFlagsService: FeatureFlags,
}

interface NotePageState extends INotepadStore {
  documentType: { [key: string]: string };
  projects: any[];
  tripsByTripIds: any[];
  allItemsAnimatedClass: string;
  allItemsLoading: boolean;
  nodeList: IResponseGetNotes;
  selectedItemId: number;
}

class NotePage extends Component<NotePageProps, NotePageState> {
  unsubscribeFn: () => void;

  isNoBookingTaxi: boolean = this.props.accountSettingsService.getBookingTaxi();
  getPersonalBonusCards: boolean = this.props.accountSettingsService.getPersonalBonusCards();

  state = {
    ...this.props.notepadService.get(),
    documentType: this.props.userSessionService.get().enums.documents,
    projects: [],
    tripsByTripIds: [],
    allItemsAnimatedClass: '',
    allItemsLoading: false,
    nodeList: {
      ServerTime: '',
      Items: [],
    },
    selectedItemId: 0,
  };

  async componentDidMount() {
    const { notepadService, tripTagsService } = this.props;
    const { Rights: { Login } } = this.props.workspaceService.get();

    this.unsubscribeFn = notepadService.subscribeNote(this.updateState);

    notepadService.start();
    const userIdentity = await this.props.notepadService.getUserIdentity(
      {
        UserName: Login,
        AccountId: getAccountId(),
      },
    );

    MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.NOTE.MENU_TO_NOTE);

    await notepadService.load(isSmartAgent, userIdentity);
    tripTagsService.loadAccountTripTags();
  }

  componentWillUnmount() {
    this.props.notepadService.clearFilters();
    this.unsubscribeFn();
  }

  getTrainAvailability = (items: IPrepareNoteItem[]) => {
    const { trainService } = this.props;

    if (items.every(({ ServiceType }) => ServiceType !== SERVICETYPE.TRAIN)) {
      return Promise.resolve();
    }

    const trainItems = items.filter(({ ServiceType }) => ServiceType === SERVICETYPE.TRAIN);

    return Promise.all(trainItems.map(({ Data, OwnerId }) => {
      try {
        const { CarNumber, TrainId, SearchId, CarId, PlaceNumber } = parseJsonString(Data);

        return trainService.getAvailability({
          CarNumber,
          TrainId,
          SearchId,
          CarId,
          Place: PlaceNumber,
          UserId: OwnerId,
          isSmartAgent,
        })
          .then(() => Promise.resolve())
          .catch(() => Promise.reject(LABELS.PLACE_IS_TAKEN));
      } catch (e) {
        return Promise.reject();
      }
    }));
  };

  updateState = (state: INotepadStore) => {
    const { enums: { documents }, projects } = this.props.userSessionService.get();

    this.setState({
      userId: state.userId,
      sources: state.sources,
      loading: state.loading,
      items: state.items,
      fouls: state.fouls,
      tripsByTripIds: state.tripsByTripIds,
      sourcesFouls: state.sourcesFouls,
      filters: state.filters,
      serverTime: state.serverTime,
      documentType: documents,
      projects,
    });
  };

  handleSelectCompany = () => MainAnalytic.send(MainAnalytic.CATEGORY.CART, MainAnalytic.ACTIONS.CART.SELECTORGANIZATION);

  handleInputChange = (searchValue: string) => {
    const { notepadService: { changeInputSearch, changeSelectedAll } } = this.props;
    const { filters: { type: { current: serviceType } } } = this.state;

    changeInputSearch(searchValue, serviceType as string);
    changeSelectedAll(!!searchValue.length);
  };

  handleCheckboxChangeAll = (selectedAll: boolean) => this.props.notepadService.changeSelectedAll(selectedAll);

  handleCheckboxChange = (item: IPrepareNoteItem, value: boolean) => {
    const { notepadService } = this.props;
    const { MultiTripInfo } = parseJsonString(item.Data);
    const multiTrip = MultiTripInfo?.Id;

    if (multiTrip) {
      const { items } = this.state;
      const multiTripIds: any = getMultiTripIds(items)[multiTrip];

      notepadService.changeMultiSelected(value, multiTripIds);
    } else {
      notepadService.changeSelected(value, item);
    }
  };

  changeFilters = (noteType: string, tripType: string | Tab) => {
    const { notepadService } = this.props;
    const { sources, sourcesFouls, userId } = this.state;

    this.reseltFilters();
    notepadService.changeNoteTypeFilter(noteType, tripType, sources, sourcesFouls, userId as string);
  };

  handleChangeFilter = (value: Tab) => {
    if (isSmartAgent) {
      MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.SMART_AGENT.NOTE_SERVICE_FILTER, { SAservice: value });
    }

    this.changeFilters(this.state.filters.noteType.current as string, value);
  };

  handleChangeNoteTypeFilter = (value: string) => {
    const { userId } = this.state;

    const preparedUsers = preparedFiltersType(value, userId as string);

    this.changeFilters(value, this.state.filters.type.current as string);
    MainAnalytic.sendAmplitude(
      MainAnalytic.ACTIONS.SMART_AGENT.NOTE_USER_FILTER,
      { SAnote: preparedUsers },
    );
  };

  handleDownload = () => {
    const ids = this.props.notepadService.getIdSelectedItems();

    return this.props.reportService.downloadNote(ids);
  };

  sendNotification = (itemsLength: number | null, serviceType: string | null) => {
    this.props.notificationService.send({
      message: itemsLength && itemsLength > 1 ?
        LABELS.NOTIFICATION.MOVE_TO_CART.SUCCESS.MANY :
        LABELS.NOTIFICATION.MOVE_TO_CART.SUCCESS.ONE(serviceType as string),
      type: 'success',
      // @ts-ignore
      onClick: () => history.push(ROUTES.CART.MAIN),
      qaAttr: QA_ATTRIBUTES.note.notification.moveToCart,
    });
  };

  reseltFilters = () => {
    const { notepadService } = this.props;

    notepadService.changeSelectedAll(false);
    this.handleInputChange('');
  };

  handleResetAllFilters = () => {
    const { notepadService } = this.props;
    const { userId } = this.state;

    notepadService.changeSelectedAll(false);
    notepadService.clearFilters(isSmartAgent, userId as string);

    this.handleInputChange('');
  };

  handleMoveItemsToCart = (item?: IPrepareNoteItem) => {
    const { notepadService, uiSettingsProtocol, popupsProtocol } = this.props;
    const { items } = this.state;
    let itemsLength: number | null = null;
    let serviceType: string | null = null;
    let multiTrip: number | null = null;

    const ids = this.props.notepadService.getIdSelectedItems();
    const selectedItems: IPrepareNoteItem[] = items.filter(({ Id }) => ids.includes(Id));

    if (item) {
      // @ts-ignore
      itemsLength = item.length; // TODO what is this ???
      serviceType = this.getTypeMessage(item.ServiceType);

      const { MultiTripInfo } = parseJsonString(item.Data);
      multiTrip = MultiTripInfo?.Id;
    } else {
      itemsLength = selectedItems.length;
      serviceType = items.length > 1 ? '' : this.getTypeMessage(selectedItems[0].ServiceType);
    }

    // todo: make it works with selectedItems.length > 1
    if (item && item.ServiceType === SERVICETYPE.AIR && uiSettingsProtocol.isSearchAeroexpress) {
      const { Routes, Metadata: { TravellersCount: count } } = parseJsonString(item.Data);

      popupsProtocol.offerAeroexpress({ Routes }, { count, isComplex: false }, item.TripId);
    }

    return this.getTrainAvailability(item ? [item] : selectedItems).then(() => {
      if (multiTrip) {
        // @ts-ignore
        const multiTripIds: number[] = getMultiTripIds(items)[multiTrip];

        if (item) {
          this.setState({
            selectedItemId: item.Id,
            allItemsLoading: true,
          });
        }

        return notepadService.moveToCartMulti(multiTripIds).then(async () => {
          this.sendNotification(itemsLength, serviceType);

          await notepadService.load(isSmartAgent, this.state.userId as string);

          this.reseltFilters();

          return Promise.resolve();
        });
      }

      return notepadService.moveToCart(item?.Id || null).then(async () => {
        this.sendNotification(itemsLength, serviceType);
        MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.NOTE.NOTE_TO_CART, { service: serviceType });

        await notepadService.load(isSmartAgent, this.state.userId as string);

        this.reseltFilters();

        notepadService.getNodeList(isSmartAgent).then((list) => {
          this.setState({ nodeList: list, allItemsLoading: !item });

          return Promise.resolve();
        });
      });
    })
      .catch((e) => {
        const message = e || (itemsLength && itemsLength > 1 ?
          LABELS.NOTIFICATION.MOVE_TO_CART.ERROR.MANY :
          LABELS.NOTIFICATION.MOVE_TO_CART.ERROR.ONE);

        this.props.notificationService.send({
          message,
          type: 'error',
        });

        return Promise.reject();
      });
  };

  handleMoveAllItemsToCart = async () => {
    try {
      await this.handleMoveItemsToCart();

      this.setState({ allItemsAnimatedClass: ANIMATION.MOVETOCART }, () => {
        setTimeout(async () => {
          this.setState({ allItemsAnimatedClass: '', allItemsLoading: false });
          await this.updateItems();

          return Promise.resolve();
        }, TIMEOUT);
      });
    } catch {
      this.setState({ allItemsAnimatedClass: '' });
    }
  };

  handleDeleteItems = (item: IPrepareNoteItem | null = null, notify: boolean = true) => {
    const { notepadService, notificationService } = this.props;
    const { items } = this.state;
    let itemsLength: number | null = null;
    let serviceType: string | null = null;
    let multiTrip = null;

    const ids = notepadService.getIdSelectedItems();
    const selectedItems = items.filter(({ Id }) => ids.includes(Id));

    if (item) {
      // @ts-ignore
      itemsLength = item.length; // Это как и зачем???
      serviceType = this.getTypeMessage(item.ServiceType);

      const { MultiTripInfo } = parseJsonString(item.Data);
      multiTrip = MultiTripInfo?.Id;
    } else {
      itemsLength = selectedItems.length;
      serviceType = itemsLength > 1 ? '' : this.getTypeMessage(selectedItems[0].ServiceType);
    }

    const sendNotification = () => {
      notificationService.send({
        message: itemsLength && itemsLength > 1 ?
          LABELS.REMOVE.MANY :
          LABELS.REMOVE.ONE(serviceType as string),
        type: 'success',
        qaAttr: QA_ATTRIBUTES.note.notification.delete,
      });
    };

    if (multiTrip) {
      // @ts-ignore
      const multiTripIds: number[] = getMultiTripIds(items)[multiTrip];

      if (item) {
        this.setState({
          selectedItemId: item.Id,
          allItemsLoading: true,
          allItemsAnimatedClass: ANIMATION.DELETE_CART,
        });
      }

      return notepadService.multiRemove(multiTripIds).then(async () => {
        if (notify) {
          sendNotification();
        }
      });
    }

    // @ts-ignore
    return notepadService.remove(item?.Id).then(async () => {
      const list = await notepadService.getNodeList(isSmartAgent);
      this.setState({ nodeList: list, allItemsLoading: !item });

      if (item) {
        MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.NOTE.DELETE_NOTE, { service: item.ServiceType });
      }

      if (notify) {
        sendNotification();
      }
    });
  };

  handleDeleteAllItems = async () => {
    try {
      await this.handleDeleteItems();

      this.setState({ allItemsAnimatedClass: ANIMATION.DELETE_CART }, () => {
        setTimeout(async () => {
          this.setState({ allItemsAnimatedClass: '', allItemsLoading: false });
          await this.updateItems();
        }, TIMEOUT);
      });
    } catch {
      this.setState({ allItemsAnimatedClass: '' });
    }
  };

  updateItems = async () => {
    const { notepadService } = this.props;
    const { userId } = this.state;
    notepadService.setNodeList(this.state.nodeList, userId as string, isSmartAgent);

    const list = await notepadService.getNodeList(isSmartAgent);
    this.setState({ nodeList: list });

    this.reseltFilters();
  };

  handleRemoveAllFoul = () => {
    const { notepadService } = this.props;
    const { fouls } = this.state;

    notepadService.removeAllFouls().then(() => {
      this.props.notificationService.send({
        message: fouls.length > 1 ?
          LABELS.REMOVE.MANY :
          LABELS.REMOVE.ALL(this.getTypeMessage(fouls[0].ServiceType)),
        type: 'success',
      });

      notepadService.load(isSmartAgent, this.state.userId);

      MainAnalytic.send(MainAnalytic.CATEGORY.NOTE, MainAnalytic.ACTIONS.NOTE.REMOVEFOULNOTEALL);
    });
  };

  handleTimeIsUp = (item: IPrepareNoteItem) => this.props.notepadService.updateTimeIsUp(item);

  handleSend = (email: string, comment: string) => {
    const sendPromise = this.props.notepadService.send(email, comment);

    sendPromise.then(() => {
      this.props.notificationService.send({
        message: LABELS.SEND_TO_APPROVE(email),
        type: 'success',
        qaAttr: QA_ATTRIBUTES.note.dialogSendToEmail.notification,
      });
    });

    return sendPromise;
  };

  handleCreateReservation = () =>
    MainAnalytic.send(MainAnalytic.CATEGORY.NOTE, MainAnalytic.ACTIONS.NOTE.CREATERESERVATION);

  handleMoveNoteToCart = (label: string, value: number = 1) => {
    MainAnalytic.send(MainAnalytic.CATEGORY.NOTE, MainAnalytic.ACTIONS.NOTE.ADDTOCART, {
      label: MainAnalytic.LABELS.NOTE[label],
      value,
    });
  };

  handleRemoveServiceNote = (label: string, value: number = 1) =>
    MainAnalytic.send(MainAnalytic.CATEGORY.NOTE, MainAnalytic.ACTIONS.NOTE.REMOVESERVICE, {
      label: MainAnalytic.LABELS.NOTE[label],
      value,
    });

  handleOtherEventPanel = (action: string, value: number) =>
    MainAnalytic.send(MainAnalytic.CATEGORY.NOTE, MainAnalytic.ACTIONS.NOTE[action], {
      value,
    });

  handleBlurFastSearch = (label: string, value: number) =>
    MainAnalytic.send(MainAnalytic.CATEGORY.NOTE, MainAnalytic.ACTIONS.NOTE.FASTSEARCH, {
      label,
      value,
    });

  handleRemoveFoulService = () =>
    MainAnalytic.send(MainAnalytic.CATEGORY.NOTE, MainAnalytic.ACTIONS.NOTE.REMOVEFOULNOTE);

  getTypeMessage = (type: string): string => {
    switch (type) {
      case SERVICETYPE.AIR:
        return LABELS.SERVICETYPE.AIR;
      case SERVICETYPE.HOTEL:
        return LABELS.SERVICETYPE.HOTEL;
      case SERVICETYPE.TRAIN:
        return LABELS.SERVICETYPE.TRAIN;
      case SERVICETYPE.TRANSFER:
        return LABELS.SERVICETYPE.TRANSFER;
      default:
        return LABELS.SERVICETYPE.DEFAULT;
    }
  };

  renderFilter = () => {
    const { filters: { type: { tabs, current } } } = this.state;

    if (isSmartAgent || tabs.length <= 2) {
      return null;
    }

    return (
      <FilterTabs
        tabs={ tabs }
          // @ts-ignore
        currentTab={ current.value }
        onClick={ this.handleChangeFilter }
      />
    );
  };

  renderSearch = () => {
    const { filters, items, userId } = this.state;

    const initTop = filters.type.tabs.length > 2 ? 313 : 228;

    return (
      <NoteSearchPanel
        initTop={ initTop }
        selectedCount={ filters.selectedCount }
        selectedAll={ filters.selectedAll }
        items={ items }
        userId={ userId as string }
        query={ filters.query }
        handleInputChange={ this.handleInputChange }
        handleDownload={ this.handleDownload }
        handleCheckboxChange={ this.handleCheckboxChangeAll }
        handleMoveItemsToCart={ this.handleMoveAllItemsToCart }
        handleDeleteItems={ this.handleDeleteAllItems }
        handleSend={ this.handleSend }
        onMoveNoteToCart={ this.handleMoveNoteToCart }
        onRemoveServiceNote={ this.handleRemoveServiceNote }
        onOtherEventPanel={ this.handleOtherEventPanel }
        onBlurFastSearch={ this.handleBlurFastSearch }
      />
    );
  };

  renderLoading = () => (
    <PageLoader text={ LABELS.OPEN_NOTE } />
  );

  renderIcon = (icon: IconType, isHtml: boolean) => {
    if (isHtml) {
      return (
        <div>
          <Icon type={ icon } alternativeDesign={ isSmartAgent }/>
        </div>
      );
    }

    return icon;
  };

  renderIconByNoteType = (value: string, isHtml: boolean = false) => {
    const icon: IconType = iconByNoteType(value, this.state.userId as string);

    return this.renderIcon(icon, isHtml);
  };

  renderIconByServiceType = (value: string, isHtml: boolean = false): ReactNode | IconType => {
    const icon: IconType | null = iconByServiceType(value);

    return this.renderIcon(icon as IconType, isHtml);
  };

  renderIconByType = (selectorType: string, value: string) => {
    if (selectorType === FILTERS_TYPES.NOTE) {
      return this.renderIconByNoteType(value, !!selectorType);
    }

    if (selectorType === FILTERS_TYPES.SERVICE) {
      return this.renderIconByServiceType(value, !!selectorType);
    }

    return null;
  };

  renderItem = ({ value, label }: { label: string, value: string }, selectorType: string) => (
    <div key={ value + label } className={ styles.action_item }>
      {this.renderIconByType(selectorType, value)}
      <Text className={ styles.action_text }> {label} </Text>
    </div>
  );

  renderEmpty = () => {
    const { uiSettingsProtocol: { searchServiceTypeForMenu } } = this.props;

    return (
      <EmptyPanel
        searchMenuItems={ getDataForEmptyPanel(searchServiceTypeForMenu, this.isNoBookingTaxi) }
        title={ LABELS.EMPTY }
        iconType='noteBig'
        alternativeDesign={ isSmartAgent }
        qaAttr={ QA_ATTRIBUTES.note.empty.text }
        qaAttrSearchPanel={ QA_ATTRIBUTES.note.empty.searchPanel }
      />
    );
  };

  renderItemNote = () => {
    const {
      items,
      serverTime,
      documentType,
      projects,
      allItemsAnimatedClass,
      allItemsLoading,
      userId,
      tripsByTripIds,
      selectedItemId,
    } = this.state;

    const {
      history,
      formatService,
      workspaceService,
      tripTagsService,
      airlineService,
      favoritesService,
      notificationService,
      hotelsService,
      notepadService,
      aggregationId,
    } = this.props;

    const warningBlockHtml = s7BonusCardWarning(items) && <WarningBlock
      text={ LABELS.BONUS_CARD_S7_WARNING }
      type='SEMIBOLD_16'
      color='red'
    />;

    const itemStyle = isSmartAgent
      ? styles['new-list']
      : styles.list;

    const itemHtml = items.map(item => {
      const { MultiTripInfo } = parseJsonString(item.Data);
      const multiTrip = MultiTripInfo?.Id;

      const multiTripIds = getMultiTripIds(items)[multiTrip] as unknown as number[];

      const isValidTripIds = multiTripIds?.some(id => id === selectedItemId);

      const isValidItemsLoading = isValidTripIds || (item.Selected && isValidTripIds);

      const currentAllItemsAnimatedClass = isValidItemsLoading && allItemsAnimatedClass
        ? allItemsAnimatedClass
        : '';

      const currentAllItemsLoading = isValidItemsLoading && allItemsLoading
        ? allItemsLoading
        : false;

      const isPhantom = isSmartAgent
        && (userId === item.OriginalOwnerId && userId !== item.OwnerId);

      return (
        <NoteItem
          key={ item.Id }
          item={ item }
          userId={ userId }
          aggregationId={ aggregationId }
          projects={ projects }
          serverTime={ serverTime }
          documentType={ documentType }
          selected={ item.Selected }
          history={ history }
          tripsByTripIds={ tripsByTripIds }
          isPhantom={ isPhantom }
          formatService={ formatService }
          workspaceService={ workspaceService }
          tripTagsService={ tripTagsService }
          isPersonalBonusCards={ this.getPersonalBonusCards }
          allItemsAnimatedClass={ currentAllItemsAnimatedClass }
          allItemsLoading={ currentAllItemsLoading }
          moveToCart={ this.handleMoveItemsToCart }
          deleteItem={ (notify: boolean) => this.handleDeleteItems(item, notify) }
          updateItems={ this.updateItems }
          onItemSelect={ (value: boolean) => this.handleCheckboxChange(item, value) }
          handleTimeIsUp={ this.handleTimeIsUp }
          onCreateReservation={ this.handleCreateReservation }
          onMoveNoteToCart={ (label: string, value?: number) => this.handleMoveNoteToCart(label, value) }
          onRemoveServiceNote={ this.handleRemoveServiceNote }
          onSelectCompany={ this.handleSelectCompany }
          notepadService={ notepadService }
          airlineService={ airlineService }
          notificationService={ notificationService }
          favoritesService={ favoritesService }
          hotelsService={ hotelsService }
        />
      );
    });

    return (
      <div className={ itemStyle }>
        { warningBlockHtml }
        { itemHtml }
      </div>
    );
  };

  renderFoulNote = () => {
    const {
      fouls,
      serverTime,
      documentType,
      userId,
      allItemsAnimatedClass,
      allItemsLoading,
      tripsByTripIds,
    } = this.state;
    const {
      history,
      formatService,
      workspaceService,
      tripTagsService,
      airlineService,
      favoritesService,
      hotelsService,
      notificationService,
      notepadService,
      aggregationId,
    } = this.props;

    const disableRemove = fouls.some(({ OwnerId }) => userId !== OwnerId);

    const renderTooltip = (info: string) => (
      <Text
        color='white'
        type='NORMAL_14_130'
        className={ styles.tooltip_style }
      >
        <div>{ info }</div>
      </Text>
    );

    const foulHtml = fouls.map(foul => {
      const currentAllItemsAnimatedClass = foul.Selected && allItemsAnimatedClass
        ? allItemsAnimatedClass
        : '';

      const currentAllItemsLoading = foul.Selected && allItemsLoading
        ? allItemsLoading
        : false;

      return (
        <NoteItem
          key={ foul.Id }
          item={ foul }
          foul
          userId={ userId }
          aggregationId={ aggregationId }
          serverTime={ serverTime }
          documentType={ documentType }
          history={ history }
          tripsByTripIds={ tripsByTripIds }
          formatService={ formatService }
          workspaceService={ workspaceService }
          tripTagsService={ tripTagsService }
          selected={ false }
          isPersonalBonusCards={ this.getPersonalBonusCards }
          allItemsAnimatedClass={ currentAllItemsAnimatedClass }
          allItemsLoading={ currentAllItemsLoading }
          deleteItem={ () => this.handleDeleteItems(foul) }
          updateItems={ this.updateItems }
          onRemoveFoulService={ this.handleRemoveFoulService }
          notepadService={ notepadService }
          airlineService={ airlineService }
          hotelsService={ hotelsService }
          favoritesService={ favoritesService }
          notificationService={ notificationService }
        />
      );
    });

    return (
      <div className={ styles.fouls }>
        <div className={ styles.header }>
          <Text type='bold_24'>{ LABELS.NON_ACTUAL }</Text>
          <Tooltip
            show={ disableRemove }
            renderContent={ () => renderTooltip(LABELS.REMOVE_AVAILABLE) }
          >
            <Button
              type='textual'
              onClick={ this.handleRemoveAllFoul }
              disabled={ disableRemove }
            >
              { LABELS.REMOVE_ALL }
            </Button>
          </Tooltip>
        </div>
        <div className={ styles.foul_list }>
          { foulHtml }
        </div>
      </div>
    );
  };

  renderAdditionalSearch = () => isSmartAgent && (
    <AdditionalSearch
      filters={ this.state.filters }
      renderItem={ this.renderItem }
      onInputChange={ this.handleInputChange }
      onChangeFilter={ this.handleChangeFilter }
      onBlurFastSearch={ this.handleBlurFastSearch }
      renderIconByNoteType={ this.renderIconByNoteType }
      renderIconByServiceType={ this.renderIconByServiceType }
      onChangeNoteTypeFilter={ this.handleChangeNoteTypeFilter }
    />
  );

  renderFilterEmptyPanel = () => !this.state.items.length && !this.state.fouls.length && (
    <FilterEmptyPanel onResetAllFilters={ this.handleResetAllFilters }/>
  );

  render() {
    const {
      sources,
      items,
      fouls,
      sourcesFouls,
      loading,
    } = this.state;

    if (loading) {
      return this.renderLoading();
    }

    const isNoteEmpty = !sources.length && !items.length && !sourcesFouls.length;

    if (isNoteEmpty) {
      return this.renderEmpty();
    }

    const searchHtml = !!sources.length && this.renderSearch();
    const itemHtml = this.renderItemNote();
    const foulHtml = !!fouls.length && this.renderFoulNote();

    return (
      <div className={ styles.wrapper }>
        <Text
          type='bold_32'
          className={ styles.header }
          qaAttr={ QA_ATTRIBUTES.note.header }
        >
          { LABELS.TITLE }
        </Text>
        { this.renderAdditionalSearch() }
        <div className={ styles.content }>
          { this.renderFilter() }
          { this.renderFilterEmptyPanel() }
          { searchHtml }
          { itemHtml }
          { foulHtml }
        </div>
      </div>
    );
  }
}

export default NotePage;
