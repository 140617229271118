import Api from '../../api';

import { airlineSeatsStore, IAirlineSeatsStore } from './store';

import type {
  SeatType,
  SegmentType,
} from '../../../components/AirlineSeatMap/types/types';
import { IEmployees } from '../employee/types';
import { AirSegment } from '../../types/airline';

class AirlineSeatsService {
  api: Api['airlineSeats'];
  store: IAirlineSeatsStore;

  constructor(api: Api) {
    this.api = api.airlineSeats;
    this.store = airlineSeatsStore;
  }

  clearPlaces = () => {
    this.store.clearPlaces();
  };

  getEmployeeIdWithoutSeat = (Employees: { Employee: IEmployees }[]) => {
    const selectedSeats = this.getSelectedSeatsForCurrentSegment();

    const employeeWithoutSeat = Employees
      .find(({ Employee: { Id: employeeId } }) =>
        !selectedSeats.some(({ travellerId }) => travellerId === employeeId));

    return employeeWithoutSeat ? employeeWithoutSeat.Employee.Id : null;
  };

  clearPlace = ({ employeeId }: { employeeId: number }) => {
    const selectedSeats = this.getSelectedSeatsForCurrentSegment();
    const newSelectedSeats = selectedSeats.filter(({ travellerId }) => travellerId !== employeeId);

    this.store.clearArrayInfo();

    this.store.clearPlace(newSelectedSeats);
  };

  bookSeats = async (
    itemId: string,
    segmentId: number,
    cartId: number,
    requestBody: {
      SavedPriceItemId: string,
      SegmentSeats: {
        SeatNumber: string,
        TravellerId: number,
      }[]
    },
  ): Promise<void> => {
    try {
      const response = await this.api.bookSeats(
        itemId,
        segmentId,
        cartId,
        requestBody,
      );
      this.store.setArrayInfo(response.Error[0]);
    } catch (error) {}
  };

  deleteAllPlaces = (cartItemId: number) => this.api.deleteAllPlaces(cartItemId);

  getAdditionalServiceData = async () => {
    try {
      const additionalServiceData = await this.api.getAirlineAdditionalService();

      this.store.updateAirlineAdditionalServices(additionalServiceData);
    } catch (error) {
      console.error(error);
    }
  };

  getSelectedSeatsForCurrentSegment = (): Array<{ seat: SeatType, travellerId: number }> => {
    if (!this.store.currentSegment) {
      return [];
    }

    const selectedPlacesForCurrentSegment =
      this.store.selectedPlaces.get(this.store.currentSegment.SegmentId) || [];

    if (!selectedPlacesForCurrentSegment.length) {
      return [];
    }

    return selectedPlacesForCurrentSegment;
  };

  isSelected = (segmentId: number, seat: SeatType): boolean => {
    const selectedSeats = this.store.selectedPlaces.get(segmentId) || [];

    if (!selectedSeats || !selectedSeats.length) {
      return false;
    }

    const index = selectedSeats.findIndex(
      ({ seat: { Row, Column } }) => Row === seat.Row && Column === seat.Column,
    );

    return index >= 0;
  };

  // suppress error
  wrappedSeatMapsRequest = async ({
    Routes,
    Class,
    ProviderName,
    PNR,
    RemarkToken,
    FlightToken,
  }: {
    Routes: any[],
    Class: number,
    ProviderName: string,
    PNR: string,
    RemarkToken: string,
    FlightToken: string,
  }): Promise<SegmentType[]> => {
    let seatMap = [];
    try {
      seatMap = await this.api.getSeatMaps({
        Routes,
        Class,
        ProviderName,
        PNR,
        RemarkToken,
        FlightToken,
      });

      return seatMap;
    } catch {}

    return seatMap;
  };

  loadSeatMapsForEntireCart = async (
    items: Array<{
      ItemId: number,
      Routes: any[],
      Class: number,
      ProviderName: string,
      PNR: string,
      RemarkToken: string,
      FlightToken: string,
    }> = [],
  ) => {
    const filteredItems = items.filter(Boolean);

    const promises = filteredItems.map(({ Routes, Class, ProviderName, PNR, RemarkToken, FlightToken }) =>
      this.wrappedSeatMapsRequest({ Routes, Class, ProviderName, PNR, RemarkToken, FlightToken }),
    );

    const seatMaps = await Promise.all(promises);

    seatMaps.forEach((seatMap, index) => {
      const { ItemId } = filteredItems[index];
      this.store.setSegmentsForItem(ItemId, seatMap);
    });
  };

  getSeatMapsByItemId = (id: number) =>
    this.store.segmentsByItemId.get(id) || [];

  async getSeatMaps(data: { ProviderName: string, Segment: AirSegment, Class: string, RemarkToken: string }): Promise<void> {
    try {
      const seatMapsForSegements: SegmentType = await this.api.getSeatMaps(
        data,
      );

      this.store.setSegmentsMaps([seatMapsForSegements]);
    } catch (error) {
      this.store.setSegmentsMaps([]);
    }
  }

  setSelectedPlace = (currentSegmentIndex: number, seat: SeatType, travellerId: number | null): void => {
    this.store.clearArrayInfo();
    this.store.setSelectedPlace(currentSegmentIndex, seat, travellerId);
  };

  /**
   * При отсутствии аргумента Id будет взято из segments[0].SegmentId
   * @param {number} index - ID сегмента
   */
  setCurrentSegmentIndex = (index: number | null = null) => {
    this.store.setCurrentSegmentIndex(index);
  };

  setLoading(value = false): void {
    this.store.setLoading(value);
  }

  setLoadingForCart(value = false): void {
    this.store.setLoadingForCart(value);
  }

  reset(): void {
    this.store.clearArrayInfo();
    this.store.reset();
  }
}

export default AirlineSeatsService;
