import React from 'react';
import { IDocumentTablePreset } from 'new-ui/src/components/UniversalTable/types';

import {
  SimpleSignatureDocumentActionsCell,
} from '../shared/cells/DocumentActionsCell';
import { SSDNameCell } from '../shared/cells/DocumentNameCell';
import { SSDStatusCell } from '../shared/cells/DocumentStatusCell';

import { LABELS } from '../DocumentsGosSign/consts';

import { DocumentType } from '../../../../bi/services/employee/types';
import { SortType } from '../../../../bi/services/signature/types';

interface ITableSettingsArgs {
  selectable: boolean
  handleOpenSendToEmailDialog: (documentId: string, employeeId: number[]) => void
  handleShowDocument: (documentId: string, employeeIds: number) => void
  handleDownloadDocument: (documentId: string, employeeIds: number) => void
}

export const tableSettings = ({
  selectable,
  handleOpenSendToEmailDialog,
  handleShowDocument,
  handleDownloadDocument,
}: ITableSettingsArgs): IDocumentTablePreset<DocumentType> => ({
  rowKey: 'documentId',
  selectable,
  sort: {
    defaultColumn: 'signingStatus',
    asc: SortType.Asc,
    desc: SortType.Desc,
  },
  fields: {
    name: {
      fieldName: LABELS.TABLE.NAME,
      sortSupport: true,
      Component: (props) => (
        <SSDNameCell { ...props } onShowDocument={ handleShowDocument } onDownloadDocument={ handleDownloadDocument } />
      ),
    },
    signingStatus: {
      fieldName: LABELS.TABLE.STATUS,
      sortSupport: true,
      Component: SSDStatusCell,
    },
    documentId: {
      fieldName: LABELS.TABLE.ACTIONS,
      Component: (props) => <SimpleSignatureDocumentActionsCell { ...props } onClick={ handleOpenSendToEmailDialog } />,
    },
  },
});
