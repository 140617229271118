import React from 'react';
import { clsx } from 'clsx';
import { CellComponent } from 'new-ui/src/components/UniversalTable/types';

import { LABELS } from './consts';

import { IDocumentInfo, SigningStatus, SigningStatusSimple } from '../../../../../bi/services/signature/types';
import { DocumentType } from '../../../../../bi/services/employee/types';

import styles from './styles.module.css';

interface IDocumentStatusCellProps {
  marker: string
  title: string
}

export const DocumentStatusCell = (
  { marker, title }: IDocumentStatusCellProps,
) => (
  <div className={ styles.documentStatusCell }>
    <div
      className={ clsx(styles.markerBase, marker) }
    />
    <div className={ styles.noWrap }>
      { title }
    </div>
  </div>
);

export const SSDStatusCell: CellComponent<DocumentType, 'signingStatus'> = ({ fieldValue }) => (
  <DocumentStatusCell
    marker={ styles[`markerSimpleStatus${SigningStatusSimple[fieldValue]}`] }
    title={ LABELS.SIGNING_STATUS_SIMPLE[fieldValue as SigningStatusSimple] }
  />
);

export const ESDStatusCell: CellComponent<IDocumentInfo, 'signingStatus'> = ({ fieldValue }) => (
  <DocumentStatusCell
    marker={ styles[`marker${SigningStatus[fieldValue]}`] }
    title={ LABELS.SIGNING_STATUS[fieldValue] }
  />
);
