// @ts-nocheck
import Popups from './popups';
import { ApprovalRequest } from './approvalRequest';
import { ChartsAnalytics } from './chartsAnalytics';
import { UiSettingsProtocol as UiSettings } from './uiSettings';
import { Chat } from './chat';
import { SettingsProtocol } from './settings';
import { ExpenseReports } from './expenseReport';
import { ProductAnalytics } from './productAnalytics';

const PROTOCOLS_DEPENDENCIES = {
  Popups: ['Popups', 'Cart', 'Notification', 'Aeroexpress'],
  ApprovalRequest: ['Workspace', 'TravelApproval'],
  ChartsAnalytics: ['Workspace', 'ChartsAnalytics', 'Departments', 'FeatureFlags', 'CustomAnalytics'],
  UiSettings: ['FeatureFlags', 'Workspace', 'UiSettings', 'AccountSettings', 'App', 'Quality'],
  SettingsProtocol: ['App', 'Settings'],
  Chat: ['FeatureFlags', 'ChatDecorator'],
  ExpenseReports: ['Workspace', 'ExpenseReports'],
  ProductAnalytics: ['ProductAnalytics', 'Workspace'],
};

export const CONSTRUCTORS = {
  Popups,
  ApprovalRequest,
  ChartsAnalytics,
  UiSettings,
  Chat,
  SettingsProtocol,
  ExpenseReports,
  ProductAnalytics,
};

const getProtocolConstructor = (name: string) => CONSTRUCTORS[name];

export { getProtocolConstructor, PROTOCOLS_DEPENDENCIES };
