import ACTION from './action';
import Store from './store';

import { getText } from '../../../../i18n';

import { DEFAULT_MENU_ITEMS, DEFAULT_MENU_TYPE } from '../../constants/app';
import { QA_ATTRIBUTES } from '../../constants/attributesForTests';
import { STATUS_CODE } from '../../constants/status';

import { Company } from '../workspace/types';
import { IDataReconnect, IHeader, IHeaderShort, IMenuItem } from '../../types/app';

class App {
  menuItems: (IMenuItem | { items: IMenuItem[] })[] = [
    DEFAULT_MENU_ITEMS.SMARTDESCK,
    {
      ...DEFAULT_MENU_ITEMS.SEARCH,
      items: [
        DEFAULT_MENU_ITEMS.SEARCHAIRLINE,
        DEFAULT_MENU_ITEMS.SEARCHTRAIN,
        DEFAULT_MENU_ITEMS.SEARCHHOTEL,
        DEFAULT_MENU_ITEMS.TAXI,
        DEFAULT_MENU_ITEMS.SEARCHAEROEXPRESS,
        DEFAULT_MENU_ITEMS.SEARCHTRANSFER,
        DEFAULT_MENU_ITEMS.SEARCHVIP,
        DEFAULT_MENU_ITEMS.SEARCH_CONFERENCE,
        DEFAULT_MENU_ITEMS.SEARCH_GROUP,
        DEFAULT_MENU_ITEMS.SEARCH_EVENT,
      ],
    },
    DEFAULT_MENU_ITEMS.TRIPS,
    DEFAULT_MENU_ITEMS.EVENT,
    {
      ...DEFAULT_MENU_ITEMS.APPLICATION,
      items: [],
    },
    {
      ...this.waitingApproveItem,
      items: [],
    },
    DEFAULT_MENU_ITEMS.TRAVELERS,
    DEFAULT_MENU_ITEMS.REPORT,
    DEFAULT_MENU_ITEMS.CHARTS_ANALYTICS,
    {
      ...DEFAULT_MENU_ITEMS.ACCOUNTING,
      items: [],
    },
  ];

  menuSearchItemsWithoutAeroexpress: IMenuItem[] = [
    DEFAULT_MENU_ITEMS.SEARCHAIRLINE,
    DEFAULT_MENU_ITEMS.SEARCHTRAIN,
    DEFAULT_MENU_ITEMS.SEARCHHOTEL,
    DEFAULT_MENU_ITEMS.TAXI,
    DEFAULT_MENU_ITEMS.SEARCHTRANSFER,
  ];

  menuSearchItemsSmartagent: IMenuItem[] = [
    DEFAULT_MENU_ITEMS.SEARCHAIRLINE,
    DEFAULT_MENU_ITEMS.SEARCHTRAIN,
    DEFAULT_MENU_ITEMS.SEARCHHOTEL,
    DEFAULT_MENU_ITEMS.SEARCHTRANSFER,
  ];

  menuSearchItemsWithoutTaxi: IMenuItem[] = [
    DEFAULT_MENU_ITEMS.SEARCHAIRLINE,
    DEFAULT_MENU_ITEMS.SEARCHTRAIN,
    DEFAULT_MENU_ITEMS.SEARCHHOTEL,
    DEFAULT_MENU_ITEMS.SEARCHAEROEXPRESS,
  ];

  tripsMenuItems: IMenuItem = DEFAULT_MENU_ITEMS.REQUESTS;

  applicationSchemeRequest: IMenuItem = DEFAULT_MENU_ITEMS.REQUESTSAPPLICATIONSCHEME;

  applicationSchemeRequestAggregator: IMenuItem = DEFAULT_MENU_ITEMS.REQUESTSAPPLICATIONSCHEMEAGGREGATOR;

  requestsItem1c: IMenuItem = this.requestsItem;

  approveMenuExpenses: IMenuItem[] = [
    DEFAULT_MENU_ITEMS.APPROVE_EXPENSE_REPORTS,
  ];

  approveMenuTrips: IMenuItem[] = [
    DEFAULT_MENU_ITEMS.APPROVE_TRIPS,
    DEFAULT_MENU_ITEMS.APPROVE_TRAVEL,
  ];

  accountingMenuItemReport: IMenuItem = DEFAULT_MENU_ITEMS.REPORT;

  accountingMenuItemExpenseReport : IMenuItem = DEFAULT_MENU_ITEMS.EXPENSE_REPORT;

  hotelMenuItems: IMenuItem[] = [
    {
      ...DEFAULT_MENU_ITEMS.SEARCHHOTEL,
      title: getText('services:app.menuItems.searchHotel'),
    },
    {
      ...DEFAULT_MENU_ITEMS.TRIPS,
      title: getText('services:app.menuItems.myBooks'),
    },
    DEFAULT_MENU_ITEMS.REPORT,
    DEFAULT_MENU_ITEMS.SETTINGS,
  ];

  api: any;
  store: any;

  constructor(api: any) {
    this.api = api;
    this.store = Store();
  }

  get = (): IHeaderShort => this.store.getState();

  cartMenuItem = (): IMenuItem => ({
    icon: 'smartway-cart',
    title: getText('services:app.menuItems.cart'),
    url: '/cart',
    type: DEFAULT_MENU_TYPE.CART,
    needClickHandle: false,
    qaAttr: QA_ATTRIBUTES.header.cart,
  });

  get waitingApproveItem(): IMenuItem {
    return {
      icon: 'smartway-mail',
      url: '/approve',
      title: getText('services:app.menuItems.approve'),
      type: DEFAULT_MENU_TYPE.WAITING_APPROVE,
      needClickHandle: false,
      qaAttr: QA_ATTRIBUTES.header.approval.general,
    };
  }

  get requestsItem(): IMenuItem {
    return {
      icon: 'smartway-requests',
      url: '/requests',
      title: getText('services:app.menuItems.requests_1C'),
      type: DEFAULT_MENU_TYPE.REQUEST_1C,
      needClickHandle: false,
    };
  }

  get servicesItems(): IMenuItem[] {
    return [
      DEFAULT_MENU_ITEMS.SEARCHAIRLINE,
      DEFAULT_MENU_ITEMS.SEARCHTRAIN,
      DEFAULT_MENU_ITEMS.SEARCHHOTEL,
      DEFAULT_MENU_ITEMS.TAXI,
      DEFAULT_MENU_ITEMS.SEARCHAEROEXPRESS,
      DEFAULT_MENU_ITEMS.SEARCHTRANSFER,
      DEFAULT_MENU_ITEMS.SEARCHVIP,
      DEFAULT_MENU_ITEMS.SEARCH_CONFERENCE,
      DEFAULT_MENU_ITEMS.SEARCH_GROUP,
      DEFAULT_MENU_ITEMS.SEARCH_EVENT,
    ];
  }

  loadHeader = (companies: Company[]): Promise<void> => this.api.header.get().then((data: IHeader) =>
    this.updateHeader(data, companies));

  updateHeader = (data: IHeader, companies: Company[]): void => {
    const mappedCompanyFunds = data.CompanyFunds.map((companyFund) => {
      const company = companies.find(({ CompanyId }) => CompanyId === companyFund.CompanyId);
      const schema = company && company.Schema ? company.Schema : '';

      return {
        ...companyFund,
        schema,
      };
    });

    const mappedData = {
      ...data,
      CompanyFunds: mappedCompanyFunds,
    };

    this.store.dispatch({
      type: ACTION.UPDATEHEADER,
      payload: mappedData,
    });
  };

  updateHeaderPartial = (data: any): void => {
    this.store.dispatch({
      type: ACTION.UPDATEHEADERPARTIAL,
      payload: { ...data },
    });
  };

  updateAppState = (state: STATUS_CODE): void => {
    this.store.dispatch({
      type: ACTION.UPDATEAPPSTATE,
      payload: state,
    });
  };

  showLogin = (): void => {
    this.store.dispatch({
      type: ACTION.LOGIN,
      payload: true,
    });
  };

  hideLogin = (): void => {
    this.store.dispatch({
      type: ACTION.LOGIN,
      payload: false,
    });
  };

  showReconnectDialog = (data: IDataReconnect): void => {
    this.store.dispatch({
      type: ACTION.SHOWRECONNECT,
      payload: data,
    });
  };

  hideReconnectDialog = (): void => {
    this.store.dispatch({
      type: ACTION.HIDERECONNECT,
    });
  };

  subscribe = (cb: any) => this.store.subscribe(cb);

  logout = () => this.api.logout();
}

export default App;
