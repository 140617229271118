import { getText } from '../../../../../../i18n';

import { SigningStatus, SigningStatusSimple } from '../../../../../bi/services/signature/types';

export const LABELS = {
  SHOW: getText('settings:documentsForSignature.table.show'),
  DOWNLOAD: getText('settings:documentsForSignature.table.download'),
  SEND_TO_EMAIL: getText('settings:documentsForSignature.sendToEmail'),
  SIGNING_STATUS: {
    [SigningStatus.Processing]: getText('settings:documentsForSignatureEnhanced.signStatus.processing'),
    [SigningStatus.SimpleSigned]: getText('settings:documentsForSignatureEnhanced.signStatus.signed'),
    [SigningStatus.NotSigned]: getText('settings:documentsForSignatureEnhanced.signStatus.notSigned'),
    [SigningStatus.EnhancedSigned]: getText('settings:documentsForSignatureEnhanced.signStatus.signed'),
    [SigningStatus.SignError]: getText('settings:documentsForSignatureEnhanced.signStatus.notSigned'),
  },
  SIGNING_STATUS_SIMPLE: {
    [SigningStatusSimple.Signed]: getText('settings:documentsForSignatureEnhanced.signStatus.signed'),
    [SigningStatusSimple.NotValid]: getText('settings:documentsForSignatureEnhanced.signStatus.signed'),
    [SigningStatusSimple.Undefined]: getText('settings:documentsForSignatureEnhanced.signStatus.notSigned'),
  },
};
