import React, { useState } from 'react';
import { Button, Collapsible, Text } from 'new-ui';

import { getText } from '../../../../../i18n';

import { useStores } from '../../../../bi/context';
import { MOBX_STORES } from '../../../../bi/context/stores';

import { AirlineAdditionalServicesRow } from './AirlineAdditionalServicesRow';
import { SidePanelAdditionalService } from './SidePanelAdditionalService';

import parseJSON from '../../../../bi/utils/parseJsonString';

import { ADDITIONAL_SERVICES } from '../../../../bi/constants/airline';
import { useServices } from '../../../../bi/context/services';

import { AdditionaService, AirRoute, AirSegment, AirSegmentWithRouteId } from '../../../../bi/types/airline';

import styles from './styles.module.css';

const prepareRequestBody = (JsonData: any, segment: AirSegment) => ({
  ProviderName: JsonData.ProviderName,
  Segment: segment,
  Class: JsonData.Metadata.Fare.Class,
  RemarkToken: JsonData.Metadata.Fare.RemarkToken,
});

interface AirlineAdditionalServicesProps {
  segments: AirSegmentWithRouteId[];
  everyEmployeeChoosen: boolean;
  currentItem: any;
  renderAditionalPriceCollapsed: () => React.ReactNode | null;
  cartId: number,
}

const LABELS = {
  TITLE: getText('cart:airlineAdditionalService.title'),
  SELECTION_SEATS: getText('cart:airlineAdditionalService.seats.selectionSeats'),
  RESET_SELECTED_PLACES: getText('cart:airlineAdditionalService.seats.resetSelectedPlaces'),
};

const AirlineAdditionalServices = ({
  segments,
  everyEmployeeChoosen,
  currentItem,
  renderAditionalPriceCollapsed,
  cartId,
}: AirlineAdditionalServicesProps) => {
  const [showPanel, setShowPanel] = useState<boolean>(false);
  const [segmentSelected, setSegmentSelected] = useState<{ segmentId: number, number: number, RouteId: string | number } | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingDeleteSeats, setLoadingDeleteSeats] = useState<boolean>(false);
  const { airlineSeatsService, cartService } = useServices(['AirlineSeats', 'Cart']);
  const { airlineSeatsStore } = useStores([MOBX_STORES.AIRLINE_SEATS]);

  const toggleHadler = () => {
    setShowPanel(prev => !prev);
    airlineSeatsService.reset();
  };

  const loadScheme = async (SegmentIdSelected: number, RouteId: number | string): Promise<void> => {
    setLoading(true);

    const JsonData = parseJSON(currentItem.Data);

    const { Routes: routes = [] }: { Routes: AirRoute[] } = JsonData;

    const newRoutes = routes.map((route: AirRoute) => ({
      ...route,
      Segments: route.Segments.map(segment => ({ ...segment, ArrivalTime: segment.ArrivalTime_DateTime, DepartureTime: segment.DepartureTime_DateTime })),
    }));

    const route = newRoutes.find(el => el.ID === RouteId);

    if (!route) return;

    const segmentFinded = route.Segments.find(({ ID }) => ID === SegmentIdSelected);

    if (!segmentFinded) return;

    const requestBody = prepareRequestBody(JsonData, segmentFinded);

    const selectedSeats = routes
      .reduce((acc: AdditionaService[], { Segments = [] }) => [
        ...acc,
        ...Segments.reduce((seatsAcc: AdditionaService[], { AdditionalServices = [] }) => [
          ...seatsAcc,
          ...AdditionalServices.filter(({ Type }) => Type === ADDITIONAL_SERVICES.Seat),
        ], []),
      ], []);

    await airlineSeatsService.getSeatMaps(requestBody);

    setLoading(false);

    airlineSeatsService.setCurrentSegmentIndex();

    selectedSeats.forEach(({ SegmentId: segmentId, Service: { Number: rowAndColumnAsString }, TravellerId }) => {
      const segmentIdAsNumber = Number(segmentId);
      const segment = airlineSeatsStore.segments.find(({ SegmentId }) => SegmentId === segmentIdAsNumber) || { Rows: [] };

      const { Rows: rows = [] } = segment;

      const seatsOfCurrentSegment = rows
        .reduce((acc, row = []) => [
          ...acc,
          ...row,
        ], []);

      const currentSeat = seatsOfCurrentSegment.find(({ Row, Column }) => `${Row}${Column}` === rowAndColumnAsString);

      if (!currentSeat) {
        return;
      }

      airlineSeatsService.setSelectedPlace(segmentIdAsNumber, currentSeat, TravellerId);
    });
  };

  const deleteAllPlaces = async () => {
    setLoadingDeleteSeats(true);

    await airlineSeatsService.deleteAllPlaces(currentItem.Id);
    await cartService.load();

    setLoadingDeleteSeats(false);
  };

  const handleChooseSeat = ({ segmentId, number, RouteId }: { segmentId: number, number: number, RouteId: string | number }) => {
    toggleHadler();
    setSegmentSelected({ segmentId, number, RouteId });
    loadScheme(segmentId, RouteId);
  };

  const renderAirlineAdditionalServicesRows = () => segments.map((segment, index) =>
    <AirlineAdditionalServicesRow
      key={ index }
      number={ index + 1 }
      segment={ segment }
      everyEmployeeChoosen={ everyEmployeeChoosen }
      handleChooseSeat={ handleChooseSeat }
    />,
  );

  const renderCollapsibleHeader = () => {
    const renderPrice = () => {
      if (!currentItem?.AdditionalServiceInfo?.SeatsSum) return null;

      const { AdditionalServiceInfo: { SeatsSum } } = currentItem;

      return (
        `+ ${SeatsSum} ₽`
      );
    };

    return (
      <Text
        type='SEMIBOLD_16'
      >
        { LABELS.SELECTION_SEATS }
        &nbsp;
        {renderPrice()}
      </Text>
    );
  };

  return (
    <>
      <div className={ styles['additional-wrapper'] }>
        <Text type='bold_20' className={ `${styles.title} ${styles.background}` }>
          { LABELS.TITLE }
        </Text>
        <Collapsible
          open={ false }
          title={ renderCollapsibleHeader() }
          headerWrapperClassname={ styles['header-collapsible-wrapper'] }
        >
          <div className={ `${styles['wrapper-places']} ${styles.paddings}` }>
            { renderAirlineAdditionalServicesRows() }
          </div>
          <div className={ styles.amount }>
            { renderAditionalPriceCollapsed() }
            <Button
              className={ styles['delete-places'] }
              type='primary-outline'
              onClick={ deleteAllPlaces }
              loading={ loadingDeleteSeats }
            >
              { LABELS.RESET_SELECTED_PLACES }
            </Button>
          </div>
        </Collapsible>
      </div>
      <SidePanelAdditionalService
        cartId={ cartId }
        segments={ segments }
        segmentSelected={ segmentSelected }
        showPanel={ showPanel }
        toggleHadler={ toggleHadler }
        currentItem={ currentItem }
        loading={ loading }
      />
    </>
  );
};

export { AirlineAdditionalServices };
