import React, { FC, useState } from 'react';
import { Button, Checkbox, Text, IconButton } from 'new-ui';

import { getText } from '../../../../../i18n';

import { PRICE_AIR_CERTIFICATE } from '../../../../bi/constants/airline';

import ProjectSelect from '../../../../components/EmployeeList/ProjectSelect';

import styles from './styles/index.module.css';

const LABELS = {
  NOT_ENOUGH_MONEY: getText('components:tripDetailsDialog.flightCertDialogs.confirm.notEnoughMoney'),
  AGREEMENT: getText('components:tripDetailsDialog.flightCertDialogs.confirm.agreement'),
  FLIGHT_CERTIFICATE: getText('components:tripDetailsDialog.flightCertDialogs.confirm.flightCertificate'),
  REGISTRATION_COST: getText('components:tripDetailsDialog.flightCertDialogs.confirm.registrationCost'),
  CURRENCY: '₽',
  SELECT_COST_CENTER: getText('components:tripDetailsDialog.flightCertDialogs.confirm.selectCostCenter'),
  NOT_SELECTED: getText('components:tripDetailsDialog.flightCertDialogs.confirm.notSelected'),
  ORIGINAL_WILL_BE_SEND: getText('components:tripDetailsDialog.flightCertDialogs.confirm.originalWillBeSent'),
  ORDER_FLIGHT_CERTIFICATE: getText('components:tripDetailsDialog.flightCertDialogs.confirm.orderCertificate'),
  LABEL_FLIGHT_CERTIFICATE: getText('components:additionalServices.radioButtons.orderFlightCertificate'),
  BACK: getText('components:changeAirTrip.back'),
  ADD_PROJECT: getText('components:employeeList.project.included'),
  LABEL: getText('components:employeeList.project.label'),
};

interface ConfirmDialogProps {
  isLoading: boolean;
  mandatoryProject: boolean;
  tripProjectId: number | null;
  projects: { Name: string, Id: number, IsArchival: boolean }[];
  bookflightCertificateInfo: { Success: boolean | null, Error: string };
  onSubmit(curProjectId: number): void;
  onBack(): void;
}

const setEmptyValue = (mandatoryProject: boolean) => (
  !mandatoryProject
    ? [{ Name: LABELS.NOT_SELECTED, Id: 0 }]
    : []);

const getProjectsList = (projects: { Name: string, Id: number, IsArchival: boolean }[], mandatoryProject: boolean) => projects.reduce((acc, { Name, Id, IsArchival }) => {
  if (IsArchival) return acc;

  return ([...acc, { Name, Id }]);
}, setEmptyValue(mandatoryProject));

const findName = (projectsList: { Name: string, Id: number }[], project: number) => projectsList.find(projectItem => project === projectItem.Id)?.Name;

const ConfirmFlightCertificate: FC<ConfirmDialogProps> = ({
  isLoading,
  mandatoryProject,
  tripProjectId,
  projects,
  bookflightCertificateInfo,
  onSubmit,
  onBack,
}) => {
  const projectsList = projects.length && getProjectsList(projects, mandatoryProject);
  const [isAgreeWithPrice, setAgreeWithPrice] = useState(false);
  const [curProjectId, setCurProjectId] = useState(tripProjectId || 0);
  const [projectSettings, setProjectSettings] = useState({
    isProjectSelectable: !curProjectId,
    projectName: curProjectId && projectsList ? findName(projectsList, curProjectId) : '',
    isFirst: true,
  });

  const { Success, Error } = bookflightCertificateInfo;

  const projectIsFalse = projectSettings.projectName === '' ? tripProjectId : false;

  const disableApproveButton = mandatoryProject
    ? (projectIsFalse || !isAgreeWithPrice || !Success)
    : !isAgreeWithPrice;

  const handleSubmit = () => {
    onSubmit(curProjectId);

    if (Success) {
      onBack();
    }
  };

  const handleChangeSelectedProject = (id: number, projectName: string) => {
    setProjectSettings({ isProjectSelectable: false, projectName, isFirst: false });
    setCurProjectId(id);
  };

  const errorTxt = () => <Text color='red'> { Error } </Text>;

  const confirmAction = () => (
    <Checkbox
      onChange={ setAgreeWithPrice }
      value={ isAgreeWithPrice }
    >
      <Text type='NORMAL_16_140'>{ LABELS.AGREEMENT }</Text>
    </Checkbox>
  );

  const renderAgreeSection = () => (!Success ? errorTxt() : confirmAction());

  const renderSelectCostCenter = () => {
    if (!projects.length && !mandatoryProject) {
      return null;
    }

    if (projectSettings.isProjectSelectable && !curProjectId && projectSettings.isFirst) {
      return (
        <div className={ styles.project }>
          <Text color='gray' >
            {LABELS.LABEL}
          </Text>
          <Button
            className={ styles.button }
            type='textual'
            onClick={ () => setProjectSettings({ isProjectSelectable: true, projectName: '', isFirst: false }) }
          >
            &nbsp;
            {LABELS.ADD_PROJECT}
          </Button>
        </div>
      );
    }

    if (!projectSettings.isProjectSelectable && (curProjectId || projectSettings.projectName === LABELS.NOT_SELECTED)) {
      const selectedProjectName = curProjectId && projectsList ?
        findName(projectsList, curProjectId) :
        LABELS.NOT_SELECTED;

      const deleteProjectButton = (
        <IconButton
          className={ styles.remove }
          iconType='closeButton'
          onClick={ () => handleChangeSelectedProject(0, '') }
        />
      );

      return (
        <div className={ styles.project }>
          <Text color='gray'>{LABELS.LABEL}:</Text>
          &nbsp;
          <Text >
            { selectedProjectName }
          </Text>
          { deleteProjectButton }
        </div>
      );
    }

    return (
      <div className={ styles.project }>
        <ProjectSelect
          projects={ projectsList }
          projectName={ projectSettings.projectName }
          onSelect={ handleChangeSelectedProject }
          onClose={ () => setProjectSettings({ isProjectSelectable: true, projectName: '', isFirst: false }) }
        />
      </div>
    );
  };

  return (
    <div className={ styles.wrap }>
      <Text className={ styles.title } type='bold_20'>{ LABELS.LABEL_FLIGHT_CERTIFICATE }</Text>
      { renderSelectCostCenter() }
      <div className={ styles.content }>
        <Text type='NORMAL_16_140'>
          { LABELS.REGISTRATION_COST } — <b>{PRICE_AIR_CERTIFICATE} { LABELS.CURRENCY }</b>.
        </Text>
        <Text type='NORMAL_16_140'>{ LABELS.ORIGINAL_WILL_BE_SEND }</Text>
      </div>
      <div className={ styles.content }>
        {renderAgreeSection()}
      </div>
      <div className={ styles.buttons }>
        <Button
          className={ styles.button }
          type='secondary'
          loading={ isLoading }
          disabled={ !!disableApproveButton }
          onClick={ handleSubmit }
        >
          { LABELS.ORDER_FLIGHT_CERTIFICATE }
        </Button>
        <Button
          className={ styles.cancel_btn }
          type='textual-medium'
          disabled={ isLoading }
          onClick={ onBack }
        >
          { LABELS.BACK }
        </Button>
      </div>
    </div>
  );
};

export { ConfirmFlightCertificate };
