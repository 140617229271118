import React from 'react';
import { observer } from 'mobx-react';
import { Text } from 'new-ui';

import { useStores } from '../../../../bi/context';
import { MOBX_STORES } from '../../../../bi/context/stores';

import { getText } from '../../../../../i18n';

import MoneyFormat from '../../../../bi/utils/money';

import styles from './styles.module.css';

const TRAVEL_APPROVE_LABELS = {
  RENEGOTIATION: getText('components:travelApproval.renegotiation'),
  BUDGET_ERROR: (price: string) => `${TRAVEL_APPROVE_LABELS.RENEGOTIATION} ${price} ₽`,
};

const CartWarningApprovalText = observer(() => {
  const { travelApprovalsStore } = useStores([MOBX_STORES.TRAVEL_APPROVALS]);

  const { validateApprovedRequest, chosenApprovedRequest } = travelApprovalsStore;

  if (!chosenApprovedRequest) {
    return null;
  }

  const availableBalance = Number(validateApprovedRequest?.AvailableBalance);

  const formatPrice = availableBalance ? Math.abs(availableBalance) : null;

  const price = formatPrice ? MoneyFormat.moneyWithDecimal(formatPrice) : null;

  return price && (
    <Text
      type='NORMAL_14_130'
      color='gray'
      className={ styles.cart_actions }
    >
      { TRAVEL_APPROVE_LABELS.BUDGET_ERROR(price) }
    </Text>
  );
});

export { CartWarningApprovalText };
