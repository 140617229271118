// @ts-nocheck
import PropTypes from 'prop-types';
import React, { Component } from 'react';

class Timer extends Component {
  static propTypes = {
    getCurrentValue: PropTypes.func.isRequired,
    updateInterval: PropTypes.number.isRequired,
  };

  state = {
    value: '00:00',
  };

  interval = 0;

  componentDidMount() {
    const { updateInterval } = this.props;
    this.interval = setInterval(() => {
      this.setState({
        value: this.props.getCurrentValue(),
      });
    }, updateInterval);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  render() {
    const { value } = this.state;

    return <span>{ value }</span>;
  }
}

export default Timer;
