import React from 'react';
import { DotLoading, RadioButton } from 'new-ui';
import { getText } from '../../../../i18n';

import { dateUtcFormat } from '../../../bi/utils/formatDate';
import MoneyFormat from '../../../bi/utils/money';
import trimTimezone from '../../../bi/utils/trimTimezone';
import { isSmartAgent } from '../../../bi/utils/env';

import { MEAL, CANCELLATION } from '../../../bi/constants/hotel';

import styles from '../styles/index.module.css';

const LABELS = {
  ANALOG_ROOMS: {
    SUGGEST: getText('booking:analogRooms.suggest'),
  },
};

type AnalogRoomsProps = {
  cartItemId: number,
  cartItemByAnalogRooms: any,
  onLoadAnalogRooms: () => void,
  onChange: () => void,
};

type AnalogRoomsState = {
  loading: boolean,
};

class AnalogRooms extends React.Component<AnalogRoomsProps, AnalogRoomsState > {
  state: AnalogRoomsState = {
    loading: true,
  };

  componentDidMount() {
    const { onLoadAnalogRooms } = this.props;

    // @ts-ignore
    onLoadAnalogRooms().finally(this.updateAnalogRooms);
  }

  updateAnalogRooms = (): void => this.setState({
    loading: false,
  });

  renderRoom = (item: any) => {
    const { RoomName, Breakfast, CancellationPolicy: { Refundable, DeadLine }, Price } = item;

    const mealTypeText = Breakfast ? MEAL.HAS_MEAL : MEAL.NOT_HAS_MEAL;
    const cancellationText = Refundable ?
      `${CANCELLATION.HAS_CANCELLATION}${dateUtcFormat(trimTimezone(DeadLine))}` :
      CANCELLATION.NOT_HAS_CANCELLATION;

    return (
      <div className={ styles.room }>
        <div className={ styles.analog_room }>
          <b>{RoomName}</b>,&nbsp;<span className={ styles.text }>{ mealTypeText.toLocaleLowerCase() }, { cancellationText }</span>
        </div>
        <div className={ styles.price }>{MoneyFormat.money(Price, true)}</div>
      </div>
    );
  };

  renderRadioButton = (item: any, index: number) => {
    const { cartItemId, onChange } = this.props;
    const { Guid, checked } = item;
    const orHtml = index !== 0 && <div className={ styles.or }>или</div>;

    return (
      <div className={ styles.wrapper_room } key={ `another_room_${Guid}_${index}` }>
        { orHtml }
        <RadioButton
          checked={ !!checked }
          value={ checked }
          // @ts-ignore
          onChange={ (value) => onChange(Guid, cartItemId, value) }
          alternativeDesign={ isSmartAgent }
          contentClassName={ styles.radio }
        >
          { this.renderRoom(item) }
        </RadioButton>
      </div>
    );
  };

  render() {
    const { cartItemByAnalogRooms, cartItemId } = this.props;
    const { loading } = this.state;

    if (loading) {
      return (
        <div className={ styles.loading }>
          <DotLoading />
        </div>
      );
    }

    const analogRooms = cartItemByAnalogRooms[cartItemId];
    const rooms = analogRooms && analogRooms.length ? analogRooms : [];

    if (!rooms.length) {
      return <div />;
    }

    const roomsHtml = rooms.length === 1 ?
      this.renderRoom(rooms[0]) :
      rooms.map((room: any, index: number) => this.renderRadioButton(room, index));

    return (
      <div className={ styles.wrapper_another_rooms }>
        <div className={ styles.title_another_rooms }>{LABELS.ANALOG_ROOMS.SUGGEST}</div>
        { roomsHtml }
      </div>
    );
  }
}

export default AnalogRooms;
