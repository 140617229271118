// @ts-nocheck
import * as Sentry from '@sentry/react';

import CONFIG from '../config';

import { detectBrowser } from '../App/bi/utils/detectBrowser';

const sentryIsEnabled = (): boolean => {
  const { name } = detectBrowser();
  const { SENTRY: { ENABLED } } = CONFIG;

  return ENABLED && name !== 'ie';
};

const initSentry = (): void => {
  if (!sentryIsEnabled()) {
    return;
  }

  const { SENTRY: { DSN } } = CONFIG;

  Sentry.init({
    dsn: DSN,
  });
};

const setUserSentry = ({ email, id }: { email: string, id: string }): void => {
  if (!sentryIsEnabled()) {
    return;
  }

  if (typeof email !== 'string' || typeof id !== 'string') {
    return;
  }

  Sentry.setUser({ id, email });
};

const clearSentryContext = (): void => {
  if (!sentryIsEnabled()) {
    return;
  }

  Sentry.configureScope(scope => scope.clear());
};

export { initSentry, setUserSentry, clearSentryContext };
