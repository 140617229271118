// @ts-nocheck
import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';

import { useStores } from '../../../../../../bi/context';
import { MOBX_STORES } from '../../../../../../bi/context/stores';

import { STRUCTURE_TYPE } from '../../../../../../bi/constants/chartsAnalytics';

import scrollToTop from '../../../../../utils/scrollToTop';

import { AirlineNestedTable } from '../../../common/airlineNestedTable';
import { TrainNestedTable } from '../../../common/trainNestedTable';
import { HotelNestedTable } from '../../../common/hotelNestedTable';
import { OtherNestedTable } from '../../../common/otherNestedTable';
import { TaxiVoucherTable } from '../../../taxi/tableForVoucher';
import { MiceNestedTable } from '../../../common/miceNestedTable';

const ProjectsDoubleNestedTable = observer(({ chartsAnalyticsService }) => {
  const {
    chartsAnalyticsSummaryStore,
    chartsAnalyticsStore: { filtersType, filters: { employees, departments, projects, analytics, tags } },
  } = useStores([MOBX_STORES.CHARTS_ANALYTICS_SUMMARY_STORE, MOBX_STORES.CHARTS_ANALYTICS_STORE]);

  const { type } = chartsAnalyticsSummaryStore;
  const { getStructureProjectsDoubleSubPage, setShowFilterProject } = chartsAnalyticsService;

  useEffect(() => {
    getStructureProjectsDoubleSubPage();

    return () => setShowFilterProject(false);
  }, [
    getStructureProjectsDoubleSubPage,
    setShowFilterProject,
    filtersType,
    employees.selected,
    departments.selected,
    projects.selected,
    analytics.selected,
    tags.selected,
  ]);

  const handlePageChange = page => chartsAnalyticsService.changePageStructureProjects(page).then(() => scrollToTop());

  const airTable = (
    <AirlineNestedTable
      store={ chartsAnalyticsSummaryStore }
      onPageChange={ handlePageChange }
    />
  );

  const trainTable = (
    <TrainNestedTable
      store={ chartsAnalyticsSummaryStore }
      onPageChange={ handlePageChange }
    />
  );

  const hotelTable = (
    <HotelNestedTable
      store={ chartsAnalyticsSummaryStore }
      onPageChange={ handlePageChange }
    />
  );

  const taxiTable = (
    <TaxiVoucherTable
      chartsAnalyticsService={ chartsAnalyticsService }
      typeStore={ chartsAnalyticsSummaryStore }
      onPageChange={ handlePageChange }
    />
  );

  const aeroexpressTable = (
    <TrainNestedTable
      store={ chartsAnalyticsSummaryStore }
      onPageChange={ handlePageChange }
    />
  );

  const otherTable = (
    <OtherNestedTable
      store={ chartsAnalyticsSummaryStore }
      onPageChange={ handlePageChange }
    />
  );

  const miceTable = (
    <MiceNestedTable
      store={ chartsAnalyticsSummaryStore }
      onPageChange={ handlePageChange }
    />
  );

  return ({
    [STRUCTURE_TYPE.AIR]: airTable,
    [STRUCTURE_TYPE.TRAIN]: trainTable,
    [STRUCTURE_TYPE.HOTEL]: hotelTable,
    [STRUCTURE_TYPE.TAXI_OPERATION]: taxiTable,
    [STRUCTURE_TYPE.AEROEXPRESS]: aeroexpressTable,
    [STRUCTURE_TYPE.TRANSFER]: trainTable,
    [STRUCTURE_TYPE.MICE]: miceTable,
    [STRUCTURE_TYPE.OTHER]: otherTable,
  }[type]);
});

ProjectsDoubleNestedTable.propTypes = {
  chartsAnalyticsService: PropTypes.object.isRequired,
};

export { ProjectsDoubleNestedTable };
