export enum AnalyticTypeClassAeroExpress {
  standard = 'standard',
  business = 'business',
}

interface ITrip {
  airport: string,
  backward_date: string | null,
  count: number,
  departure_date: string,
  direction: string,
  schedule_id: number,
  tariff_type: string,
}
export interface IItem {
  Id: number,
  Price: number,
  PriceOrigin: number,
  ProviderName: string,
  Trip: ITrip,
}
