const ACTION = {
  LOADHISTORY: 'history.load',
  FINDAIRLINETICKET: 'airline.find',
  LOADAIRLINETICKET: 'airline.search',
  CLEARSEARCH: 'airline.clear',

  NEWSEARCH: 'search.new',
  UPDATECOMPLEXCHECKBOX: 'search.complex',
  UPDATEDIRECTCHECKBOX: 'search.direct',
  UPDATEFLYCLASS: 'search.flightClass',
  UPDATEADULT: 'search.adult',
  UPDATESUGGESTINPUT: 'search.suggest.label',
  UPDATESUGGESTS: 'search.suggest.list',
  UPDATE_SUGGESTS_LOADING: 'search.suggest.list.loading',
  UPDATEDATE: 'search.date',
  UPDATEDATEBACK: 'search.date.back',
  FLIPFROMTO: 'search.flip',
  INITHISTORY: 'search.init.history',
  NEW_SEARCH_FROM_APPROVE_REQUEST: 'search.approve.request',
  SCHEME_LOADING: 'search.approve.schemeLoading',

  ADDSEARCHROUTE: 'search.route.add',
  REMOVESEARCHROUTE: 'search.route.remove',
  SELECTSUGGEST: 'search.suggest.select',
  WARNINGTEMPLATE: 'search.warning',
  CLEARSUGGEST: 'search.suggest.clear',
  SET_ROUTES: 'search.set.routes',
  SKELETON_SEARCH: 'search.set.skeleton',

  UPDATERESULTSORT: 'result.sort',
  PAGING: 'result.paging',

  UPDATEPRICEFILTER: 'result.filters.price',
  UPDATEDIRECTFILTER: 'result.filters.direct.count',
  UPDATEAIRLINEFILTER: 'result.filters.airline',
  UPDATEAIRPORTFILTER: 'result.filters.airport',
  UPDATEFEWAIRLINECOMPNAIES: 'result.filters.fewAirlineCompanies',
  UPDATEDURATIONTIMEFILTER: 'result.filters.duration.time',
  UPDATEREFUNDABLEFILTER: 'result.filters.refundable',
  UPDATEBAGGAGEFILTER: 'result.filters.baggage',
  UPDATEFLIGHTSNUMBERSFILTER: 'result.filters.flightsNumbers',
  UPDATEBORDERTIMEFILTER: 'result.filters.border.time',
  UPDATE_TRAVEL_TIME_FILTER: 'result.filters.travel.time',
  UPDATEFAVORITEFILTER: 'result.filters.favorite',
  UPDATEROUTESFILTER: 'result.filters.routes',
  UPDATETRAVELPOLICYFILTER: 'result.filters.travelPolicy',
  DELETETAGS: 'result.filters.tags.delete',
  RESETFILTERS: 'result.filters.reset',
  TRANSFER_AIRPORTS_FILTER: 'result.filters.transferAirports',
  CHANGEFAVORITESTATE: 'favorite.change',
};

export default ACTION;
