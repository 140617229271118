import React, { useEffect, useState } from 'react';
import { Searcher } from 'new-ui';
import { observer } from 'mobx-react';

import { Route } from 'react-router-dom';
import { ItemsSearcher } from 'new-ui/src/components/Searcher/type';
import { useStores } from '../../bi/context';
import { MOBX_STORES } from '../../bi/context/stores';

import { getText } from '../../../i18n';

import AirlineSearchPage from '../AirlineSearch';
import HotelsSearchPage from '../HotelsSearch';
import { TrainsSearchPage } from '../TrainsSearch';
import { TransferSearch } from '../TransferSearch';
import AdditionalServiceSearchPage from '../AdditionalServiceSearch';
import { AeroexpressPage } from '../Aeroexpress';

import ReservedPanel from '../../components/ReservedPanel';
import TripsPanel from '../../components/TripsPanel';
import BalancePanel from '../../components/BalancePanel';

import { MainAnalytic } from '../../bi/utils/analytics';

import SERVICETYPE, { ANALYTIC_SERVICE_TYPES } from '../../bi/constants/serviceType';
import ROUTES from '../../bi/constants/routes';
import { DEFAULT_ITEMS_MENU_SA, ITEM_MENU_SA } from './const';

import { SmartdeskSmartagentProps } from './types';

import styles from './styles/index.module.css';

const LABELS = {
  VIP_HALL: getText('search:titles.vipLoungeTitle'),
  TEXT_VIP_HALL: getText('search:additional.mainTextForVZone'),
  TEXT_VIP_HALL_TWO: getText('search:additional.mainTextForVZtwo'),
  SECONDARY_TEXT_VIP_HALL: getText('search:additional.secondaryTextForVZ'),
  VIP_LOUNGE_TITLE: getText('search:titles.vipLoungeTitle'),
  MENU: {
    AIR: getText('constants:serviceType.typesRuMn.air'),
    HOTEL: getText('constants:serviceType.typesRuMnV2.hotel'),
    TRANSFER: getText('constants:serviceType.typesRuMn.transfer'),
    TRAIN: getText('constants:serviceType.typesRuMn.train'),
    VIP_HALL: getText('constants:serviceType.typesRuMnV2.vipHall'),
  },
};

const routeTab = (route: string) => {
  const basePath = ROUTES.SMARTDESK;

  if (route.startsWith(basePath) && route !== basePath) {
    return (route.substring(basePath.length + 1));
  }

  return '';
};

const SmartdeskSA = observer(({
  history,
  location,
  airlineService,
  userSessionService,
  chatService,
  workspaceService,
  requestsService,
  travelApprovalService,
  featureFlagsService,
  hotelsService,
  trainsService,
  transferService,
  smartdeskService,
  appService,
  productAnalyticsProtocol,
  notificationService,
  aeroexpressService,
  accountSettings,
  aggregationId,
}: SmartdeskSmartagentProps) => {
  const {
    smartdeskStore: {
      isSearchSmartdesk,
      notepad,
      isNotepad,
      trips,
      isTrips,
    },
  } = useStores([MOBX_STORES.SMARTDESK]);

  const { searchSettings } = accountSettings.getAccountSettings();

  const [route, setRoute] = useState(routeTab(history.location.pathname));

  const items = DEFAULT_ITEMS_MENU_SA;

  const addingMenu = (service: string, item: ItemsSearcher) => {
    if (searchSettings.includes(service) && !items.includes(item)) {
      items.push(item);
    }
  };

  useEffect(() => {
    airlineService.setSkeletonSearch(false);
    smartdeskService.setSearchSmartdesk(false);

    routeTab(history.location.pathname);

    smartdeskService.getReservationNotepad();
    smartdeskService.getTripsSmartagentPanel();

    addingMenu(SERVICETYPE.AEROEXPRESS, ITEM_MENU_SA.AERO);
    addingMenu(SERVICETYPE.TRANSFER, ITEM_MENU_SA.TRANSFER);
    addingMenu(SERVICETYPE.VIP_HALL, ITEM_MENU_SA.VIP_HALL);
  }, []);

  const onClickTab = (value: string) => {
    setRoute(routeTab(value));
  };

  const renderAirlineSearch = () => (
    <AirlineSearchPage
      history={ history }
      location={ location }
      airlineService={ airlineService }
      userSessionService={ userSessionService }
      workspaceService={ workspaceService }
      requestsService={ requestsService }
      travelApprovalService={ travelApprovalService }
      featureFlagsService={ featureFlagsService }
      smartdeskService={ smartdeskService }
    />
  );

  const renderHotelSearch = () => (
    <HotelsSearchPage
      history={ history }
      location={ location }
      featureFlagsService={ featureFlagsService }
      hotelsService={ hotelsService }
      travelApprovalService={ travelApprovalService }
      aggregationId={ aggregationId }
      smartdeskService={ smartdeskService }
    />
  );

  const renderTrainsSearch = () => (
    <TrainsSearchPage
      history={ history }
      location={ location }
      featureFlagsService={ featureFlagsService }
      travelApprovalService={ travelApprovalService }
      trainsService={ trainsService }
      smartdeskService={ smartdeskService }
    />
  );

  const renderTransferSearch = () => (
    <TransferSearch
      history={ history }
      location={ location }
      userSessionService={ userSessionService }
      transferService={ transferService }
      featureFlagsService={ featureFlagsService }
      smartdeskService={ smartdeskService }
      accountSettings={ accountSettings }
    />
  );

  const renderVipHall = () => (
    <AdditionalServiceSearchPage
      key='2'
      mainText={ LABELS.TEXT_VIP_HALL }
      mainTextTwo={ LABELS.TEXT_VIP_HALL_TWO }
      secondaryText={ LABELS.SECONDARY_TEXT_VIP_HALL }
      title={ LABELS.VIP_LOUNGE_TITLE }
      analyticValue={ MainAnalytic.ACTIONS.SEARCH.SEARCH_VIPHALL_SCREEN_OPENED }
      analyticPropOnSend={ ANALYTIC_SERVICE_TYPES.VIP_ROOM }
      history={ history }
      chatService={ chatService }
      workspaceService={ workspaceService }
      featureFlagsService={ featureFlagsService }
      productAnalyticsProtocol={ productAnalyticsProtocol }
      accountSettings={ accountSettings }
    />
  );

  const renderAeroexpress = () => (
    <AeroexpressPage
      key={ 0 }
      history={ history }
      location={ location }
      featureFlagsService={ featureFlagsService }
      aeroexpressService={ aeroexpressService }
      notificationService={ notificationService }
      aeroexpressStore={ aeroexpressService.store }
      travelApprovalService={ travelApprovalService }
      accountSettings={ accountSettings }
    />
  );

  const wrapper = !isSearchSmartdesk ? styles.wrapper : `${styles.wrapper} ${styles.wrapper_search}`;

  const renderSearcher = () => (
    <>
      <Route
        path={ [ROUTES.SMARTDESK, ROUTES.SEARCH_SA.AIR] }
        exact
        render={ renderAirlineSearch }
      />
      <Route
        path={ ROUTES.SEARCH_SA.TRAIN }
        exact
        render={ renderTrainsSearch }
      />
      <Route
        path={ ROUTES.SEARCH_SA.HOTEL }
        exact
        render={ renderHotelSearch }
      />
      <Route
        path={ ROUTES.SEARCH_SA.TRANSFER }
        exact
        render={ renderTransferSearch }
      />
      <Route
        path={ ROUTES.SEARCH_SA.VIP_HALL }
        exact
        render={ renderVipHall }
      />
      <Route
        path={ ROUTES.SEARCH_SA.AEROEXPRESS }
        exact
        render={ renderAeroexpress }
      />
    </>
  );

  const renderPanels = () => {
    if (isSearchSmartdesk) {
      return null;
    }

    return (
      <div className={ styles.wrapper_panel }>
        <div className={ styles.wrapper_sub_panel }>
          <BalancePanel
            appService={ appService }
            workspaceService={ workspaceService }
          />
          <ReservedPanel
            note={ notepad }
            isNote={ isNotepad }
          />
        </div>
        <div className={ styles.wrapper_sub_panel }>
          <TripsPanel
            isTrips={ isTrips }
            trips={ trips }
            history={ history }
          />
        </div>
      </div>
    );
  };

  return (
    <div className={ wrapper }>
      <Searcher
        classname={ styles.searcher }
        route={ route }
        showHeader={ !isSearchSmartdesk }
        items={ items }
        onClickTab={ (value) => onClickTab(value) }
      />
      { renderSearcher() }
      { renderPanels() }
    </div>
  );
});

export { SmartdeskSA };
