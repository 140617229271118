import React from 'react';
import { Icon, Text, TextColor, TextType } from 'new-ui';
import { getText } from '../../../../../i18n';

import { formatDate } from '../../../../bi/utils/formatDate';

import { PATTERN } from '../../../../bi/constants/dateFormats';

import { RegionRate } from '../../../../bi/services/hotels/types';

import styles from './styles.module.css';

interface HotelItemRateProps {
  rate: RegionRate,
  containerClassName?: string,
  isMapView: boolean,
}

interface ITitleProps {
  color: TextColor,
  type: TextType,
}

interface IMealProp {
  text: string,
  color: TextColor,
}

const LABELS = {
  FREE_CANCELLATION: (time: string) => getText('hotels:regionResult.item.freeCancellation', { time }),
  NOT_MEAL: getText('hotels:regionResult.item.noMeal'),
  NOT_HAS_CANCELLATION: getText('hotels:hotelResult.item.roomGroup.cancellation.nonFree'),
};

const HotelItemRate = ({
  rate: {
    RoomInfo: {
      Name,
      Meal,
      Bed,
      Deadline,
    },
  },
  containerClassName,
  isMapView,
}: HotelItemRateProps) => {
  const deadline = Deadline
    ? LABELS.FREE_CANCELLATION(formatDate(Deadline, PATTERN.DAY_MONTH_TIME_WITHOUT_SECONDS))
    : LABELS.NOT_HAS_CANCELLATION;

  const titleProps: ITitleProps = isMapView
    ? {
      color: 'gray',
      type: 'SEMIBOLD_14',
    }
    : {
      color: 'default',
      type: 'SEMIBOLD_16',
    };

  const renderIcon = () => {
    if (!Meal) return null;

    return (
      <div className={ styles.icon }>
        <Icon
          color='green'
          type='cup'
        />
      </div>
    );
  };

  const renderMeal = () => {
    const mealProp: IMealProp = Meal
      ? {
        color: 'green',
        text: Meal,
      }
      : {
        color: 'red',
        text: LABELS.NOT_MEAL,
      };

    return (
      <div className={ styles.meal }>
        <Text type='NORMAL_12' color={ mealProp.color }>
          { mealProp.text }
        </Text>
        { renderIcon() }
      </div>
    );
  };

  return (
    <div className={ containerClassName }>
      <Text color={ titleProps.color } type={ titleProps.type } className={ styles.title }>
        { Name }
      </Text>
      { renderMeal() }
      <Text color='gray' type='NORMAL_12' className={ styles.text }>
        { Bed }
      </Text>
      <Text color='gray' type='NORMAL_12'>
        { deadline }
      </Text>
    </div>
  );
};

export { HotelItemRate };
