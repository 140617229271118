import { action, computed, makeObservable, observable } from 'mobx';

import { FeatureFlagsProps } from './types';

const DEFAULT_PROPS = {
  SearchServiceType: false,
  SendSms: false,
  DisableAirSeat: false,
  OnlyNewAnalytics: false,
  DisableS7Balance: false,
  ShowExpenseReports: false,
  ExternalLink: false,
  EmployeeNumSixDigits: false,
  ShowExpenseReportApprove: false,
  MixvelWarning: false,
  VSKWarnText: false,
  TripRuleCustomMessage: false,
  BookingInMicroservice: false,
  PrefilledExpenseValues: false,
  MenuChartsAnalytics: false,
  NewFilters: false,
  FiltersHotelsInMicroservice: false,
  BurCustomMessage: false,
  ReportGsprom: false,
  ReportSevmash: false,
  ExpansionFiltersEmployees: false,
  SurveyForm: false,
  CKRRestrictions: false,
  AirlineAdditionalServices: [],
  ShowNoteForSmartagent: false,
  SimpleSignature: false,
  PprDiscount: false,
  NewAirlineSearch: false,
  NotShowHotelSmartagent: false,
  DepartmentOnly: false,
  UpsellFlags: [],
};

export class FeatureFlagsStore {
  constructor() {
    makeObservable(this);
  }

  @observable featureFlags: FeatureFlagsProps = DEFAULT_PROPS;
  @observable companiesOfSummaryReviseAct = [];

  @action
  setFeatureFlags = (featureFlags: FeatureFlagsProps) => {
    this.featureFlags = featureFlags;

    return featureFlags;
  };

  @action
  setCompaniesOfSummaryReviseAct = (companies: any) => {
    this.companiesOfSummaryReviseAct = companies;
  };

  @computed
  get getFeatureFlags() {
    return this.featureFlags;
  }
}

export const featureFlagsStore = new FeatureFlagsStore();
