const ACTION = {
  LOADCART: 'load.cart',
  UPDATESTATUSV2: 'update.statusv2',
  UPDATECART: 'update.cart',
  STARTBOKING: 'start.booking',
  RESET: 'reset',
  CANCELBOOKING: 'cancel.booking',
  APPLYCHANGES: 'cancel.changes',
  CONTINUEBOOKING: 'continue.booking',
  UPDATEANALOGROOMS: 'update.check.analog.rooms',
  LOADANALOGROOMS: 'load.analog.rooms',
};

export default ACTION;
