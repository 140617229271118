// @ts-nocheck
import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import * as queryString from 'query-string';
import { Button, Tooltip, Text, ItemPanel, PROPS } from 'new-ui';

import { getText } from '../../../../../i18n';

import ItemLayout from '../../../../components/ItemLayout';
import EmployeeList from '../../../../components/EmployeeList';
import AirlineItem from '../../../../components/AirlineItem';
import { TripTags } from '../../../../components/TripTags';
import { BookChildDialog } from '../../../../components/BookChildDialog';
import { RoundIconWithTooltip } from '../../../../components/RoundIconWithTooltip';

import {
  cartHasAirUnderageByProviders,
  isCartHasAirChildren,
  cartItemIsNotTotallyFilled,
  getDataForCart,
  addSelectedTripTags,
} from '../../../../bi/utils/cart';
import parseJsonString from '../../../../bi/utils/parseJsonString';
import { isSmartAgent } from '../../../../bi/utils/env';

import ROUTES from '../../../../bi/constants/routes';
import { SERVICETYPE } from '../../../../bi/constants/serviceType';
import { QA_ATTRIBUTES } from '../../../../bi/constants/attributesForTests';

import styles from './styles/index.module.css';

const LABELS = {
  BOOK: getText('note:booking.book'),
  UNDERAGE_TOOLTIP: getText('note:booking.underageTooltip'),
  DISABLED_BUTTON: (user) => getText('note:booking.disabledButton', { user }),
  MANDATORY_PROJECT: (user) => getText('note:booking.mandatoryProject', { user }),
  EMPLOYEES: getText('note:employees'),
  TRAVELERS: getText('note:travelers'),
  C: 'С',
};

class NoteBookingWrap extends Component {
  static propTypes = {
    item: PropTypes.object.isRequired,
    meta: PropTypes.object.isRequired,
    data: PropTypes.object.isRequired,
    buyingEmployee: PropTypes.object,
    documentType: PropTypes.object.isRequired,
    employees: PropTypes.array.isRequired,
    projects: PropTypes.array.isRequired,
    employeesMax: PropTypes.number.isRequired,
    notepadService: PropTypes.object.isRequired,
    employeeService: PropTypes.object.isRequired,
    workspaceService: PropTypes.object.isRequired,
    featureFlagsService: PropTypes.object.isRequired,
    tripTagsService: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    bookingProcess: PropTypes.bool.isRequired,
    mandatoryProject: PropTypes.bool.isRequired,
    optionalProject: PropTypes.bool.isRequired,
    isPersonalBonusCards: PropTypes.bool.isRequired,
    onConfirmBooking: PropTypes.func.isRequired,
    redirectToEmployee: PropTypes.func.isRequired,
    companiesWithCostCenter: PropTypes.array.isRequired,
    settingsService: PropTypes.object.isRequired,
    accountSettingsService: PropTypes.object.isRequired,
    isMulti: PropTypes.bool,
  };

  static defaultProps = {
    isMulti: false,
    buyingEmployee: null,
  };

  employeesContainerRef = createRef();

  constructor(props) {
    super(props);

    const { item: { Data, Tags } } = this.props;

    const selectedTripTags = Tags ? Tags.map(({ Id }) => Id) : [];
    const itemData = parseJsonString(Data);

    this.travelersOrEmployees = isSmartAgent ? LABELS.TRAVELERS : LABELS.EMPLOYEES;

    this.state = {
      itemData,
      loading: false,
      selectedTripTags,
      haveAlreadyScrolledToEmployee: false,
      showBookingChildrenModal: false,
      showTicketBullits: true,
    };
  }

  componentDidMount() {
    this.scrollToIncompleteEmployee();
  }

  scrollToIncompleteEmployee = () => {
    const { item, mandatoryProject } = this.props;
    const { haveAlreadyScrolledToEmployee } = this.state;

    if (!haveAlreadyScrolledToEmployee && this.employeesContainerRef && cartItemIsNotTotallyFilled({ ...item, EmployeeCartItems: item.EmployeeNoteItems }, mandatoryProject)) {
      const { offsetHeight, clientHeight, offsetTop } = this.employeesContainerRef.current;

      const headerOffset = 130;
      const windowHeight = window.innerHeight;

      const cartHeightLessThanWindowHeight = (windowHeight - headerOffset) > clientHeight;
      const toTop = offsetTop - headerOffset + 140;
      const toBottom = offsetTop + offsetHeight - windowHeight + 80 + 140;

      const scrollTo = cartHeightLessThanWindowHeight
        ? toTop
        : toBottom;

      this.setState({ haveAlreadyScrolledToEmployee: true }, () => {
        window.scroll({ top: scrollTo, behavior: 'smooth' });
      });
    }
  };

  componentDidUpdate() {
    this.scrollToIncompleteEmployee();
  }

  getQAAttrs = () => {
    const {
      select,
      input,
      firstEl,
      loadingEmployee,
      loadingSuggest,
    } = QA_ATTRIBUTES.note.booking.employee;

    return {
      select,
      input,
      firstEl,
      loadingEmployee,
      loadingSuggest,
    };
  };

  addEmployeeProject = (employeeId, projectId) => {
    const { item: { Id }, notepadService } = this.props;

    if (projectId) {
      return notepadService.addEmployeeProject(Id, employeeId, projectId);
    }

    return notepadService.delEmployeeProject(Id, employeeId);
  };

  handleShowBookingChildrenModal = (showBookingChildrenModal) => () => this.setState({ showBookingChildrenModal });

  handleConfirm = (e) => {
    const { item, onConfirmBooking } = this.props;

    const hasChildren = isCartHasAirChildren([item]);

    if (hasChildren) {
      this.handleShowBookingChildrenModal(true)();

      return;
    }

    onConfirmBooking(e);
  };

  handleContinueConfirm = (e) => {
    this.setState({ showBookingChildrenModal: false }, () => {
      this.props.onConfirmBooking(e);
    });
  };

  handleLoadingEnd = () => {
    this.setState({
      loading: false,
    });
  };

  handleAddEmployee = async (employee) => {
    const { item, notepadService } = this.props;

    const res = await notepadService.addEmployee(item.Id, employee.Id);
    this.addSelectedTripTags(employee.Tags);

    return res;
  };

  addSelectedTripTags = (newTags) => {
    const { selectedTripTags } = this.state;

    const tagsArray = addSelectedTripTags(selectedTripTags, newTags);

    this.setState({ selectedTripTags: tagsArray });
  };

  handleRemoveEmployee = (employee) => {
    const { item, notepadService } = this.props;

    return notepadService.removeEmployee(item.Id, employee.Id);
  };

  handleAddDocument = (employeeId, documentId) => {
    this.setState({
      loading: true,
    });

    const { item, notepadService } = this.props;

    return notepadService.addEmployeeDocument(item.Id, employeeId, documentId).then((res) => {
      this.handleLoadingEnd();

      return res;
    });
  };

  handleAddCompany = (employeeId, companyId) => {
    const { item, notepadService } = this.props;

    return notepadService.addEmployeeCompany(item.Id, employeeId, companyId).then((res) => {
      this.handleLoadingEnd();

      return res;
    });
  };

  handleUpdateTripTags = (tags) => {
    const { tripTagsService, item: { Id } } = this.props;

    this.setState({ loading: true });

    return tripTagsService.updateNoteItemTripTags(Id, tags).then(() => this.handleLoadingEnd());
  };

  handleChangeSelectedTripTags = selectedTripTags => this.setState({ selectedTripTags });

  redirectToProject = (employeeId) => {
    const { item: { Id: noteItemId }, history } = this.props;

    history.push({
      pathname: ROUTES.COSTCENTERS,
      search: queryString.stringify({ noteItemId, employeeId }),
    });
  };

  renderHeaderItem = () => {};

  renderTooltip = (disabledAccount, locked, isUnderage, validProject) => {
    const renderText = (text) => (
      <Text
        color='white'
        type='NORMAL_14_130'
      >
        {text}
      </Text>
    );

    const disabledAccountText = disabledAccount && renderText(this.props.workspaceService.bookStatus.DisabledAccount.msg);

    const notUnlockText = locked && renderText(LABELS.DISABLED_BUTTON(this.travelersOrEmployees));

    const underageText = isUnderage && renderText(LABELS.UNDERAGE_TOOLTIP);

    const invalidProjectText = !validProject && renderText(LABELS.MANDATORY_PROJECT(this.travelersOrEmployees));

    const lockedText = invalidProjectText || notUnlockText;

    return (
      <div className={ styles['tooltip-wrapper'] }>
        { underageText }
        { disabledAccountText }
        { lockedText }
      </div>
    );
  };

  renderOption = () => {
    const {
      item,
      bookingProcess,
      workspaceService,
      mandatoryProject,
      companiesWithCostCenter,
    } = this.props;

    const disabledAccount = workspaceService.isDisabledAccount;
    const { unlock } = getDataForCart([{ ...item, EmployeeCartItems: item.EmployeeNoteItems }], companiesWithCostCenter);

    const validProject = mandatoryProject ? item.EmployeeNoteItems.every(({ ProjectId }) => !!ProjectId) : true;
    const hasUnderage = cartHasAirUnderageByProviders([item]);

    const disabled = disabledAccount || !unlock || !validProject || hasUnderage;
    const tooltipHtml = disabled && this.renderTooltip(disabledAccount, !unlock, hasUnderage, validProject);

    return (
      <div className={ styles.button }>
        <Tooltip renderContent={ () => tooltipHtml } show={ !!tooltipHtml }>
          <Button
            type={ PROPS.BUTTON.TYPES.SECONDARY }
            disabled={ disabled }
            loading={ bookingProcess }
            onClick={ this.handleConfirm }
            qaAttr={ QA_ATTRIBUTES.note.booking.buttonBook }
          >
            { LABELS.BOOK }
          </Button>
        </Tooltip>
      </div>
    );
  };

  renderItem = () => {
    const { data, meta } = this.props;
    const { showTicketBullits } = this.state;

    return data.Routes.map(route => (
      <AirlineItem
        key={ route.ID }
        route={ route }
        meta={ meta }
        showTicketBullits={ showTicketBullits }
      />
    ));
  };

  renderTripTags = () => {
    const { tripTagsService, employees, workspaceService } = this.props;
    const { selectedTripTags } = this.state;

    if (employees.length === 0) return null;

    return (
      <TripTags
        tripTagsService={ tripTagsService }
        onUpdateTags={ this.handleUpdateTripTags }
        onChangeTags={ this.handleChangeSelectedTripTags }
        className={ styles['trip-tags'] }
        selectedTags={ selectedTripTags }
        store={ tripTagsService.store }
        workspaceService={ workspaceService }
      />
    );
  };

  renderBookChildrenDialog = () => (
    <BookChildDialog
      show={ this.state.showBookingChildrenModal }
      onClose={ this.handleShowBookingChildrenModal(false) }
      onContinue={ this.handleContinueConfirm }
    />
  );

  renderAirAFlag = () => {
    const { item: { Data } } = this.props;
    const { AFlag } = JSON.parse(Data);

    if (!AFlag) {
      return null;
    }

    return (
      <RoundIconWithTooltip
        label={ LABELS.C }
        className={ styles.a_flag }
      />
    );
  };

  render() {
    const {
      item: { Price: { TotalPrice }, ServiceType, EmployeeNoteItems, Id, AgentFee },
      documentType,
      meta,
      employees,
      projects,
      employeesMax,
      isMulti,
      isPersonalBonusCards,
      mandatoryProject,
      optionalProject,
      employeeService,
      workspaceService,
      featureFlagsService,
      redirectToEmployee,
      buyingEmployee,
      settingsService,
      accountSettingsService,
      notepadService: {
        setAdditionalFeeSmartagentNotepad,
      },
    } = this.props;

    const { loading, itemData } = this.state;

    const price = meta.Fare.Price.TotalPrice || TotalPrice;
    const agentFee = meta.Fare.AgentFee || AgentFee;

    const { DepartmentOnly } = featureFlagsService.getFeatureFlags();
    const sendSmsFlag = featureFlagsService.getSendSms();
    const airAFlagHtml = ServiceType === SERVICETYPE.AIR && this.renderAirAFlag();

    return (
      <ItemPanel
        renderHeader={ this.renderHeaderItem }
        loading={ loading }
      >
        <div className={ styles.wrapper }>
          <ItemLayout
            price={ price }
            serviceType={ ServiceType }
            renderOption={ this.renderOption }
            absoluteHtml={ airAFlagHtml }
            feeSmartagent={ agentFee }
            isAdditionalFee
            isNoteItem
            itemId={ Id }
            setAdditionalFeeSmartagent={ setAdditionalFeeSmartagentNotepad }
          >
            { this.renderItem() }
          </ItemLayout>
          <div className={ styles.actions } ref={ this.employeesContainerRef }>
            <EmployeeList
              routes={ itemData.Routes }
              highlightNonFilledEmployee
              withNumber={ sendSmsFlag }
              items={ employees }
              projects={ projects }
              max={ employeesMax }
              buyingEmployee={ buyingEmployee }
              showDocument
              isChoiceForEachEmployee
              enumOfDocuments={ documentType }
              employeeService={ employeeService }
              workspaceService={ workspaceService }
              employeesWithCompanies={ EmployeeNoteItems }
              departmentOnly={ DepartmentOnly }
              isMulti={ isMulti }
              isPersonalBonusCards={ isPersonalBonusCards }
              mandatoryProject={ mandatoryProject }
              optionalProject={ optionalProject }
              serviceType={ ServiceType }
              handleAddEmployee={ this.handleAddEmployee }
              handleRemoveEmployee={ this.handleRemoveEmployee }
              handleAddDocument={ this.handleAddDocument }
              handleAddCompany={ this.handleAddCompany }
              redirectToEmployee={ redirectToEmployee }
              redirectToProject={ this.redirectToProject }
              onChangeProjectSelect={ this.addEmployeeProject }
              onAddProject={ this.addEmployeeProject }
              settingsService={ settingsService }
              accountSettingsService={ accountSettingsService }
              qaAttr={ this.getQAAttrs() }
            />
            { this.renderTripTags() }
          </div>
        </div>
        { this.renderBookChildrenDialog() }
      </ItemPanel>
    );
  }
}

export default NoteBookingWrap;
