// @ts-nocheck
const departmentsListMapper = (payload) => {
  const { payloadRequests, payloadDepartments } = payload;
  const companiesSet = new Set();
  const departmentsSet = new Set();

  payloadRequests.forEach(({ Companies = [], Departments = [] }) => {
    Companies.forEach(i => companiesSet.add(i));
    Departments.forEach(i => departmentsSet.add(i));
  });

  const deparmentToObject = (departments) => departments.reduce((res, { Id, Name }) => {
    if (departmentsSet.has(Id)) {
      return [...res, { value: Id, label: Name }];
    }

    return res;
  }, []);

  return payloadDepartments.reduce((res, { CompanyId, ShortCompanyName, CompanyName, Departments }) => {
    if (companiesSet.has(CompanyId)) {
      const reducedDepartments = deparmentToObject(Departments);

      const nested = reducedDepartments.length ? reducedDepartments : null;
      const newCompany = { value: CompanyId, label: ShortCompanyName || CompanyName };

      if (nested) {
        newCompany.nested = nested;
      }

      return [...res, newCompany];
    }

    return res;
  }, []);
};

const companiesWithDepartmentsMapper = (list) => list.map(({
  CompanyId = null,
  CompanyName = '',
  ShortCompanyName = '',
  Departments = [],
}) =>
  ({
    value: CompanyId,
    label: ShortCompanyName || CompanyName,
    nested: Departments.map(({ Id, Name: dName }) => ({ value: Id, label: dName })),
  }),
);

export {
  departmentsListMapper,
  companiesWithDepartmentsMapper,
};
