import React, { useEffect, useLayoutEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';
import {
  Button,
  Text,
  Dialog,
  DotLoading,
  SidePanel,
} from 'new-ui';

import { useStores } from '../../../../bi/context';
import { MOBX_STORES } from '../../../../bi/context/stores';

import { getText } from '../../../../../i18n';

import { EmployeeService } from '../../../../bi/types/employees';
import { WorkspaceService } from '../../../../bi/types/workspace';
import { NotificationService } from '../../../../bi/types/notification';

import { TYPE_IMAGE } from '../../../../bi/constants/signature';
import ROUTES from '../../../../bi/constants/routes';

import { CodeDialog } from '../../../Employee/components/Signature/components/CodeDialog';

import { DocumentTableWrap } from './components/DocumentTableWrap';
import { EmployeeSuggest } from '../../../../components/EmployeeSuggest';

import { MainAnalytic } from '../../../../bi/utils/analytics';

import styles from './styles/index.module.css';

export const LABELS = {
  MENU_TITLE: getText('settings:documentsForSignature.menuTitle'),
  MENU_TITLE_ROOT: getText('settings:documentsForSignature.header'),
  TITLE: getText('settings:documentsForSignature.header'),
  DOWNLOAD: getText('settings:documentsForSignature.downloadXlsx'),
  SIGN: getText('settings:documentsForSignature.sign'),
  DIALOG: {
    HEADER: getText('settings:documentsForSignature.dialog.header'),
    SUB: getText('settings:documentsForSignature.dialog.sub'),
    SUBMIT: getText('settings:documentsForSignature.dialog.submit'),
    SIGN_FAILED: getText('settings:documentsForSignature.dialog.signFailed'),
    SIGN_SUCCESS: getText('settings:documentsForSignature.dialog.signSuccess'),
    SEND_TO_MAIL_SUCCESS: getText('settings:documentsForSignature.dialog.sendToMailSuccess'),
    SEND_TO_MAIL_FAILED: getText('settings:documentsForSignature.dialog.sendToMailFailed'),
  },
  TOOLTIP: {
    TITLE: getText('settings:title'),
    DOCUMENTS_FOR_SIGNATURE: getText('constants:settings.documentsForSignature'),
    HOW_IT_WORKS_FIRST: getText('constants:settings.howItWorks.sectionOne'),
    HOW_IT_WORKS_SECOND: getText('constants:settings.howItWorks.sectionTwo'),
    HOW_IT_WORKS_THIRD: getText('constants:settings.howItWorks.sectionThree'),
    HOW_IT_WORKS_FOURTH: getText('constants:settings.howItWorks.sectionFour'),
    HOW_IT_WORKS_FIFTH: getText('constants:settings.howItWorks.sectionFive'),
    HOW_IT_WORKS_SIXTH: getText('constants:settings.howItWorks.sectionSix'),
  },
};

interface DocumentsForSignatureType {
  employeeService: EmployeeService,
  workspaceService: WorkspaceService,
  notificationService: NotificationService,
}

const DocumentsForSignature = observer(({
  employeeService,
  workspaceService,
  notificationService,
}: DocumentsForSignatureType) => {
  const { simpleSignatureStore } = useStores([MOBX_STORES.SIMPLE_SIGNATURE_STORE]);
  const [showCodeDialog, setShowCodeDialog] = useState(false);
  const [showPreviewDialog, setShowPreviewDialog] = useState(false);
  const [downloadReportXlsxLoading, setDownloadReportXlsxLoading] = useState(false);
  const [isAllSelect, setIsAllSelect] = useState(false);

  const {
    employee,
    loading,
    documentPreview,
    loadings: { loadingDocPreview },
    codeResponse,
    isDisabledSignButton,
    documentsForSignature,
    checkBoxValues,
    signature: {
      registrationNumber,
      phoneNumber,
    },
  } = simpleSignatureStore;

  const {
    autocomplete,
    getEmployeeAndDocsForSignature,
    setEmployeeAndGetDocsSignature,
    setSortType,
    resetSignatureStore,
    setCheckBoxValues,
    setPageSignature,
    setActiveTimer,
    setSignatureCode,
    setResetSignatureCode,
    singingDocs,
    singingDocsCode,
    downloadReportXlsx,
    downloadDocument,
    getDocumentPreviewSignature,
    sendDocumentsToEmail,
    resetCheckedDocumentsForSignature,
  } = employeeService;

  const history = useHistory();

  const { rights: { EmployeeId }, isAdmin } = workspaceService;

  useLayoutEffect(() => {
    employeeService.getSimpleSignature(EmployeeId);

    MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.SIMPLE_SIGNATURE.DOCUMENTS_FOR_SIGNATURE_PAGE_OPENED);
  }, []);

  useEffect(() => {
    if (!isAdmin) {
      getEmployeeAndDocsForSignature(EmployeeId);
    }

    return () => resetSignatureStore();
  }, [getEmployeeAndDocsForSignature]);

  const handleDownloadReportXlsx = async (employeeId: number) => {
    setDownloadReportXlsxLoading(true);
    await downloadReportXlsx(employeeId);
    setDownloadReportXlsxLoading(false);

    MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.SIMPLE_SIGNATURE.DOWNLOAD_XLSX_REPORT_BUTTON_PRESSED);
  };

  const handleCloseSignatureCodeDialog = () => {
    setShowCodeDialog(false);
    setResetSignatureCode();
  };

  const handleSendDocumentToMail = async (email: string, docId: string, employeeId: string) => {
    try {
      await sendDocumentsToEmail(email, docId, employeeId);
      notificationService.send({
        message: LABELS.DIALOG.SEND_TO_MAIL_SUCCESS,
      });
    } catch {
      notificationService.send({
        message: LABELS.DIALOG.SEND_TO_MAIL_FAILED,
      });
    }
  };

  const handleGetDocuments = () => {
    history.push(ROUTES.SETTINGS.DOCUMENTS_FOR_SIGNATURE);
  };

  const handleShowDocument = (docId: string, employeeId: number) => {
    getDocumentPreviewSignature(docId, employeeId);
    setShowPreviewDialog(true);
  };

  const handleSendSignatureCode = () => {
    setShowCodeDialog(false);
    singingDocs()
      .then(() => {
        setShowCodeDialog(false);

        if (!isAdmin) {
          getEmployeeAndDocsForSignature(EmployeeId);
        }

        resetCheckedDocumentsForSignature();
        notificationService.send({
          message: LABELS.DIALOG.SIGN_SUCCESS,
        });
        setIsAllSelect(false);
      })
      .catch(() => {
        setShowCodeDialog(false);
        notificationService.send({
          message: LABELS.DIALOG.SIGN_FAILED,
        });
        setIsAllSelect(false);
      });
  };

  const handleRepeatGetCode = () => {
    singingDocsCode();
    setResetSignatureCode();
  };

  const handleShowCodeDialog = () => {
    singingDocsCode();
    setShowCodeDialog(true);
    setActiveTimer(true);

    MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.SIMPLE_SIGNATURE.DOCUMENTS_SIGN_BUTTON_PRESSED);
  };

  const employeeIsNotOwnerOfAllDoc = documentsForSignature
    .filter(({ documentId }) => checkBoxValues.includes(documentId))
    .some(({ employeeIds }) => !employeeIds.includes(EmployeeId));

  const signButton = !isAdmin ? (
    <Button
      className={ styles.sign }
      type='secondary'
      loading={ false }
      onClick={ handleShowCodeDialog }
      disabled={ isDisabledSignButton || employeeIsNotOwnerOfAllDoc || !registrationNumber }
    >
      { LABELS.SIGN }
    </Button>
  ) : null;

  const selectEmployee = (
    <div className={ styles.suggest }>
      <EmployeeSuggest
        onFetch={ (value) => autocomplete(value, false) }
        onSelect={ (value) => {
          resetCheckedDocumentsForSignature();
          setEmployeeAndGetDocsSignature(value);
        } }
        value={ employee }
        canAddEmployee={ false }
        createNewEmployee={ () => {} }
        withFetching
      />
    </div>
  );

  const renderSingingCodeDialog = showCodeDialog && !loading && (
    <CodeDialog
      showChangeNumber={ false }
      phoneNumber={ phoneNumber }
      show={ showCodeDialog }
      onSetActiveTimer={ setActiveTimer }
      onClose={ handleCloseSignatureCodeDialog }
      onChange={ setSignatureCode }
      onRepeat={ handleRepeatGetCode }
      onSave={ handleSendSignatureCode }
      amplitudeProperty={ 'signature' }
    />
  );

  const imageContent = (image: string, index: number) => {
    if (loadingDocPreview && index === 0) {
      return <div className={ styles.images_loading }><DotLoading /></div>;
    }

    const srcString = () => TYPE_IMAGE.SVG + image;

    return (
      <div className={ styles.image_wrapper }>
        <img
          className={ styles.preview }
          src={ srcString() }
          alt='agreement'
        />
      </div>
    );
  };

  const renderPreviewDialog = (
    <SidePanel
      show={ showPreviewDialog }
      onClose={ () => setShowPreviewDialog(false) }
    >
      <div className={ styles.image_content }>
        { documentPreview.map(imageContent) }
      </div>
    </SidePanel>
  );

  const renderSingingConfirmDialog = () => {
    const header = codeResponse ? (
      <Text
        className={ styles.header }
        type='NORMAL_16'
      >
        { codeResponse }
      </Text>
    ) : (
      <>
        <Text
          className={ styles.header }
          type='SEMIBOLD_18'
        >
          { LABELS.DIALOG.HEADER }
        </Text>
        <Text
          className={ styles.header }
          type='NORMAL_14'
        >
          { LABELS.DIALOG.SUB }
        </Text>
      </>
    );

    return (
      <Dialog
        showClosing
        show={ false }
        onChange={ handleGetDocuments }
      >
        <div className={ styles.singing_dialog }>
          { header }
          <Button
            className={ styles.confirm }
            onClick={ handleGetDocuments }
            type='secondary'
          >
            { LABELS.DIALOG.SUBMIT }
          </Button>
        </div>
      </Dialog>
    );
  };

  return (
    <div className={ styles.wrapper }>
      <div className={ styles.header_wrapper }>
        <Text
          type='bold_20'
          className={ styles.header }
        >
          { LABELS.TITLE }
        </Text>
        <Button
          type='textual'
          onClick={ () => handleDownloadReportXlsx(employee?.Id) }
          className={ styles.download }
          disabled={ !documentsForSignature.length }
          loading={ downloadReportXlsxLoading }
        >
          { LABELS.DOWNLOAD }
        </Button>
      </div>
      <div className={ styles.sub_header }>
        { selectEmployee }
        { signButton }
      </div>
      <div className={ styles.table }>
        <DocumentTableWrap
          isAdmin={ isAdmin }
          disabled={ !registrationNumber }
          isEmployeeSelect={ employee?.Name }
          onSetPageSignature={ setPageSignature }
          onSetSortType={ setSortType }
          onSetCheckBoxValues={ setCheckBoxValues }
          onDownloadDocument={ downloadDocument }
          onShowDocument={ handleShowDocument }
          onSendDocumentToEmail={ handleSendDocumentToMail }
          isAllSelect={ isAllSelect }
          setIsAllSelect={ setIsAllSelect }
        />
      </div>
      { renderSingingConfirmDialog() }
      { renderSingingCodeDialog }
      { renderPreviewDialog }
    </div>
  );
});

export { DocumentsForSignature };
