import React, { FC } from 'react';
import { Button, Dialog, Text } from 'new-ui';

import { getText } from '../../../../../i18n';

import styles from './styles.module.css';

const LABELS = {
  TITLE: (names: string) => getText('cart:mandatoryCodeErrorDialog.title', { names }),
  CLOSE: getText('cart:mandatoryCodeErrorDialog.close'),
};

interface MandatoryCodeErrorDialogProps {
  show: boolean;
  toogleDialog(value: boolean): void;
  uniqueEmployeesNames: string[];
}

const MandatoryCodeErrorDialog: FC<MandatoryCodeErrorDialogProps> = ({
  show,
  toogleDialog,
  uniqueEmployeesNames,
}) => (
  <Dialog
    show={ show }
    onChange={ toogleDialog }
    showClosing={ false }
  >
    <div className={ styles['dialog-content'] }>
      <Text type={ 'NORMAL_16_140' }>
        { LABELS.TITLE(uniqueEmployeesNames.join(', ')) }
      </Text>
      <div className={ styles.actions }>
        <Button onClick={ () => toogleDialog(false) }>{ LABELS.CLOSE }</Button>
      </div>
    </div>
  </Dialog>
);

export { MandatoryCodeErrorDialog };
