import React, { useEffect, useState } from 'react';
import { Icon, Text } from 'new-ui/src';

import { getText } from '../../../../../i18n';

import FlightRouteComponent from './FlightRouteComponent';
import AirlineInfoComponent from './AirlineInfoComponent';

import {
  processData,
  calculateDuration,
  calculatePriceDiscount,
} from '../../../../bi/utils/minimalFares';
import { MainAnalytic } from '../../../../bi/utils/analytics';

import { Fare, FlightInfoComponentProps } from './types';

import styles from './styles/index.module.css';

const rusType = 'ru-RU';

const LABELS = {
  MINIMAL_TARIF: {
    MINIMAL: getText('constants:airline.minimalTarif.minimal'),
    CHEAPER_BY: (priceDiscount: number) =>
      getText('constants:airline.minimalTarif.cheaperBy', { priceDiscount }),
  },
};

const FlightInfoComponent = ({
  lowestFares,
  data,
}: FlightInfoComponentProps) => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.ANALYTICS.AIRLINE_MINIMAL_FARE_OPENED);
  }, []);

  if (!lowestFares || !data) {
    return null;
  }

  const { totalPrice } = processData(data);

  const groupedByRouteNumber = lowestFares.reduce<Record<string, Fare[]>>((acc, fare) => {
    const { RouteNumber } = fare.Additional;

    if (!acc[RouteNumber]) {
      acc[RouteNumber] = [];
    }

    acc[RouteNumber].push(fare);

    return acc;
  }, {});

  const firstRouteNumber = Object.keys(groupedByRouteNumber)[0];

  const routeFaresFirst = groupedByRouteNumber[firstRouteNumber];

  const {
    Price: firstPrice,
    Organization,
    Additional: { FlightClass, IsBaggageIncluded },
  } = routeFaresFirst[0];

  const priceDiscount = calculatePriceDiscount(
    firstPrice,
    Number(totalPrice),
  );

  const iconType = isOpen ? 'arrowsUpDimblue' : 'arrowsDownDimblue';

  const handleToggle = () => {
    setIsOpen(!isOpen);

    if (!isOpen) {
      MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.ANALYTICS.AIRLINE_MINIMAL_FARE_PRESSED);
    }
  };

  const renderAirline = () => {
    if (!isOpen) {
      return null;
    }

    const routeComponents = Object.keys(groupedByRouteNumber).map((routeNumber) => {
      const routeFares = groupedByRouteNumber[routeNumber];
      const firstFare = routeFares[0];
      const lastFare = routeFares[routeFares.length - 1];

      return (
        <div key={ routeNumber }>
          <FlightRouteComponent
            routeFares={ routeFares }
            lastFare={ lastFare }
            totalDuration={ calculateDuration(firstFare.Additional.Duration) }
          />
        </div>
      );
    });

    return (
      <Text className={ styles.container_color } >
        <div>
          { routeComponents }
        </div>
        <div>
          <AirlineInfoComponent
            organization={ Organization }
            flightClass={ FlightClass }
            isBaggageIncluded={ IsBaggageIncluded }
          />
        </div>
      </Text>
    );
  };

  return (
    <div className={ styles.flight_info_container }>
      <div className={ styles.flight_info }>
        <div>
          <Text type='bold_16'>
            { LABELS.MINIMAL_TARIF.MINIMAL }
          </Text>
        </div>
        <div className={ styles.flight_info_minimal_item }>
          <Text type='bold_16'>
            { firstPrice.toLocaleString(rusType) }
          </Text>
          <Text type='SEMIBOLD_12'> ₽</Text>
          <Text
            type='NORMAL_16'
            color='green'
          >
            { LABELS.MINIMAL_TARIF.CHEAPER_BY(priceDiscount) }
          </Text>
          <div
            className={ styles.item_click_button }
            onClick={ handleToggle }
          >
            <Icon
              type={ iconType }
              color='black'
            />
          </div>
        </div>
      </div>
      { renderAirline() }
    </div>
  );
};

export default FlightInfoComponent;
