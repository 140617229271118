// @ts-nocheck
import { getText } from '../../../i18n';

import { DEPARTMENTS } from '../constants/departments';

const LABELS = {
  VALUE: getText('trips:companies.companies'),
};

const getObjectForSelect = item => (
  Array.isArray(item) ? { label: item[1], value: item[0] } : { label: item, value: item }
);

const mapQueryParams = (query, isNumbers) => {
  const arrFromQuery = Array.isArray(query) ? query : [query];

  return isNumbers ? arrFromQuery.map(item => Number(item || 0)) : arrFromQuery;
};

const getSelectedFromSet = (itemsSet, query, isNumbers) => {
  const arrFromQuery = mapQueryParams(query, isNumbers);

  return arrFromQuery.filter(item => itemsSet.has(item));
};

const getSelectedFromArray = (arr, query, type) => {
  const arrFromQuery = mapQueryParams(query, true);

  if (type === LABELS.VALUE) {
    return arrFromQuery.filter(item => arr.some(({ CompanyId }) => CompanyId === item));
  }

  if (type === DEPARTMENTS) {
    return arrFromQuery.filter(item => arr.some(({ Departments }) => Departments.some(({ Id }) => Id === item)));
  }

  return arrFromQuery.filter(item => arr.some(({ Id }) => Id === item));
};

export {
  getObjectForSelect,
  getSelectedFromSet,
  getSelectedFromArray,
};
