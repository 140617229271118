import React, { MouseEvent } from 'react';
import { NavLink } from 'react-router-dom';
import { Button, ItemPanel, Text } from 'new-ui';

import { getText } from '../../../../../i18n';

import { useServices } from '../../../../bi/context/services';

import { AddresserInfoItem } from '../../../../components/AddresserInfoItem';

import { getDateDescriptor } from '../../../../bi/utils/formatDate';

import ROUTES from '../../../../bi/constants/routes';

import { FactualApprovesLiteResponse } from '../../../../bi/services/approve/types';

import styles from './styles.module.css';

const LABELS = {
  COMMENTS: getText('components:travelApproval.comments'),
};

export const ApproveRequest = ({
  CartName,
  Employee,
  EventDate,
  CartId,
}: FactualApprovesLiteResponse) => {
  const { reportService } = useServices(['Report']);

  const handleOpenComments = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    e.preventDefault();

    reportService.approveReportStore.openCommentDialog(CartId);
  };

  const renderCartName = () => (
    <Text className={ styles.itemPanel } type='NORMAL_18'>
      { CartName }
    </Text>
  );

  return (
    <NavLink to={ `${ROUTES.CART.SPECIFIC}${CartId}` }>
      <ItemPanel renderHeader={ renderCartName }>
        <div className={ styles.itemPanel }>
          <div>
            <AddresserInfoItem
              email={ Employee.Email }
              name={ `${Employee.Name} ${Employee.Surname}` }
              date={ `${getDateDescriptor(EventDate).toLowerCase()}` }
            />
          </div>
          <Button type='textual-medium' onClick={ handleOpenComments }>
            <Text type='NORMAL_16_130' color='accent'>
              { LABELS.COMMENTS }
            </Text>
          </Button>
        </div>
      </ItemPanel>
    </NavLink>
  );
};
