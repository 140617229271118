// @ts-nocheck
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Button, Dialog, Icon, ItemPanel } from 'new-ui';
import { getText } from '../../../../i18n';

import { SERVICETYPE, SERVICES_ICON } from '../../../bi/constants/serviceType';

import { FavoriteAction } from '../../../components/FavoriteAction';

import TrainSearchStartDialog from './dialog/train';
import HotelSearchStartDialog from './dialog/hotel';
import AirlineSearchStartDialog from './dialog/airline';

import styles from '../styles/wrap.module.css';

const LABELS = {
  BUTTON: getText('favorites:chooseDates'),
  START_SEARCH: getText('favorites:dialogs.transfer.startSearch'),
};

const DIALOG_FORM = {
  [SERVICETYPE.AIR]: AirlineSearchStartDialog,
  [SERVICETYPE.TRAIN]: TrainSearchStartDialog,
  [SERVICETYPE.HOTEL]: HotelSearchStartDialog,
};

class FavoriteItemWrap extends Component {
  static propTypes = {
    type: PropTypes.string.isRequired,
    children: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.object,
    ]).isRequired,
    dialogProps: PropTypes.object,
    id: PropTypes.string,
    onOpenDialog: PropTypes.func,
    onRemoveItem: PropTypes.func.isRequired,
    onSearch: PropTypes.func.isRequired,
    qaAttrChooseDates: PropTypes.string,
    qaAttrDelete: PropTypes.string,
    qaAttrDeleteDialog: PropTypes.object,
    qaAttrDialog: PropTypes.object,
    qaAttrIcon: PropTypes.string,
  };

  static defaultProps = {
    dialogProps: null,
    id: '',
    onOpenDialog: () => {},
    qaAttrChooseDates: '',
    qaAttrDelete: '',
    qaAttrIcon: '',
    qaAttrDeleteDialog: {
      wrapper: '',
      close: '',
      buttons: {
        confirm: '',
        close: '',
      },
    },
    qaAttrDialog: {
      air: {
        wrapper: '',
        header: '',
        date: '',
        passengers: '',
        buttons: {
          startSearch: '',
          cancel: '',
        },
      },
      train: {
        wrapper: '',
        header: '',
        date: '',
        passengers: '',
        buttons: {
          startSearch: '',
          cancel: '',
        },
      },
      hotel: {
        wrapper: '',
        header: '',
        dateFrom: '',
        dateTo: '',
        guests: '',
        buttons: {
          startSearch: '',
          cancel: '',
        },
      },
    },
  };

  state = {
    openDialog: false,
  };

  handleOpenDialog = () => this.setState({ openDialog: !this.state.openDialog }, () => {
    const { type, onOpenDialog } = this.props;
    const { openDialog } = this.state;

    if (onOpenDialog && openDialog) {
      onOpenDialog(type);
    }
  });

  getDialogQaAttr = () => {
    const { type, qaAttrDialog } = this.props;

    switch (type) {
      case SERVICETYPE.AIR: {
        return qaAttrDialog.air;
      }
      case SERVICETYPE.TRAIN: {
        return qaAttrDialog.train;
      }
      case SERVICETYPE.HOTEL: {
        return qaAttrDialog.hotel;
      }
      default:
        return {};
    }
  };

  renderDialog = () => {
    const { type, onSearch, dialogProps } = this.props;

    const Form = DIALOG_FORM[type];
    const qaAttrs = this.getDialogQaAttr();

    return (
      <Dialog
        isClosing
        show={ this.state.openDialog }
        onChange={ this.handleOpenDialog }
        qaAttrWrapper={ qaAttrs.wrapper }
      >
        <Form
          { ...dialogProps }
          onSearch={ onSearch }
          onClose={ this.handleOpenDialog }
          qaAttrs={ qaAttrs }
        />
      </Dialog>
    );
  };

  renderHeader = () => {
    const { id, onRemoveItem, qaAttrDelete, qaAttrDeleteDialog } = this.props;

    return (
      <div className={ styles.header }>
        <div className={ styles.action }>
          <FavoriteAction
            id={ id }
            onClick={ onRemoveItem }
            qaAttrIcon={ qaAttrDelete }
            qaAttrDialog={ qaAttrDeleteDialog }
          />
        </div>
      </div>
    );
  };

  render() {
    const { type, children, onSearch, qaAttrChooseDates, qaAttrIcon } = this.props;

    const dialogHtml = this.state.openDialog && this.renderDialog();

    const searchTransfer = () => onSearch();
    const buttonAction = type === SERVICETYPE.TRANSFER ? searchTransfer : this.handleOpenDialog;
    const label = type === SERVICETYPE.TRANSFER ? LABELS.START_SEARCH : LABELS.BUTTON;

    return (
      <ItemPanel
        renderHeader={ this.renderHeader }
      >
        <div className={ styles.wrapper }>
          <div className={ styles.content }>
            <div className={ styles.icon }>
              <Icon qaAttr={ `${qaAttrIcon}-${type}` } type={ SERVICES_ICON[type] } />
            </div>
            <div className={ styles.children }>
              { children }
            </div>
          </div>
          <div className={ styles.action }>
            <Button
              onClick={ buttonAction }
              qaAttr={ qaAttrChooseDates }
            >
              { label }
            </Button>
          </div>
        </div>
        { dialogHtml }
      </ItemPanel>
    );
  }
}

export default FavoriteItemWrap;
