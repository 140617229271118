import React, { useState, useEffect, FC } from 'react';
import { observer } from 'mobx-react';
import { Prompt } from 'react-router-dom';

import { withStores } from '../../bi/context';
import { MOBX_STORES } from '../../bi/context/stores';

import PaymentService from '../../bi/services/payment';

import getAccountId from '../../bi/utils/getAccountId';
import { MainAnalytic } from '../../bi/utils/analytics';

import { PAYMENT } from '../../bi/constants/payment';

import Operations from './components/operations';
import { DocumentPackages } from './components/documents';

import styles from './styles/report.module.css';
import { ReportService } from '../../bi/types/report';
import { IFeatureFlagsService } from '../../bi/types/featureFlags';
import { WorkspaceService } from '../../bi/types/workspace';
import { NotificationService } from '../../bi/types/notification';
import { Company } from '../../bi/services/workspace/types';
import { ICloudPaymentsStore } from '../../bi/services/payment/store';
import { IAppServiceObj, ICompanyFunds, IAppService } from '../../bi/types/app';

interface IReportPageComponentProps {
  reportService: ReportService,
  appService: IAppService,
  notificationService: NotificationService,
  workspaceService: WorkspaceService,
  paymentService: PaymentService,
  featureFlagsService: IFeatureFlagsService,
  stores: {
    cloudPaymentsStore: ICloudPaymentsStore
  },
}

const ReportPageComponent: FC<IReportPageComponentProps> = ({
  reportService,
  appService,
  notificationService,
  workspaceService,
  paymentService,
  featureFlagsService,
  stores: { cloudPaymentsStore },
}) => {
  const getShowFinanceDetails = (companiesDetails: Company[], companyIdDetails: number) => {
    const company = companiesDetails.find(item => item.CompanyId === companyIdDetails);

    return !!company && !!company.ShowFinanceDetails;
  };

  const { Companies, CompanyId, Multi, Email, IsDemo, CompanyName, CompanyInn } = workspaceService.get();
  const valueShowFinanceDetails = getShowFinanceDetails(Companies, CompanyId);
  const valueCompanyFunds = appService.get().header.CompanyFunds;

  const [companyId, setCompanyId] = useState<number>(CompanyId);
  const [companyEmail, setCompanyEmail] = useState<string>(Email);
  const [companyName, setCompanyName] = useState<string>(CompanyName);
  const [companyInn, setCompanyInn] = useState<string>(CompanyInn);
  const [companies, setCompanies] = useState<Company[]>(Companies);
  const [multi, setMulti] = useState<boolean>(Multi);
  const [isDemo, setIsDemo] = useState<boolean>(IsDemo);
  const [companyFunds, setCompanyFunds] = useState<ICompanyFunds[]>(valueCompanyFunds);
  const [showFinanceDetails, setShowFinanceDetails] = useState<boolean>(valueShowFinanceDetails);
  const [accountId, setAccountId] = useState<number>(0);

  useEffect(() => {
    setCompanyId(CompanyId);
    setCompanyEmail(Email);
    setCompanyName(CompanyName);
    setCompanyInn(CompanyInn);
    setCompanies(Companies);
    setMulti(Multi);
    setIsDemo(IsDemo);
    setShowFinanceDetails(valueShowFinanceDetails);
  }, []);

  useEffect(() => {
    MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.REPORTING.SCREEN_OPENED);

    const fetchSettingsFlags = async () => {
      const AccountId: number = getAccountId();
      setAccountId(AccountId);
      await featureFlagsService.getSettingsFlags(AccountId);
    };

    const subscribeFn = ({ header: { CompanyFunds } }: IAppServiceObj) => {
      setCompanyFunds(CompanyFunds);
    };

    fetchSettingsFlags();
    appService.subscribe(subscribeFn);

    return () => {
      appService.subscribe(subscribeFn);
    };
  }, []);

  useEffect(() => {
    const handleBeforeUnload = (e: any) => {
      if (cloudPaymentsStore.cantLeave) {
        e.preventDefault();

        return '';
      }

      return null;
    };

    window.onbeforeunload = handleBeforeUnload;

    return () => {
      window.onbeforeunload = null;
    };
  }, []);

  useEffect(() => {
    const updateShowFinanceDetails = () => {
      const company = companies.find(item => item.CompanyId === companyId);
      setShowFinanceDetails(!!company && !!company.ShowFinanceDetails);
    };

    updateShowFinanceDetails();
  }, [companies, companyId]);

  const handleChangeCompanyId = (newCompanyId: number) => {
    if (newCompanyId !== companyId) {
      setCompanyId(newCompanyId);
      const updatedId = newCompanyId;
      setCompanyId(updatedId);
      setShowFinanceDetails(getShowFinanceDetails(companies, updatedId));
    }
  };

  const { cantLeave } = cloudPaymentsStore;

  const funds: { Overdraft: number, PaymentType: string | number } = companyFunds.find(item => item.CompanyId === companyId) || { Overdraft: 0, PaymentType: '' };
  const { Overdraft, PaymentType } = funds;
  const companiesForOperations: Company[] | null = multi ? companies : null;

  const isSpecifiedReportGS = featureFlagsService.getReportGsprom();
  const isSpecifiedReport = isSpecifiedReportGS || featureFlagsService.getReportSevmash();

  return (
    <div className={ styles.wrap }>
      <Prompt
        when={ cantLeave }
        message={ PAYMENT.EXITMESSAGE }
      />
      <div className={ styles.operations }>
        <Operations
          multi={ multi }
          currentCompany={ companyId }
          accountId={ accountId }
          reportService={ reportService }
          paymentService={ paymentService }
          companies={ companiesForOperations }
          onChangeCompanyId={ handleChangeCompanyId }
          sendNotification={ opts => notificationService.send(opts) }
          overdraft={ Overdraft }
          showFinanceDetails={ showFinanceDetails }
          companyEmail={ companyEmail }
          companyInn={ companyInn }
          companyName={ companyName }
          isDemo={ isDemo }
          isSpecifiedReport={ isSpecifiedReport }
          isSpecifiedReportGS={ isSpecifiedReportGS }
          allCompanies={ companies }
          paymentType={ PaymentType }
        />
      </div>
      <div className={ styles.upd }>
        <DocumentPackages
          currentCompany={ companyId }
          reportService={ reportService }
          workspaceService={ workspaceService }
        />
      </div>
    </div>
  );
};

export default withStores([MOBX_STORES.CLOUD_PAYMENTS])(observer(
  ReportPageComponent as unknown as React.ComponentClass,
));
