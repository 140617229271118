import React, { useState } from 'react';
import { Dialog, Text, Button, ItemPanel } from 'new-ui';
import { getText } from '../../../../i18n';

import { formatDate, getDateDescriptor, isSameDate } from '../../../bi/utils/formatDate';

import { PATTERN } from '../../../bi/constants/dateFormats';

import { AddresserInfoItem } from '../../../components/AddresserInfoItem';

import styles from '../styles/expenseReportItem.module.css';

const LABELS = {
  COMMENTS: getText('approve:travelItem.comments'),
  HISTORY_COMMENTS: getText('approve:travelItem.historyComments'),
  EXPENSE_REPORT: getText('approve:expenseReportItem.expenseReport'),
};

interface IExpenseReportItem {
  item: {
    Email: string,
    Messages: any[],
    Name: string,
  },
  onClick(): void,
}

const ExpenseReportItem = ({
  item: {
    Email,
    Messages,
    Name,
  },
  onClick,
}: IExpenseReportItem) => {
  const [dialog, setDialog] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleClick = async () => {
    setLoading(true);

    await onClick();
  };

  const renderHeader = () => (
    <div className={ styles.header }>
      <Text type='NORMAL_18'>{ Name }</Text>
    </div>
  );

  const renderComments = () => {
    const addresserComment = Messages.find(item => item.Email !== Email && item.Comment);
    const messages = Messages.filter(item => !!addresserComment && !isSameDate(item.Date, addresserComment.Date) && item.Comment);

    if (!addresserComment || !Messages.length) {
      return null;
    }

    const addresserDate = formatDate(addresserComment.Date, PATTERN.FULLDATEWITHTIME);

    const comment = (item: any, i: number) => (
      <Text type='NORMAL_16_140' className={ styles.comment } key={ `comment_${item.Date}_${i}` }>
        { formatDate(item.Date, PATTERN.FULLDATEWITHTIME)}, { item.Name } - { item.Comment }
      </Text>
    );

    const renderDialog = () => dialog && (
      <Dialog
        showClosing
        show={ dialog }
        onChange={ setDialog }
      >
        <Text type='bold_20' className={ styles['dialog-header'] }>
          { LABELS.HISTORY_COMMENTS }
        </Text>
        <Text type='NORMAL_16_140' className={ styles.comment }>
          { addresserDate }, { addresserComment.Name } - { addresserComment.Comment }
        </Text>
        { messages.map(comment) }
      </Dialog>
    );

    return (
      <div
        className={ styles.comments }
        onClick={ (e) => e.stopPropagation() }
      >
        <Button
          type='textual'
          onClick={ (e) => {
            e.stopPropagation();
            setDialog(true);
          } }
        >
          { LABELS.COMMENTS }
        </Button>
        { renderDialog() }
      </div>
    );
  };

  const firstItem = Messages[0];
  const date = getDateDescriptor(firstItem.Date).toLowerCase();

  return (
    <ItemPanel
      loading={ loading }
      className={ styles.wrapper }
      renderHeader={ renderHeader }
    >
      <div className={ styles.body } onClick={ handleClick }>
        <AddresserInfoItem
          date={ date }
          email={ firstItem.Email }
          name={ firstItem.Name }
          label={ LABELS.EXPENSE_REPORT }
        />
        { renderComments() }
      </div>
    </ItemPanel>
  );
};

export { ExpenseReportItem };
