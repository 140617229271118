import ACTION from './action';
import Store from '../../store';

import { IHeaderShort, IAppStore, IUser } from '../../types/app';

const DEFAULT_HEADER: IHeaderShort = {
  CartItemsCount: 0,
  Approve: {
    Show: false,
    Count: 0,
  },
  header: {},
  CompanyFunds: [],
  NoteItemsCount: 0,
  FavoriteCount: 0,
  RequestItemsCount: 0,
};

const DEFAULT_USER: IUser = {
  email: '',
};

const createNewState = (): IAppStore => ({
  user: DEFAULT_USER,
  company: null,
  header: DEFAULT_HEADER,

  reconnect: {
    showDialog: false,
    attempt: 0,
  },

  loginDialog: false,
  appStatus: null,
});

const reducer = (action: { [key: string]: any, type: string }, state: IAppStore) => {
  switch (action.type) {
    case ACTION.UPDATEHEADER: {
      return {
        ...state,
        user: {
          email: action.payload.UserName,
        },
        company: {
          shortName: action.payload.ShortCompanyName,
        },
        header: {
          ...action.payload,
        },
      };
    }

    case ACTION.UPDATEHEADERPARTIAL: {
      return {
        ...state,
        user: {
          email: action.payload.UserName ? action.payload.UserName : state.user.email,
        },
        header: {
          ...state.header,
          ...action.payload,
        },
      };
    }

    case ACTION.UPDATEAPPSTATE: {
      return {
        ...state,
        appStatus: action.payload,
      };
    }

    case ACTION.LOGIN: {
      return {
        ...state,
        loginDialog: action.payload,
      };
    }

    case ACTION.SHOWRECONNECT: {
      return {
        ...state,
        reconnect: {
          showDialog: true,
          attempt: action.payload.reconnectAttempt,
        },
      };
    }

    case ACTION.HIDERECONNECT: {
      return {
        ...state,
        reconnect: {
          showDialog: false,
          attempt: 0,
        },
      };
    }
  }

  return state;
};

const createStore = () => new (Store as any)(reducer, createNewState());

export default createStore;
