// @ts-nocheck
import { observer } from 'mobx-react';
import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Button, Paginate, Table, PageLoader } from 'new-ui';

import { getText } from '../../../../../../i18n';

import ROUTES from '../../../../../bi/constants/routes';

import ChartsAnalytics from '../../../../../bi/services/chartsAnalytics';

import { ChartAnalyticsNoResult } from '../../noResult';

import styles from './styles.module.css';

const CANCELED = getText('chartsAnalytics:canceled');

interface TaxiNestedTableProps {
  onPageChange(page: string | number): void,
  changeVoucher(tripId: any): void,
  typeStore: any,
  isService?: boolean,
  path?: string,
  chartsAnalyticsService: ChartsAnalytics
}

const TaxiNestedTable = observer(({
  onPageChange,
  typeStore,
  isService,
  path,
  chartsAnalyticsService,
  changeVoucher,
}: TaxiNestedTableProps) => {
  const {
    subTableInfo: {
      strings,
      columnsName,
    },
    paginate: {
      currentPage,
      itemsPerPage,
      total,
    },
    loading,
  } = typeStore;

  if (loading) return <PageLoader />;

  if (!strings?.length) return <ChartAnalyticsNoResult />;

  const history = useHistory();

  const redirectToSubPage = (tripId: number, subPageInfo: any, serviceType: string) => {
    chartsAnalyticsService.changeSmartType(serviceType);
    changeVoucher(tripId);
    history.push(path);
  };

  const renderCanceled = (isCanceled, data, indexData) => {
    if (isCanceled && indexData === data.length - 1) {
      return <div className={ styles.canceled }>{ CANCELED }</div>;
    }

    return null;
  };

  const valuesOnClickHtml = (value, tripId, indexData, subPageInfo, serviceType) => (tripId && indexData === 0 && !path ? (
    <Link
      to={ `${ROUTES.TRIP.MAIN}/${tripId}` }
      target='_blank'
    >
      <Button
        className={ styles.link }
        type='textual-medium'
        onClick={ () => {} }
      >
        { value }
      </Button>
    </Link>
  ) : (
    <div className={ styles.redirect } onClick={ () => redirectToSubPage(tripId, subPageInfo, serviceType) }>{value}</div>
  ));

  const valuesHtml = (value, tripId, indexData, subPageInfo, serviceType) => (tripId ?
    valuesOnClickHtml(value, tripId, indexData, subPageInfo, serviceType)
    : <div className={ styles.route_color }>{ value }</div>);

  const valueTdClassName = isService ? '' : styles.row;
  const valueClassName = isService ? '' : styles.value_wrap;

  return (
    <>
      <div className={ styles.table }>
        <Table
          labelsHeader={ columnsName }
        >
          { strings.map(({ data, subPageInfo, subPageInfo: { isCanceled, voucherId }, serviceType }, indexRow) => (
            <tr key={ indexRow }>
              { data.map((value, indexData) =>
                <td className={ valueTdClassName } key={ indexData }>
                  <div className={ valueClassName }>
                    { valuesHtml(value, voucherId, indexData, subPageInfo, serviceType) }
                    { renderCanceled(isCanceled, data, indexData) }
                  </div>
                </td>)
              }
            </tr>
          )) }
        </Table>
      </div>
      <div className={ styles.footer }>
        <div className={ styles.paginate }>
          <Paginate
            page={ currentPage }
            itemsPerPage={ itemsPerPage }
            total={ total }
            onChange={ onPageChange }
          />
        </div>
      </div>
    </>
  );
});

export { TaxiNestedTable };
