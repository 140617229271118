import { observer } from 'mobx-react';
import { MomentInput } from 'moment';
import React from 'react';
import { Text, NoResults } from 'new-ui';

import { useServices } from '../../../../bi/context/services';

import ReportApproveDialog from '../ApproveReportDialog';
import { ApproveRequest } from '../ApproveRequest';

import { QA_ATTRIBUTES } from '../../../../bi/constants/attributesForTests';
import { APPROVE_TYPE } from '../../../../bi/constants/approve';
import { MainAnalytic } from '../../../../bi/utils/analytics';
import { LABELS } from '../../index';

import { IMultiSelectWithMainList } from '../../../../bi/types/shared';

import styles from '../../styles/index.module.css';

const EmptyList = () => (
  <NoResults label={ LABELS.NO_LIST } />
);

export const Content = observer(() => {
  const {
    reportService: {
      approveReportStore: { downloadDialog },
      downloadApproveReport,
      setDownloadReportDialog,
    },
    approveService,
    departmentsService,
  } = useServices(['Report', 'Approve', 'Departments']);

  const list = approveService.store.factualApprovesLiteResponseList;

  const handleDownloadApproveReport = (
    startDate: MomentInput, endDate: MomentInput, companies: IMultiSelectWithMainList[],
  ) => {
    downloadApproveReport(startDate, endDate, companies, APPROVE_TYPE.TRIPS);

    MainAnalytic.sendAmplitude(
      MainAnalytic.ACTIONS.APPROVEMENT.COORDINATION_TRIPS_DOWNLOAD_PRESSED,
    );
  };

  const List = list.length > 0
    ? () => list.map(item => <ApproveRequest key={ item.Id } { ...item } />)
    : EmptyList;

  return (
    <>
      <div className={ styles.list }>
        <div className={ styles.header }>
          <Text type='bold_32' qaAttr={ QA_ATTRIBUTES.approve.headerTrips }>
            { LABELS.HEADER }
          </Text>
          <ReportApproveDialog
            show={ downloadDialog.show }
            loading={ downloadDialog.loading }
            onClose={ (show: boolean) => setDownloadReportDialog(show, false) }
            onDownload={ handleDownloadApproveReport }
            companies={ departmentsService.store.departmentForSelectList }
          />
        </div>
      </div>
      <List />
    </>
  );
});
