import { TripTagsStoreType, TripTagsStore } from './store';
import { TripTagType } from './types';

class TripTags {
  store: TripTagsStoreType;
  api: any;

  constructor(api: any) {
    this.api = api;
    this.store = TripTagsStore;
  }

  loadAccountTripTags = () => {
    this.setLoading(true);

    return this.api.tripTags.loadAccountTripTags()
      .then(this.setTripTags)
      .finally(() => this.setLoading(false));
  };

  setLoading = (value: boolean) => this.store.setLoading(value);

  setTripTags = (tripTags: TripTagType[]) => {
    this.store.setTripTags(tripTags);
  };

  addTripTag = (tripTag: TripTagType) => this.store.addTripTag(tripTag);

  getAccountTripTags = () => this.store.getTripTags();

  addAccountTripTag = (tag: string) => this.api.tripTags.addAccountTripTag({ Id: 0, Value: tag });

  updateCartItemTripTags = (cartItemId: number, tags: TripTagType[]) => this.api.tripTags.updateCartItemTripTags(cartItemId, tags);

  updateTripItemTripTags = (tripItemId: number, tags: TripTagType[]) => this.api.tripTags.updateTripItemTripTags(tripItemId, tags);

  updateNoteItemTripTags = (noteItemId: number, tags: TripTagType[]) => this.api.tripTags.updateNoteItemTripTags(noteItemId, tags);

  updateEmployeeTripTags = (employeeId: number, tags: TripTagType[]) => this.api.tripTags.updateEmployeeTripTags(employeeId, tags);
}

export default TripTags;
