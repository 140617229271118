import React, { useEffect } from 'react';
import { PageLoader, Text } from 'new-ui/src';

import { observer } from 'mobx-react';
import { getText } from '../../../../../i18n';

import { useStores } from '../../../../bi/context';
import { MOBX_STORES } from '../../../../bi/context/stores';

import { IFeeConstructionComponents } from './types';
import { IFeeConstruction } from '../../../../bi/services/settings/types';

import SERVICETYPE from '../../../../bi/constants/serviceType';

import MainAnalytic from '../../../../bi/utils/analytics/main';

import { ConstructionForm } from './components/ConstructionForm';

import styles from './styles/index.module.css';

const LABELS = {
  TITLE: getText('settings:feeConstructor:header'),
  SUB_TITLE_FIRST: getText('settings:feeConstructor:subHeader.first'),
  SUB_TITLE_SECOND: getText('settings:feeConstructor:subHeader.second'),
  SUB_TITLE_THIRD: getText('settings:feeConstructor:subHeader.third'),
  FEES_FOR_AIR_TICKETS: getText('settings:feeConstructor:feesForAirTickets'),
  FEES_FOR_TRAIN: getText('settings:feeConstructor:feesForTrain'),
  FEES_FOR_HOTEL: getText('settings:feeConstructor:feesForHotel'),
};

const TYPE_FEE_VALUES = {
  RUB: 'Currency',
  PERCENT: 'Percentages',
};

const TYPE_FEE_VALUE_PROPERTIES = {
  RUB: 'rub',
  PERCENT: 'persent',
};

const FeeConstruction = observer(({
  settingsService: {
    getFeeConstruction,
    updateFeeConstruction,
  },
}: IFeeConstructionComponents) => {
  const {
    settingsStore: {
      feeConstruction, feeWaitingResponse,
    },
  } = useStores([MOBX_STORES.SETTINGS_STORE]);

  useEffect(() => {
    MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.SMART_AGENT.OPENED);
    getFeeConstruction();
  }, [getFeeConstruction]);

  if (feeWaitingResponse) {
    return <PageLoader/>;
  }

  const handleSave = (data: IFeeConstruction, isChangeForm: boolean) => {
    const { ServiceType, FeeNote, PricingType, Value } = data;

    const preparedPricingType = PricingType === TYPE_FEE_VALUES.RUB
      ? TYPE_FEE_VALUE_PROPERTIES.RUB
      : TYPE_FEE_VALUE_PROPERTIES.PERCENT;

    updateFeeConstruction(data);

    if (Value === '0') {
      return MainAnalytic.sendAmplitudeArrayArgs(
        MainAnalytic.ACTIONS.SMART_AGENT.DELETE_PRESSED({ service: ServiceType, taxobject: FeeNote, taxtype: preparedPricingType }),
      );
    }

    if (isChangeForm) {
      return MainAnalytic.sendAmplitudeArrayArgs(
        MainAnalytic.ACTIONS.SMART_AGENT.EDIT_PRESSED({ service: ServiceType, taxobject: FeeNote, taxtype: preparedPricingType }),
      );
    }

    return MainAnalytic.sendAmplitudeArrayArgs(
      MainAnalytic.ACTIONS.SMART_AGENT.SAVE_PRESSED({ service: ServiceType, taxobject: FeeNote, taxtype: preparedPricingType }),
    );
  };

  const renderConstructionFee = ({ FeeNote, Value, PricingType, ServiceType }: IFeeConstruction) => {
    const isServiceTypeAir = ServiceType === SERVICETYPE.AIR;
    const preparedName = ({
      [SERVICETYPE.AIR]: LABELS.FEES_FOR_AIR_TICKETS,
      [SERVICETYPE.TRAIN]: LABELS.FEES_FOR_TRAIN,
      [SERVICETYPE.HOTEL]: LABELS.FEES_FOR_HOTEL,
    })[ServiceType];

    return (
      <ConstructionForm
        title={ preparedName }
        typeFee={ PricingType }
        valueFee={ Value }
        applicationFee={ FeeNote }
        serviceType={ ServiceType }
        addApplication={ isServiceTypeAir }
        onSave={ handleSave }
      />
    );
  };

  const renderTitle = (
    <>
      <div>
        <Text type='bold_24'>
          { LABELS.TITLE }
        </Text>
      </div>
      <div className={ styles.sub_header_wrapper }>
        <Text type='NORMAL_14' color='gray' className={ styles.sub_header }>
          { LABELS.SUB_TITLE_FIRST }
        </Text>
        <Text type='NORMAL_14' color='gray' className={ styles.sub_header }>
          { LABELS.SUB_TITLE_SECOND }
        </Text>
        <Text type='NORMAL_14' color='gray' className={ styles.sub_header }>
          { LABELS.SUB_TITLE_THIRD }
        </Text>
      </div>
    </>
  );

  const content = feeConstruction.map(renderConstructionFee);

  return (
    <div>
      { renderTitle }
      { content }
    </div>
  );
});

export { FeeConstruction };
